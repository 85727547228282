import React, { useState, useRef } from "react";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import useOnClickOutside from "../../../../hooks/use-on-click-outside";
import { ReactComponent as SwitchIcon } from "../../../../assets/icons/switch.svg";
import { ReactComponent as GraphsIcon } from "../../../../assets/icons/data-view-graphs.svg";
import { ReactComponent as MetricsIcon } from "../../../../assets/icons/data-view-metrics.svg";
import { ReactComponent as NoneIcon } from "../../../../assets/icons/data-view-none.svg";
import { ReactComponent as GraphsAndMetricsIcon } from "../../../../assets/icons/data-view-graph&metric.svg";
import { DATA_VIEW_TYPE_MAP, DATA_VIEW_TYPE_TITLE_MAP } from "./data/constants";
import Style from "./DataViewDropdown.module.css";

export const DataViewDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dataViewDropdownContainerRef = useRef(null);
  const { selectedDataView, setSelectedDataView } = useWorkOrderTrackPageStateContext();
  useOnClickOutside(dataViewDropdownContainerRef, () => {
    setIsDropdownOpen(false);
  });

  const getDataViewIcon = (dataViewKey) => {
    switch (dataViewKey) {
      case DATA_VIEW_TYPE_MAP.graphs:
        return <GraphsIcon />;
      case DATA_VIEW_TYPE_MAP.metrics:
        return <MetricsIcon />;
      case DATA_VIEW_TYPE_MAP.graphsAndMetrics:
        return <GraphsAndMetricsIcon />;
      case DATA_VIEW_TYPE_MAP.none:
        return <NoneIcon />;
      default:
        return null;
    }
  };

  return (
    <div
      ref={dataViewDropdownContainerRef}
      className={Style.data_view_dropdown_container}
    >
      <button
        type="button"
        className={`${Style.change_data_view_button} ${isDropdownOpen && Style.change_data_view_button_active}`}
        onClick={() => {
          setIsDropdownOpen((currentValue) => {
            return !currentValue;
          });
        }}
      >
        <SwitchIcon className={Style.change_data_view_icon} />
        <p className={Style.change_data_view_text}>Change Data View</p>
      </button>
      {isDropdownOpen && (
        <div
          data-testid="data-view-dropdown-background"
          className={Style.data_view_dropdown_background}
        >
          {Object.keys(DATA_VIEW_TYPE_MAP).map((eachDataViewKey) => {
            return (
              <div
                key={eachDataViewKey}
                className={`${Style.data_view_dropdown_item} ${
                  selectedDataView === eachDataViewKey && Style.data_view_dropdown_item_active
                }`}
                onClick={() => {
                  setSelectedDataView(eachDataViewKey);
                  setIsDropdownOpen(false);
                }}
              >
                <div
                  className={`${Style.data_view_dropdown_item_icon_container} ${
                    selectedDataView === eachDataViewKey && Style.data_view_dropdown_item_icon_container_active
                  }`}
                >
                  {getDataViewIcon(eachDataViewKey)}
                </div>
                <p className={Style.data_view_dropdown_item_text}>{DATA_VIEW_TYPE_TITLE_MAP[eachDataViewKey]}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
