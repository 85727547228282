import React from "react";

import { REPORT_STATE, REPORT_STATE_DISPLAY_STRING } from "../../data/constant";
import Style from "./ReportHeader.module.css";

export const ReportHeader = ({ headerText, subHeaderText, reportStatus }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case REPORT_STATE.APPROVED:
      case REPORT_STATE.AUTO_APPROVED:
        return "#4B9910";
      case REPORT_STATE.PENDING:
      case REPORT_STATE.RETURNED:
        return "#FF3D46";
      case REPORT_STATE.STANDBY:
        return "#FF781F";
      default:
        return "#000000";
    }
  };

  return (
    <div className={`${Style.flex_column} ${Style.title_container}`}>
      <p className={Style.title}>
        {headerText}
        {reportStatus && (
          <span
            className={Style.status_text}
            style={{ color: getStatusColor(reportStatus) }}
          >
            ({REPORT_STATE_DISPLAY_STRING[REPORT_STATE[reportStatus]]})
          </span>
        )}
      </p>
      {subHeaderText && <p className={Style.sub_title}>{subHeaderText}</p>}
    </div>
  );
};
