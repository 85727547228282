import { AlertBuilder } from "./alert-builder";

const DefaultRuleConditionConfigurations = [
  {
    type: "detector_offline"
  },
  {
    type: "detector_recover"
  }
];

const ruleTypePayloadMap = {
  detector_offline: {
    tags: {
      friendlySymptom: "offline"
    },
    conditions: [
      { property: "symptom", op: "=", value: "no-pulse" },
      { property: "duration", op: ">", value: 5 }
    ]
  },
  detector_recover: {
    tags: {
      friendlySymptom: "online"
    },
    conditions: [{ property: "symptom", op: "=", value: "healthy" }]
  }
};

const findCondition = (ruleConditions, conditionConfig) => {
  const { type: configType } = conditionConfig;
  const found = ruleConditions.find((condition) => {
    const { name, tags } = condition;
    // 2 cases determine if conditions exists,
    // 1st case, by name
    // 2nd case, by tags.conditionType
    return name === configType || tags?.conditionType === configType;
  });
  return found;
};

export class DetectorAlertBuilder extends AlertBuilder {
  constructor() {
    super("detectorOffline");
  }

  /**
   * @param rules
   * @param configuration { displayName: string }
   * @return {*}
   */
  // eslint-disable-next-line class-methods-use-this
  filterRule(rules, configuration) {
    const { displayName } = configuration;
    const foundByName = rules.find((rule) => {
      return rule.name === displayName;
    });
    return foundByName;
  }

  /**
   * @param ruleClient
   * @param configuration
   * @param defaultTemplate
   * @return {Promise<object>}
   */
  // eslint-disable-next-line class-methods-use-this
  async createRule(ruleClient, configuration, defaultTemplate) {
    const { displayName, emailTemplate, ruleTemplate } = configuration;
    const { ruleEmailTemplate: defaultEmailTemplate, ruleTemplate: defaultRuleTemplate } = defaultTemplate;

    const createdRuleId = await ruleClient.createRule(
      displayName,
      "detector-issue",
      emailTemplate || defaultEmailTemplate,
      ruleTemplate || defaultRuleTemplate
    );

    return this.getRule(ruleClient, createdRuleId);
  }

  /**
   * Check if all of the conditions exist on the rule. Returns { true }
   * @param rule { ruleConditions: object[] }
   * @param configuration { conditions: { name: string, type: string}[] }
   * @return {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  hasConditionsExist(rule, configuration = {}) {
    const { conditions: requiredConditionConfigs = DefaultRuleConditionConfigurations } = configuration;
    const { ruleConditions: actualConditions = [] } = rule;

    if (requiredConditionConfigs.length !== actualConditions.length) {
      return false;
    }

    // is satisfied when all required conditions have been created, otherwise, the requirement is not satisfied.
    const satisfied = requiredConditionConfigs.reduce((state, conditionConfig) => {
      const found = findCondition(actualConditions, conditionConfig);
      return state && found;
    }, true);

    return satisfied;
  }

  /**
   * @param ruleClient
   * @param rule
   * @param configuration
   * @return {Promise<object[]>}
   */
  // eslint-disable-next-line class-methods-use-this
  async createRuleConditions(ruleClient, rule, configuration) {
    const { ruleConditions: actualConditions = [] } = rule;
    const { conditions: requiredConditionConfigs = DefaultRuleConditionConfigurations } = configuration;

    const promises = [];
    const { id: ruleId } = rule;
    requiredConditionConfigs.forEach((conditionConfig) => {
      const found = findCondition(actualConditions, conditionConfig);
      if (found) {
        return;
      }

      const { type } = conditionConfig;
      const payload = ruleTypePayloadMap[type];
      if (!payload) {
        throw new Error(`Payload for Rule Condition ${type} is not defined`);
      }

      const { tags, conditions } = payload;
      const promise = ruleClient.createRuleCondition(
        type,
        { ...tags, conditionType: type },
        ruleId,
        conditions,
        "symptom" // this field is notification strategy, the same for all conditions
      );
      promises.push(promise);
    });

    if (promises.length === 0) {
      return Promise.resolve([]);
    }

    const conditionIds = await Promise.all(promises);
    return this.getRuleConditions(ruleClient, ruleId, conditionIds);
  }
}
