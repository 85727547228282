import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageDataSourceContext from "../../contexts/work-order-track-page-data-source-context";
import useWorkOrderParser from "./hooks/use-work-order-parser";
import { TAB_OPTION_MAP } from "../../data/constants";
import { getValueByType } from "../../components/order-track-page-list-table/OrderTrackPageListTable";
import useDebounce from "../../../../hooks/use-debounce";
import DefaultWOTable from "./features/default-wo-table";
import LiveQueueWOTable from "./features/live-queue-wo-table";
import PrioritizedQueueWOTable from "./features/prioritized-queue-wo-table";
import { WO_TABLE_ID_MAP } from "./data/constants";
import { SUPPORTED_SETTING_ID_MAP } from "../wo-settings-menu/hooks/use-settings-builder/data/constants";

export const WorkOrderListTable = () => {
  const history = useHistory();
  const {
    liveQueue,
    listTable: {
      orders: { pageSize, headers }
    },
    useBacklogStatusOnly,
    settings: settingsConfig,
    unknownLocationControl
  } = useWorkOrderTrackPageConfigContext();
  const {
    selectedTab,
    selectedLocationId,
    freeTextSearchInputText,
    isWorkOrderDataListLoading,
    applySideFilterFn,
    setExportCsvFn
  } = useWorkOrderTrackPageStateContext();
  const { workOrderDataList, locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const workOrderList = useWorkOrderParser(
    workOrderDataList,
    locationTreeMap,
    useBacklogStatusOnly,
    unknownLocationControl?.properties?.id
  );
  const [exportCSVStateFn, setExportCSVStateFn] = useState(() => {});
  const [sortIdReplacementMap] = useState({ status: "statusPriority" });
  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputText, 500);

  const workOrderTableFeatureId = useMemo(() => {
    const { sortPreferenceKey } =
      settingsConfig?.optionControl?.[SUPPORTED_SETTING_ID_MAP.editQueuePriorityModal] || {};

    const { [sortPreferenceKey]: sortPreferenceAttributeListString } = locationTreeMap[selectedLocationId] || {};
    const sortPreferenceAttributeList = JSON.parse(sortPreferenceAttributeListString || "[]") || [];
    let tableId = WO_TABLE_ID_MAP.defaultWOTable;
    const { enable, locationCategories, prioritizedMode } = liveQueue;
    if (
      (enable && locationCategories === "all") ||
      locationCategories.includes(selectedLocationId ? locationTreeMap[selectedLocationId]?.category : "root")
    ) {
      tableId = WO_TABLE_ID_MAP.liveQueueWOTable;
      if (prioritizedMode) {
        tableId = WO_TABLE_ID_MAP.prioritizedQueueWOTable;
        if (!sortPreferenceAttributeList.length) {
          tableId = WO_TABLE_ID_MAP.defaultWOTable;
        }
      }
    }
    return tableId;
  }, [liveQueue, selectedLocationId, locationTreeMap, settingsConfig]);

  const filterStringList = useMemo(() => {
    return freeTextSearchInputTextDebounced
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });
  }, [freeTextSearchInputTextDebounced]);

  const filteredWorkOrderList = useMemo(() => {
    return workOrderList.filter((eachWorkOrder) => {
      let shouldInclude = true;
      if (filterStringList.length) {
        const displayString = headers.reduce((accumulator, eachHeader) => {
          return (
            accumulator + getValueByType(eachWorkOrder[eachHeader.id], eachHeader.type, eachHeader.timeFormat, true)
          );
        }, "");

        filterStringList.forEach((eachFilterString) => {
          if (!displayString.toString().toLowerCase().includes(eachFilterString.toLowerCase())) {
            shouldInclude = false;
          }
        });
      }
      return applySideFilterFn(eachWorkOrder) && shouldInclude;
    });
  }, [workOrderList, filterStringList, applySideFilterFn, headers]);

  useEffect(() => {
    if (selectedTab === TAB_OPTION_MAP.orderTab) {
      setExportCsvFn(() => {
        return exportCSVStateFn;
      });
    }
  }, [exportCSVStateFn, selectedTab]);

  const csvFileName = useMemo(() => {
    return `${locationTreeMap[selectedLocationId]?.name || "All"}-work-orders`;
  }, [selectedLocationId, locationTreeMap]);

  const WOTableComponent = useMemo(() => {
    switch (workOrderTableFeatureId) {
      case WO_TABLE_ID_MAP.prioritizedQueueWOTable:
        return PrioritizedQueueWOTable;
      case WO_TABLE_ID_MAP.liveQueueWOTable:
        return LiveQueueWOTable;
      case WO_TABLE_ID_MAP.defaultWOTable:
      default:
        return DefaultWOTable;
    }
  }, [workOrderTableFeatureId]);

  return (
    <WOTableComponent
      numItemsPerPage={pageSize}
      isLoading={isWorkOrderDataListLoading}
      headers={headers}
      dataList={filteredWorkOrderList}
      getTitleURLCallbackFn={(eachItem) => {
        return `${history.location.pathname}/detail?itemId=${eachItem.id}`;
      }}
      sortIdReplacementMap={sortIdReplacementMap}
      getExportCsvFn={(newFn) => {
        setExportCSVStateFn(() => {
          return newFn;
        });
      }}
      csvFileName={csvFileName}
    />
  );
};
