/**
 *  @typedef HeaderType
 *  @property {string} id
 *  @property {string} [type]
 */

/**
 * @typedef RowType
 * @property {string} id
 * @property {string} task_identifier
 * @property {string} outputPart_identifier
 * @property {string} outputPart_description
 * @property {string} outputPart_gauge_ts
 * @property {string} outputPart_color_ts
 * @property {number} outputPart_strechout_ts
 * @property {number} mustStartTimestamp
 * @property {number} task_scheduledDate
 * @property {number} task_dueDate
 */

/**
 * This function check if work order matched filter input by property or all property
 * @param {HeaderType[]} headerList
 * @param {string[] | number[]} filterInput
 * @param {RowType} eachRow
 * @param {string} filterProperty
 * @returns {boolean}
 */
export const isWorkOrderMatchFilterInput = (headerList, filterInput, eachRow, filterProperty = "any") => {
  let keyToCheckList = Object.values(headerList);
  if (filterProperty !== "any") {
    keyToCheckList = headerList.filter((header) => {
      return header.id === filterProperty;
    });
  }
  let match = false;
  if (!filterInput?.length || !keyToCheckList.length) {
    return true;
  }

  const lowerCaseInput = filterInput.map((eachInput) => {
    if (typeof eachInput !== "string") {
      return eachInput;
    }
    return eachInput.toLowerCase();
  });

  lowerCaseInput.forEach((input) => {
    keyToCheckList.forEach((eachKey) => {
      let newInput = input;
      const { id, type } = eachKey;
      let eachValue = eachRow[id];

      if (type && type === "date") {
        eachValue = new Date(eachValue).toLocaleDateString();
        newInput = newInput ? new Date(newInput).toLocaleDateString() : "";
      }

      if (typeof eachValue !== "string") {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(newInput)) {
        match = true;
      }
    });
  });

  return match;
};
