import React from "react";
import { AddTransferOrderFeature } from "./AddTransferOrderFeature";
import { AddTransferOrderFeatureConfigContextProvider } from "./contexts/add-transfer-order-feature-config-context/AddTransferOrderFeatureConfigContext";
import AddTransferOrderFeatureStateContextProvider from "./contexts/add-transfer-order-feature-state-context";


export default (props) => {
    return (
      <AddTransferOrderFeatureConfigContextProvider>
        <AddTransferOrderFeatureStateContextProvider>
          <AddTransferOrderFeature {...props} />
        </AddTransferOrderFeatureStateContextProvider>
      </AddTransferOrderFeatureConfigContextProvider>
    );
  };
  