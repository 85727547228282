import React, { createContext, useContext, useMemo } from "react";
import { DEFAULT_STAGE_OPTIONS, DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS, STAGES } from "../../data/constants";
import { useAppConfigProvider } from "../../../../../../../../services/soft-cache-service";
import useKioskSearchParams from "../../../../../../hooks/use-kiosk-search-params";

const TransferOrderActionsConfigContext = createContext();

const SOLUTION_ID = "kiosk";

const initialState = {
  stageOptions: DEFAULT_STAGE_OPTIONS,
  unexpectedScanTableHeaders: DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS,
  useMissingItemModal: true,
  customText: {
    transferOrder: "picklist",
    transferOrderIdentifierLabel: "Tracking #",
    transferOrderIdentifier: "tracking number",
    containerIdentifierLabel: "Pallet #",
    containerIdentifier: "pallet number",
    container: "pallet",
    itemType: "SKU",
    item: "item"
  },
  containerAssociationOptions: {
    enabled: false,
    containerTypeIdentifier: "Pallet",
    tagFieldLabel: "Pallet RFID Tag"
  },
  defaultStageId: STAGES.STAGE,
  allowUnexpectedItems: false,
  GPOScenarioMap: {},
  GPOPayloadMap: {}
};

export const useTransferOrderActionsConfigContext = () => {
  return useContext(TransferOrderActionsConfigContext);
};

export const TransferOrderActionsConfigContextProvider = ({ children }) => {
  const { actionId } = useKioskSearchParams();

  const kioskConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const configValues = useMemo(() => {
    const { config = {} } = kioskConfigProvider;
    const { transferOrderOptions = {} } = config;

    const { actionsMap: newActionsMap = {} } = config;
    const actionConfig = newActionsMap[actionId] || {};
    const { transferOrderOptions: actionTransferOrderOptions = {} } = actionConfig;

    const getConfigValue = (key) => {
      return actionTransferOrderOptions[key] ?? transferOrderOptions[key] ?? initialState[key];
    };

    // Merge custom text from action config with default custom text
    const customText = {
      ...initialState.customText,
      ...getConfigValue("customText")
    };

    return {
      stageOptions: getConfigValue("stageOptions"),
      unexpectedScanTableHeaders: getConfigValue("unexpectedScanTableHeaders"),
      useMissingItemModal: getConfigValue("useMissingItemModal"),
      containerAssociationOptions: getConfigValue("containerAssociationOptions"),
      defaultStageId: getConfigValue("defaultStageId"),
      allowUnexpectedItems: getConfigValue("allowUnexpectedItems"),
      GPOScenarioMap: getConfigValue("GPOScenarioMap"),
      GPOPayloadMap: getConfigValue("GPOPayloadMap"),
      customText
    };
  }, [kioskConfigProvider, actionId]);

  return (
    <TransferOrderActionsConfigContext.Provider value={configValues}>
      {children}
    </TransferOrderActionsConfigContext.Provider>
  );
};
