import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Div } from "../../../../../components/div";
import "./style.css";
import { FlexibleInput } from "../../../../../components/flexible-input";
import { checkPayloadAgainstPropertiesForError } from "../../../../domains/validations/validate-for-input-requirement";

export const AddLocationFormSection = ({
  providedArgument,
  properties,
  categoryName,
  onSubmit,
  submitRequestTicket,
  sectionId
}) => {
  // Theses states contain the information regarding the which fields are in violation of validation.
  //  If the property is in validation, it will be represented as { `property-name`: true } in the map.
  const [errorResultMap, setErrorResultMap] = useState({});

  const [lastHandledSubmitRequest, setLastHandledSubmitRequest] = useState(false);
  const [payload, setPayload] = useState({});
  const [vendorOptions, setVendorOptions] = useState([]);

  useEffect(() => {
    const vendorProperty = properties.find((prop) => {
      return prop.name === "vendor";
    });
    if (vendorProperty) {
      const { options } = vendorProperty;
      setVendorOptions(options);
    }
  }, [properties]);

  const onPayloadChange = useCallback((propertyName, value) => {
    setPayload((currentPayload) => {
      const cloned = { ...currentPayload };
      cloned[propertyName] = value;
      return cloned;
    });
  }, []);

  // Effect to autopopulate the vendor field based on the prefix provided
  useEffect(() => {
    const { vid, vendor } = payload;
    if (vendorOptions && vendorOptions.length) {
      const defaultOption = vendorOptions.find((option) => {
        return !option.prefix;
      });
      if (vid) {
        const option = vendorOptions
          .filter((option) => {
            return option.prefix;
          })
          .find((option) => {
            return vid.toUpperCase().startsWith(option.prefix.toUpperCase());
          });
        if (option && vendor !== option.value) {
          onPayloadChange("vendor", option.value);
          onPayloadChange("class", option.class);
        } else if (!option && vendor !== defaultOption.value) {
          onPayloadChange("vendor", defaultOption.value);
          onPayloadChange("class", defaultOption.class);
        }
      } else if (vendor !== defaultOption.value) {
        onPayloadChange("vendor", defaultOption.value);
        onPayloadChange("class", defaultOption.class);
      }
    }
  }, [payload, vendorOptions, onPayloadChange]);

  /**
   * Validate location payload against its property definitions
   * @type {function(): {errorResultMap: {}, hasError: boolean}}
   */
  const checkForPayloadError = useCallback(() => {
    // validate the location payload
    const result = checkPayloadAgainstPropertiesForError(payload, properties);
    return result;
  }, [payload, properties]);

  /**
   * Returns the payload of the form.
   * @type {function(): {}}
   */
  const getSubmitPayload = useCallback(() => {
    const completePayload = { ...payload, category: categoryName };
    return completePayload;
  }, [payload, categoryName]);

  /**
   * Name: validateAndSubmitPayload
   * This effect triggers when there is a request to validate form payload sent.
   */
  useEffect(() => {
    let cancelled = false;
    const unsubscriptionCallback = () => {
      cancelled = true;
    };

    if (!submitRequestTicket) {
      return unsubscriptionCallback;
    }

    if (lastHandledSubmitRequest === submitRequestTicket) {
      return unsubscriptionCallback;
    }

    const payloadValidationResult = checkForPayloadError();

    if (!cancelled) {
      const hasError = payloadValidationResult.hasError || payloadValidationResult.hasError;
      const completePayload = getSubmitPayload();
      const option = vendorOptions.find((option) => {
        return option.value === completePayload.vendor;
      });
      if (option) {
        completePayload.protocol = option.protocol;
      }
      onSubmit({ hasError, payload: completePayload, sectionId, submitRequestTicket });
      setErrorResultMap(payloadValidationResult.errorResultMap);
      setLastHandledSubmitRequest(submitRequestTicket);
    }

    return unsubscriptionCallback;
  }, [submitRequestTicket, onSubmit, getSubmitPayload, sectionId, lastHandledSubmitRequest, checkForPayloadError]);

  return (
    <Div className="add-location-form-section">
      {properties.map((prop) => {
        const {
          options,
          displayName,
          name,
          __addable: canEdit,
          optional,
          optionsProvided,
          autoPopulate,
          detectorProperty
        } = prop;
        const label = name === "parentId" || detectorProperty ? displayName : `${categoryName} ${displayName}`;
        const className = errorResultMap[name] ? "error-require" : "";
        let input = options;
        if (optionsProvided) {
          input = providedArgument[name];
        }
        return (
          <FlexibleInput
            label={label}
            propertyName={name}
            editable={canEdit}
            optional={optional}
            options={input}
            onPayloadChanged={onPayloadChange}
            className={className}
            key={name}
            value={payload && payload[name]}
            autoPopulate={autoPopulate}
          />
        );
      })}
    </Div>
  );
};

AddLocationFormSection.defaultProps = {
  onSubmit: () => {},
  submitRequestTicket: null,
  properties: [],
  providedArgument: {}
};

AddLocationFormSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  submitRequestTicket: PropTypes.string,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ),
  providedArgument: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    ])
  )
};
