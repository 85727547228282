import { AddPageInputTypeMap } from "../../../../data/constants";
import formatText from "../../../../utils/format-text";

/**
 *
 * @param {{type: string, value: any, outputType?: string, textFormatOptions?: string[] }} field
 */
export const getFieldValue = (field) => {
  if (!field) {
    return field;
  }

  const { value: fieldValue, outputType, type, textFormatOptions } = field;

  let value = fieldValue;

  switch (type) {
    case AddPageInputTypeMap.SEARCH_DROP_DOWN:
    case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
      value = fieldValue?.value;
      break;
    case AddPageInputTypeMap.MULTI_SELECT_DROP_DOWN:
      value = fieldValue?.map((option) => {
        return option.value;
      });
      break;
    default:
  }

  switch (outputType) {
    case "array":
      if (!Array.isArray(value) && value !== null && value !== undefined) {
        value = [value];
      }
      break;
    case "number":
      value = Number(value);
      break;
    default:
      break;
  }

  if (textFormatOptions) {
    value = formatText(value, textFormatOptions);
  }

  return value;
};
