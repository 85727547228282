import { useCallback } from "react";

export const useAutoFocus = () => {
  const autoFocusCallbackRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return autoFocusCallbackRef;
};
