import React, { useEffect, useState } from "react";
import usePackageTrackPageConfigContext from "../../contexts/package-track-page-config-context";
import usePackageTrackPageStateContext from "../../contexts/package-track-page-state-context";
import SideFilter from "../../../side-filter/components/side-filter/SideFilter";
import useSideFilterConfigBuilder from "./hooks/use-filter-configuration";

export const SideFilters = () => {
  const { sideFilter } = usePackageTrackPageConfigContext();

  const { filterConfiguration, initialFilterValue, applySideFilters, isLoading } =
    useSideFilterConfigBuilder(sideFilter);

  const [sideFilterValue, setSideFilterValue] = useState(initialFilterValue);

  const { setApplySideFilterFn } = usePackageTrackPageStateContext();

  useEffect(() => {
    setSideFilterValue(initialFilterValue);
  }, [initialFilterValue]);

  useEffect(() => {
    setApplySideFilterFn(() => {
      return (item) => {
        return applySideFilters(filterConfiguration, sideFilterValue, item);
      };
    });
  }, [sideFilterValue]);

  return (
    <SideFilter
      isLoading={isLoading}
      filterConfiguration={filterConfiguration}
      initialFilterValues={{ ...sideFilterValue }}
      onFilterChange={({ allValues }) => {
        setSideFilterValue(allValues);
      }}
    />
  );
};
