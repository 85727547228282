import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ChevLeft } from "../../assets/icons/chev-left.svg";
import { ReactComponent as ChevRight } from "../../assets/icons/chev-right.svg";
import SideNavigationBarStyle from "./SideNavigationBar.module.css";
import xemelgoStyle from "../../styles/variable";
import TabMenu from "../../components/tab-menu";

const SideNavigationBar = ({
  containerStyle,
  tabStructure,
  defaultTab,
  nestedTabListCollapsed,
  onCollapse,
  collapsed,
  hasCollapseIcon
}) => {
  const color = xemelgoStyle.theme.APP_BLUE;
  return (
    <div className={containerStyle}>
      {hasCollapseIcon && (
        <div
          className={SideNavigationBarStyle.collapse_icon_container}
          onClick={onCollapse}
        >
          {collapsed ? (
            <ChevRight
              title="chevron-icon"
              color={color}
              data-cy="sidebar-open-icon"
            />
          ) : (
            <ChevLeft
              title="chevron-icon"
              color={color}
              data-cy="sidebar-close-icon"
            />
          )}
        </div>
      )}
      <TabMenu
        tabConfiguration={tabStructure}
        defaultTab={defaultTab}
        collapsed={collapsed}
        nestedTabListCollapsed={nestedTabListCollapsed}
        classOverrides={{
          rootContainer: SideNavigationBarStyle.tab_container,
          menuGroupContainer: SideNavigationBarStyle.tab_list_style,
          menuTab: SideNavigationBarStyle.tab_style,
          menuTabChild: SideNavigationBarStyle.child_tab_style,
          focusedMenuTab: SideNavigationBarStyle.focused_tab_style,
          focusedMenuTabChildren: SideNavigationBarStyle.focused_tab_children_style
        }}
      />
    </div>
  );
};

export default SideNavigationBar;

SideNavigationBar.defaultProps = {
  containerStyle: null,
  defaultTab: null,
  nestedTabListCollapsed: {},
  onCollapse: () => {},
  collapsed: false,
  hasCollapseIcon: false
};

const menuItemShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  render: PropTypes.func,
  action: PropTypes.func
});

menuItemShape.children = PropTypes.arrayOf(menuItemShape);

SideNavigationBar.propTypes = {
  containerStyle: PropTypes.string,
  tabStructure: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(menuItemShape)
      })
    )
  }).isRequired,
  defaultTab: PropTypes.string,
  nestedTabListCollapsed: PropTypes.objectOf(PropTypes.bool),
  onCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
  hasCollapseIcon: PropTypes.bool
};
