import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { OpenInNew, Home } from "@material-ui/icons";
import BreadcrumbsComponentStyle from "./BreadcrumbsComponentV2.module.css";

const renderBreadcrumbsItem = (dataList, itemStyle) => {
  return dataList.map((each, index) => {
    return (
      <div
        key={each.id}
        className={BreadcrumbsComponentStyle.item_container}
      >
        {each.home ? (
          <Home
            data-cy-breadcrumbsv2-home-icon
            classes={{
              root:
                each.onClick && index !== dataList.length - 1
                  ? BreadcrumbsComponentStyle.home_icon
                  : BreadcrumbsComponentStyle.home_icon_inactive
            }}
            onClick={() => {
              if (each.onClick && index !== dataList.length - 1) {
                each.onClick(each.id);
              }
            }}
          />
        ) : (
          <button
            data-cy-breadcrumbsv2-item
            className={`${BreadcrumbsComponentStyle.breadcrumbs_item} ${itemStyle} ${
              index === dataList.length - 1 && BreadcrumbsComponentStyle.breadcrumbs_item_last
            }`}
            type="button"
            id={each.id}
            key={each.id}
            disabled={!each.onClick || index === dataList.length - 1}
            onClick={() => {
              if (typeof each.onClick === "function") {
                each.onClick(each.id);
              }
            }}
            style={{ opacity: 1.0 }}
          >
            {each.value}
          </button>
        )}
        {each.linkIconClickFn && (
          <div
            className={BreadcrumbsComponentStyle.item_detail_container}
            title={each.linkIconTitle}
          >
            <OpenInNew
              classes={{
                root: BreadcrumbsComponentStyle.item_type_link
              }}
              onClick={() => {
                each.linkIconClickFn(each.id);
              }}
              fontSize="inherit"
            />
          </div>
        )}
      </div>
    );
  });
};

const BreadcrumbsComponent = ({ containerStyle, componentStyle, itemStyle, componentList, dataList }) => {
  return (
    <Breadcrumbs
      separator=":"
      classes={{
        root: containerStyle,
        ol: componentStyle
      }}
    >
      {Array.isArray(componentList) && componentList.length
        ? componentList.map((each) => {
            return each;
          })
        : Array.isArray(dataList) && renderBreadcrumbsItem(dataList, itemStyle)}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
BreadcrumbsComponent.defaultProps = {
  containerStyle: BreadcrumbsComponentStyle.root,
  componentStyle: BreadcrumbsComponentStyle.ol,
  componentList: [],
  dataList: [],
  itemStyle: ""
};

BreadcrumbsComponent.propTypes = {
  containerStyle: PropTypes.string,
  componentStyle: PropTypes.string,
  componentList: PropTypes.arrayOf(PropTypes.object),
  dataList: PropTypes.arrayOf(PropTypes.object),
  itemStyle: PropTypes.string
};
