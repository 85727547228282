import { ITEM_SCAN_STATUS_MAP } from "../../../../data/constants";
import getReportStatus from "../get-report-status";

export const handleItemScans = (newItems, processedItems, itemTypeIdToReport, customText, allowUnexpectedItems) => {
  const newUnexpectedItemMap = {};
  const updatedItemTypeIds = new Set();

  newItems.forEach((item) => {
    const { vid, type, associatedWithTransferItemState } = item;

    if (processedItems[vid]) {
      return;
    }

    const { id: itemTypeId } = type;
    const report = itemTypeIdToReport[itemTypeId];

    const invalidType = !report;
    const isAssociatedWithOtherOrder = associatedWithTransferItemState?.length > 0 && !report?.epcToItemMap?.[vid];
    const extraItem =
      report && !report.epcToItemMap[vid]?.isAssociatedWithOrder && report.quantityScanned >= report.quantityTotal;

    let itemIssue = "";
    if (invalidType || extraItem || isAssociatedWithOtherOrder) {
      const typeFields = Object.entries(type).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [`itemType_${key}`]: value
        };
      }, {});

      if (invalidType) {
        itemIssue = `Invalid ${customText.itemType}`;
      } else if (extraItem) {
        itemIssue = `Extra ${customText.item}`;
      } else {
        itemIssue = `Associated with another ${customText.transferOrder}`;
      }

      newUnexpectedItemMap[vid] = {
        ...item,
        ...typeFields,
        reason: itemIssue
      };

      if (!allowUnexpectedItems) {
        return;
      }
    } else {
      report.quantityScanned++;
    }

    processedItems[vid] = true;

    if (!report) {
      return;
    }

    report.epcToItemMap[vid] = {
      id: item.itemId,
      ...report.epcToItemMap[vid],
      ...item,
      scanStatus: itemIssue ? ITEM_SCAN_STATUS_MAP.INVALID : ITEM_SCAN_STATUS_MAP.SCANNED
    };

    updatedItemTypeIds.add(itemTypeId);
  });

  updatedItemTypeIds.forEach((itemTypeId) => {
    const report = itemTypeIdToReport[itemTypeId];
    const scannedItems = Object.values(report.epcToItemMap).filter((item) => {
      return item.scanStatus?.id !== ITEM_SCAN_STATUS_MAP.NOT_SCANNED.id;
    });

    report.quantityScanned = scannedItems.length;
    report.status = getReportStatus(Object.values(report.epcToItemMap), scannedItems.length, report.quantityTotal);
  });

  return newUnexpectedItemMap;
};
