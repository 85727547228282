import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";
import getTransferOrderUpdates from "../get-transfer-order-updates";

export const publishMultiTransferOrderDetectorEvents = async (detectorId, transferOrders, itemTypeReports, stage) => {
  const publishClient = XemelgoService.getClient().getPublishClient();

  const transferOrderEvents = getTransferOrderUpdates(transferOrders, itemTypeReports, stage);
  const publishPromises = [];

  transferOrderEvents.forEach((transferOrderEvent) => {
    const { id, itemTypeEvents } = transferOrderEvent;

    itemTypeEvents.forEach((itemTypeEvent) => {
      const { sensorProfileIds, itemsEntryId } = itemTypeEvent;

      if (!sensorProfileIds.length) {
        return;
      }

      const context = {
        actions: {
          updateTransferOrder: {
            transfer_order_id: id,
            transfer_item_entry_id: itemsEntryId,
            transfer_status: stage.submitStatus
          }
        }
      };

      publishPromises.push(publishClient.publishDetectorEventInBatches(detectorId, sensorProfileIds, context));
    });
  });

  await Promise.all(publishPromises);
};
