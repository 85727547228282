export const getMapLayerOpacity = (zoom, selectedStateId = null) => {
  return [
    "let",
    "opacityByZoom",
    [
      "-",
      1,
      [
        "case",
        ["<=", ["-", zoom, ["get", "zoom"]], 0],
        ["/", ["abs", ["-", zoom, ["get", "zoom"]]], ["-", ["get", "zoom"], ["get", "minzoom"]]],
        [">", ["-", zoom, ["get", "zoom"]], 0],
        ["/", ["abs", ["-", zoom, ["get", "zoom"]]], ["-", ["get", "maxzoom"], ["get", "zoom"]]],
        0
      ]
    ],
    [
      "let",
      "finalOpacity",
      [
        "case",
        ["<=", ["var", "opacityByZoom"], 0.1],
        0.1,
        [">=", ["var", "opacityByZoom"], 0.4],
        0.4,
        ["var", "opacityByZoom"]
      ],
      [
        "case",
        [">", zoom, ["get", "maxzoom"]],
        0,
        ["any", ["==", selectedStateId === null, true], ["boolean", ["feature-state", "selected"], false]],
        ["var", "finalOpacity"],
        0.1
      ]
    ]
  ];
};
