import React, { useMemo } from "react";
import useAssetMapStateContext from "../../../../contexts/asset-map-state-context";
import PaginatedList from "../../../../../../components/PaginatedList/PaginatedList";
import Style from "../../../../AssetMap.module.css";
import useAssetMapConfigContext from "../../../../contexts/asset-map-config-context";
import { SORT_DIRECTION } from "../../../../../../data/constants";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";

export const AssetTypeCountsContent = () => {
  const { selectedLocationId, locationMap, selectedAssetTypeSet } = useAssetMapStateContext();
  const { assetTypeTableOptions } = useAssetMapConfigContext();

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      assetTypeTableOptions.headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      assetTypeTableOptions.headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [assetTypeTableOptions]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const assetTypes = useMemo(() => {
    let newAssetTypes = Object.values(locationMap[selectedLocationId]?.assetTypeMap || {});

    if (selectedAssetTypeSet.size) {
      newAssetTypes = newAssetTypes.filter((assetType) => {
        return selectedAssetTypeSet.has(assetType.properties.identifier);
      });
    }

    newAssetTypes = newAssetTypes.map((assetType) => {
      return {
        ...assetType,
        ...assetType.counts,
        ...assetType.properties
      };
    });

    newAssetTypes = newAssetTypes.filter((assetType) => {
      return assetType.totalCount > 0;
    });

    return getSortedData(newAssetTypes);
  }, [locationMap, selectedLocationId, order, orderBy, selectedAssetTypeSet]);

  const totalAssetCount = useMemo(() => {
    return assetTypes.reduce((accumulator, assetType) => {
      return accumulator + assetType.totalCount;
    }, 0);
  }, [assetTypes]);

  return (
    <div>
      <p className={Style.total_assets_text}>
        Total Assets:
        <span className={Style.total_assets_count}>{totalAssetCount.toLocaleString()}</span>
      </p>
      <PaginatedList
        header={assetTypeTableOptions.headers}
        headerContainerClassName={Style.table_header}
        renderHeader={(eachHeader) => {
          return getSortableHeader(eachHeader, {
            headerContainerClass: `${Style.table_column}`,
            className: Style.table_header_text
          });
        }}
        data={assetTypes}
        renderItem={(assetType, index) => {
          return (
            <div
              key={index}
              className={`${Style.flex_row} ${Style.table_item}`}
            >
              {assetTypeTableOptions.headers.map((eachHeader) => {
                return (
                  <div className={`${Style.flex_row} ${Style.table_column}`}>
                    {(assetType[eachHeader.id] || eachHeader.defaultValue || "-").toLocaleString()}
                  </div>
                );
              })}
            </div>
          );
        }}
        paginatorLocation="top"
        paginatorClassName={Style.paginator}
      />
    </div>
  );
};
