import { useMemo, useEffect } from "react";
import useSortableHeader from "../../../../hooks/use-sortable-header";
import useDebounce from "../../../../hooks/use-debounce";
import getValueByType from "../../features/package-list/utils/get-value-by-type";
import { exportCsv } from "../../../../common/Utilities";
import usePackageTrackPageConfigContext from "../../contexts/package-track-page-config-context";
import usePackageTrackPageStateContext from "../../contexts/package-track-page-state-context";
import { SORT_DIRECTION } from "../../data/constants";
import usePackageTrackPageDataSourceContext from "../../contexts/package-track-page-data-source-context";

export const usePackageTrackPageExportCSVHook = () => {
  const { isLocationExpandedMap, freeTextSearchInputText, setExportCsvFn } = usePackageTrackPageStateContext();
  const { locationPackageListMap } = usePackageTrackPageDataSourceContext();
  const { listTable } = usePackageTrackPageConfigContext();

  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputText, 500);

  const filterStringList = useMemo(() => {
    return freeTextSearchInputTextDebounced
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });
  }, [freeTextSearchInputTextDebounced]);

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      listTable.headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      listTable.headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.descending
    };
  }, [listTable.headers]);

  const packageList = useMemo(() => {
    return Object.keys(isLocationExpandedMap)
      .filter((eachLocationId) => {
        return isLocationExpandedMap[eachLocationId];
      })
      .reduce((accumulator, eachLocationId) => {
        return [...accumulator, ...(locationPackageListMap?.[eachLocationId] || [])];
      }, []);
  }, [locationPackageListMap, isLocationExpandedMap]);

  const { order, orderBy, getSortedData } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const finalPackageList = useMemo(() => {
    const filteredPackageList = packageList.filter((eachWorkOrder) => {
      let shouldInclude = true;
      if (filterStringList.length) {
        const displayString = listTable.headers.reduce((accumulator, eachHeader) => {
          return (
            accumulator + getValueByType(eachWorkOrder[eachHeader.id], eachHeader.type, eachHeader.timeFormat, true)
          );
        }, "");

        filterStringList.forEach((eachFilterString) => {
          if (!displayString.toString().toLowerCase().includes(eachFilterString.toLowerCase())) {
            shouldInclude = false;
          }
        });
      }
      return shouldInclude;
    });
    return getSortedData(filteredPackageList, orderBy);
  }, [packageList, packageList, order, orderBy, listTable.headers, filterStringList]);

  const buildTrackPageListTableCSVDataFn = (dataArray, headerArray) => {
    return dataArray.map((eachData) => {
      return {
        ...headerArray.reduce((accumulator, { id, label, type, timeFormat }) => {
          accumulator[label] = getValueByType(eachData[id], type, timeFormat, true);
          return accumulator;
        }, {})
      };
    });
  };

  useEffect(() => {
    const newExportCSVFn = () => {
      const csvData = buildTrackPageListTableCSVDataFn(finalPackageList, listTable.headers);

      return exportCsv(
        csvData,
        {
          header: listTable.headers.map((eachHeader) => {
            return eachHeader.label;
          }),
          cellDates: true
        },
        `packages.csv`
      );
    };
    setExportCsvFn(() => {
      return newExportCSVFn;
    });
  }, [finalPackageList]);
};
