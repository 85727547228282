export const getCreateValue = (attribute) => {
  const { type, value, label, numberOnly } = attribute;

  let finalValue;
  switch (type) {
    case "dropdown":
    case "api-dropdown":
      finalValue = value.value;
      break;
    case "checkbox":
      finalValue = value ? [label] : [];
      break;
    case "date":
    case "datetime":
    default:
      finalValue = numberOnly ? value * 1 : value;
      break;
  }
  return finalValue;
};
