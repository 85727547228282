import { getFormattedDate, getCurrentTimestamp } from "../../../common/utils";

export const AddPageInputTypeMap = {
  DATE_PICKER: "datepicker",
  INPUT: "input",
  SEARCH_DROP_DOWN: "dropdown",
  CHECK_BOX_GROUP: "checkboxGroup",
  SEARCH_DROP_DOWN_FROM_API: "dropdownWithOptionsFromAPI"
};

export function getStringWithZeroes(numOfZeroes) {
  let stringWithZeroes = "";
  for (let i = 0; i < numOfZeroes; i++) {
    stringWithZeroes += "0";
  }
  return stringWithZeroes;
}

export const generateSensorProfileVidUsingInput = (input, currentTimestamp = getCurrentTimestamp()) => {
  const dateFormat = "YYMMDDHHmmssSSS";
  if (typeof input === "string") {
    const inputValue = input ?? "";
    // remove non-hex characters and leading and trailing white spaces
    const inputString = inputValue
      .toString()
      .replace(/[^A-Fa-f0-9]/g, "")
      .trim();

    if (inputString && inputString.length > 0) {
      const formattedDate = getFormattedDate(currentTimestamp, dateFormat);
      const concatString = `${formattedDate}${inputString}`;

      if (concatString.length % 8 === 0) {
        return concatString;
      }

      const stringWithZeroes = getStringWithZeroes(8 - (concatString.length % 8));
      return `${formattedDate}${stringWithZeroes}${inputString}`;
    }
    return null;
  }
  if (Array.isArray(input)) {
    const timestampUniquenessCheckMap = {};
    return input.map((eachInput) => {
      const inputValue = eachInput ?? "";

      // remove non-hex characters and leading and trailing white spaces
      const inputString = inputValue
        .toString()
        .replace(/[^A-Fa-f0-9]/g, "")
        .trim();

      if (inputString && inputString.length > 0) {
        let formattedDate = getFormattedDate(currentTimestamp, dateFormat);
        // to account for multiple vids getting generated within the same millisecond
        while (timestampUniquenessCheckMap[formattedDate]) {
          formattedDate++;
        }
        timestampUniquenessCheckMap[formattedDate] = true;

        const concatString = `${formattedDate}${inputString}`;

        if (concatString.length % 8 === 0) {
          return concatString;
        }
        const stringWithZeroes = getStringWithZeroes(8 - (concatString.length % 8));
        return `${formattedDate}${stringWithZeroes}${inputString}`;
      }
      return null;
    });
  }
};

export const getValue = (type, value, autoGenerateValueUsingKey, formData, numberOnly) => {
  let finalValue;
  switch (type) {
    case AddPageInputTypeMap.DATE_PICKER:
      finalValue = value ? value.getTime() : null;
      break;
    case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
    case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      finalValue = value && typeof value === "object" ? value.value : value;
      break;
    case AddPageInputTypeMap.CHECK_BOX_GROUP:
      finalValue = [];
      Object.keys(value).forEach((eachId) => {
        if (value[eachId]) {
          finalValue.push(eachId);
        }
      });
      break;
    case AddPageInputTypeMap.INPUT:
      if (autoGenerateValueUsingKey) {
        if (formData[autoGenerateValueUsingKey]) {
          finalValue = generateSensorProfileVidUsingInput(
            formData[autoGenerateValueUsingKey].multiOrder
              ? formData[autoGenerateValueUsingKey].value.map((id) => {
                  return id.replace("/", "");
                })
              : formData[autoGenerateValueUsingKey].value
          );
        }
      } else if (numberOnly && !value) {
        finalValue = 0;
      } else {
        finalValue = value;
      }
      break;
    default:
      finalValue = value;
  }
  return typeof finalValue === "string" ? finalValue.trim() : finalValue;
};

/**
 * This function extract {prefix, identifier number} from the response of generateIdentifier call
 * and create new identifiers with given separator
 * @param {object} generateIdentifiersResponse
 * @param {string} identifierSeparator
 * @returns {array} generatedIdentifiers - new identifiers
 */
export const extractIdentifiersData = (generateIdentifiersResponse, identifierSeparator) => {
  const generatedIdentifiers = [];
  const { data, errors } = generateIdentifiersResponse;

  if (errors) {
    const [error] = errors;
    if (error.errorType === "ReferenceError") {
      throw "Group does not exist";
    }
    throw error;
  }

  const { generateIdentifiers } = data;
  const { data: identifierData } = generateIdentifiers;
  const { prefix } = identifierData;
  const { identifiers } = generateIdentifiers;
  const doubleDigitIdentifiers = identifiers.map((identifier) => {
    return parseInt(identifier).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  });
  doubleDigitIdentifiers.map((identifier) => {
    generatedIdentifiers.push(`${prefix}${identifierSeparator}${identifier}`);
  });

  return generatedIdentifiers;
};

/**
 * This function format the input WO number by remove white space and separate each input by each line, then
 * count their quantity
 * @param {object} identifier - hold the WO number input value
 * @returns {Map} identifiersWithQuantity - a map of identifiers and its quantity
 */
export const getIdentifiersQuantity = (identifier) => {
  const inputIdentifiers = identifier.value.trim().split("\n");
  const formattedInputIdentifiers = inputIdentifiers.map((identifier) => {
    return identifier.replace(/[\s\/]/g, "");
  });
  const identifiersWithQuantity = formattedInputIdentifiers.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
  }, {});
  return identifiersWithQuantity;
};
