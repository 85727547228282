import React from "react";
import PropTypes from "prop-types";
import CheckboxStyle from "./Checkbox.module.css";

export const Checkbox = ({ onClick, isChecked, rounded, color }) => {
  return (
    <div
      data-cy-checkbox-box
      className={`${CheckboxStyle.checkbox_outer} ${rounded && CheckboxStyle.rounded}`}
      onClick={onClick}
    >
      <div
        className={`${isChecked ? CheckboxStyle.checkbox_checked : CheckboxStyle.checkbox_unchecked} ${
          rounded && CheckboxStyle.rounded
        }`}
        style={isChecked ? { backgroundColor: color } : {}}
      />
    </div>
  );
};

Checkbox.defaultProps = {
  rounded: false,
  isChecked: false,
  onClick: () => {},
  color: ""
};

Checkbox.propTypes = {
  rounded: PropTypes.bool,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string
};
