class AlertDefaultTemplate {
  getTemplates(ruleId) {
    return templateMap[ruleId];
  }
}

const templateMap = {
  /* eslint-disable no-template-curly-in-string */
  lowStock: {
    ruleTemplate:
      "Stock count for ${this.itemType} is low at ${this.locationName}. You have ${this.itemTypeCount} left in stock, which is below the threshold of ${this.threshold}.",
    ruleEmailTemplate:
      "Stock count for <strong>${this.itemType}</strong> is low at ${this.locationName}. You have <strong>${this.itemTypeCount}</strong> left in stock, which is below the threshold of <strong>${this.threshold}</strong>."
  },
  outOfStock: {
    ruleTemplate: "Stock count for ${this.itemType} has reached 0.",
    ruleEmailTemplate: "Stock count for <strong>${this.itemType}</strong> has reached <strong>0</strong>"
  },
  optimalStock: {
    ruleTemplate:
      "Stock count for ${this.itemType} is below the optimal level at ${this.locationName}. You have ${this.itemTypeCount} left in stock, which is below the threshold of ${this.optimalThreshold}.",
    ruleEmailTemplate:
      "Stock count for <strong>${this.itemType}</strong> is below the optimal level at ${this.locationName}. You have <strong>${this.itemTypeCount}</strong> left in stock, which is below the threshold of <strong>${this.optimalThreshold}</strong>."
  },
  backlog: {
    ruleTemplate:
      "Order backlog at location ${this.locationName} has exceeded ${this.stage} backlog threshold of ${this.threshold} order(s). Current backlog: ${this.backlogCount} order(s)",
    ruleEmailTemplate:
      "Order backlog at location <strong>${this.locationName}</strong> has exceeded a <strong>${this.stage}</strong> backlog threshold of <strong>${this.threshold} order(s)</strong>. Current backlog: <strong>${this.backlogCount} order(s)</strong>."
  },
  missingItem: {
    ruleTemplate:
      '${this.itemIdentifier} has been marked as ${this.state}${this.state.toUpperCase() === "MISSING" ? (this.location && ", last seen at " + this.location) : this.location && (" at " + this.location)}.',
    ruleEmailTemplate:
      '<strong>${this.itemIdentifier}</strong> has been marked as <strong>${this.state}${this.state.toUpperCase() === "MISSING" ? (this.location && ", last seen at " + this.location) : this.location && (" at " + this.location)}</strong>.'
  },
  idle: {
    ruleTemplate:
      '${this.itemCount} ${this.itemCount > 1 ? "items have" : "item has"} been at location ${this.locationName} for more than ${this.duration} hours.',
    ruleEmailTemplate:
      "<div>The following <strong>${this.class}</strong> has been at location <strong>${this.locationName}</strong> for more than <strong>${this.duration} hours:</strong></div><div>${this.emailIdentifier}</div>"
  },
  expiration: {
    ruleTemplate: "There are items that have expired! The items that have expired are: ${this.expiredTypes}",
    ruleEmailTemplate: "There are items that have expired! The items that have expired are: ${this.expiredTypes}"
  },
  expiringSoon: {
    ruleTemplate: "The following items are expiring soon: ${this.soonToExpireTypes}",
    ruleEmailTemplate: "The following items are expiring soon: ${this.soonToExpireTypes}"
  },
  shipmentIssue: {
    ruleTemplate:
      'Item ${this.identifier} was marked with issue ${this.issueDetail} at ${this.locationName}. Additional Comments: ${this.description || "N/A"}',
    ruleEmailTemplate:
      '<div><strong>Item ${this.identifier}</strong> was marked with issue <strong>${this.issueDetail}</strong> at <strong>${this.locationName}</strong>.</div><div>Additional Comments: ${this.description || "N/A"}</div>'
  },
  shipmentVerified: {
    ruleTemplate:
      "The following item(s) were ${this.issueType} and marked as ${this.issueDetail} at ${this.locationName} by ${this.description}: ${this.itemIdentifierList}",
    ruleEmailTemplate:
      "The following item(s) were <strong>${this.issueType}</strong> and marked as <strong>${this.issueDetail}</strong> at <strong>${this.locationName}</strong> by <strong>${this.description}</strong>: ${this.itemIdentifierList}"
  },
  detectorOffline: {
    ruleTemplate: "The following detectors ${this.resourceIdentities} are now ${this.friendlySymptom}",
    ruleEmailTemplate: "The following detectors ${this.resourceIdentities} are now ${this.friendlySymptom}"
  },
  recharge: {
    ruleTemplate: "The following items need to be recharged today: ${this.refreshTypes}",
    ruleEmailTemplate: "The following items need to be recharged today: ${this.refreshTypes}"
  },
  rechargeSoon: {
    ruleTemplate: "The following items need to be recharged soon: ${this.refreshSoonTypes}",
    ruleEmailTemplate: "The following items need to be recharged soon: ${this.refreshSoonTypes}"
  },
  calibrate: {
    ruleTemplate: "The following items need to be calibrated today: ${this.refreshTypes}",
    ruleEmailTemplate: "The following items need to be calibrated today: ${this.refreshTypes}"
  },
  calibrateSoon: {
    ruleTemplate: "The following items need to be calibrated soon: ${this.refreshSoonTypes}",
    ruleEmailTemplate: "The following items need to be calibrated soon: ${this.refreshSoonTypes}"
  },
  usageLimit: {
    ruleTemplate: "${this.itemType} - ${this.itemIdentifier} was detected leaving at ${this.locationName}.",
    ruleEmailTemplate:
      "<strong>${this.itemType} - ${this.itemIdentifier}</strong> was detected leaving at <strong>${this.locationName}</strong>."
  },
  expedited: {
    ruleTemplate: "Order Number ${this.orderNumber} has been marked as Expedited.",
    ruleEmailTemplate:
      "Order Number <strong>${this.orderNumber}</strong> has been marked as <strong>Expedited</strong>."
  },
  timeExceeded: {
    ruleTemplate: "${this.smsString}",
    ruleEmailTemplate: "${this.emailString}"
  },
  lateToStart: {
    ruleTemplate: "Late To Start",
    ruleEmailTemplate: "Late To Start"
  }
};

export default new AlertDefaultTemplate();
