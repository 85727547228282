import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Tooltip from "../tooltip";
import Style from "./MetricsCard.module.css";
import xemelgoStyle from "../../styles/variable";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import "react-loading-skeleton/dist/skeleton.css";

export const MetricsCard = ({ data, primaryColor, secondaryColor, valueColor, isLoading }) => {
  const processedValue = useMemo(() => {
    if (typeof data?.value === "number") {
      return Intl.NumberFormat("en").format(data.value);
    }
    return data.value;
  }, [data.value]);

  return (
    <div
      className={Style.container}
      style={{ backgroundColor: secondaryColor }}
    >
      {!isLoading && data.description && (
        <div className={Style.info_icon_container}>
          <Tooltip
            placement="left"
            content={data.description}
          >
            <InfoIcon
              width={20}
              height={20}
              className={Style.info_icon}
            />
          </Tooltip>
        </div>
      )}
      <div className={Style.value_container}>
        {isLoading ? (
          <Skeleton width={100} />
        ) : (
          <p
            className={Style.value_text}
            style={{ color: valueColor || primaryColor }}
          >
            {processedValue || "-"}
          </p>
        )}
      </div>
      <div className={Style.label_container}>
        {isLoading ? <Skeleton width={75} /> : <p className={Style.label_text}>{data.label}</p>}
      </div>
    </div>
  );
};

MetricsCard.defaultProps = {
  data: {},
  primaryColor: xemelgoStyle.theme.APP_BLUE,
  secondaryColor: xemelgoStyle.theme.APP_LIGHTBLUE,
  valueColor: "",
  isLoading: false
};

MetricsCard.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.element, PropTypes.number, PropTypes.string]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element])
  }),
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  valueColor: PropTypes.string,
  isLoading: PropTypes.bool
};
