import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Div } from "../../../../../components/div";
import { AddResourceFormSection } from "../add-resource-form-section";
import "./style.css";
import { autoPopulatePayload, registerUpdatedPayloadToSectionPayloads } from "./utils/update-section-payload";

export const AddDetectorFormBody = ({
  properties,
  numberOfSections,
  submitTicket,
  onValidationPassed,
  creationError,
  resources
}) => {
  const [showError, setShowError] = useState(creationError);
  const [sectionIds, setSectionIds] = useState([v4()]);
  const [payloads, setPayloads] = useState([]);
  const [editedSections, setEditedSections] = useState([]);
  const [validateTicket, setValidateTicket] = useState(null);
  const error = creationError || "Action was not completed. Please review highlighted items and try again.";
  const [vendorOptions, setVendorOptions] = useState([]);
  const [defaultVendorOptionName, setDefaultVendorOptionName] = useState(null);
  const [shouldAutoPopulate, setShouldAutoPopulate] = useState(false);

  useEffect(() => {
    const vendorProperty = properties.find((prop) => {
      return prop.name === "vendor";
    });
    if (vendorProperty) {
      const { input, defaultValue, autoPopulate } = vendorProperty;
      setVendorOptions(input);
      setDefaultVendorOptionName(defaultValue);
      setShouldAutoPopulate(autoPopulate);
    }
  }, [properties]);

  /**
   * Name: resetPayloadsAndIssueValidationTicket
   * This effect is a way to ensure orders, it first clean up all the collected payloads before issue a new validation
   *  ticket
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!submitTicket) {
      return cancelCallback;
    }

    if (!cancelled) {
      setPayloads([]);
      setValidateTicket(submitTicket);
    }

    return cancelCallback;
  }, [submitTicket]);

  /**
   * Name: handle section changes
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (numberOfSections === sectionIds.length) {
      return cancelCallback;
    }

    const diff = numberOfSections - sectionIds.length;
    const addNew = diff > 0;
    const clonedSectionIds = [...sectionIds];
    let op = (list) => {
      return list.push(v4());
    };
    if (!addNew) {
      op = (list) => {
        return list.pop();
      };
    }

    for (let i = 0; i < Math.abs(diff); i++) {
      op(clonedSectionIds);
    }

    if (!cancelled) {
      setSectionIds(clonedSectionIds);
    }

    return cancelCallback;
  }, [numberOfSections, sectionIds]);

  /**
   * Name: handleSubmit
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (payloads.length !== numberOfSections) {
      return cancelCallback;
    }

    if (!cancelled) {
      payloads.forEach((payload) => {
        const option = vendorOptions.find((option) => {
          return option.value === payload.vendor;
        });
        payload.protocol = option.protocol;
      });
      onValidationPassed(payloads);
    }

    return cancelCallback;
  }, [payloads, numberOfSections, onValidationPassed, vendorOptions]);

  const onSectionValidationPassedCallback = useCallback((sectionId, payload) => {
    setPayloads((list) => {
      const cloned = [...list];
      cloned.push(payload);
      return cloned;
    });
  }, []);

  const onSectionValidationErrorCallback = useCallback(() => {
    setShowError(true);
    setValidateTicket(null);
  }, []);

  const onSectionChanged = useCallback(
    (id, propertyName, value) => {
      setEditedSections((list) => {
        const [updatedSections, index] = registerUpdatedPayloadToSectionPayloads(list, id, propertyName, value);

        if (shouldAutoPopulate) {
          const sectionPayload = updatedSections[index];
          updatedSections[index] = autoPopulatePayload(sectionPayload, vendorOptions, defaultVendorOptionName);
        }

        return updatedSections;
      });
    },
    [defaultVendorOptionName, shouldAutoPopulate, vendorOptions]
  );

  const findEditedSection = (id) => {
    return editedSections.find((section) => {
      return section.id === id;
    });
  };

  return (
    <Div className="add-location-form-body">
      {showError && (
        <Div className="error-message-group">
          <Div className="error-title">Error</Div>
          <Div className="error-message">{error}</Div>
        </Div>
      )}
      {sectionIds.map((sectionId) => {
        const section = findEditedSection(sectionId);
        return (
          <AddResourceFormSection
            sectionId={sectionId}
            key={sectionId}
            properties={properties}
            submitTicket={validateTicket}
            onValidationPassed={onSectionValidationPassedCallback}
            onValidationError={onSectionValidationErrorCallback}
            onSectionChanged={onSectionChanged}
            editedSections={editedSections}
            resources={resources}
            values={section}
          />
        );
      })}
    </Div>
  );
};

AddDetectorFormBody.defaultProps = {
  properties: [],
  numberOfSections: 1,
  submitTicket: null,
  onValidationPassed: () => {}
};

AddDetectorFormBody.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      optional: PropTypes.bool,
      optionallyDependsOn: PropTypes.arrayOf(PropTypes.string),
      input: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          flag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            defaultValue: PropTypes.bool
          })
        })
      )
    })
  ),
  numberOfSections: PropTypes.number,
  submitTicket: PropTypes.string,
  onValidationPassed: PropTypes.func
};
