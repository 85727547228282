import React, { Component } from "react";

export default class UsageLimitContent extends Component {
  render() {
    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Geofence</p>
          <p style={{ color: "#343434" }}>Send a notification if any asset triggered the geofence</p>
        </div>
      </div>
    );
  }
}
