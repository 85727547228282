import xemelgoStyle from "../../../../../../../styles/variable";
import { VALUE_TYPE_MAP } from "../../../../../../package-track-page-feature/data/constants";

export const ITEM_TYPE_STATUS_MAP = {
  complete: {
    id: "complete",
    label: "Complete",
    backgroundColor: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 3
  },
  partial: {
    id: "partial",
    label: "Partial",
    backgroundColor: xemelgoStyle.theme.STATUS_YELLOW,
    textColor: xemelgoStyle.theme.TEXT_PRIMARY,
    sortIndex: 1
  },
  extraScanned: {
    id: "extraScanned",
    label: "Extra Scanned",
    backgroundColor: xemelgoStyle.theme.STATUS_RED,
    sortIndex: 0
  },
  notScanned: {
    id: "notScanned",
    label: "Not Scanned",
    backgroundColor: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 2
  }
};

export const ITEM_SCAN_STATUS_MAP = {
  SCANNED: {
    id: "scanned",
    label: "Scanned",
    backgroundColor: xemelgoStyle.theme.STATUS_GREEN,
    sortIndex: 2
  },
  NOT_SCANNED: {
    id: "notScanned",
    label: "Not Scanned",
    backgroundColor: xemelgoStyle.theme.NEUTRAL_PRIMARY,
    sortIndex: 1
  },
  INVALID: {
    id: "invalid",
    label: "Invalid",
    backgroundColor: xemelgoStyle.theme.STATUS_RED,
    sortIndex: 0
  }
};

export const STAGES = {
  STAGE: "stage",
  SHIP: "ship",
  RECEIVE: "receive",
  VERIFY: "verify"
};

export const DEFAULT_STAGE_OPTIONS = [
  {
    index: 0,
    label: "Stage",
    value: STAGES.STAGE
  },
  {
    index: 1,
    label: "Ship",
    value: STAGES.SHIP
  },
  {
    index: 2,
    label: "Receive",
    value: STAGES.RECEIVE
  },
  {
    index: 3,
    label: "Verify",
    value: STAGES.VERIFY
  }
];

export const TRANSFER_ORDER_STATUS = {
  CREATED: "Created",
  STAGING_IN_PROGRESS: "StagingInProgress",
  STAGED: "Staged",
  SHIPPING_IN_PROGRESS: "ShippingInProgress",
  SHIPPED: "InProgress",
  RECEIVING_IN_PROGRESS: "ReceivingInProgress",
  RECEIVED: "Received",
  VERIFYING_IN_PROGRESS: "VerifyingInProgress",
  VERIFIED: "Verified",
  CANCELLED: "Cancelled"
};

export const TRANSFER_ITEM_STATE_STATUS = {
  ...TRANSFER_ORDER_STATUS,
  CREATED: undefined,
  ADDED: "Added"
};

export const TRANSFER_ORDER_STAGES = {
  [STAGES.STAGE]: {
    id: STAGES.STAGE,
    actionLabel: "stage",
    quantityName: "quantityStaged",
    submitStatus: TRANSFER_ORDER_STATUS.STAGED,
    statusLabel: "Staging in Progress"
  },
  [STAGES.SHIP]: {
    id: STAGES.SHIP,
    actionLabel: "ship",
    quantityName: "quantityInProgress",
    submitStatus: TRANSFER_ORDER_STATUS.SHIPPED,
    statusLabel: "Shipping in Progress"
  },
  [STAGES.RECEIVE]: {
    id: STAGES.RECEIVE,
    actionLabel: "receive",
    quantityName: "quantityReceived",
    submitStatus: TRANSFER_ORDER_STATUS.RECEIVED,
    statusLabel: "Receiving in Progress"
  },
  [STAGES.VERIFY]: {
    id: STAGES.VERIFY,
    actionLabel: "verify",
    quantityName: "quantityVerified",
    submitStatus: TRANSFER_ORDER_STATUS.VERIFIED,
    statusLabel: "Verification in Progress"
  }
};

export const STAGE_TO_SHOWN_ITEM_STATUSES = {
  [STAGES.STAGE]: [TRANSFER_ITEM_STATE_STATUS.ADDED],
  [STAGES.SHIP]: [
    TRANSFER_ITEM_STATE_STATUS.ADDED,
    TRANSFER_ITEM_STATE_STATUS.STAGING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.STAGED
  ],
  [STAGES.RECEIVE]: [
    TRANSFER_ITEM_STATE_STATUS.ADDED,
    TRANSFER_ITEM_STATE_STATUS.STAGING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.STAGED,
    TRANSFER_ITEM_STATE_STATUS.SHIPPING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.SHIPPED
  ],
  [STAGES.VERIFY]: [
    TRANSFER_ITEM_STATE_STATUS.ADDED,
    TRANSFER_ITEM_STATE_STATUS.STAGING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.STAGED,
    TRANSFER_ITEM_STATE_STATUS.SHIPPED,
    TRANSFER_ITEM_STATE_STATUS.SHIPPING_IN_PROGRESS,
    TRANSFER_ITEM_STATE_STATUS.RECEIVED,
    TRANSFER_ITEM_STATE_STATUS.RECEIVING_IN_PROGRESS
  ]
};

export const DEFAULT_ITEM_TYPE_DISPLAY_NAME = "Item Type";

export const DEFAULT_EPC_TABLE_HEADERS = [
  { id: "vid", label: "EPC", isNavLink: true, openLinkInNewTab: true },
  { id: "status", label: "Status", type: VALUE_TYPE_MAP.status, defaultSort: true }
];

export const QR_CODE_COLUMN_TYPE = "QRCode";

export const DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS = [
  {
    id: "itemType_identifier",
    label: DEFAULT_ITEM_TYPE_DISPLAY_NAME
  },
  {
    id: "identifier",
    label: "Item"
  },
  {
    id: "reason",
    label: "Reason"
  },
  {
    id: "vid",
    label: "",
    type: QR_CODE_COLUMN_TYPE
  }
];

export const GPO_SCENARIOS = {
  START: "start",
  UNEXPECTED_ITEM: "unexpectedItem",
  ALL_ITEMS_SCANNED: "allItemsScanned",
  MISSING_ITEM: "missingItem",
  MISSING_ITEM_ON_SUBMIT: "missingItemOnSubmit",
  UNEXPECTED_ITEM_ON_SUBMIT: "unexpectedItemOnSubmit",
  SUBMIT_START: "submitStart",
  SUBMIT_SUCCESS: "submitSuccess",
  SUBMIT_FAILURE: "submitFailure"
};
