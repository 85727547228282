import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { REPORT_STATE } from "../../../../data/constant";
import PaginatedList from "../../../../../../components/PaginatedList/PaginatedList";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";
import { useConfigurationProvider } from "../../../../../../services/soft-cache-service";
import getAliasedHeaders from "../../util/get-aliased-headers";
import Style from "./ApprovalReportChildTable.module.css";

export const ApprovalReportChildTable = ({ rows, selectable, onSelectClick, isItemSelectableFunc }) => {
  const { getSortedData, getSortableHeader, order, orderBy } = useSortableHeader();
  const reportsConfig = useConfigurationProvider().config?.features?.reports;

  const sortedData = useMemo(() => {
    return getSortedData(rows);
  }, [rows, order, orderBy]);

  const headers = useMemo(() => {
    return getAliasedHeaders(
      [
        { id: "vid", label: "EPC" },
        { id: "lotNumber", label: "Lot Number" }
      ],
      reportsConfig
    );
  }, [reportsConfig]);

  return (
    <PaginatedList
      header={headers}
      data={sortedData}
      headerContainerClassName={Style.table_header}
      selectable={selectable}
      isItemSelectableFunc={isItemSelectableFunc}
      hideSelectAll
      onSelectClick={onSelectClick}
      renderEmptyList={() => {
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container}`}>
            <p className={Style.empty_list_text}>No Rows Available</p>
          </div>
        );
      }}
      renderHeader={(eachHeader) => {
        return getSortableHeader(eachHeader, { headerContainerClass: Style.header_container });
      }}
      renderItem={(eachItem, index) => {
        return (
          <div
            key={index}
            className={`${Style.flex_row} ${Style.table_item} ${!selectable ? Style.bottom_border : null}`}
          >
            {headers.map((eachHeader, subIndex) => {
              return (
                <div
                  key={`${eachItem[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column}`}
                >
                  <p>{eachItem[eachHeader.id] || "-"}</p>
                </div>
              );
            })}
            {eachItem.state === REPORT_STATE.AUTO_APPROVED && (
              <p className={`${Style.auto_approved_text}`}>Auto Approved</p>
            )}
          </div>
        );
      }}
    />
  );
};

ApprovalReportChildTable.defaultProps = {
  selectable: false,
  onSelectClick: null,
  isItemSelectableFunc: null
};

ApprovalReportChildTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      vid: PropTypes.string,
      lotNumber: PropTypes.string
    })
  ).isRequired,
  selectable: PropTypes.bool,
  isItemSelectableFunc: PropTypes.func,
  onSelectClick: PropTypes.func
};
