export class BaseConfigurationProvider {
  constructor(config) {
    this.config = config;
  }

  /**
   * TODO: need some ways to handle if type is not as expected
   * @param fieldName
   * @param expectType
   * @returns {null|*}
   */
  getValue(fieldName, expectType = "string", defaultValue = null) {
    const value = this.config[fieldName];

    if (expectType === "array") {
      if (!Array.isArray(value)) {
        return defaultValue;
      }
      return value;
    }

    const actualType = typeof value;
    if (expectType === "object") {
      if (Array.isArray(value)) {
        return defaultValue;
      }
    }

    if (expectType !== actualType) {
      return defaultValue;
    }

    return value;
  }

  toJson() {
    return JSON.stringify(this.config);
  }

  getConfiguration() {
    return this.config;
  }
}
