import React, { useMemo, useCallback } from "react";
import { SUPPORTED_SETTING_ID_MAP } from "./data/constants";
import LocationThresholdSettingModal from "../../features/location-threshold-setting-modal";
import EditQueuePriorityModal from "../../features/edit-queue-priority-modal";

export const useSettingsBuilder = (settingsConfig, currentLocationCategory) => {
  const settingOptions = useMemo(() => {
    return (
      settingsConfig?.options?.filter(({ locationCategories, id: settingOptionId }) => {
        return (
          (locationCategories?.includes?.(currentLocationCategory) || locationCategories?.includes("all")) &&
          Object.values(SUPPORTED_SETTING_ID_MAP).includes(settingOptionId)
        );
      }) || []
    );
  }, [settingsConfig, currentLocationCategory]);

  const initialModalMapVisibilityControl = useMemo(() => {
    return settingOptions.reduce((accumulator, eachSettingOption) => {
      return { ...accumulator, [eachSettingOption.id]: false };
    }, {});
  }, [settingOptions]);

  const SettingModalsRenderer = useCallback(
    ({ modalMapVisibilityControl, onClose }) => {
      return (
        <>
          {settingOptions.map((eachSettingOption) => {
            const { id } = eachSettingOption;
            const optionControl = { ...eachSettingOption, ...settingsConfig?.optionControl?.[id] };
            switch (id) {
              case SUPPORTED_SETTING_ID_MAP.locationThresholdSettingModal:
                return (
                  <LocationThresholdSettingModal
                    key={id}
                    show={modalMapVisibilityControl[id]}
                    onClose={() => {
                      onClose(id);
                    }}
                    optionControl={optionControl}
                  />
                );
              case SUPPORTED_SETTING_ID_MAP.editQueuePriorityModal:
                return (
                  <EditQueuePriorityModal
                    key={id}
                    show={modalMapVisibilityControl[id]}
                    onClose={() => {
                      onClose(id);
                    }}
                    optionControl={optionControl}
                  />
                );
              default:
                return null;
            }
          })}
        </>
      );
    },
    [settingOptions, settingsConfig]
  );

  return { settingOptions, initialModalMapVisibilityControl, SettingModalsRenderer };
};
