import React, { useEffect, useState, useRef } from "react";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import { Modal } from "react-bootstrap";
import AddPageComponent from "../../components/add-page-component/AddPageComponent";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { XemelgoService } from "../../services/XemelgoService";
import { validCSVHeaderCheck, validCSVDataCheck, convertAsciiToHex } from "../../common/Utilities";
import { getValue, getIdentifiersQuantity, extractIdentifiersData } from "./utils/AddOrderUtils";
import { AddOrderFeatureFunctionMap, processOnboardingPageAttributes } from "../../common/commonAPICalls";
import AddPageComponentStyle from "../../components/add-page-component/AddPageComponent.module.css";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import useWorkOrderFormDataParser from "./hooks/use-work-order-form-data-parser/useWorkOrderFormDataParser";
import useCreateWorkOrderWorkflow from "./hooks/use-create-work-order-workflow/useCreateWorkOrderWorkflow";
import { useUploadWorkOrderCSVDataParser } from "./hooks/use-upload-work-order-csv-data-parser/useUploadWorkOrderCSVDataParser";
import useConfirmation from "../../hooks/use-confirmation";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  WORK_ORDER_ONBOARDING_V1,
  WORK_ORDER_ONBOARDING_V1_STEPS
} from "../../constants/mixpanel-constant/workOrderOnboardingV1";
import { AddPageInputTypeMap } from "../../data/constants";

const FEATURE_ID = "addOrder";
const PART_CONSTRAINT = "partConstraint";
const REUSE_SENSOR_PROFILE = "reuseSensorProfile";
const APP_ID = "order";

const SENSOR_PROFILE_VID_KEY = "sensorProfileVid";

const AddOrderPageFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [customAttributeMap, setCustomAttributeMap] = useState({});
  const [partConstraint, setPartConstraint] = useState({});
  const [reuseSensorProfile, setReuseSensorProfile] = useState({});
  const [generatePrintFile, setGeneratePrintFile] = useState(false);
  const [printFilePayload, setPrintFilePayload] = useState(null);
  const [printFileData, setPrintFileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const bannerMessage = useRef("");
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [uploadCsv, setUploadCsv] = useState(false);
  const [enableOnboardingToLocation, setEnableOnboardingToLocation] = useState(false);
  const [workOrderClient, setWorkOrderClient] = useState(null);
  const [locationClient, setLocationClient] = useState(null);
  const [identifierSeparator, setIdentifierSeparator] = useState("");
  const [possibleOnboardingLocationCategories, setPossibleOnboardingLocationCategories] = useState([]);
  const [sensorProfileVidCharLimit, setSensorProfileVidCharLimit] = useState(null);
  const xemelgoAppsyncClient = useXemelgoAppsyncClient();
  const [appsyncWorkOrderClient] = useState(xemelgoAppsyncClient.getWorkOrderClient());
  const [useV2API, setUseV2API] = useState(false);

  const [isDuplicatedSensorProfileModalOpen, setIsDuplicatedSensorProfileModalOpen] = useState(false);
  const [duplicatedSensorProfilesModalList, setDuplicatedSensorProfilesModalList] = useState([]);
  const { sendMixPanelEvent } = useMixpanelContext();

  const [confirmReuseSensorProfileFn, getReuseSensorProfileConfirmation] = useConfirmation();

  const { parse: generateWorkOrderPayload } = useWorkOrderFormDataParser(
    defaultAttributeMap,
    customAttributeMap,
    identifierSeparator,
    partConstraint
  );
  const { parse: generateWorkOrderPayloadCSV } = useUploadWorkOrderCSVDataParser(
    defaultAttributeMap,
    customAttributeMap
  );
  const { submit: submitWorkOrder } = useCreateWorkOrderWorkflow(reuseSensorProfile, generatePrintFile, printFileData);

  useEffect(() => {
    sendMixPanelEvent(WORK_ORDER_ONBOARDING_V1, WORK_ORDER_ONBOARDING_V1_STEPS.ENTRY);
    onLoad();
    // eslint-disable-next-line
  }, []);

  const autoGenerateWOIdentifiers = async (inputIdentifier, quantity) => {
    let generatedIdentifiers = [];
    const payload = {
      groupName: inputIdentifier,
      quantity,
      prefix: inputIdentifier,
      identifierStartingValue: 1,
      identifierEndingValue: 99
    };
    try {
      const response = await appsyncWorkOrderClient.generateIdentifiers(payload.groupName, payload.quantity);
      generatedIdentifiers = extractIdentifiersData(response, identifierSeparator);
    } catch (error) {
      if (error === "Group does not exist") {
        const result = await appsyncWorkOrderClient.createIdentifierGenerationGroup(
          payload.groupName,
          payload.prefix,
          payload.identifierStartingValue,
          payload.identifierEndingValue
        );
        const { error } = result;
        if (error) {
          throw error;
        }
        const response = await appsyncWorkOrderClient.generateIdentifiers(payload.groupName, payload.quantity);
        generatedIdentifiers = extractIdentifiersData(response, identifierSeparator);
        return generatedIdentifiers;
      }
      throw error;
    }
    return generatedIdentifiers;
  };

  const onLoad = async () => {
    setLoading(true);
    const addOrderConfiguration = configProvider.getValue(FEATURE_ID, "object");
    const newPartConstraint = configProvider.getValue(PART_CONSTRAINT, "object") || {};
    const newReuseSensorProfile = configProvider.getValue(REUSE_SENSOR_PROFILE, "object") || {};
    const {
      defaultAttributeMap: newDefaultAttributeMap = {},
      customAttributeMap: newCustomAttributeMap = {},
      uploadCsv: newUploadCsv,
      enableOnboardingToLocation: newEnableOnboardingToLocation,
      possibleOnboardingLocationCategories: newPossibleOnboardingLocationCategories = ["Department"],
      generatePrintFile: newGeneratePrintFile = false,
      printFileData: newPrintFileData = {},
      identifierSeparator: newIdentifierSeparator = "",
      sensorProfileVidCharLimit: newSensorProfileVidCharLimit = 2048,
      useV2API: newUseV2API = false
    } = addOrderConfiguration;

    const xemelgoClient = XemelgoService.getClient();
    setWorkOrderClient(xemelgoClient.getWorkOrderClient());
    setLocationClient(xemelgoClient.getLocationClient());

    // Get options from API
    const processedAttributes = await processOnboardingPageAttributes(
      newDefaultAttributeMap,
      newCustomAttributeMap,
      AddOrderFeatureFunctionMap
    );

    setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    setCustomAttributeMap(processedAttributes.customAttributeMap);
    setPartConstraint(newPartConstraint);
    setReuseSensorProfile(newReuseSensorProfile);
    setGeneratePrintFile(newGeneratePrintFile);
    setPrintFileData(newPrintFileData);
    setUploadCsv(newUploadCsv);
    setEnableOnboardingToLocation(newEnableOnboardingToLocation);
    setPossibleOnboardingLocationCategories(newPossibleOnboardingLocationCategories);
    setSensorProfileVidCharLimit(newSensorProfileVidCharLimit);
    setLoading(false);
    setIdentifierSeparator(newIdentifierSeparator);
    setUseV2API(newUseV2API);
  };

  const generateOrderPayload = async (formData) => {
    const orderPayload = { task: {} };
    const newFormData = { ...formData };

    for (const id in newFormData) {
      let autoGeneratedIdentifiers = [];
      const { value, type, autoGenerateValueUsingKey = false, multiOrder, numberOnly } = newFormData[id];
      if (id === "identifier" && multiOrder) {
        const identifiersWithQuantity = getIdentifiersQuantity(newFormData.identifier);
        const autoGenerateIdentifiersPromises = Object.entries(identifiersWithQuantity).map((data) => {
          const [identifier, occurrences] = data;
          try {
            return autoGenerateWOIdentifiers(identifier, occurrences);
          } catch (error) {
            throw error;
          }
        });
        const autoGenerateIdentifiersResults = await Promise.all(autoGenerateIdentifiersPromises).catch((error) => {
          throw error;
        });
        if (typeof autoGenerateIdentifiersResults === "object") {
          autoGenerateIdentifiersResults.forEach((result) => {
            autoGeneratedIdentifiers = autoGeneratedIdentifiers.concat(result);
          });
        }
        autoGeneratedIdentifiers = value
          .trim()
          .split("\n")
          .map((identifier) => {
            return autoGeneratedIdentifiers.splice(
              autoGeneratedIdentifiers.findIndex((generatedIdentifier) => {
                return generatedIdentifier.includes(identifier);
              }),
              1
            )[0];
          });
        newFormData.identifier.value = autoGeneratedIdentifiers;
      }
      const newValue = autoGeneratedIdentifiers.length ? autoGeneratedIdentifiers : value;

      const finalValue = getValue(type, newValue, autoGenerateValueUsingKey, newFormData, numberOnly);
      if (finalValue !== null) {
        const { propertyFor = "item" } = defaultAttributeMap[id] || customAttributeMap[id];
        if (propertyFor !== "item") {
          orderPayload[propertyFor][id] = finalValue;
        } else {
          orderPayload[id] = finalValue;
        }
      }
    }
    return orderPayload;
  };

  const onSubmit = async (formData, partData) => {
    setLoading(true);
    setLoadingMessage("Creating Order");
    const createAndAssociate = [];
    const associate = [];
    const { allow, autoDisassociationOnReuse } = reuseSensorProfile;

    if (partConstraint.quantity !== "none") {
      partData.forEach((partEntry) => {
        if (partEntry.new) {
          createAndAssociate.push({
            partNumber: partEntry.number,
            quantity: partEntry.qty * 1,
            name: partEntry.name,
            rfid: partEntry.rfid,
            reuseTags: allow,
            autoDisassociationOnReuse
          });
        } else {
          associate.push({
            id: partEntry.id,
            quantity: partEntry.qty * 1,
            rfid: partEntry.rfid,
            reuseTags: reuseSensorProfile.allow,
            autoDisassociationOnReuse
          });
        }
      });
    }
    const partsToAssociate = { createAndAssociate, associate };
    const orderPayload = await generateOrderPayload(formData).catch((error) => {
      const errorMessage = "Auto-generate work order number failed. Please try again.";
      bannerDisplayHandler(true, errorMessage);
    });
    try {
      const { identifier, onboardingLocation, task, sensorProfileVid } = orderPayload;
      delete orderPayload.onboardingLocation;
      const onboardingLocationId = enableOnboardingToLocation ? onboardingLocation : null;

      let generatedFilePayload = null;
      if (generatePrintFile) {
        const { headerToPropertyMap, printerName, templateFilePath, delimiter } = printFileData;

        if (
          !headerToPropertyMap ||
          Object.keys(headerToPropertyMap).length === 0 ||
          !printerName ||
          !templateFilePath
        ) {
          throw "Missing required config data for label printing. Please contact Xemelgo Support for resolution.";
        }

        const finalPayload = [];

        const headers = Object.keys(headerToPropertyMap);
        let isGeneratedFilePayloadDone = false;
        let currentIteration = 0;
        while (!isGeneratedFilePayloadDone) {
          const payload = {};
          let arrayLength = 1;
          headers.forEach((eachHeader) => {
            const propertyKey = headerToPropertyMap[eachHeader];
            const payloadValue = orderPayload[propertyKey];
            if (!payloadValue) {
              throw `Missing information required for label printing: ${propertyKey}. Please contact Xemelgo Support for resolution.`;
            }
            let assignedValue = "";
            if (Array.isArray(payloadValue)) {
              arrayLength = payloadValue.length;
              assignedValue = payloadValue[currentIteration];
            } else {
              assignedValue = payloadValue;
            }

            if (propertyKey === SENSOR_PROFILE_VID_KEY) {
              assignedValue = assignedValue.substring(0, sensorProfileVidCharLimit);
            }

            payload[eachHeader] = assignedValue;
          });
          finalPayload.push(payload);
          currentIteration += 1;
          if (currentIteration >= arrayLength) {
            isGeneratedFilePayloadDone = true;
          }
        }

        generatedFilePayload = {
          headers,
          payload: finalPayload,
          templateFilePath,
          printerName,
          delimiter
        };
      }
      let workOrders;
      delete orderPayload.identifier;
      if (typeof identifier === "string") {
        workOrders = [
          {
            ...orderPayload,
            identifier: identifier.toUpperCase(),
            task: { ...task, identifier: identifier.toUpperCase() }
          }
        ];
        await workOrderClient.createWorkOrderBatch(workOrders, onboardingLocationId, partsToAssociate);
        if (generatedFilePayload) {
          await workOrderClient.generateWorkOrderPrintFile(generatedFilePayload);
          bannerMessage.current = " Print command file uploaded. Label will be printed momentarily.";
        }
        bannerMessage.current = `Order(s) created.`;
      } else {
        delete orderPayload.sensorProfileVid;
        workOrders = identifier.map((workOrderIdentifier) => {
          const sensorProfile = sensorProfileVid.find((sp) =>
            /* Check against WO identifier stripped of non-hex characters
             * as the SP vid that is generated is stripped off of non-hex chars as well
             */
            {
              return sp.includes(workOrderIdentifier.trim().replace(/[^A-Fa-f0-9]/g, ""));
            }
          );
          return {
            ...orderPayload,
            sensorProfileVid: sensorProfile.substring(0, sensorProfileVidCharLimit),
            identifier: workOrderIdentifier.toUpperCase(),
            task: { ...task, identifier: workOrderIdentifier.toUpperCase() }
          };
        });
        await workOrderClient.createWorkOrderBatch(workOrders, onboardingLocationId, partsToAssociate);
        if (generatedFilePayload) {
          setPrintFilePayload(generatedFilePayload);
          await workOrderClient.generateWorkOrderPrintFile(generatedFilePayload);
          bannerMessage.current = " Print command file uploaded. Label will be printed momentarily.";
        }
        bannerMessage.current = `${identifier.length} order(s) created. ${bannerMessage.current}`;
      }
      sendMixPanelEvent(WORK_ORDER_ONBOARDING_V1, WORK_ORDER_ONBOARDING_V1_STEPS.ITEM_ONBOARD_SUCCESS, {
        item_onboarded_count: typeof identifier === "string" ? 1 : identifier.length
      });
      setBannerError(false);
    } catch (err) {
      let errorMsg = "";
      if (typeof err === "string") {
        errorMsg = err;
      } else {
        errorMsg = err.message;
      }
      bannerMessage.current = errorMsg;
      sendMixPanelEvent(WORK_ORDER_ONBOARDING_V1, WORK_ORDER_ONBOARDING_V1_STEPS.ITEM_ONBOARD_FAILED, {
        error_message: errorMsg
      });
      setBannerError(true);
    }

    setLoading(false);
    setLoadingMessage("");
    setShowBanner(true);
  };

  const formatPropValue = (propValue, attributeMap) => {
    const { type, transformInput, convertToHex, numberOnly } = attributeMap;

    let finalValue = propValue;
    switch (type) {
      case AddPageInputTypeMap.DATE_PICKER:
        finalValue = propValue ? Date.parse(propValue) : undefined;
        break;

      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        finalValue = propValue
          ? propValue.split(",").map((value) => {
              return value.trim();
            })
          : undefined;
        break;

      case AddPageInputTypeMap.INPUT:
        if (transformInput) {
          if (transformInput === "toUpperCase") {
            finalValue = propValue.toUpperCase();
            break;
          }
        }
      // eslint-disable-next-line
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
      default:
        if (numberOnly) {
          finalValue = propValue ? parseFloat(propValue) : 0;
        }
    }

    if (convertToHex) {
      finalValue = convertAsciiToHex(finalValue);
    }

    return finalValue;
  };

  const bannerDisplayHandler = (isError, prefixString, error) => {
    let errorMsg = prefixString;

    if (error) {
      errorMsg += ` - ${error.message ? error.message : error}`;
    }

    setBannerError(isError);
    bannerMessage.current = errorMsg;
    setShowBanner(true);
    setLoading(false);
    setLoadingMessage("");
  };

  const createWorkOrderWorkFlow = async (workOrdersPayload, onboardingLocationToWorkOrderMap) => {
    try {
      const { createdWorkOrderIdentifiers, printFilePayload: newPrintFilePayload } = await submitWorkOrder(
        workOrdersPayload,
        onboardingLocationToWorkOrderMap,
        (currentStateMessage) => {
          setLoadingMessage(currentStateMessage);
        },
        async (newDuplicatedSensorProfilesModalList) => {
          setDuplicatedSensorProfilesModalList([...newDuplicatedSensorProfilesModalList]);
          setIsDuplicatedSensorProfileModalOpen(true);
          return getReuseSensorProfileConfirmation();
        }
      );
      setPrintFilePayload(newPrintFilePayload);
      bannerDisplayHandler(false, `${createdWorkOrderIdentifiers.length} Order(s) created successfully.`);
      sendMixPanelEvent(
        WORK_ORDER_ONBOARDING_V1,
        workOrdersPayload.length > 1
          ? WORK_ORDER_ONBOARDING_V1_STEPS.BULK_CREATE_SUCCESS
          : WORK_ORDER_ONBOARDING_V1_STEPS.ITEM_ONBOARD_SUCCESS,
        {
          item_onboarded_count: workOrdersPayload.length
        }
      );
    } catch (error) {
      bannerMessage.current = error.message;
      bannerDisplayHandler(true, "", error);
      sendMixPanelEvent(
        WORK_ORDER_ONBOARDING_V1,
        workOrdersPayload.length > 1
          ? WORK_ORDER_ONBOARDING_V1_STEPS.BULK_CREATE_FAILED
          : WORK_ORDER_ONBOARDING_V1_STEPS.ITEM_ONBOARD_FAILED,
        {
          error_message: error.message
        }
      );
    }
  };

  const onSubmitV2 = async (formData, partData) => {
    setLoading(true);
    const { workOrdersPayload, onboardingLocationToWorkOrderMap } = await generateWorkOrderPayload(formData, partData);
    await createWorkOrderWorkFlow(workOrdersPayload, onboardingLocationToWorkOrderMap);
    setLoading(false);
  };

  const onUploadCSVSubmitV2 = async (dataList) => {
    setLoading(true);
    const { workOrdersPayload, onboardingLocationToWorkOrderMap } = await generateWorkOrderPayloadCSV(dataList);
    await createWorkOrderWorkFlow(workOrdersPayload, onboardingLocationToWorkOrderMap);
    setLoading(false);
    return dataList;
  };

  const onUploadCSVSubmit = async (dataList) => {
    const locationIdentifierToIdMap = {};
    setLoading(true);

    if (enableOnboardingToLocation) {
      try {
        const allLocations = (await locationClient.getLocationsOfCategory(possibleOnboardingLocationCategories)) || [];

        allLocations.forEach((location) => {
          if (location.identifier) {
            locationIdentifierToIdMap[location.identifier.toUpperCase()] = location.id;
          }
        });
      } catch (err) {
        bannerDisplayHandler(true, "Loading Error", err);
        sendMixPanelEvent(WORK_ORDER_ONBOARDING_V1, WORK_ORDER_ONBOARDING_V1_STEPS.BULK_CREATE_FAILED, {
          error_message: err.message
        });
        return [];
      }
    }

    const locationIdToItemListMap = {}; // for bulk user event payload
    const createPayloadByLocation = {};
    const dataListWithStatus = [];

    // Prepare createPayload
    dataList.forEach((workOrder) => {
      const orderPayload = {};
      Object.keys(workOrder).forEach((propKey) => {
        const attributeMap = defaultAttributeMap[propKey] || customAttributeMap[propKey];
        const { propertyFor } = attributeMap;
        const propValue = formatPropValue(workOrder[propKey], attributeMap);

        if (propertyFor) {
          orderPayload[propertyFor] = orderPayload[propertyFor] || {};
          orderPayload[propertyFor][propKey] = propValue;
        } else {
          orderPayload[propKey] = propValue;
        }
      });

      const locationId = locationIdentifierToIdMap[orderPayload.onboardingLocation?.toUpperCase()] || "No Location";
      if (locationId) {
        locationIdToItemListMap[locationId] = locationIdToItemListMap[locationId] || [];
        locationIdToItemListMap[locationId].push(orderPayload.identifier);
      }

      createPayloadByLocation[locationId] = createPayloadByLocation[locationId] || [];
      createPayloadByLocation[locationId].push(orderPayload);
      dataListWithStatus.push(orderPayload);
    });

    // Create Work Orders
    for (const locationId of Object.keys(createPayloadByLocation)) {
      try {
        if (locationId === "No Location") {
          await workOrderClient.createWorkOrderBatch(createPayloadByLocation[locationId]);
        } else {
          await workOrderClient.createWorkOrderBatch(createPayloadByLocation[locationId], locationId);
        }
      } catch (err) {
        bannerDisplayHandler(true, "Failed to create work order in batch.", err);
        return [];
      }
    }

    bannerDisplayHandler(false, "Order(s) created successfully.");

    return dataListWithStatus;
  };

  const bannerRightComponent = () => {
    return (
      <div
        className={AddPageComponentStyle.refresh_button}
        onClick={async () => {
          setLoading(true);
          setLoadingMessage("Reprinting Label(s)");
          await workOrderClient.generateWorkOrderPrintFile(printFilePayload);
          bannerMessage.current = "Print command file uploaded. Label will be printed momentarily.";
          setLoading(false);
        }}
      >
        <RefreshRoundedIcon fontSize="large" />
        Reprint
      </div>
    );
  };

  const DuplicatedSensorProfileModal = () => {
    return (
      <Modal
        centered
        backdrop="static"
        show={isDuplicatedSensorProfileModalOpen}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", gap: 20, flexDirection: "column" }}>
          <p>The following RFID tag(s) entered are actively tracking something else:</p>
          <b style={{ fontWeight: "bold" }}>{duplicatedSensorProfilesModalList.join(", ")}</b>
          <p>
            {reuseSensorProfile?.allow
              ? "Are you sure you want to mark the tracked work orders as complete and reuse the RFID tags?"
              : "Please use different RFID tag(s) and try again."}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="cancel-button"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              setIsDuplicatedSensorProfileModalOpen(false);
              confirmReuseSensorProfileFn(false);
            }}
          >
            {reuseSensorProfile?.allow ? "Cancel" : "OK"}
          </button>
          {reuseSensorProfile?.allow && (
            <button
              type="button"
              className="confirm-delete-button"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                setIsDuplicatedSensorProfileModalOpen(false);
                confirmReuseSensorProfileFn(true);
              }}
            >
              Confirm
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <AddPageComponent
        loading={loading}
        loadingMessage={loadingMessage}
        title="Order"
        validCSVHeaderCheck={(data) => {
          sendMixPanelEvent(WORK_ORDER_ONBOARDING_V1, WORK_ORDER_ONBOARDING_V1_STEPS.BULK_CREATE_ENTRY);
          const { valid, errorMessage } = validCSVHeaderCheck(data, defaultAttributeMap, customAttributeMap);
          if (!valid) {
            setBannerError(!valid);
            bannerMessage.current = errorMessage;
            setShowBanner(true);
          }
          return valid;
        }}
        validCSVDataCheck={(data) => {
          const { valid, errorMessage } = validCSVDataCheck(data, defaultAttributeMap, customAttributeMap);
          if (!valid) {
            setBannerError(!valid);
            bannerMessage.current = errorMessage;
            setShowBanner(true);
          }
          return valid;
        }}
        bannerError={bannerError}
        showBanner={showBanner}
        setShowBanner={setShowBanner}
        bannerMessage={bannerMessage.current}
        onCloseBanner={() => {
          setBannerError(false);
          bannerMessage.current = "";
          setShowBanner(false);
        }}
        defaultAttributeMap={defaultAttributeMap}
        customAttributeMap={customAttributeMap}
        uploadCsv={uploadCsv}
        partConstraint={partConstraint}
        onSubmit={useV2API ? onSubmitV2 : onSubmit}
        onUploadCSVSubmit={useV2API ? onUploadCSVSubmitV2 : onUploadCSVSubmit}
        bannerRightComponent={printFilePayload && bannerRightComponent()}
      />
      <DuplicatedSensorProfileModal />
    </>
  );
};

export default AddOrderPageFeature;
