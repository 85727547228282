  /**
   * Sorts an array of items based on the specified sorting criteria.
   * 
   * @param {Array} items - The array of items to be sorted.
   * @param {Array} tableHeaders - The array of table headers.
   * @param {Object} tableSort - The sorting criteria.
   * @returns {Array} - The sorted array of items.
   */
  const sortItems = (items, tableHeaders, tableSort) => {
    if (!items) {
      return [];
    }
    const copy = items.slice();
    copy.sort((a, b) => {
      // get the actual propery name from the header
      const column = tableHeaders.find((header) => {return header.header === tableSort.header}) ?? tableHeaders[0];
      const {property} = column;
      const {direction} = tableSort;
      const propertyBagA = property.endsWith("_ts") ? a.ts_properties : a;
      const propertyBagB = property.endsWith("_ts") ? b.ts_properties : b;
      const valueA = propertyBagA[property];
      const valueB = propertyBagB[property];

      if (valueA && valueB) {
        if (typeof valueA === "string" && typeof valueB === "string") {
          if (Date.parse(valueA) && Date.parse(valueB)) {
            // it is a date object
            return (new Date(valueA) < new Date(valueB) ? -1 : 1) * (direction === "asc" ? 1 : -1);
          }
          // string sort
          return direction === "asc" ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }
        // numeric sort
        return (valueA < valueB ? -1 : 1) * (direction === "asc" ? 1 : -1);
      } if (valueA) {
        return -1;
      } if (valueB) {
        return 1;
      } 
      return 0;
    });
    return copy;
  };

  export {
    sortItems
  }