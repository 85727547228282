import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useQueuePriorityWorkflowControlContext from "../../contexts/queue-priority-workflow-control-context";
import Style from "./AllLocationList.module.css";
import { ListHeader } from "../../components/list-header/ListHeader";
import { ListItem } from "../../components/list-item/ListItem";
import ListTable from "../../../../../../../../components/ListTable/ListTable";
import { STEP_ID_MAP } from "../../data/constants";
import { ReactComponent as EditIcon } from "../../../../../../../../assets/icons/edit.svg";
import useWorkOrderTrackPageDataSourceContext from "../../../../../../contexts/work-order-track-page-data-source-context";
import { ListEmpty } from "../../components/list-empty/ListEmpty";

export const AllLocationList = ({ onModalClose, optionControl }) => {
  const { sortPreferenceKey } = optionControl || {};

  const { setModalHeader, setModalFooterButtons, setCurrentStep, setSelectedLocationIds, setPreviousStep } =
    useQueuePriorityWorkflowControlContext();
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();

  const header = () => {
    return (
      <div>
        <div className={Style.header_text}>Your Sort Preferences</div>
        <p className={Style.header_subtext}>Sort preferences that have been set up for locations will show below:</p>
      </div>
    );
  };

  useEffect(() => {
    setModalHeader(header);
    setModalFooterButtons([
      {
        id: "close",
        title: "Close",
        onClick: () => {
          onModalClose();
        }
      }
    ]);
  }, []);

  const locationList = useMemo(() => {
    return Object.values(locationTreeMap)
      .map((eachLocation) => {
        return eachLocation;
      })
      .filter((eachLocation) => {
        return eachLocation[sortPreferenceKey]?.length;
      })
      .sort((a, b) => {
        return (
          a.category.localeCompare(b.category, undefined, {
            numeric: true,
            sensitivity: "base"
          }) ||
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        );
      });
  }, [locationTreeMap]);

  const headersList = [
    { id: "name", label: "Location" },
    { id: "category", label: "Type" },
    {
      id: "bulkEdit",
      label: "Group Edit",
      onClick: () => {
        if (locationList.length) {
          setPreviousStep(STEP_ID_MAP.addNewLocation);
          setCurrentStep(STEP_ID_MAP.groupedLocationList);
        }
      }
    }
  ];

  return (
    <div className={Style.container}>
      <div className={Style.list_container}>
        <ListTable
          header={headersList}
          headerContainerClassName={Style.table_header}
          renderEmptyList={() => {
            return (
              <div className={Style.list_empty_container}>
                <ListEmpty title="No sort preferences available" />
              </div>
            );
          }}
          renderHeader={(eachHeader) => {
            return (
              <ListHeader
                key={eachHeader.id}
                label={eachHeader.label}
                onClick={eachHeader.onClick}
              />
            );
          }}
          data={locationList}
          renderItem={(eachLocation) => {
            const displayList = headersList.map(({ id: headerId }) => {
              if (headerId === "bulkEdit") {
                return {
                  id: "edit",
                  label: (
                    <div
                      className={Style.edit_button}
                      onClick={() => {
                        setPreviousStep(STEP_ID_MAP.allLocationList);
                        setCurrentStep(STEP_ID_MAP.editSortPreference);
                        setSelectedLocationIds([eachLocation.id]);
                      }}
                    >
                      <EditIcon />
                    </div>
                  )
                };
              }
              return { id: `${eachLocation.id}${headerId}`, label: eachLocation[headerId] };
            });
            return (
              <ListItem
                key={eachLocation.id}
                item={displayList}
              />
            );
          }}
        />
      </div>
      <div
        className={Style.add_button}
        onClick={() => {
          setPreviousStep(STEP_ID_MAP.allLocationList);
          setCurrentStep(STEP_ID_MAP.addNewLocation);
        }}
      >
        Add new +
      </div>
    </div>
  );
};

AllLocationList.defaultProps = {
  optionControl: {},
  onModalClose: () => {}
};

AllLocationList.propTypes = {
  onModalClose: PropTypes.func,
  optionControl: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    locationCategories: PropTypes.arrayOf(PropTypes.string),
    sortPreferenceKey: PropTypes.string,
    sortPreferenceAttributeMap: PropTypes.object,
    sortPreferenceSections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        attributes: PropTypes.arrayOf(PropTypes.string)
      })
    )
  })
};
