import React, { useEffect, useMemo, useState } from "react";
import { DatePicker } from "components/DateTimePicker/DateTimePicker";
import { XemelgoService } from "services/XemelgoService";
import { CheckboxListFilter } from "components/checkbox-list-filter/CheckboxListFilter";
import { useScheduleManagementContext } from "../context/ScheduleManagementContext.context";
import Style from "../ScheduleTrackPageFeature.module.css";
import { useXemelgoClient } from "../../../services/xemelgo-service";

export const SideFilter = ({}) => {
  // useState variables
  const [showFilter, setShowFilter] = useState(true);
  const [itemTypeList, setItemTypeList] = useState(undefined);
  const [workCenterList, setWorkCenterList] = useState(undefined);
  const [filteredDate, setFilteredDate] = useState(undefined);
  // context variables
  const { setFilterInputs } = useScheduleManagementContext();
  // services
  const xemelgoClient = useXemelgoClient(); // will be use to execute API
  const itemTypeClient = xemelgoClient.getItemTypeClient();
  const [scheduleOrderClient] = useState(xemelgoClient.getScheduleOrderClient());

  const formatCheckboxItems = (unformattedList) => {
    return unformattedList
      .map((item) => {
        const { id, identifier } = item;
        return {
          id,
          label: identifier || "",
          value: false
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  };

  const handleOnCheckboxChange = (newCheckBoxList, filterType) => {
    const filterMap = {};
    const selectedItems = newCheckBoxList.filter((item) => {
      return item.value === true;
    });

    filterMap[filterType] = {
      id: "outputPart_identifier",
      value: selectedItems.map((item) => {
        return filterType === "outputPart_identifier" ? item.label : item.id;
      })
    };

    setFilterInputs((existingFilters) => {
      return {
        ...existingFilters,
        ...filterMap
      };
    });
  };

  const handleOnTimeChange = (timestamp) => {
    setFilteredDate(timestamp);

    const scheduleFilter = {
      scheduled_start_date: {
        id: "scheduled_start_date",
        value: [timestamp] || [filteredDate]
      }
    };

    setFilterInputs((existingFilters) => {
      return {
        ...existingFilters,
        ...scheduleFilter
      };
    });
  };

  const onLoad = async () => {
    try {
      const itemTypeList = await itemTypeClient.getItemTypes(["identifier", "id"]);
      setItemTypeList(formatCheckboxItems(itemTypeList));
    } catch {
      setItemTypeList([]);
    }

    try {
      const workCenterList = await scheduleOrderClient.getWorkCenters();
      setWorkCenterList(formatCheckboxItems(workCenterList));
    } catch {
      // TODO: display API errors
      setWorkCenterList([]);
    }
  };

  const sideFilterStructureMap = useMemo(() => {
    return {
      outputPart_identifier: {
        id: "outputPart_identifier",
        label: "Parts",
        checkboxList: itemTypeList,
        isLoading: itemTypeList === undefined
      },
      workCenters: {
        id: "workcenters",
        label: "Work Centers",
        checkboxList: workCenterList,
        isLoading: workCenterList === undefined
      }
    };
  }, [itemTypeList, workCenterList]);

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div className={Style.sidebar}>
      <div className={Style.side_filter_container}>
        <div className={Style.filter_title_container}>
          <p className={Style.groupby_label}>Filter By</p>
          <div
            className={Style.hide_filter}
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            {`${!showFilter ? "Show Filters" : "Hide Filters"}`}
          </div>
        </div>

        {showFilter && (
          <div>
            {Object.keys(sideFilterStructureMap).map((filterType) => {
              const { id, label, checkboxList, isLoading } = sideFilterStructureMap[filterType];
              return (
                <CheckboxListFilter
                  key={id}
                  title={label}
                  checkboxList={checkboxList}
                  onChange={(newCheckBoxList) => {
                    handleOnCheckboxChange(newCheckBoxList, filterType);
                  }}
                  isLoading={isLoading}
                />
              );
            })}
            <div className={Style.date_filter_container}>
              <p className={Style.date_filter_title}>Scheduled Date</p>
              <DatePicker
                value={filteredDate}
                onTimeChange={(timestamp) => {
                  handleOnTimeChange(timestamp);
                }}
                inputClassName={Style.date_filter_input}
                inputContainerClassName={Style.date_filter}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
