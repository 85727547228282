import React, { useState } from "react";
import PropTypes from "prop-types";
import Style from "./TagInput.module.css";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

export const TagInput = ({ values, onChange }) => {
  const [inputText, setInputText] = useState("");

  return (
    <div className={Style.container}>
      <AutoSizeTextArea
        value={inputText}
        onChangeText={setInputText}
        placeholder="Search"
        onKeyDown={(event) => {
          const trimmedInputText = inputText?.trim() || "";
          if (event.key === "Enter" && trimmedInputText && !values.includes(trimmedInputText)) {
            onChange([...values, trimmedInputText]);
            setInputText("");
          }
        }}
      />
      {!!inputText?.length && <div className={Style.hint}>Press Enter to search</div>}
      <div className={Style.tags_container}>
        {values?.map((eachValue, index) => {
          return (
            <div
              className={Style.tag}
              key={`${eachValue}${index}`}
            >
              <p className={Style.tag_text}>{eachValue}</p>
              <CloseIcon
                className={Style.remove_button}
                width={15}
                height={15}
                onClick={() => {
                  const newValues = [...values];
                  newValues.splice(index, 1);
                  onChange(newValues);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

TagInput.defaultProps = {
  values: [],
  onChange: () => {}
};

TagInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};
