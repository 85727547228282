import { getCurrentTimestamp, getFormattedDate } from "../../../../common/Utilities";

export const generateCustomIdentifier = (itemPropertiesMap, customItemIdentifierTemplate) => {
  const timestamp = getCurrentTimestamp();
  const date = getFormattedDate(timestamp, "YYMMDD");
  const time = getCurrentTime();
  const templateProperties = { ...itemPropertiesMap, date, timestamp, time };
  return new Function(`return \`${customItemIdentifierTemplate}\`;`).call(templateProperties);
};

export const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return hours + minutes + seconds;
};
