import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getPartnerDisplayValue } from "features/list-partners/ListPartnersUtil";
import { ListedItem } from "../../../../../components/collapsible-list-view/listed-item";
import { Div } from "../../../../../components/div";

import "./style.css";

export const PartnerRows = ({ partners, onPartnerSelected, focus, displayProperty }) => {
  const [selectedId, setSelectedId] = useState(null);
  const onPartnerSelectedCallback = (id) => {
    setSelectedId(id);
    onPartnerSelected(id);
  };

  useEffect(() => {
    if (!focus) {
      setSelectedId(null);
    }
  }, [focus]);

  return (
    <>
      {partners.map((partner) => {
        const { id } = partner;
        const displayValue = getPartnerDisplayValue(partner, displayProperty);
        const styleClassName = `partner-rows ${selectedId === id ? "partner-selected" : ""}`;

        return (
          <ListedItem
            className={styleClassName}
            content={<Div className="partner-name">{displayValue}</Div>}
            key={id}
            onClickCallback={() => {
              return onPartnerSelectedCallback(id);
            }}
          />
        );
      })}
    </>
  );
};

PartnerRows.defaultProps = {
  focus: false
};

PartnerRows.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      identifier: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  onPartnerSelected: PropTypes.func.isRequired,
  focus: PropTypes.bool
};
