export const getDetailsPageLink = (nodeType, subCategory, id) => {
  switch (nodeType) {
    case "item":
      switch (subCategory) {
        case "Part":
          return `/work-order/part/detail?itemId=${id}`;
        case "Traveller":
          return `/work-order/detail?itemId=${id}`;
        case "Asset":
          return `/asset/detail?itemId=${id}`;
        case "Inventory":
        case "Stock":
          return `/inventory/item/detail?itemId=${id}`;
        case "TransferOrder":
          return `/inventory/transfer-order-detail/detail?id=${id}`;
        default:
          return `/${subCategory}/detail?itemId=${id}`;
      }
    case "itemType":
    default:
      return `/item-types-management/detail?id=${id}`;
  }
};
