import React from "react";
import PropTypes from "prop-types";
import Style from "./MainAppComponent.module.css";

const MainAppComponent = ({ headerComponent, sideNavigationComponent, renderComponent, containerStyle }) => {
  return (
    <div
      className={Style.container}
      data-cy="main-app"
    >
      {headerComponent && headerComponent()}
      {sideNavigationComponent && sideNavigationComponent()}
      <div className={containerStyle}>{renderComponent()}</div>
    </div>
  );
};

export default MainAppComponent;

MainAppComponent.defaultProps = {
  containerStyle: null
};

MainAppComponent.propTypes = {
  headerComponent: PropTypes.func.isRequired,
  sideNavigationComponent: PropTypes.func.isRequired,
  renderComponent: PropTypes.func.isRequired,
  containerStyle: PropTypes.string
};
