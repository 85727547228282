import React, { Component, useState, useEffect } from "react";
import EditableTableComponent from "../../components/editable-table/EditableTableComponent";
import { XemelgoService } from "../../services/XemelgoService";

export const ShipmentVerifiedRuleContent = ({ ruleConditionsList, ruleId, onLoad, locationDataList }) => {
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [conditions, setConditions] = useState([]);

  const headers = [
    {
      displayName: "",
      id: "subscribed",
      cell: {
        input: "switch",
        display: "switch",
        modifiable: true // whether it can be edited after being added
      }
    },
    {
      displayName: "Location",
      id: "locationName",
      cell: {
        input: "dropdown",
        data: dropDownOptions,
        display: "text",
        modifiable: false // whether it can be edited after being added
      }
    }
  ];

  const parseConditions = (ruleConditionsList) => {
    let condition = ruleConditionsList.map((eachCondition) => {
      return {
        id: eachCondition.id,
        locationName: eachCondition.tags.locationName || "All Locations",
        locationId: eachCondition.tags.locationId || "All Locations",
        subscribed: eachCondition.hasSubscriptions,
        disableDelete: !eachCondition.tags.locationName,
        disableEdit: true
      };
    });

    condition = condition.sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    });
    return condition;
  };

  useEffect(() => {
    const conditionsToUpdate = parseConditions(ruleConditionsList);
    let tempDropDownOptions = locationDataList
      .map((eachLocation) => {
        return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
      })
      .sort((a, b) => {
        const aName = a.objectTypeName.toUpperCase();
        const bName = b.objectTypeName.toUpperCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
      });

    conditionsToUpdate.forEach((eachCondition) => {
      const locationInfo = locationDataList.find((location) => {
        return location.id === eachCondition.locationId;
      });
      if (locationInfo) {
        eachCondition.locationName = locationInfo.name;
      }
      tempDropDownOptions = tempDropDownOptions.filter((eachOption) => {
        return eachOption.objectTypeId !== eachCondition.locationId;
      });
    });

    setConditions(conditionsToUpdate);
    setDropDownOptions(tempDropDownOptions);
  }, [ruleConditionsList, locationDataList]);

  const buildCreateRuleConditionPayloadFromRequest = (requestPayload) => {
    const { locationName } = requestPayload;
    const selectedLocation = locationDataList.find((location) => {
      return location.name.includes(locationName);
    });
    const locationId = selectedLocation.id;

    const tags = {
      locationName,
      locationId
    };
    const conditions = [
      {
        property: "locationId",
        op: "=",
        value: tags.locationId
      }
    ];
    return { tags, conditions };
  };

  const buildUpdateRuleConditionPayloadFromRequest = (requestPayload) => {
    const tags = {
      locationId: requestPayload.locationId,
      locationName: requestPayload.locationName
    };
    const conditions = [
      {
        property: "locationId",
        op: "=",
        value: tags.locationId
      }
    ];
    return { id: requestPayload.id, tags, conditions };
  };

  const handleRuleConditionRequests = async (requests) => {
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    if (!requests || requests.length === 0) {
      return;
    }

    await Promise.all(
      requests.map(async (request) => {
        const event = request._event;
        let payload;
        switch (event) {
          case "pending_new":
            payload = buildCreateRuleConditionPayloadFromRequest(request);
            await RulePageClient.createRuleCondition(
              `shipment_verification_${payload.tags.locationName.replace(/[^\w\s]/gi, "_")}_${payload.tags.locationId}`,
              payload.tags,
              ruleId,
              payload.conditions,
              "no_repeat"
            );
            break;
          case "pending_update":
            payload = buildUpdateRuleConditionPayloadFromRequest(request);
            await RulePageClient.updateRuleCondition(payload.id, undefined, payload.tags, payload.conditions);
            break;
          case "pending_delete":
            await RulePageClient.removeRuleCondition(request.id, false);
            break;
          default:
            console.log(`Unsupported Event[Name:${event}]`);
            break;
        }
      })
    );

    await onLoad();
    return conditions.map((condition) => {
      return condition.id;
    });
  };

  const handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    const ruleCondition = ruleConditionsList.find((condition) => {
      return condition.id === ruleConditionId;
    });
    const isLocationCondition = ruleCondition.tags.locationId;
    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      subscribedRuleConditionIdList.push(ruleConditionId);
      if (isLocationCondition) {
        const allLocationRuleCondition = ruleConditionsList.find((each) => {
          return !each.tags.locationId;
        });
        unsubscribedRuleConditionIdList.push(allLocationRuleCondition.id);
      } else {
        const listOfCondition = ruleConditionsList.filter((each) => {
          return each.id !== ruleConditionId;
        });
        listOfCondition.forEach((each) => {
          unsubscribedRuleConditionIdList.push(each.id);
        });
      }
    } else {
      unsubscribedRuleConditionIdList.push(ruleConditionId);
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    await RulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await onLoad();
  };

  return (
    <div>
      <div>
        <p className="tabbedSectionComponent-contentTitle">Shipment Verified</p>
        <p style={{ color: "#343434" }}>Sends a notification when a Received Shipment is Verified.</p>
      </div>
      <EditableTableComponent
        handleSubscriptionChange={handleSubscriptionChange}
        headers={headers}
        dataList={conditions}
        handleChangesFn={handleRuleConditionRequests}
      />
    </div>
  );
};
