export const generateMovePayload = (activeItems, targetLocationId) => {
  const tagsToMove = [];
  const movePayload = activeItems
    .filter((item) => {
      return item.hasExited || item.locationId !== targetLocationId;
    })
    .map((item) => {
      if (item.vid) {
        tagsToMove.push(item.vid);
      }
      return item.trackingSessionId;
    });
  return { movePayload, tagsToMove };
};
