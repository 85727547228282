import { TIME_IN_MS } from "../../../../common/Utilities";

export const getStatusByExpiredTime = (expiredTime) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate = currentDate.getTime();
  if (!expiredTime) {
    return "EmptyLocation";
  }
  let status = "Healthy";

  const daysToExpired = (expiredTime - currentDate) / TIME_IN_MS.DAYS;
  if (daysToExpired < 0) {
    status = "Critical";
  } else if (daysToExpired <= 7) {
    status = "Warning";
  }
  return status;
};

export const getLocationStatus = (locationData) => {
  const locationStatusDate = locationData.assets.reduce((prevDate, asset) => {
    const { refresh_date: refreshDate, expiry_date: expiryDate } = asset;
    const calibrationDate = refreshDate || expiryDate;
    if (calibrationDate && (!prevDate || calibrationDate < prevDate)) {
      return calibrationDate;
    }
    return prevDate;
  }, null);
  return getStatusByExpiredTime(locationStatusDate);
};
