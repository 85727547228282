import React from "react";
import { Page, Text, Image, View, Document, StyleSheet, BlobProvider } from "@react-pdf/renderer";
import { getFormattedDate } from "../../../common/Utilities";

const inputPartsTableHeaders = [
  {
    id: "identifier",
    index: 0,
    label: "Part #",
    width: "25%"
  },
  {
    id: "description",
    index: 1,
    label: "Description",
    width: "30%"
  },
  {
    id: "rank",
    index: 2,
    label: "Option",
    width: "10%"
  },
  {
    id: "quantity",
    index: 3,
    label: "Qty",
    width: "12%"
  },
  {
    id: "on_hand_quantity",
    index: 4,
    label: "On-hand Qty",
    width: "23%"
  }
];
const operationsTableHeadersEmpty = [
  {
    id: "rank",
    index: 0,
    label: "#",
    width: "5%"
  },
  {
    id: "name",
    index: 1,
    label: "Step Name",
    width: "20%"
  },
  {
    id: "date_run",
    index: 2,
    label: "Date Run",
    width: "20%"
  },
  {
    id: "quantity",
    index: 3,
    label: "Quantity",
    width: "12%"
  },
  {
    id: "machine",
    index: 4,
    label: "Machine",
    width: "25%"
  },
  {
    id: "operator",
    index: 5,
    label: "Operator",
    width: "20%"
  }
];
const operationsTableHeaders = [
  {
    id: "rank",
    index: 0,
    label: "Step #",
    width: "20%"
  },
  {
    id: "name",
    index: 1,
    label: "Step Name",
    width: "25%"
  },
  {
    id: "description",
    index: 2,
    label: "Instructions",
    width: "55%"
  }
];

const WorkOrderPDF = ({ customerLogo, taskData, taskBOMData, taskRoutingData, outputPartData }) => {
  return (
    <Document>
      <Page
        size="A4"
        style={styles.body}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 20,
            borderBottom: "1px solid grey"
          }}
        >
          {customerLogo ? (
            <Image
              style={styles.logo_image}
              src={customerLogo}
            />
          ) : null}
          <View>
            <Text style={{ fontSize: 20, fontWeight: 800 }}>WORK ORDER</Text>
            {taskData?.status_flags.length && !taskData?.status_flags.includes("Expedited") ? (
              <Text style={styles.expedited_text}>Expedited!!</Text>
            ) : null}
          </View>
        </View>

        <View style={styles.header}>
          <Text style={{ fontSize: 11, color: "#FFF" }}>{`W.O# ${taskData?.identifier}`}</Text>
          <Text style={{ fontSize: 11, color: "#FFF" }}>
            {`Due Date: ${taskData?.due_date ? getFormattedDate(taskData.due_date, "MMMM Do, YYYY") : "N/A"}`}
          </Text>
        </View>

        <View style={{ backgroundColor: "#F6F7F9", padding: 12 }}>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <View>
              <Text style={{ padding: 3, fontSize: 10 }}>{taskData?.location_ts || "-"}</Text>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ padding: 3, fontSize: 10 }}>{`Part #: ${outputPartData?.identifier}`}</Text>
                <Text style={{ padding: 3, fontSize: 10 }}>{`Qty: ${outputPartData?.quantity}`}</Text>
              </View>
              <View style={{ marginLeft: 15, paddingTop: 8, paddingBottom: 8 }}>
                <Text style={{ padding: 2, fontSize: 10 }}>{`Description: ${outputPartData?.description || "-"}`}</Text>
                <Text style={{ padding: 2, fontSize: 10 }}>{`Gauge: ${outputPartData?.gauge_ts || "-"}`}</Text>
                <Text style={{ padding: 2, fontSize: 10 }}>{`Stretch Out: ${outputPartData?.stretch_ts || "-"}`}</Text>
              </View>
              <Text style={{ padding: 3, fontSize: 10 }}>
                {`Start Date & Time: ${
                  taskData?.start_date ? getFormattedDate(taskData.start_date, "MMMM Do, YYYY") : "N/A"
                }`}
              </Text>
              <Text style={{ padding: 3, fontSize: 10 }}>
                {`Est. Date & Time: ${
                  taskData?.estimated_date ? getFormattedDate(taskData.estimated_date, "MMMM Do, YYYY") : "N/A"
                }`}
              </Text>
            </View>
            <View style={{ paddingBottom: 9 }}>
              {outputPartData?.image_path ? (
                <Image
                  src={outputPartData.image_path}
                  style={{ maxHeight: 300, maxWidth: 250, padding: 3 }}
                />
              ) : null}
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderTop: "1px solid grey",
              paddingTop: 3
            }}
          >
            <Text style={{ padding: 3, fontSize: 10 }}>{`Sticker: ${outputPartData?.sticker_ts || "N/A"}`}</Text>
            <Text style={{ padding: 3, fontSize: 10 }}>{`Tape: ${outputPartData?.tape_ts || "N/A"}`}</Text>
          </View>
        </View>

        <View
          wrap={false}
          style={{ marginTop: 20 }}
        >
          <View style={styles.table_header_row}>
            {inputPartsTableHeaders?.map((header) => {
              return <Text style={{ ...styles.table_header_cell, width: header?.width }}>{header?.label}</Text>;
            })}
          </View>
          {taskBOMData?.inputParts?.map((inputPart, index) => {
            return (
              <View
                style={{
                  ...styles.table_data_row,
                  backgroundColor: index % 2 === 0 ? "#FFF" : "#F6F7F9"
                }}
              >
                {inputPartsTableHeaders?.map((header) => {
                  return (
                    <Text style={{ ...styles.table_data_cell, width: header?.width }}>
                      {inputPart[header?.id] === null || inputPart[header?.id] === undefined
                        ? ""
                        : inputPart[header?.id]}
                    </Text>
                  );
                })}
              </View>
            );
          })}
        </View>

        <View
          wrap={false}
          style={{ marginTop: 20 }}
        >
          <View style={styles.table_header_row}>
            {operationsTableHeadersEmpty?.map((header) => {
              return <Text style={{ ...styles.table_header_cell, width: header?.width }}>{header?.label}</Text>;
            })}
          </View>
          {taskRoutingData?.operations?.map((operation, index) => {
            return (
              <View
                style={{
                  ...styles.table_data_row,
                  backgroundColor: index % 2 === 0 ? "#FFF" : "#F6F7F9"
                }}
              >
                {operationsTableHeadersEmpty?.map((header) => {
                  return (
                    <Text style={{ ...styles.table_data_cell, width: header?.width }}>
                      {operation[header?.id] === null || operation[header?.id] === undefined
                        ? ""
                        : operation[header?.id]}
                    </Text>
                  );
                })}
              </View>
            );
          })}
        </View>
        <View style={{ marginTop: 20 }}>
          <View style={{ ...styles.table_header_row, padding: 8 }}>
            <Text style={{ fontSize: 10, color: "#FFF" }}>Remarks</Text>
          </View>
          <View style={{ height: 50, backgroundColor: "#F6F7F9", padding: 8 }}>
            <Text style={{ fontSize: 10 }}>{taskBOMData?.remarks}</Text>
          </View>
        </View>
      </Page>
      <Page
        size="A4"
        style={styles.body}
      >
        <View
          wrap={false}
          style={{ marginTop: 20 }}
        >
          <View style={styles.table_header_row}>
            {operationsTableHeaders?.map((header) => {
              return <Text style={{ ...styles.table_header_cell, width: header?.width }}>{header?.label}</Text>;
            })}
          </View>
          {taskRoutingData?.operations?.map((operation, index) => {
            return (
              <View
                style={{
                  ...styles.table_data_row,
                  backgroundColor: index % 2 === 0 ? "#FFF" : "#F6F7F9"
                }}
              >
                {operationsTableHeaders?.map((header) => {
                  return (
                    <Text style={{ ...styles.table_data_cell, width: header?.width }}>
                      {operation[header?.id] === null || operation[header?.id] === undefined
                        ? ""
                        : operation[header?.id]}
                    </Text>
                  );
                })}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default WorkOrderPDF;

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald"
  },
  logo_image: {
    width: 150
  },
  expedited_text: {
    paddingTop: 2,
    color: "#E56201"
  },
  header: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#000",
    justifyContent: "space-between",
    padding: 8,
    marginTop: 10
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  table_header_row: {
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#000",
    justifyContent: "flex-start"
  },
  table_header_cell: {
    borderRight: "1px solid #FFF",
    width: "5%",
    padding: 8,
    fontSize: 10,
    color: "#FFF"
  },
  table_data_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  table_data_cell: {
    borderRight: "1px solid #FFF",
    width: "5%",
    padding: 8,
    fontSize: 10
  }
});
