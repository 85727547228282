import React, { Component } from "react";
import "./Node.css";

class Node extends Component {
  /**
   * Adds the relevant style classes to the
   * current node
   */
  stateClass() {
    // Set default style class to node
    let styleClass = "react_journey__node";

    // if this isn't the last node, add edge for the next node
    styleClass += ` ${this.props.intermediate}`;

    // if the user has finished this step,
    // show the node as done, missing, or misrouted based on status
    if (this.props.done) {
      if (this.props.is_shipping !== "undefined" ? this.props.is_shipping : false) {
        if (this.props.status === "Missing") {
          styleClass += " react_journey__missing_shipping";
        } else if (this.props.status === "Misroute") {
          styleClass += " react_journey__misrouted_shipping";
        } else {
          styleClass += " react_journey__done_shipping";
        }
      } else if (this.props.current) {
        if (this.props.status === "Missing") {
          styleClass += " react_journey__missing_current";
        } else if (this.props.status === "Misroute") {
          styleClass += " react_journey__misrouted_current";
        } else {
          styleClass += " react_journey__done_current";
        }
      } else if (this.props.status === "Missing") {
        styleClass += " react_journey__missing";
      } else if (this.props.status === "Misroute") {
        styleClass += " react_journey__misrouted";
      } else {
        styleClass += " react_journey__done";
      }
    }
    if (this.props.stillProcessing) {
      styleClass += " react_journey__processing";
    }
    return styleClass;
  }

  render() {
    return (
      <div className={this.stateClass()} />
    );
  }
}

// if (this.props.intermediate.length > 0)
//   if (this.props.is_shipping) {
//     styleClass += ' react_journey__intermediate_shipping';
//   } else {
//     styleClass += ` ${this.props.intermediate}`;
//   }
export default Node;
