import React from "react";
import PropTypes from "prop-types";
import Style from "./FreeTextSearchInput.module.css";

const FreeTextSearchInput = ({ value, onChangeText, placeholder, searchInputClassName }) => {
  return (
    <div className={`${Style.search_container}`}>
      <span className={`fa fa-search ${Style.search_icon}`} />
      <input
        value={value}
        placeholder={placeholder}
        onChange={({ currentTarget }) => {
          onChangeText(currentTarget.value || "");
        }}
        className={`${Style.search_input} ${searchInputClassName}`}
        data-cy="free-text-search"
      />
    </div>
  );
};

FreeTextSearchInput.defaultProps = {
  value: "",
  onChangeText: () => {},
  placeholder: "Type to Filter",
  searchInputClassName: ""
};

FreeTextSearchInput.propTypes = {
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  placeholder: PropTypes.string,
  searchInputClassName: PropTypes.string
};

export default FreeTextSearchInput;
