/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { Card, CardBody } from "mdbreact";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Alert } from "react-bootstrap";
import BulkUpdateComponentStyle from "./BulkUpdateComponent.module.css";
import ListView from "../TrackPageComponents/ListView";
import LoadingCircle from "../loading/LoadingCircle";
import xemelgoStyle from "../../styles/variable";

const BulkUpdateComponent = ({
  history,
  title,
  bannerError,
  showBanner,
  validCSVHeaderCheck,
  validCSVDataCheck,
  bannerMessage,
  onCloseBanner,
  resetItemStateAttribute,
  isResetItemState,
  setIsResetItemState,
  defaultAttributeMap,
  customAttributeMap,
  onUploadCSVSubmit,
  onUploadCSVSubmitForHiscoReset,
  loading,
  loadingMessage,
  errorExists,
  setErrorExistsFn,
  closeBannerFn,
  cleanUpBulkUpdateStateSetup
}) => {
  const [csvData, setCsvData] = useState([]);
  const [displayListView, setDisplayListView] = useState(false);
  const [csvSubmitted, setCsvSubmitted] = useState(false);

  const parseCSV = (event) => {
    // Create headersToIdMap for later use that will map the Headers to Ids
    const headersToIdMap = {};
    Object.keys(defaultAttributeMap).forEach((id) => {
      headersToIdMap[defaultAttributeMap[id].label] = id;
    });
    Object.keys(customAttributeMap).forEach((id) => {
      headersToIdMap[customAttributeMap[id].label] = id;
    });

    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const allRows = reader.result.split(/\r|\n/).filter((each) => {
          return each;
        });
        const headers = allRows[0].replace("ï»¿", "").split(",");

        const isHeaderIncludeState = resetItemStateAttribute && headers.includes(resetItemStateAttribute.label);
        if (isHeaderIncludeState) {
          headersToIdMap.State = resetItemStateAttribute.label.toLowerCase();
        }

        if (validCSVHeaderCheck(headers)) {
          // looping through each row except the header and return an array of object that has multiple attribute based on headers
          const result = allRows.slice(1, allRows.length).reduce((accumulator, eachRow) => {
            const columns = eachRow.replace('"', "").split(",");
            accumulator.push(
              columns.reduce((accu, eachData, index) => {
                const headerId = headersToIdMap[headers[index]];
                if (headerId) {
                  accu[headerId] = eachData && eachData !== "-" ? eachData : undefined;
                }
                return accu;
              }, {})
            );
            return accumulator;
          }, []);
          switchToCSVView(result, isHeaderIncludeState);
        }
      };
      reader.readAsBinaryString(event.target.files[0]);
    }
  };

  const switchToCSVView = async (data, isHeaderIncludeState) => {
    if (validCSVDataCheck(data, isHeaderIncludeState)) {
      setCsvData(data);
      setDisplayListView(true);
    }
    setIsResetItemState(isHeaderIncludeState);
  };

  const onListViewSubmit = async () => {
    if (isResetItemState) {
      await onUploadCSVSubmitForHiscoReset(csvData);
    } else {
      await onUploadCSVSubmit(csvData);
    }
    setCsvSubmitted(true);
  };

  const onExitListView = () => {
    setCsvData([]);
    setCsvSubmitted(false);
    setDisplayListView(false);
    onCloseBanner();
  };

  const RenderCSVOption = () => {
    return (
      <div className={BulkUpdateComponentStyle.list_view_container}>
        <form>
          <p className={BulkUpdateComponentStyle.main_text}> Upload CSV </p>
          <div>
            <input
              type="file"
              onChange={parseCSV}
              accept=".csv"
            />
          </div>
          <p className={BulkUpdateComponentStyle.sub_text}>
            Please upload a csv file with the following columns:
            <br />
            {[
              ...Object.keys(defaultAttributeMap).filter(attribute => defaultAttributeMap[attribute].editable && defaultAttributeMap[attribute].editable.bulk),
              ...Object.keys(customAttributeMap)
            ]
              .sort((a, b) => {
                const aIndex = defaultAttributeMap[a]?.index || customAttributeMap[a]?.index;
                const bIndex = defaultAttributeMap[b]?.index || customAttributeMap[b]?.index;
                return aIndex - bIndex;
              })
              .map((eachKey, index, array) => {
                const { label, required } = defaultAttributeMap[eachKey] || customAttributeMap[eachKey];
                return (
                  <span key={`${index}_${label}`}>
                    {label}
                    {required && <span style={{ color: xemelgoStyle.theme.STATUS_RED }}> *</span>}
                    {index !== array.length - 1 && ", "}
                  </span>
                );
              })}
          </p>
          <p className={BulkUpdateComponentStyle.sub_text}>
            <span style={{ color: xemelgoStyle.theme.STATUS_RED }}>*</span> - required
          </p>
  
          {resetItemStateAttribute && (
            <p className={BulkUpdateComponentStyle.sub_text}>
              Please provide a State and Current Location Column to set items to on-hand state .
            </p>
          )}
        </form>
      </div>
    );
  };  

  const DisplayBanner = () => {
    if (showBanner) {
      return (
        <Alert
          variant={bannerError ? "danger" : "success"}
          onClose={onCloseBanner}
          dismissible
        >
          <h4>{bannerError ? "Error" : "Success!"}</h4>
          <p>{bannerMessage}</p>
        </Alert>
      );
    }
    return null;
  };

  const RenderListView = () => {
    const headerStructureList = [];
    Object.keys(defaultAttributeMap).forEach((id) => {
      const { label } = defaultAttributeMap[id];
      headerStructureList.push({ id, label });
    });
    Object.keys(customAttributeMap).forEach((id) => {
      const { label } = customAttributeMap[id];
      headerStructureList.push({ id, label });
    });

    return (
      <ListView
        onboarding
        handleDelete={
          csvSubmitted
            ? null
            : (row) => {
                setCsvData(
                  csvData.filter((each) => {
                    return JSON.stringify(each) !== JSON.stringify(row);
                  })
                );
              }
        }
        dataList={csvData}
        headerStructureList={headerStructureList}
      />
    );
  };

  if (loading) {
    return <LoadingCircle message={loadingMessage} />;
  }

  return (
    <div>
      <DisplayBanner />
      <Card>
        <div className={BulkUpdateComponentStyle.title}>{`Bulk Update ${title}`}</div>
        <CardBody className={BulkUpdateComponentStyle.content_container}>
          <div
            role="button"
            onClick={() => {
              cleanUpBulkUpdateStateSetup();
              if (displayListView) {
                setDisplayListView(false);
                setCsvData({});
                setErrorExistsFn(false);
                closeBannerFn();
                setCsvSubmitted(false);
              } else {
                history.goBack();
                setErrorExistsFn(false);
              }
            }}
            tabIndex={-1}
            className={BulkUpdateComponentStyle.cancel_button_group}
          >
            <KeyboardArrowLeftIcon
              style={{ color: xemelgoStyle.theme.APP_BLUE }}
              className={BulkUpdateComponentStyle.cancel_button_icon}
            />
            <div className={BulkUpdateComponentStyle.cancel_button_text}>Cancel</div>
          </div>
          {displayListView ? (
            <RenderListView />
          ) : (
            <div className={BulkUpdateComponentStyle.outer_attribute_container}>
              <div className={BulkUpdateComponentStyle.right_attribute_container}>
                <RenderCSVOption />
              </div>
            </div>
          )}
          {!errorExists && (
            <div className={BulkUpdateComponentStyle.submit_button_container}>
              <button
                type="submit"
                onClick={displayListView ? (csvSubmitted ? onExitListView : onListViewSubmit) : () => {}}
                className="request-submit-button"
              >
                {csvSubmitted ? "OK" : `Bulk Update ${title}`}
              </button>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

BulkUpdateComponent.defaultProps = {
  history: {},
  title: "",
  bannerError: false,
  showBanner: false,
  validCSVDataCheck: () => {},
  validCSVHeaderCheck: () => {},
  bannerMessage: "",
  onCloseBanner: () => {},
  defaultAttributeMap: {},
  customAttributeMap: {},
  loading: false,
  onUploadCSVSubmit: () => {},
  loadingMessage: "",
  cleanUpBulkUpdateStateSetup: () => {},
  resetItemStateAttribute: null,
  isResetItemState: false,
  setIsResetItemState: (isResetItemState) => {}
};

BulkUpdateComponent.propTypes = {
  history: PropTypes.object,
  title: PropTypes.string,
  bannerError: PropTypes.bool,
  showBanner: PropTypes.bool,
  validCSVDataCheck: PropTypes.func,
  validCSVHeaderCheck: PropTypes.func,
  bannerMessage: PropTypes.string,
  onCloseBanner: PropTypes.func,
  defaultAttributeMap: PropTypes.object,
  customAttributeMap: PropTypes.object,
  loading: PropTypes.bool,
  onUploadCSVSubmit: PropTypes.func,
  loadingMessage: PropTypes.string,
  cleanUpBulkUpdateStateSetup: PropTypes.func,
  resetItemStateAttribute: PropTypes.object,
  isResetItemState: PropTypes.bool,
  setIsResetItemState: PropTypes.func
};

export default withRouter(BulkUpdateComponent);
