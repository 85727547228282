import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export const ModalForm = ({ title, body, footer, show, size, className, scrollable, prefix, centered }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      size={size}
      dialogClassName={className}
      scrollable={scrollable}
      centered={centered}
    >
      <Modal.Header bsPrefix={`${prefix}-header modal-header`}>
        <Modal.Title bsPrefix={`${prefix}-title modal-title`}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix={`${prefix}-body modal-body`}>{body}</Modal.Body>
      {footer && <Modal.Footer bsPrefix={`${prefix}-footer modal-footer`}>{footer}</Modal.Footer>}
    </Modal>
  );
};

ModalForm.defaultProps = {
  size: null,
  className: "",
  prefix: "modal"
};

ModalForm.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  prefix: PropTypes.string,
  centered: PropTypes.bool
};
