/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import Style from "./SectionList.module.css";

export const SectionList = ({ sections, renderItem, renderSectionHeader, sectionContainerClassName }) => {
  return (
    <li className={`${Style.flex_column} ${sectionContainerClassName}`}>
      {sections.map((eachSection, index) => {
        return (
          <ul
            key={`${eachSection.label} ${index}`}
            className={`${Style.flex_column}`}
          >
            {renderSectionHeader?.(eachSection) || <p>{eachSection.label}</p>}
            <li>
              {eachSection.data.map((eachItem, dataIndex) => {
                return (
                  <ul key={`${eachItem.id} ${dataIndex}`}>
                    {renderItem?.(eachItem, eachSection) || <p>{eachItem.label}</p>}
                  </ul>
                );
              })}
            </li>
          </ul>
        );
      })}
    </li>
  );
};

SectionList.defaultProps = {
  sections: [],
  renderItem: null,
  renderSectionHeader: null,
  sectionContainerClassName: ""
};

SectionList.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
    })
  ),
  renderItem: PropTypes.func,
  renderSectionHeader: PropTypes.func,
  sectionContainerClassName: PropTypes.string
};
