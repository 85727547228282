import { ITEM_SCAN_STATUS_MAP, ITEM_TYPE_STATUS_MAP } from "../../../../data/constants";

export const getReportStatus = (items, scannedCount, totalCount) => {
  const hasInvalidItem = items.some((item) => {
    return item.scanStatus?.id === ITEM_SCAN_STATUS_MAP.INVALID.id;
  });

  if (hasInvalidItem || scannedCount > totalCount) {
    return ITEM_TYPE_STATUS_MAP.extraScanned;
  }
  if (scannedCount === 0) {
    return ITEM_TYPE_STATUS_MAP.notScanned;
  }
  if (scannedCount === totalCount) {
    return ITEM_TYPE_STATUS_MAP.complete;
  }
  return ITEM_TYPE_STATUS_MAP.partial;
};
