export const calculateLabelContainerSize = (tagSizeInDots, numberOfColumns, numberOfRows, workOrderStatus) => {
  const { x, y } = tagSizeInDots;
  let headerHeight = 50;
  let lineBreakGap = 50;
  let workOrderStatusContainer = 100;

  // 300dpi, 4x6 tag
  if (x === 1800 && y === 1200) {
    headerHeight = 100;
  }

  // 203 dpi, 2x4 tag
  if (x === 812 && y === 406) {
    headerHeight = 25;
    lineBreakGap = 25;
    workOrderStatusContainer = 65;
  }

  const containerWidth = Math.floor((x - 100 - 50 * (numberOfColumns + 1)) / numberOfColumns);
  const containerHeight = Math.floor(
    (y -
      (100 +
        (lineBreakGap * (numberOfRows + 1) +
          headerHeight * numberOfRows +
          (workOrderStatus ? workOrderStatusContainer : 0)))) /
      numberOfRows
  );
  return { containerWidth, containerHeight };
};
