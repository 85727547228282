import React from "react";
import PropTypes from "prop-types";
import Style from "./css/Overview.module.css";

const Overview = ({ leftComponent, rightComponent, rightComponentClassName }) => {
  return (
    <div className={Style.container}>
      {leftComponent && <div className={Style.left_component}>{leftComponent}</div>}
      <div className={`${Style.right_component} ${rightComponentClassName}`}>{rightComponent}</div>
    </div>
  );
};

Overview.defaultProps = {
  leftComponent: null,
  rightComponentClassName: ""
};

Overview.propTypes = {
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element.isRequired,
  rightComponentClassName: PropTypes.string
};
export default Overview;
