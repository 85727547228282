import React from "react";
import Styles from "./ModalFormFooter.module.css";

export const ModalFormFooter = ({ onConfirm, onCancel, confirmDisabled, cancelDisabled }) => {
  return (
    <div className={Styles.modal_footer_container}>
      <div
        className={`${Styles.form_button} ${Styles.cancel_button} ${cancelDisabled ? Styles.form_button_disabled : ""}`}
        onClick={() => {
          if (!cancelDisabled) {
            onCancel();
          }
        }}
      >
        Cancel
      </div>
      <div
        onClick={() => {
          if (!confirmDisabled) {
            onConfirm();
          }
        }}
        className={`${Styles.form_button} ${Styles.confirm_button} ${
          confirmDisabled ? Styles.form_button_disabled : ""
        }`}
      >
        Confirm
      </div>
    </div>
  );
};
