import React from "react";
import PropTypes from "prop-types";
import Selector from "../selector";
import LoadingCircle from "../loading/LoadingCircle";
import InfoCard from "../add-inventory-v2-components/InfoCard";
import Style from "./Style.module.css";
import TextInput from "../add-page-component/add-page-inputs/TextInput";

export const PrintSection = ({
  isPrintEnabled,
  singlePrint,
  quantity,
  onChangeQuantity,
  printers,
  selectedPrinter,
  onSelectPrinter,
  contentLoading,
  printerError,
  inputStyle,
  disableQuantity,
  printerLabel
}) => {
  return (
    <>
      {!singlePrint && (
        <>
          <p className={Style.info_content_label}>
            Number of Tags
            <span className={Style.asterisk}> *</span>
          </p>
          <div className={Style.text_input_container}>
            <TextInput
              id="qty"
              numberOnly
              placeholder="--"
              value={quantity}
              disabled={disableQuantity}
              variant="standard"
              InputProps={{ classes: inputStyle, disableUnderline: true }}
              inputProps={{
                style: {
                  padding: 10
                }
              }}
              onChange={(_, value) => {
                onChangeQuantity(parseInt(value));
              }}
            />
          </div>
        </>
      )}
      {isPrintEnabled && (
        <>
          <p className={Style.info_content_label}>
            {printerLabel}
            <span className={Style.asterisk}> *</span>
          </p>
          <div className={Style.dropdown_input_container}>
            <Selector
              disableTabFocus
              options={printers}
              onSelect={(printer) => {
                onSelectPrinter(printer);
              }}
              value={selectedPrinter?.name}
              placeholder="Select a printer"
              error={printerError}
            />
          </div>
          <div className={Style.info_card}>
            {contentLoading ? (
              <LoadingCircle
                shouldCenter
                isSmall
              />
            ) : (
              <InfoCard info={selectedPrinter} />
            )}
          </div>
        </>
      )}
    </>
  );
};

PrintSection.defaultProps = {
  isPrintEnabled: false,
  singlePrint: false,
  quantity: 0,
  onChangeQuantity: () => {},
  printers: [],
  selectedPrinter: {},
  onSelectPrinter: () => {},
  contentLoading: true,
  printerError: false,
  inputStyle: {},
  disableQuantity: false,
  printerLabel: "Printer"
};

PrintSection.propTypes = {
  isPrintEnabled: PropTypes.bool,
  singlePrint: PropTypes.bool,
  quantity: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  printers: PropTypes.array,
  selectedPrinter: PropTypes.object,
  onSelectPrinter: PropTypes.func,
  contentLoading: PropTypes.bool,
  printerError: PropTypes.bool,
  inputStyle: PropTypes.object,
  disableQuantity: PropTypes.bool,
  printerLabel: PropTypes.string
};
