import React, { useState, useEffect, useMemo, Fragment } from "react";
import { CloseRounded } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";
import Spinner from "react-bootstrap/Spinner";
import { ModalForm } from "components/modal-form";
import { DatePicker } from "components/DateTimePicker/DateTimePicker";
import DisplayBanner from "components/display-banner/DisplayBanner";
import PaginatedListTable from "components/PaginatedList/PaginatedList";
import "./ScheduleWorkOrderModal.css";
import { WorkOrderRecord } from "features/schedule-track-page-feature/components/work-order-record/WorkOrderRecord";
import _ from "lodash";
import { isWorkOrderMatchFilterInput } from "features/schedule-track-page-feature/services/isWorkOrderMatchFilterInput";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
import { useAppConfigProvider } from "services/soft-cache-service";
import useSortableHeader from "../../../../hooks/use-sortable-header";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { getFilterMap } from "./services/getFilterMap";
import Style from "./ScheduleWorkOrderModal.module.css";

const APP_ID = "scheduleOrder";
const SCHEDULE_PAGE_CONFIG = "scheduleOrderPage";

const OUTPUT_PART_PREFIX = "outputPart_";

const ScheduleWorkOrderModalHeader = ({ onCloseClick }) => {
  return (
    <div className={`${Style.header_container} ${Style.flex_column}`}>
      <button
        className={`${Style.header_close_button} ${Style.flex_row}`}
        onClick={onCloseClick}
      >
        <CloseRounded className={Style.header_close_button_icon} />
      </button>
      <div className={`${Style.header_title_container} ${Style.flex_row}`}>
        <div className={Style.header_title_icon_container}>
          <img
            src={require("../../../../img/scheduling-management.png")}
            className={Style.header_title_icon}
          />
        </div>
        <p>Schedule Work Order</p>
      </div>
    </div>
  );
};

const ScheduleWorkOrderModalFooter = ({ onDiscardClick, onScheduleClick, isScheduleButtonDisabled }) => {
  return (
    <div className={`${Style.footer_container} ${Style.flex_row}`}>
      <button onClick={onDiscardClick}>Discard</button>
      <button
        disabled={isScheduleButtonDisabled}
        onClick={onScheduleClick}
      >
        Schedule
      </button>
    </div>
  );
};

const ScheduleWorkOrderModal = ({ onCloseClick }) => {
  // configuration
  const configProvider = useAppConfigProvider(APP_ID);
  const SchedulePageConfig = configProvider.getValue(SCHEDULE_PAGE_CONFIG, "object");

  const xemelgoClient = useXemelgoClient();
  const [scheduleOrderClient] = useState(xemelgoClient.getScheduleOrderClient());

  // useState
  const [isLoading, setIsLoading] = useState(true); // TODO - change to true after API integration
  const [showBanner, setShowBanner] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [bannerMessage, setBannerMessage] = useState();

  const [scheduleWorkCenter, setScheduleWorkCenter] = useState({});
  const [scheduleDate, setScheduleDate] = useState(null);

  const [selectedFilterMap, setSelectedFilterMap] = useState({});
  const [unscheduledWOMap, setUnscheduledWOMap] = useState({});
  const [rowsPerPage] = useState(5);

  const [formDataMap, setFormDataMap] = useState({
    workOrders: {
      id: "workOrders",
      label: "Work Orders",
      type: "table",
      value: null,
      error: false,
      errorMessage: ""
    },
    scheduleDate: {
      id: "scheduleDate",
      label: "Schedule Date",
      type: "datePicker",
      value: null,
      error: false,
      errorMessage: ""
    },
    workCenter: {
      id: "workCenter",
      label: "Work Center",
      type: "searchDropdown",
      value: null,
      error: false,
      errorMessage: ""
    }
  });
  const [filterMap, setFilterMap] = useState({});
  const [workCenterList, setWorkCenterList] = useState([]);
  const [scheduledWorkOrderCountForDate, setScheduledWorkOrderCountForDate] = useState(0);
  const [scheduledWorkOrderCountForWorkCenter, setScheduledWorkOrderCountForWorkCenter] = useState(0);

  const { getSortedData, getSortableHeader, order, orderBy } = useSortableHeader();

  const selectedWorkOrders = useMemo(() => {
    return Object.values(unscheduledWOMap).filter((workOrder) => {
      return workOrder.isChecked === true;
    });
  }, [unscheduledWOMap]);

  const unscheduledHeaderList = useMemo(() => {
    return Object.entries(SchedulePageConfig.attributeMap)
      .sort((a, b) => {
        return a[1].index - b[1].index;
      })
      .map(([headerName, configValue]) => {
        return {
          id: headerName,
          ...configValue
        };
      });
  }, [SchedulePageConfig]);

  const outputItemTypeFieldsToQuery = useMemo(() => {
    const fields = [];
    Object.keys(SchedulePageConfig.attributeMap).forEach((key) => {
      if (key.startsWith(OUTPUT_PART_PREFIX)) {
        fields.push(key.substring(OUTPUT_PART_PREFIX.length, key.length));
      }
    });
    return fields;
  }, [SchedulePageConfig]);

  const sortedWorkCenterList = useMemo(() => {
    return workCenterList
      ?.map((wc) => {
        return {
          label: wc.identifier || wc.id,
          ...wc
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }, [workCenterList]);

  useEffect(() => {
    // reset value of scheduledWorkOrderCountForWorkCenter
    setScheduledWorkOrderCountForWorkCenter(null);
    if (scheduleDate) {
      // set to null when scheduleDate is changed, to show spinner
      setScheduledWorkOrderCountForDate(null);
      scheduleOrderClient.getScheduledWorkOrderCount(undefined, scheduleDate).then((count) => {
        setScheduledWorkOrderCountForDate(count);
      });
    } else {
      setScheduledWorkOrderCountForDate(null);
    }
  }, [scheduleDate]);

  useEffect(() => {
    // reset state to show spinner
    setScheduledWorkOrderCountForWorkCenter(null);
    if (scheduleWorkCenter.id && scheduleDate) {
      scheduleOrderClient.getScheduledWorkOrderCount(scheduleWorkCenter.id, scheduleDate).then((count) => {
        setScheduledWorkOrderCountForWorkCenter(count);
      });
    } else {
      setScheduledWorkOrderCountForWorkCenter(null);
    }
  }, [scheduleWorkCenter, scheduleDate]);

  const onLoad = async () => {
    setIsLoading(true);
    const unscheduledWorkOrders = await scheduleOrderClient.getUnscheduledWorkOrders(outputItemTypeFieldsToQuery);
    const tableWorkOrderMap = unscheduledWorkOrders.reduce((acc, curr) => {
      return { ...acc, [curr.id]: { ...curr, isChecked: false } };
    }, {});
    const newFilterMap = getFilterMap(unscheduledHeaderList, unscheduledWorkOrders);
    const newWorkCenterList = await scheduleOrderClient.getWorkCenters();

    setFilterMap(newFilterMap);
    setWorkCenterList(newWorkCenterList);
    setUnscheduledWOMap(tableWorkOrderMap);
    setScheduleWorkCenter({});
    setScheduleDate(null);
    setShowBanner(false);
    setIsLoading(false);
  };

  const validateInputFields = () => {
    let hasError = false;
    const newFormDataMap = _.cloneDeep(formDataMap);

    Object.values(newFormDataMap).forEach((eachAttribute) => {
      const { value, label } = eachAttribute;
      let formatValue = _.cloneDeep(value);
      // handle scheduleDate case
      if (typeof value === "number") {
        formatValue = String(value);
      }

      if (formatValue == null || !Object.keys(formatValue)?.length) {
        hasError = true;
        eachAttribute.error = true;
        eachAttribute.errorMessage = `${label} is a required field`;
      }
    });
    setFormDataMap(newFormDataMap);
    return hasError;
  };

  const onDiscardClick = () => {
    if (onCloseClick) {
      onCloseClick();
    }
  };
  const handleCloseBanner = () => {
    setBannerError(false);
    setBannerMessage("");
    setShowBanner(false);
  };

  const handleSubmit = async () => {
    const toScheduleWorkOrders = Object.values(unscheduledWOMap).filter((workOrder) => {
      return workOrder.isChecked === true;
    });
    const toScheduleWorkOrderNumbers = toScheduleWorkOrders.map((workOrder) => {
      return workOrder.orderNumber;
    });

    const inputFieldErrorExists = validateInputFields(toScheduleWorkOrderNumbers);

    if (!inputFieldErrorExists) {
      setIsLoading(true);
      try {
        for (const wo of selectedWorkOrders) {
          await scheduleOrderClient.scheduleWorkOrder(wo, scheduleDate, scheduleWorkCenter.id);
        }
        setBannerMessage(
          `Succesfully scheduled ${selectedWorkOrders.length} work ${
            selectedWorkOrders.length > 1 ? "orders" : "order"
          }.`
        );
      } catch (e) {
        console.error(e);
        setBannerMessage(
          `There was an error scheduling ${selectedWorkOrders.length} work ${
            selectedWorkOrders.length > 1 ? "orders" : "order"
          }. Please try again or contact Xemelgo support for assistance.`
        );
      } finally {
        onLoad();
        setScheduleWorkCenter({});
        setScheduleDate(null);
        setShowBanner(true);
      }
    }
  };

  const onCheckboxClick = (ids, newState) => {
    const newWorkOrderMap = _.cloneDeep(unscheduledWOMap);
    ids.forEach((id) => {
      newWorkOrderMap[id].isChecked = newState;
    });
    setUnscheduledWOMap(newWorkOrderMap);

    if (selectedWorkOrders.length === 0) {
      setScheduleWorkCenter({});
      setScheduleDate(null);
    }
  };

  const handleOnFilterSelect = (selectedOption, filterProperty) => {
    const newSelectedFilterMap = { ...selectedFilterMap };
    newSelectedFilterMap[filterProperty] = selectedOption;
    setSelectedFilterMap(newSelectedFilterMap);
  };

  const toDisplayWorkOrderMap = useMemo(() => {
    let filteredWorkOrders = _.cloneDeep(Object.values(unscheduledWOMap));

    Object.keys(selectedFilterMap).forEach((filterProperty) => {
      filteredWorkOrders = Object.values(filteredWorkOrders).filter((workOrder) => {
        const { value } = selectedFilterMap[filterProperty];
        return isWorkOrderMatchFilterInput(unscheduledHeaderList, value ? [value] : [], workOrder, filterProperty);
      });
    });

    const sortedWorkOrderList = getSortedData(filteredWorkOrders).reduce((obj, item) => {
      return Object.assign(obj, { [item.id]: item });
    }, {});

    return sortedWorkOrderList;
  }, [unscheduledWOMap, selectedFilterMap, order, orderBy]);

  // update formDataMap with inputs change
  useEffect(() => {
    const newFormDataMap = _.cloneDeep(formDataMap);
    Object.values(newFormDataMap).forEach((eachAttribute) => {
      const { id } = eachAttribute;

      switch (id) {
        case "workOrders": {
          eachAttribute.value = selectedWorkOrders;
          if (selectedWorkOrders.length) {
            eachAttribute.error = false;
            eachAttribute.errorMessage = "";
          }
          break;
        }
        case "scheduleDate": {
          eachAttribute.value = scheduleDate;
          if (scheduleDate) {
            eachAttribute.error = false;
            eachAttribute.errorMessage = "";
          }
          break;
        }
        case "workCenter": {
          eachAttribute.value = scheduleWorkCenter;
          if (Object.keys(scheduleWorkCenter).length) {
            eachAttribute.error = false;
            eachAttribute.errorMessage = "";
          }
          break;
        }
      }
    });
    setFormDataMap(newFormDataMap);
  }, [unscheduledWOMap, scheduleDate, scheduleWorkCenter]);

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <ModalForm
      show
      prefix="schedule_work_order-modal"
      className={`${Style.modal} ${Style.flex_column}`}
      title={
        <ScheduleWorkOrderModalHeader
          onCloseClick={() => {
            if (onCloseClick) {
              onCloseClick();
            }
          }}
        />
      }
      body={
        <div className={`${Style.body_container} ${Style.flex_column}`}>
          {showBanner && (
            <DisplayBanner
              bannerError={bannerError}
              onCloseBanner={handleCloseBanner}
              bannerTitle={bannerError ? "Error" : "Success"}
              bannerMessage={bannerMessage}
            />
          )}
          <div className={`${Style.list_table_container} ${Style.flex_column}`}>
            {isLoading ? (
              <Skeleton
                className={Style.skeleton_header}
                count={5}
                height={34}
              />
            ) : (
              Object.values(formDataMap).map((eachAttribute) => {
                const { id, value, error, errorMessage } = eachAttribute;
                switch (id) {
                  case "workOrders": {
                    return (
                      <>
                        <p className={Style.title_text}>Group work orders</p>
                        {error && (
                          <p className={`${Style.error_message} ${Style.title_text}`}>
                            Select at least 1 work order to schedule
                          </p>
                        )}
                        <div className={Style.filter_container}>
                          {Object.values(filterMap).map((eachFilter) => {
                            const { id, label, type, options } = eachFilter;
                            switch (type) {
                              case "date":
                                return (
                                  <div
                                    key={id}
                                    className={Style.filter_item}
                                  >
                                    <DatePicker
                                      inputContainerClassName={Style.datepicker_container}
                                      placeholder={label}
                                      value={selectedFilterMap[id]?.value}
                                      onTimeChange={(timestamp) => {
                                        const startDateFilter = {
                                          id,
                                          value: timestamp,
                                          type: "date"
                                        };
                                        handleOnFilterSelect(startDateFilter, startDateFilter.id);
                                      }}
                                      format="MM/dd/yyyy"
                                    />
                                  </div>
                                );
                              default: {
                                return (
                                  <div
                                    key={id}
                                    className={Style.filter_item}
                                  >
                                    <SearchDropdown
                                      key={id}
                                      options={options}
                                      placeholder={label}
                                      selectedItem={selectedFilterMap[id]}
                                      onItemSelected={(selectedOption) => {
                                        handleOnFilterSelect(selectedOption, id);
                                      }}
                                      showIcon
                                    />
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                        <div className={Style.table_container}>
                          {Object.keys(toDisplayWorkOrderMap).length ? (
                            <PaginatedListTable
                              header={unscheduledHeaderList}
                              headerContainerClassName={Style.table_header}
                              selectable
                              onSelectClick={onCheckboxClick}
                              renderHeader={(eachHeader) => {
                                return getSortableHeader(eachHeader, { style: { fontWeight: 600, fontSize: "14px" } });
                              }}
                              data={Object.values(toDisplayWorkOrderMap)}
                              numItemsPerPage={rowsPerPage}
                              renderItem={(eachItem, i) => {
                                return (
                                  <WorkOrderRecord
                                    data={eachItem}
                                    headerList={unscheduledHeaderList}
                                  />
                                );
                              }}
                            />
                          ) : (
                            <div className={Style.table_empty}> No data to show</div>
                          )}
                        </div>
                      </>
                    );
                  }
                  case "scheduleDate": {
                    return (
                      selectedWorkOrders.length > 0 && (
                        <div className={Style.user_input_container}>
                          <div className={Style.flex_row}>
                            <p className={Style.bold_text}>
                              Select the date you wish to schedule to on the calendar.
                              <span className={Style.label_asterisk}>*</span>
                            </p>
                          </div>
                          <div className={`${Style.flex_row} ${Style.user_input}`}>
                            <DatePicker
                              inputContainerClassName={Style.datepicker_container}
                              value={scheduleDate}
                              error={error}
                              errorMessage={errorMessage}
                              onTimeChange={setScheduleDate}
                              format="MM/dd/yyyy"
                            />
                            {scheduleDate && (
                              <div className={Style.flex_row}>
                                <p className={Style.info_label}>Total Number of WOs scheduled for the day:</p>
                                <div className={Style.info_value}>
                                  {scheduledWorkOrderCountForDate === null ? (
                                    <Spinner
                                      size="sm"
                                      animation="border"
                                    />
                                  ) : (
                                    <p>{scheduledWorkOrderCountForDate}</p>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    );
                  }
                  case "workCenter": {
                    return (
                      selectedWorkOrders.length > 0 &&
                      scheduleDate && (
                        <div className={Style.user_input_container}>
                          <p className={Style.bold_text}>
                            Select the work center you wish to schedule to.
                            <span className={Style.label_asterisk}>*</span>
                          </p>
                          <div className={`${Style.flex_row} ${Style.user_input}`}>
                            <SearchDropdown
                              options={sortedWorkCenterList}
                              selectedItem={scheduleWorkCenter}
                              onItemSelected={(item) => {
                                setScheduleWorkCenter(item);
                              }}
                              placeholder="Select work center"
                              showIcon
                              error={error}
                              errorMessage={errorMessage}
                            />
                            {Object.keys(scheduleWorkCenter).length > 0 && (
                              <div className={Style.flex_row}>
                                <p className={Style.info_label}>Total Number of WOs scheduled for this work center:</p>
                                <div className={Style.info_value}>
                                  {scheduledWorkOrderCountForWorkCenter === null ? (
                                    <Spinner
                                      size="sm"
                                      animation="border"
                                    />
                                  ) : (
                                    <p>{scheduledWorkOrderCountForWorkCenter}</p>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    );
                  }
                }
              })
            )}
          </div>
        </div>
      }
      footer={
        <ScheduleWorkOrderModalFooter
          onDiscardClick={onDiscardClick}
          onScheduleClick={handleSubmit}
          isCreateButtonDisabled
        />
      }
    />
  );
};

export default (props) => {
  return <ScheduleWorkOrderModal {...props} />;
};
