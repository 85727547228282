import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { SUPPORTED_SETTING_ID_MAP } from "../../features/wo-settings-menu/hooks/use-settings-builder/data/constants";
import { DATA_VIEW_TYPE_MAP } from "../../features/data-view-dropdown/data/constants";
import { LocalCacheService } from "../../../../services/local-cache-service";
import useWorkOrderTrackPageConfigContext from "../work-order-track-page-config-context";
import useWorkOrderTrackPageDataSourceContext from "../work-order-track-page-data-source-context";
import { useWorkOrderTrackPageSearchParams } from "../../hooks/use-work-order-track-page-search-params/useWorkOrderTrackPageSearchParams";

const WorkOrderTrackPageStateContext = React.createContext();

const initialState = {
  freeTextSearchInputText: "",
  isMetricsLoading: true,
  isLocationListLoading: true,
  isWorkOrderDataListLoading: true,
  isSideFilterLoading: true,
  applySideFilterFn: () => {},
  exportCsvFn: () => {},
  selectedDataView: DATA_VIEW_TYPE_MAP.graphsAndMetrics,
  selectedOrders: {},
  selectedMultiSelectAction: "",
  showActionStatusBanner: false,
  actionStatusBannerError: false,
  actionStatusBannerMessage: "",
  enabledMultiSelectOptions: []
};

export const useWorkOrderTrackPageStateContext = () => {
  return useContext(WorkOrderTrackPageStateContext);
};

export const WorkOrderTrackPageStateContextProvider = ({ children }) => {
  const {
    selectedLocationId,
    setSelectedLocationId,
    selectedTab,
    setSelectedTab,
    sideFilterValue,
    setSideFilterValue
  } = useWorkOrderTrackPageSearchParams();

  const client = useXemelgoClient();
  const {
    settings: settingsConfig,
    locationCategories,
    multiSelectOptions,
    unknownLocationControl
  } = useWorkOrderTrackPageConfigContext();

  const { isAdmin, setLocationTreeMap } = useWorkOrderTrackPageDataSourceContext();

  const [freeTextSearchInputText, setFreeTextSearchInputText] = useState(initialState.freeTextSearchInputText);
  const [isMetricsLoading, setIsMetricsLoading] = useState(initialState.isMetricsLoading);
  const [isLocationListLoading, setIsLocationListLoading] = useState(initialState.isLocationListLoading);
  const [isWorkOrderDataListLoading, setIsWorkOrderDataListLoading] = useState(initialState.isWorkOrderDataListLoading);
  const [isSideFilterLoading, setIsSideFilterLoading] = useState(initialState.isSideFilterLoading);
  const [applySideFilterFn, setApplySideFilterFn] = useState(initialState.applySideFilterFn);
  const [exportCsvFn, setExportCsvFn] = useState(initialState.exportCsvFn);
  const [selectedDataView, setSelectedDataViewState] = useState(initialState.selectedDataView);
  const [selectedOrders, setSelectedOrders] = useState(initialState.selectedOrders);
  const [selectedMultiSelectAction, setSelectedMultiSelectAction] = useState(initialState.selectedMultiSelectAction);
  const [showActionStatusBanner, setShowActionStatusBanner] = useState(initialState.showActionStatusBanner);
  const [actionStatusBannerError, setActionStatusBannerError] = useState(initialState.actionStatusBannerError);
  const [actionStatusBannerMessage, setActionStatusBannerMessage] = useState(initialState.actionStatusBannerMessage);

  const { search, pathname } = useLocation();

  useEffect(() => {
    setFreeTextSearchInputText(initialState.freeTextSearchInputText);
  }, [selectedTab, selectedLocationId]);

  const enabledMultiSelectOptions = useMemo(() => {
    return multiSelectOptions.filter((option) => {
      return !option.adminOnly || isAdmin;
    });
  }, [multiSelectOptions, isAdmin]);

  const getURLByState = useCallback(
    ({ selectedTab: selectedTabParam, selectedLocationId: selectedLocationIdParam }) => {
      const newParsedString = queryString.parse(search);
      if (selectedTabParam) {
        newParsedString.tab = selectedTabParam;
      }
      if (selectedLocationIdParam) {
        newParsedString.locationId = selectedLocationIdParam;
      }
      return `${pathname}?${queryString.stringify(newParsedString)}`;
    },
    [search]
  );

  const setSelectedDataView = (value) => {
    LocalCacheService.saveWorkOrderTrackPageDataView(value);
    setSelectedDataViewState(value);
  };

  useEffect(() => {
    const newSelectedDataView = LocalCacheService.getWorkOrderTrackPageDataView();
    setSelectedDataView(newSelectedDataView || initialState.selectedDataView);
  }, []);

  const fetchLocationTreeFn = async () => {
    const percentageThresholdKeyMap =
      settingsConfig?.optionControl?.[SUPPORTED_SETTING_ID_MAP.locationThresholdSettingModal]
        ?.percentageThresholdKeyMap || {};

    const { sortPreferenceKey } =
      settingsConfig?.optionControl?.[SUPPORTED_SETTING_ID_MAP.editQueuePriorityModal] || {};

    let locationAttributes = ["id", "name", "identifier"];
    if (Object.keys(percentageThresholdKeyMap).length) {
      locationAttributes = [...locationAttributes, ...Object.values(percentageThresholdKeyMap)];
    }
    if (sortPreferenceKey) {
      locationAttributes = [...locationAttributes, sortPreferenceKey];
    }

    const locationClient = await client.getLocationClient();
    const data = await locationClient.getLocationTree(
      locationAttributes,
      !locationCategories?.length || locationCategories.includes("all") ? null : locationCategories
    );

    if (unknownLocationControl?.enabled && unknownLocationControl?.properties?.id) {
      data[unknownLocationControl?.properties?.id] = {
        childLocations: [unknownLocationControl?.properties?.id],
        ...unknownLocationControl?.properties
      };
    }

    setLocationTreeMap(data);
  };

  return (
    <WorkOrderTrackPageStateContext.Provider
      value={{
        selectedLocationId,
        setSelectedLocationId,
        selectedTab,
        setSelectedTab,
        sideFilterValue,
        setSideFilterValue,
        getURLByState,
        freeTextSearchInputText,
        setFreeTextSearchInputText,
        isMetricsLoading,
        setIsMetricsLoading,
        isLocationListLoading,
        setIsLocationListLoading,
        isWorkOrderDataListLoading,
        setIsWorkOrderDataListLoading,
        isSideFilterLoading,
        setIsSideFilterLoading,
        applySideFilterFn,
        setApplySideFilterFn,
        exportCsvFn,
        setExportCsvFn,
        selectedDataView,
        setSelectedDataView,
        selectedOrders,
        setSelectedOrders,
        selectedMultiSelectAction,
        setSelectedMultiSelectAction,
        showActionStatusBanner,
        setShowActionStatusBanner,
        actionStatusBannerError,
        setActionStatusBannerError,
        actionStatusBannerMessage,
        setActionStatusBannerMessage,
        fetchLocationTreeFn,
        enabledMultiSelectOptions
      }}
    >
      {children}
    </WorkOrderTrackPageStateContext.Provider>
  );
};
