export const STATUS_COLOR_MAP = {
  Healthy: "#4B9910",
  Warning: "#F6B044",
  Critical: "#FF3D46",
  Expedited: "#E56201"
};

export const SORT_DIRECTION = { ascending: "asc", descending: "desc" };

export const STATUS_MAP = {
  expedited: "Expedited",
  lateToStart: "Delayed",
  timeExceeded: "Time Exceeded",
  inProgress: "In Progress",
  planned: "Planned"
};

export const STATUS_TITLE_MAP = {
  [STATUS_MAP.expedited]: "Expedited",
  [STATUS_MAP.lateToStart]: "Late To Start",
  [STATUS_MAP.timeExceeded]: "Time Exceeded",
  [STATUS_MAP.inProgress]: "In Progress",
  [STATUS_MAP.planned]: "Released"
};

export const STATUS_SEVERITY_MAP = {
  warning: "Warning",
  critical: "Critical",
  expedited: "Expedited",
  healthy: "Healthy"
};

export const TAB_OPTION_MAP = {
  locationTab: "locations",
  orderTab: "orders"
};
