import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const InventoryTrackPageConfigContext = React.createContext();

const SOLUTION_ID = "inventory";
const TRACK_PAGE_FEATURE_ID = "trackPage";

const initialState = {
  locationCategories: ["all"],
  tableViewTypeControl: {
    location: { label: "Location", viewMode: ["all"], locationCategories: ["all"] },
    itemType: { label: "Item Type", viewMode: ["all"] },
    item: { label: "Item", viewMode: ["all"] }
  },

  sideFilterControl: [],
  listTableControl: {
    location: {
      headers: [
        { id: "name", label: "Location", isNavLink: true },
        { id: "itemTotalCountByItemType", label: "Total Items", type: "number" }
      ]
    },
    itemType: {
      headers: [
        { id: "name", label: "Item Type", isNavLink: true },
        { id: "identifier", label: "Part Number" },
        { id: "unitOfMeasures", label: "UOM" },
        { id: "totalCount", label: "Total Count" }
      ]
    },
    item: {
      headers: [
        { id: "identifier", label: "EPC", isNavLink: true },
        { id: "itemTypeName", label: "Item Type" },
        { id: "itemTypeIdentifier", label: "Part Number" },
        { id: "trackingSessionUpdatedTime", label: "Last Updated", type: "dateTime" },
        { id: "detectedAtLocationName", label: "Current Location" }
      ],
      multiSelectControl: {
        enabled: false,
        availableActions: [
          {
            adminOnly: false,
            id: "printTags",
            label: "Reprint"
          }
        ]
      }
    },
    lot: {
      headers: []
    }
  },
  chartsControl: [],
  metricsControl: [
    {
      id: "itemTotalCountByItemType",
      label: "TOTAL ITEMS",
      viewMode: ["all"],
      tableViewType: ["all"]
    }
  ],
  combinedDuplicatesDataSources: ["itemType", "lot"]
};

export const useInventoryTrackPageConfigContext = () => {
  return useContext(InventoryTrackPageConfigContext);
};

export const InventoryTrackPageConfigContextProvider = ({ children }) => {
  const inventorySolutionConfigProvider = useAppConfigProvider(SOLUTION_ID);

  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [listTableControl, setListTableControl] = useState(initialState.listTableControl);
  const [viewModes, setViewModes] = useState(initialState.viewModes);
  const [tableViewTypeControl, setTableViewTypeControl] = useState(initialState.tableViewTypeControl);
  const [sideFilterControl, setSideFilterControl] = useState(initialState.sideFilterControl);
  const [metricsControl, setMetricsControl] = useState(initialState.metricsControl);
  const [chartsControl, setChartsControl] = useState(initialState.chartsControl);
  const [combinedDuplicatesDataSources, setCombinedDuplicatesDataSources] = useState(
    initialState.combinedDuplicatesDataSources
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const trackPageConfig = inventorySolutionConfigProvider.getValue(TRACK_PAGE_FEATURE_ID, "object") || {};
    const { v3: trackPageConfigV3 = {} } = trackPageConfig;
    const {
      locationCategories: newLocationCategories = initialState.locationCategories,
      listTableControl: newListTableControl = initialState.listTableControl,
      viewModes: newViewModes = initialState.viewModes,
      tableViewTypeControl: newTableViewTypeControl = initialState.tableViewTypeControl,
      sideFilterControl: newSideFilterControl = initialState.sideFilterControl,
      metricsControl: newMetricsControl = initialState.metricsControl,
      chartsControl: newChartsControl = initialState.chartsControl,
      combinedDuplicatesDataSources: newCombinedDuplicatesDataSources = initialState.combinedDuplicatesDataSources
    } = trackPageConfigV3 || {};

    setLocationCategories(newLocationCategories);
    setListTableControl(newListTableControl);
    setViewModes(newViewModes);
    setTableViewTypeControl(newTableViewTypeControl);
    setSideFilterControl(newSideFilterControl);
    setMetricsControl(newMetricsControl);
    setChartsControl(newChartsControl);
    setCombinedDuplicatesDataSources(newCombinedDuplicatesDataSources);

    setIsLoading(false);
  }, [inventorySolutionConfigProvider]);
  return (
    <InventoryTrackPageConfigContext.Provider
      value={{
        locationCategories,
        listTableControl,
        viewModes,
        tableViewTypeControl,
        sideFilterControl,
        metricsControl,
        chartsControl,
        combinedDuplicatesDataSources,
        isLoading
      }}
    >
      {children}
    </InventoryTrackPageConfigContext.Provider>
  );
};
