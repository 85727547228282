import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { extractIdentifiersData } from "../../utils/AddOrderUtils";

const IDENTIFIER_GENERATION_GROUP_NOT_EXIST_ERROR = "Group does not exist";

const useAutoGenerateWorkOrderIdentifiers = () => {
  const appsyncWorkOrderClient = useXemelgoAppsyncClient().getWorkOrderClient();

  const createIdentifierGenerationGroup = async (groupName, prefix, startingValue, endingValue) => {
    return appsyncWorkOrderClient.createIdentifierGenerationGroup(groupName, prefix, startingValue, endingValue);
  };

  const generateIdentifiers = async (groupName, quantity) => {
    const response = await appsyncWorkOrderClient.generateIdentifiers(groupName, quantity);
    const { errors = [] } = response;
    if (errors.length && errors[0].message.includes(`Identifier Generation Group '${groupName}' does not exist`)) {
      throw new Error(IDENTIFIER_GENERATION_GROUP_NOT_EXIST_ERROR);
    }
    return response;
  };

  const generate = async (groupName, quantity, identifierSeparator) => {
    let response;
    try {
      response = await generateIdentifiers(groupName, quantity);
    } catch (error) {
      switch (error.message) {
        case IDENTIFIER_GENERATION_GROUP_NOT_EXIST_ERROR:
          const result = await createIdentifierGenerationGroup(groupName, groupName, 1, 999999);
          if (result.error) {
            throw result.error;
          }
          response = await generateIdentifiers(groupName, quantity);
          break;
        default:
          throw error;
      }
    }

    return extractIdentifiersData(response, identifierSeparator);
  };

  return { generate };
};

export default useAutoGenerateWorkOrderIdentifiers;
