export const generateCheckoutPayload = (activeItems) => {
  const tagsToCheckout = [];
  const checkoutPayload = activeItems
    .filter((item) => {
      return !item.hasExited;
    })
    .map((item) => {
      if (item.vid) {
        tagsToCheckout.push(item.vid);
      }
      return item.trackingSessionId;
    });

  return { checkoutPayload, tagsToCheckout };
};
