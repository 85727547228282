import React, { useState, useMemo } from "react";
import Style from "./DisplayColumnControl.module.css";
import ColumnSelectModal from "../../../../../../../../components/column-select-modal";
import useInventoryTrackPageConfigContext from "../../../../../../contexts/inventory-track-page-config-context";
import useInventoryTrackPageStateContext from "../../../../../../contexts/inventory-track-page-state-context";

export const DisplayColumnControl = () => {
  const [showColumnsSettingModal, setShowColumnsSettingModal] = useState(false);

  const {
    displayedTableHeadersMap,
    setDisplayedTableHeadersMap,
    displayedTableHeadersMapDefault,
    selectedViewTypeId,
    selectedViewModeId
  } = useInventoryTrackPageStateContext();
  const { listTableControl } = useInventoryTrackPageConfigContext();

  const availableHeaders = useMemo(() => {
    return (displayedTableHeadersMapDefault?.[selectedViewTypeId]?.[selectedViewModeId] || []).map((eachHeaderId) => {
      return listTableControl[selectedViewTypeId].headers.find(({ id }) => {
        return id === eachHeaderId;
      });
    });
  }, [selectedViewTypeId, listTableControl, selectedViewModeId, displayedTableHeadersMapDefault]);

  const selectedHeaders = useMemo(() => {
    return (displayedTableHeadersMap?.[selectedViewTypeId]?.[selectedViewModeId] || []).map((eachHeaderId) => {
      return listTableControl[selectedViewTypeId].headers.find(({ id }) => {
        return id === eachHeaderId;
      });
    });
  }, [selectedViewTypeId, listTableControl, selectedViewModeId, displayedTableHeadersMap]);

  return (
    <div className={Style.container}>
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          setShowColumnsSettingModal((currentValue) => {
            return !currentValue;
          });
        }}
      >
        Columns
      </button>
      <ColumnSelectModal
        currentColumns={selectedHeaders}
        columnOptions={availableHeaders}
        show={showColumnsSettingModal}
        onCancel={() => {
          setShowColumnsSettingModal(false);
        }}
        onConfirm={(newHeaders) => {
          const currentValue = displayedTableHeadersMap || displayedTableHeadersMapDefault;
          const newDisplayedTableHeadersMap = {
            ...currentValue,
            [selectedViewTypeId]: {
              ...(currentValue[selectedViewTypeId] || {}),
              [selectedViewModeId]: newHeaders.map(({ id }) => {
                return id;
              })
            }
          };

          setDisplayedTableHeadersMap(newDisplayedTableHeadersMap);
          setShowColumnsSettingModal(false);
        }}
      />
    </div>
  );
};
