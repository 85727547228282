import React from "react";
import { Route } from "react-router-dom";
import ScheduleTrackPageFeature from "features/schedule-track-page-feature/ScheduleTrackPageFeature";
import { ScheduleManagementContextProvider } from "features/schedule-track-page-feature/context/ScheduleManagementContext.context";

const ScheduleManagementApp = () => {
  return (
    <>
      <ScheduleManagementContextProvider>
        <Route
          exact
          path="/scheduling-management"
          component={ScheduleTrackPageFeature}
        />
      </ScheduleManagementContextProvider>
    </>
  );
};

export default ScheduleManagementApp;
