import { useState, useEffect } from "react";
import { SUPPORTED_CHART_ID_MAP } from "../../data/constants";
import { SORT_DIRECTION } from "../../../../../../data/constants";
import BacklogChart from "../../../../components/backlog-chart";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";

const EmptyComponent = () => {
  return null;
};
export const useChartBuilderFactory = (
  chartsGroup,
  locationTreeMapProp,
  childrenLocationMetricMapProp,
  currentLocationCategory
) => {
  const [chartsList, setChartsList] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const client = useXemelgoClient();

  const getChartComponent = (chartId) => {
    switch (chartId) {
      case SUPPORTED_CHART_ID_MAP.backlog:
        return BacklogChart;
      default:
        return () => {
          return EmptyComponent;
        };
    }
  };

  const getBacklogChartDataList = (locationTreeMap, childrenLocationMetricMap) => {
    return Object.keys(childrenLocationMetricMap).map((eachChildrenLocationId) => {
      const { totalCount, backlogStatus } = childrenLocationMetricMap[eachChildrenLocationId];

      return {
        id: eachChildrenLocationId,
        x: locationTreeMap[eachChildrenLocationId].name,
        y: totalCount || 0,
        status: backlogStatus
      };
    });
  };

  const getChartDataList = (chartId) => {
    switch (chartId) {
      case SUPPORTED_CHART_ID_MAP.backlog:
        return getBacklogChartDataList(locationTreeMapProp, childrenLocationMetricMapProp);
      default:
        return [];
    }
  };

  const getRoutingWithOperationsAndLocationByIdentifier = async (routeIdentifier) => {
    const workOrderClient = client.getWorkOrderClient();
    return workOrderClient.getRoutingWithOperationsAndLocationByIdentifier(routeIdentifier);
  };

  const getIdToPriorityMapForRoutingOperationSort = async (
    curLocationCategory,
    locationCategoriesToRoutingIdentifierMap
  ) => {
    const routeIdentifier = locationCategoriesToRoutingIdentifierMap?.[curLocationCategory] || null;

    if (!routeIdentifier) {
      return {};
    }
    const route = (await getRoutingWithOperationsAndLocationByIdentifier(routeIdentifier)) || {};

    const result = route.hasOperation?.reduce((accumulator, eachOperation) => {
      const { operation_number: operationNumber, usesProductionResource } = eachOperation;
      const { productionResourceLocatedAt } = usesProductionResource?.[0] || {};
      const { id: locationId } = productionResourceLocatedAt?.[0] || {};

      if (locationId) {
        return { ...accumulator, [locationId]: operationNumber };
      }
      return accumulator;
    }, {});

    return result || {};
  };

  const applySort = async (dataList, curLocationCategory, sortingMetaData) => {
    const {
      sortByRoutingOperationControl = {},
      direction = SORT_DIRECTION.ascending,
      disabled = false,
      sortAxle = "x"
    } = sortingMetaData || {};
    if (disabled) {
      return dataList;
    }

    let IdToPriorityMapForSort = {};
    if (!sortByRoutingOperationControl.disabled) {
      IdToPriorityMapForSort = await getIdToPriorityMapForRoutingOperationSort(
        curLocationCategory,
        sortByRoutingOperationControl.locationCategoriesToRoutingIdentifierMap
      );
    }

    return dataList.sort((a, b) => {
      const result =
        IdToPriorityMapForSort[a.id] - IdToPriorityMapForSort[b.id] ||
        a[sortAxle].toString().localeCompare(b[sortAxle].toString(), undefined, {
          numeric: true,
          sensitivity: "base"
        });

      return direction === SORT_DIRECTION.descending ? -1 * result : result;
    });
  };

  useEffect(() => {
    let active = true;
    const getChartList = async () => {
      setIsChartLoading(true);

      const newChartsList = await Promise.all(
        chartsGroup.map(async (eachChart) => {
          const { id, title, metaData = {} } = eachChart;
          return {
            id,
            title,
            component: getChartComponent(id),
            dataList: await applySort(getChartDataList(id), currentLocationCategory, metaData.sorting)
          };
        })
      );

      if (!active) {
        return;
      }
      setChartsList(newChartsList);
      setIsChartLoading(false);
    };

    getChartList();

    return () => {
      active = false;
    };
  }, [chartsGroup, locationTreeMapProp, childrenLocationMetricMapProp, currentLocationCategory]);

  return { chartsList, isChartLoading };
};
