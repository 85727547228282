import React, { useState, useEffect, useMemo, useCallback } from "react";
import renderInput from "../../utils/form-utils/render-input";
import getInitialValue from "../../utils/form-utils/get-initial-value";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import _ from "lodash";
import PropTypes from "prop-types";

const APP_ID = "order";
const FEATURE_ID = "addOrder";
const GROUP_ONBOARDING_KEY = "rowCount";
const PRINT_LATER_KEY = "print_later";
const numberOnlyRegex = /^-?\d*[.,]?\d{0,2}$/;

export const GroupOnboardingWorkOrderSection = React.forwardRef(
  ({ showPrintLaterOption, isLoading, Style, onChangePrintLater }, ref) => {
    const configProvider = useAppConfigProvider(APP_ID);

    const xemelgoClient = useXemelgoClient();

    const [groupOnboardingAttributes, setGroupOnboardingAttributes] = useState({});
    const [groupOnboardingRowAttributes, setGroupOnboardingRowAttributes] = useState([]);
    const [groupOnboardingRows, setGroupOnboardingRows] = useState([]);

    const getGroupAttributes = async (attributes) => {
      const promises = Object.keys(attributes).map(async (currentKey) => {
        const { type } = attributes[currentKey];
        const value = await getInitialValue(type, attributes[currentKey], xemelgoClient);
        return [currentKey, { ...attributes[currentKey], value }];
      });

      const resultArray = await Promise.all(promises);
      const formattedObject = Object.fromEntries(resultArray);

      return formattedObject;
    };

    const onLoad = async () => {
      const {
        groupOnboardingAttributes: newGroupOnboardingAttributes = {},
        groupOnboardingRowAttributes: newGroupOnboardingRowAttributes = {}
      } = configProvider.getValue(FEATURE_ID, "object") || {};

      const [formattedGroupOnboardingAttributes, formattedGroupOnboardingRowAttributes] = await Promise.all([
        await getGroupAttributes(newGroupOnboardingAttributes),
        await getGroupAttributes(newGroupOnboardingRowAttributes)
      ]);

      setGroupOnboardingAttributes(formattedGroupOnboardingAttributes);
      setGroupOnboardingRowAttributes(formattedGroupOnboardingRowAttributes);
    };

    useEffect(() => {
      onLoad();
    }, []);

    const getFormData = () => {
      return { groupOnboardingAttributes, groupOnboardingRows };
    };

    const validateGroupAttributes = (attributes) => {
      let hasError = false;
      attributes.forEach((eachAttribute) => {
        const { required, value, type, label } = eachAttribute;
        if (required) {
          const errorMessage = `${label} is a required field.`;
          switch (type) {
            case "api-dropdown":
            case "dropdown":
              if (Object.keys(value).length < 3) {
                eachAttribute.error = true;
                eachAttribute.errorMessage = errorMessage;
                hasError = true;
              }
              break;
            default:
              if (!value) {
                eachAttribute.error = true;
                eachAttribute.errorMessage = errorMessage;
                hasError = true;
              }
              break;
          }
        }
      });

      return { hasError, attributes };
    };

    const validateGroupOnboardingFields = () => {
      const { hasError: groupHasError, attributes: newGroupAttributes } = validateGroupAttributes(
        Object.values(groupOnboardingAttributes)
      );

      let hasError = groupHasError;

      const newGroupOnboardingRows = groupOnboardingRows.map((row) => {
        const { hasError: rowHasError, attributes: newRowAttributes } = validateGroupAttributes(Object.values(row));
        if (rowHasError) {
          hasError = rowHasError;
        }
        return newRowAttributes;
      });
      if (hasError) {
        setGroupOnboardingAttributes({ ...newGroupAttributes });
        setGroupOnboardingRows([...newGroupOnboardingRows]);
      }
      return !hasError;
    };

    if (ref) {
      ref.current = {
        getFormData,
        validateGroupOnboardingFields
      };
    }

    const sortedGroupOnboardingRowAttributeKeys = useMemo(() => {
      return Object.keys(groupOnboardingRowAttributes).sort((a, b) => {
        const { index: aIndex } = groupOnboardingRowAttributes[a];
        const { index: bIndex } = groupOnboardingRowAttributes[b];
        return aIndex - bIndex;
      });
    }, [groupOnboardingRowAttributes]);

    const renderGroupOnboardingHeaders = useCallback(() => {
      return (
        <div className={Style.flex_row}>
          <p className={`${Style.attribute_label} ${Style.group_onboarding_count}`}>#</p>
          {sortedGroupOnboardingRowAttributeKeys.map((key) => {
            const { label, required } = groupOnboardingRowAttributes[key];
            return (
              <p
                className={`${Style.attribute_label} ${Style.group_onboarding_container}`}
                key={key}
              >
                {`${label}${required ? "*" : ""}`}
              </p>
            );
          })}
        </div>
      );
    }, [groupOnboardingRowAttributes, sortedGroupOnboardingRowAttributeKeys]);

    const renderGroupOnboardingRows = useCallback(() => {
      return groupOnboardingRows.map((values, index) => {
        return (
          <div
            key={`row_${index}`}
            className={`${Style.group_onboarding_row_container} ${Style.group_onboarding_count}`}
          >
            <p className={Style.attribute_label}>{index + 1}</p>
            {sortedGroupOnboardingRowAttributeKeys.map((key) => {
              const { type, editable, options, numberOnly } = values[key];

              const onGroupAttributeChange = (input) => {
                const tempGroupOnboardingRows = [...groupOnboardingRows];
                tempGroupOnboardingRows[index][key].value = input;
                if (input) {
                  tempGroupOnboardingRows[index][key].error = false;
                  tempGroupOnboardingRows[index][key].errorMessage = "";
                }
                setGroupOnboardingRows(tempGroupOnboardingRows);
              };
              return (
                <div
                  className={Style.group_onboarding_container}
                  key={`row_${key}`}
                >
                  {renderInput(
                    type,
                    onGroupAttributeChange,
                    values[key].value,
                    false,
                    "",
                    editable,
                    options,
                    numberOnly
                  )}
                </div>
              );
            })}
          </div>
        );
      });
    }, [groupOnboardingRows, setGroupOnboardingRows]);

    const sortedGroupOnboardingAttributeKeys = useMemo(() => {
      return Object.keys(groupOnboardingAttributes).sort((a, b) => {
        const { index: aIndex = 0 } = groupOnboardingAttributes[a];
        const { index: bIndex = 0 } = groupOnboardingAttributes[b];
        return aIndex - bIndex;
      });
    }, [groupOnboardingAttributes]);

    if (isLoading) {
      return null;
    }

    const onGroupAttributeChange = (key, value) => {
      const tempGroupOnboardingAttributes = { ...groupOnboardingAttributes };
      if (key === GROUP_ONBOARDING_KEY) {
        const tempGroupOnboardingRows = [...groupOnboardingRows];
        tempGroupOnboardingAttributes[key].value = value;
        if (value.trim() === "") {
          setGroupOnboardingRows([]);
        } else if (numberOnlyRegex.test(value.trim())) {
          tempGroupOnboardingAttributes[key].error = false;
          tempGroupOnboardingAttributes[key].errorMessage = "";
          if (parseInt(value) > tempGroupOnboardingRows.length) {
            while (tempGroupOnboardingRows.length < parseInt(value)) {
              tempGroupOnboardingRows.push(_.cloneDeep(groupOnboardingRowAttributes));
            }
          } else {
            const diff = tempGroupOnboardingRows.length - parseInt(value);
            tempGroupOnboardingRows.splice(value, diff);
          }
          setGroupOnboardingRows(tempGroupOnboardingRows);
        }
      } else {
        if (key === PRINT_LATER_KEY) {
          onChangePrintLater(value);
        }
        tempGroupOnboardingAttributes[key].value = value;
        if (value) {
          tempGroupOnboardingAttributes[key].error = false;
          tempGroupOnboardingAttributes[key].errorMessage = "";
        }
      }
      setGroupOnboardingAttributes(tempGroupOnboardingAttributes);
    };

    return (
      <div className={Style.group_onboarding_section}>
        <div className={`${Style.input_group_list} ${Style.grid} ${Style.bom_table_list}`}>
          {sortedGroupOnboardingAttributeKeys
            .filter((key) => {
              return (
                (!groupOnboardingAttributes[PRINT_LATER_KEY]?.value || key === PRINT_LATER_KEY) &&
                (showPrintLaterOption || key !== PRINT_LATER_KEY)
              );
            })
            .map((key) => {
              const { type, value, editable, error, errorMessage, label, options, required, numberOnly } =
                groupOnboardingAttributes[key];
              const finalValue = value;

              return (
                <div key={key}>
                  <div className={Style.flex_row}>
                    <p className={`${Style.attribute_label}`}>{`${label}`}</p>
                    {required && <p className={`${Style.attribute_label} ${Style.label_asterisk}`}>*</p>}
                  </div>
                  {renderInput(
                    type,
                    (value) => onGroupAttributeChange(key, value),
                    finalValue,
                    error,
                    errorMessage,
                    editable,
                    options,
                    numberOnly
                  )}
                </div>
              );
            })}
        </div>
        {groupOnboardingRows.length > 0 && !groupOnboardingAttributes[PRINT_LATER_KEY]?.value && (
          <div className={Style.group_onboarding_rows}>
            <div className={Style.group_onboarding_headers}>{renderGroupOnboardingHeaders()}</div>
            {renderGroupOnboardingRows()}
          </div>
        )}
      </div>
    );
  }
);

GroupOnboardingWorkOrderSection.propTypes = {
  showPrintLaterOption: PropTypes.bool,
  isLoading: PropTypes.bool,
  Style: PropTypes.object,
  onChangePrintLater: PropTypes.func
};

GroupOnboardingWorkOrderSection.defaultProps = {
  showPrintLaterOption: false,
  isLoading: false,
  Style: {},
  onChangePrintLater: () => {}
};
