import React from "react";
import PropTypes from "prop-types";
import "./style.css";

export const EmptyBox = ({ onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="empty-box"
      onClick={onClick}
    >
      <div className="cross-line" />
    </div>
  );
};

EmptyBox.defaultProps = {
  onClick: () => {}
};

EmptyBox.propTypes = {
  onClick: PropTypes.func
};
