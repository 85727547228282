import { ReactComponent as ListViewIcon } from "../../../../../../../../../assets/icons/list-view.svg";
import { ReactComponent as GridViewIcon } from "../../../../../../../../../assets/icons/grid-view.svg";

export const TABLE_DISPLAY_MODE_ID_MAP = {
  listView: "listView",
  gridView: "gridView"
};

export const TABLE_DISPLAY_MODE_ICON_MAP = {
  [TABLE_DISPLAY_MODE_ID_MAP.listView]: ListViewIcon,
  [TABLE_DISPLAY_MODE_ID_MAP.gridView]: GridViewIcon
};
