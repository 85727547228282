import React, { Component } from "react";
import EditableTableComponent from "../../components/editable-table/EditableTableComponent";
import { XemelgoService } from "../../services/XemelgoService";

export default class ShipmentIssueRuleContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownOptions: [],
      conditions: []
    };
    this.handleRuleConditionRequests = this.handleRuleConditionRequests.bind(this);
  }

  componentDidMount() {
    const { ruleConditionsList = [], locationDataList = [] } = this.props;
    const conditions = this.parseRuleConditions(ruleConditionsList);
    let dropDownOptions = locationDataList.map((eachLocation) => {
      return { objectTypeName: eachLocation.name, objectTypeId: eachLocation.id };
    });

    conditions.forEach((eachCondition) => {
      const locationInfo = locationDataList.find((location) => location.id === eachCondition.locationId);
      if (locationInfo) {
        eachCondition.locationName = locationInfo.name;
      }
      dropDownOptions = dropDownOptions.filter((eachOption) => eachOption.objectTypeId !== eachCondition.locationId);
    });
    this.setState({
      conditions,
      dropDownOptions
    });
  }

  parseRuleConditions = (ruleConditions) => {
    let condition = ruleConditions.map((eachCondition) => {
      return {
        id: eachCondition.id,
        locationName: eachCondition.tags.locationName || "All Locations",
        locationId: eachCondition.tags.locationId || "All Locations",
        subscribed: eachCondition.hasSubscriptions,
        disableDelete: !eachCondition.tags.locationName,
        disableEdit: true
      };
    });
    condition = condition.sort((a, b) => {
      return a.locationName.localeCompare(b.locationName);
    });
    return condition;
  };

  buildCreateRuleConditionPayloadFromRequest = (requestPayload) => {
    const { locationName } = requestPayload;
    const { locationDataList } = this.props;
    const selectedLocation = locationDataList.find((location) => location.name.includes(locationName));
    const locationId = selectedLocation.id;

    const tags = {
      locationName,
      locationId
    };
    const conditions = [
      {
        property: "locationId",
        op: "=",
        value: tags.locationId
      }
    ];
    return { tags, conditions };
  };

  buildUpdateRuleConditionPayloadFromRequest = (requestPayload) => {
    const tags = {
      locationId: requestPayload.locationId,
      locationName: requestPayload.locationName
    };
    const conditions = [
      {
        property: "locationId",
        op: "=",
        value: tags.locationId
      }
    ];
    return { id: requestPayload.id, tags, conditions };
  };

  handleRuleConditionRequests = async (requests) => {
    const { ruleId } = this.props;
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    if (!requests || requests.length === 0) {
      return;
    }

    await Promise.all(
      requests.map(async (request) => {
        const event = request._event;
        let payload;
        switch (event) {
          case "pending_new":
            payload = this.buildCreateRuleConditionPayloadFromRequest(request);
            await RulePageClient.createRuleCondition(
              `shipment_issue_${payload.tags.locationName.replace(/[^\w\s]/gi, "_")}_${payload.tags.locationId}`,
              payload.tags,
              ruleId,
              payload.conditions
            );
            break;
          case "pending_update":
            payload = this.buildUpdateRuleConditionPayloadFromRequest(request);
            await RulePageClient.updateRuleCondition(payload.id, undefined, payload.tags, payload.conditions);
            break;
          case "pending_delete":
            await RulePageClient.removeRuleCondition(request.id, false);
            break;
          default:
            console.log(`Unsupported Event[Name:${event}]`);
            break;
        }
      })
    );

    this.setState({ loading: false });

    await this.props.onLoad();
    const { ruleConditionsList = [] } = this.props;
    const conditions = this.parseRuleConditions(ruleConditionsList);

    return conditions.map((condition) => condition.id);
  };

  handleSubscriptionChange = async (ruleConditionId, subscribed) => {
    const { ruleConditionsList } = this.props;
    const ruleCondition = ruleConditionsList.find((condition) => condition.id === ruleConditionId);
    const isLocationCondition = ruleCondition.tags.locationId;
    const subscribedRuleConditionIdList = [];
    const unsubscribedRuleConditionIdList = [];
    if (subscribed) {
      subscribedRuleConditionIdList.push(ruleConditionId);
      if (isLocationCondition) {
        const allLocationRuleCondition = ruleConditionsList.find((each) => {
          return !each.tags.locationId;
        });
        unsubscribedRuleConditionIdList.push(allLocationRuleCondition.id);
      } else {
        const listOfCondition = ruleConditionsList.filter((each) => each.id !== ruleConditionId);
        listOfCondition.forEach((each) => {
          unsubscribedRuleConditionIdList.push(each.id);
        });
      }
    } else {
      unsubscribedRuleConditionIdList.push(ruleConditionId);
    }
    const RulePageClient = XemelgoService.getClient().getRulePageClient();
    await RulePageClient.updateSubscriptionForRuleConditions(
      subscribedRuleConditionIdList,
      unsubscribedRuleConditionIdList
    );
    await this.props.onLoad();
  };

  render() {
    const { conditions, dropDownOptions } = this.state;

    const headers = [
      {
        displayName: "",
        id: "subscribed",
        cell: {
          input: "switch",
          display: "switch",
          modifiable: true // whether it can be edited after being added
        }
      },
      {
        displayName: "Location",
        id: "locationName",
        cell: {
          input: "dropdown",
          data: dropDownOptions,
          display: "text",
          modifiable: false // whether it can be edited after being added
        }
      }
    ];

    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Shipment Issue</p>
          <p style={{ color: "#343434" }}>Sends a notification if there is an issue with shipment</p>
        </div>
        <div className="note">
          <span>Receive alerts for the locations listed:</span>
        </div>
        <EditableTableComponent
          handleSubscriptionChange={this.handleSubscriptionChange}
          headers={headers}
          dataList={conditions}
          handleChangesFn={this.handleRuleConditionRequests}
        />
      </div>
    );
  }
}
