import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "../../ProgressBar";
import Style from "./GridCardContentGroupbyItemType.module.css";

const GridCardContentGroupbyItemType = ({
  data,
  color,
  statusTab,
  title = {},
  image,
  contentList,
  progressBarEnabled,
  showTotalQuantity,
  showTotalWeight
}) => {
  const {
    minStockThreshold,
    totalItemCount,
    incomingItemCount,
    expiredItemCount,
    expiringSoonItemCount,
    totalQuantity,
    totalWeight
  } = data;

  return (
    <div className={Style.container}>
      <div
        title={title.value}
        className={Style.name}
      >
        {`${title.label ? `${title.label} ` : ""}${title.value || ""}`}
      </div>
      <div className={Style.second_container}>
        <div className={Style.third_container}>
          {image && (
            <img
              className={Style.image}
              src={image}
              alt="failed to load img"
            />
          )}
          {!!contentList?.length && (
            <div className={`${Style.data_group} ${!image && !progressBarEnabled && Style.data_group_no_image}`}>
              {contentList.map((eachContent) => {
                return (
                  <div
                    key={eachContent.id}
                    className={Style.item}
                  >
                    <p className={Style.item_label}>{`${eachContent.label ? `${eachContent.label} ` : ""}`}</p>
                    <p
                      title={eachContent.value}
                      className={Style.item_value}
                    >
                      {`${eachContent.value !== null && eachContent.value !== undefined ? eachContent.value : ""}`}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {progressBarEnabled && (
          <ProgressBar
            min={minStockThreshold}
            value={showTotalQuantity ? totalQuantity : totalItemCount}
            incoming={!showTotalWeight && incomingItemCount}
            healthColor={color}
            healthDetermineBy={statusTab}
            expired={expiredItemCount}
            soonToExpire={expiringSoonItemCount}
            totalWeight={showTotalWeight && totalWeight}
            showTotalWeight={showTotalWeight}
          />
        )}
      </div>
    </div>
  );
};

export default GridCardContentGroupbyItemType;

GridCardContentGroupbyItemType.defaultProps = {
  data: {},
  color: "",
  statusTab: "",
  title: {},
  image: {},
  contentList: [],
  progressBarEnabled: false
};

GridCardContentGroupbyItemType.propTypes = {
  data: PropTypes.object,
  color: PropTypes.string,
  statusTab: PropTypes.string,
  title: PropTypes.object,
  image: PropTypes.object,
  contentList: PropTypes.arrayOf(PropTypes.object),
  progressBarEnabled: PropTypes.bool
};
