import { TransferOrderDetails } from "../../contracts/TransferOrderDetails";
import { TransferOrderItem } from "../../contracts/TransferOrderItem";


 /**
   * Maps a transfer order node to order information.
   * 
   * @param {TransferOrderDetails} orderInfo - The order information to be mapped to.
   * @param {Object} transferOrderNode - The transfer order node to be mapped.
   * @returns {TransferOrderDetails} - The mapped order information.
   */
  const mapGraphToOrderInfo = (orderInfo, transferStatuses, transferOrderNode) => {
    const tsProperties = Object.keys(transferOrderNode).filter((key) => {return key.endsWith("_ts")});
    tsProperties.forEach((key) => {
      orderInfo.ts_properties[key] = transferOrderNode[key];
    });

    const items = transferOrderNode.itemTypeDetailList?.map((item) => {
      const status = calculateStatus(item);
      const itemTSProperties = Object.keys(item).filter((key) => {return key.endsWith("_ts")});
      const tsProps = {};
      itemTSProperties.forEach((key) => {
        tsProps[key] = item[key];
      });

      const orderItem = new TransferOrderItem(
        item.itemTypeIdentifier, 
        item.itemTypeName, 
        item.totalQuantity, 
        item.receivedQuantity, 
        status, 
        new Date(item.node_last_updated_time).toLocaleString(), 
        tsProps
      );

      return orderItem;
    });

    const order = new TransferOrderDetails(
      transferOrderNode.identifier,
      transferOrderNode.transferStatus, 
      transferStatuses.filter((status) => {return status.status === transferOrderNode.transferStatus}),
      new Date(transferOrderNode.node_last_updated_time).toLocaleString(),
      items,
      transferOrderNode.transferFrom,
      transferOrderNode.transferTo,
      tsProperties
    );

    return order;
  };

const calculateStatus = (item) => {
  if ((item.receivedQuantity ?? 0) === 0) {
    return "INCOMPLETE";
  } 

  if ((item.receivedQuantity ?? 0) === item.totalQuantity) {
    return "MATCH";
  }

  if ((item.receivedQuantity ?? 0) < item.totalQuantity) {
    return "PARTIAL";
  } 

  return "EXTRA";
}

export {
    mapGraphToOrderInfo
}