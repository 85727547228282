import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import LoadingCircle from "../../../../../../../../components/loading/LoadingCircle";
import useQueuePriorityWorkflowControlContext from "../../contexts/queue-priority-workflow-control-context";
import Style from "./EditSortPreference.module.css";
import { STEP_ID_MAP } from "../../data/constants";
import useWorkOrderTrackPageDataSourceContext from "../../../../../../contexts/work-order-track-page-data-source-context";
import RankingControlTable from "../../../../../../../../components/ranking-control-table";
import { useXemelgoClient } from "../../../../../../../../services/xemelgo-service";
import useWorkOrderTrackPageStateContext from "../../../../../../contexts/work-order-track-page-state-context";

export const EditSortPreference = ({
  optionControl: { sortPreferenceAttributeMap, sortPreferenceSections, sortPreferenceKey }
}) => {
  const xemelgoClient = useXemelgoClient();
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const { fetchLocationTreeFn } = useWorkOrderTrackPageStateContext();

  const {
    setModalHeader,
    setModalFooterButtons,
    setCurrentStep,
    selectedLocationIds,
    setSelectedLocationIds,
    previousStep
  } = useQueuePriorityWorkflowControlContext();

  const [sortPreference, setSortPreference] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { [sortPreferenceKey]: sortPreferenceAttributeListString } = locationTreeMap[selectedLocationIds?.[0]] || {};
    const sortPreferenceAttributeList = JSON.parse(sortPreferenceAttributeListString || "[]") || [];
    const newSortPreference = sortPreferenceAttributeList
      .map((eachAttribute) => {
        return sortPreferenceAttributeMap[eachAttribute];
      })
      .filter((eachSortPreference) => {
        return eachSortPreference;
      });

    setSortPreference(newSortPreference);
  }, [locationTreeMap, sortPreferenceAttributeMap]);

  const sortPreferenceSectionsData = useMemo(() => {
    return sortPreferenceSections.map((eachSection) => {
      const { id, label, attributes } = eachSection;
      return {
        id,
        label,
        data: attributes.map((eachAttribute) => {
          return sortPreferenceAttributeMap[eachAttribute];
        })
      };
    });
  }, [sortPreferenceAttributeMap, sortPreferenceSections]);

  const locationDisplayNamesString = useMemo(() => {
    return selectedLocationIds.reduce((accumulator, eachLocationId) => {
      return `${accumulator}${accumulator ? ", " : ""}${locationTreeMap[eachLocationId].name}`;
    }, "");
  }, [selectedLocationIds]);

  const updateSortPreference = async (sortPreferenceParam) => {
    setIsLoading(true);
    const sortPreferencePayload = sortPreferenceParam.map(({ id }) => {
      return id;
    });
    const locationClient = xemelgoClient.getLocationClient();
    const promiseList = selectedLocationIds.map((eachLocationId) => {
      return locationClient.updateLocationV2(eachLocationId, {
        [sortPreferenceKey]: sortPreferencePayload.length ? JSON.stringify(sortPreferencePayload) : null
      });
    });
    await Promise.all(promiseList);
    await fetchLocationTreeFn();
    setIsLoading(false);
  };

  const header = () => {
    return (
      <div>
        <div className={Style.header_title}>{`Sort Preferences - ${locationDisplayNamesString}`}</div>
        <p className={Style.header_subtext}>
          You can view orders sorted based on the preference by selecting data points you want to sort the orders by
        </p>
      </div>
    );
  };

  useEffect(() => {
    setModalHeader(header);
    setModalFooterButtons([
      {
        id: "back",
        title: "Back",
        onClick: () => {
          setCurrentStep(previousStep);
          setSelectedLocationIds([]);
        }
      },
      {
        id: "confirm",
        title: "Confirm",
        disabled: isLoading,
        onClick: async () => {
          await updateSortPreference(sortPreference);
          setCurrentStep(STEP_ID_MAP.allLocationList);
        },
        className: Style.save_button
      }
    ]);
  }, [sortPreference, isLoading]);

  return (
    <div className={Style.container}>
      {isLoading && <LoadingCircle />}
      <div className={isLoading ? Style.table_container_disabled : ""}>
        <RankingControlTable
          value={sortPreference}
          onChange={(newSortPreference) => {
            setSortPreference(newSortPreference);
          }}
          optionSections={sortPreferenceSectionsData}
        />
      </div>
    </div>
  );
};

EditSortPreference.defaultProps = {
  optionControl: {},
  onModalClose: () => {}
};

EditSortPreference.propTypes = {
  onModalClose: PropTypes.func,
  optionControl: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    locationCategories: PropTypes.arrayOf(PropTypes.string),
    sortPreferenceKey: PropTypes.string,
    sortPreferenceAttributeMap: PropTypes.object,
    sortPreferenceSections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        attributes: PropTypes.arrayOf(PropTypes.string)
      })
    )
  })
};
