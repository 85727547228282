import { useReducer, useEffect, useRef } from "react";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";

export const ACTION_TYPE = {
  RESET: "reset",
  FETCHING: "fetching",
  FETCHING_NEXT_PAGE: "fetchingNextPage",
  PUBLISH_DATA: "publishData"
};

const initialState = {
  dataList: [],
  isLoading: true,
  hasNextPage: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return { ...state, dataList: [], isLoading: initialState.isLoading, hasNextPage: initialState.hasNextPage };
    case ACTION_TYPE.FETCHING:
      return { ...state, dataList: [], isLoading: true, hasNextPage: false };
    case ACTION_TYPE.FETCHING_NEXT_PAGE:
      return { ...state, hasNextPage: true };
    case ACTION_TYPE.PUBLISH_DATA:
      return {
        ...state,
        isLoading: false,
        dataList: [...state.dataList, ...action.payload.dataList],
        hasNextPage: false
      };
    default:
      return state;
  }
};

export const useFetchItemTypeHook = ({ locationIds: locationIdsParam, filterString: filterStringParam }) => {
  const xemelgoAppSyncClient = useXemelgoAppsyncClient();

  const startTimeRef = useRef();

  const [state, dispatch] = useReducer(reducer, {
    dataList: initialState.dataList,
    isLoading: initialState.isLoading,
    hasNextPage: initialState.hasNextPage
  });

  const fetchItemTypesByLocationIds = async (locationIds) => {
    const startTime = Date.now();
    startTimeRef.current = startTime;

    const inventoryAppSyncClient = xemelgoAppSyncClient.getInventoryClient();

    dispatch({ type: ACTION_TYPE.FETCHING });

    const response = await inventoryAppSyncClient.getItemTypesByLocationIds(locationIds, filterStringParam || "");

    if (startTimeRef.current === startTime) {
      dispatch({ type: ACTION_TYPE.PUBLISH_DATA, payload: { dataList: response.itemTypes } });
    }
    let { nextTokens } = response;

    while (
      startTimeRef.current === startTime &&
      nextTokens.filter((eachToken) => {
        return !!eachToken;
      }).length !== 0
    ) {
      dispatch({ type: ACTION_TYPE.FETCHING_NEXT_PAGE });
      const { itemTypes, nextTokens: newNextTokens } = await inventoryAppSyncClient.getItemTypesByLocationIds(
        locationIds,
        filterStringParam || "",
        nextTokens
      );

      if (startTimeRef.current === startTime) {
        dispatch({ type: ACTION_TYPE.PUBLISH_DATA, payload: { dataList: itemTypes } });
      }
      nextTokens = newNextTokens;
    }
  };

  useEffect(() => {
    if (!locationIdsParam?.length) {
      dispatch({ type: ACTION_TYPE.PUBLISH_DATA, payload: { dataList: [] } });
    } else {
      fetchItemTypesByLocationIds(locationIdsParam);
    }
  }, [locationIdsParam, filterStringParam]);

  return state;
};
