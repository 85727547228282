import React from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import PropTypes from "prop-types";
import Style from "./CollapsibleView.module.css";

export const CollapsibleView = ({ children, onClick, isCollapsed }) => {
  return (
    <>
      <div
        className={`${Style.flex_row} ${Style.information_collapsible_button} ${
          isCollapsed && Style.information_collapsible_button_collapsed
        }`}
        onClick={onClick}
      >
        {isCollapsed ? <ChevronLeft /> : <ChevronRight />}
      </div>
      <div
        className={`${Style.flex_column} ${Style.information_container} ${
          isCollapsed && Style.information_container_collapsed
        }`}
      >
        {children}
      </div>
    </>
  );
};

CollapsibleView.defaultProps = {
  children: null,
  onClick: null,
  isCollapsed: false
};

CollapsibleView.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  isCollapsed: PropTypes.bool
};
