import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Div } from "../../../../../components/div";
import { XButton } from "../../../../../components/xemelgo-button";

export const AddLocationFormHeader = ({ label, className, onCancel }) => {
  return (
    <Div className={`add-location-form-header ${className}`}>
      <Div className="add-location-form-title">{label}</Div>
      <Div className="add-location-form-operations">
        <XButton
          onClick={onCancel}
          label="X"
          className="cancel-button"
        />
      </Div>
    </Div>
  );
};

AddLocationFormHeader.defaultProps = {
  className: "",
  onCancel: () => {}
};

AddLocationFormHeader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  onCancel: PropTypes.func
};
