import React from "react";
import PropTypes from "prop-types";
import Style from "./ActionButtons.module.css";

const ButtonRenderer = ({buttonClassName, buttonText, onClick, disabled, visible}) => {
  if (!visible) {
    return null;
  }
  return (
    <button className={buttonClassName} disabled={disabled} onClick={onClick}>
      {buttonText}
    </button>);
};

const ActionButtons = ({
  cancelButtonStyle,
  cancelButtonText,
  onCancel,
  cancelDisabled,
  cancelVisible,
  confirmButtonStyle,
  confirmButtonText,
  onConfirm,
  confirmDisabled,
  confirmVisible
}) => {
  return (
    <div className={Style.footer_container}>
      <div className={Style.button_container}>
        <ButtonRenderer buttonClassName={cancelButtonStyle} buttonText={cancelButtonText} onClick={onCancel} disabled={cancelDisabled} visible={cancelVisible}/>

        <ButtonRenderer buttonClassName={confirmButtonStyle} buttonText={confirmButtonText} onClick={onConfirm} disabled={confirmDisabled} visible={confirmVisible}/>
      </div>
    </div>
  );
};

ActionButtons.defaultProps = {
  cancelButtonStyle: Style.cancel_button,
  cancelButtonText: "Cancel",
  onCancel: () => {},
  cancelDisabled: false,
  cancelVisible: true,
  confirmButtonStyle: Style.confirm_button,
  confirmButtonText: "Confirm",
  confirmDisabled: false,
  confirmVisible: true,
};

ActionButtons.propTypes = {
  cancelButtonStyle: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelDisabled: PropTypes.bool,
  cancelVisible: PropTypes.bool,
  confirmButtonStyle: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmDisabled: PropTypes.bool,
  confirmVisible: PropTypes.bool
};

export default ActionButtons;
