import calculateLabelContainerSize from "../calculate-label-container-size";

const EXTRA_SMALL_FONT = "14,10";
const SMALL_FONT = "24,14";
const MEDIUM_FONT = "32,20";
const LARGE_FONT = "44,24";
const EXTRA_LARGE_FONT = "68,40";

export const generateTagLabelsCommand = (labelList, tagSizeInDots, workOrderStatus) => {
  const { x, y } = tagSizeInDots;
  let headersPerRow = 3;
  let numberOfColumns = 3;
  let numberOfRows = 2;

  // label's attributes size
  let newLineGap = 50;
  let labelGap = 50;
  let headerHeight = 50;

  // font size
  let headerFontSize = MEDIUM_FONT;
  let dataFontSize = LARGE_FONT;

  // 300dpi 4x6 tag properties
  if (x === 1800 && y === 1200) {
    newLineGap = 75;
    headerFontSize = LARGE_FONT;
    dataFontSize = EXTRA_LARGE_FONT;
  }

  // 300dpi 2x4 tag
  if (x === 1200 && y === 600) {
    headerFontSize = SMALL_FONT;
    dataFontSize = MEDIUM_FONT;
  }

  // 203dpi 2x4 tag
  if (x === 812 && y === 406) {
    newLineGap = 25;
    labelGap = 25;
    headerHeight = 25;
    headerFontSize = EXTRA_SMALL_FONT;
    dataFontSize = SMALL_FONT;
  }

  // overSizeTag;
  if (labelList.length > 6) {
    headersPerRow = 4;
    numberOfColumns = 4;
    numberOfRows = 3;
    headerFontSize = MEDIUM_FONT;
    dataFontSize = LARGE_FONT;
  }
  const { containerWidth, containerHeight } = calculateLabelContainerSize(
    tagSizeInDots,
    numberOfColumns,
    numberOfRows,
    workOrderStatus
  );

  const headerContainerSize = `${containerWidth},100`;
  const columnWidth = containerWidth + labelGap;
  const rowHeight = containerHeight + labelGap + headerHeight;

  const sortedLabelList = labelList.sort((a, b) => {
    return a.index - b.index;
  });

  return sortedLabelList
    .map((eachLabel) => {
      let isHeaderOversize = false;
      const { label, index, value } = eachLabel;
      const columnIndex = index % headersPerRow;
      const rowIndex = Math.floor(index / headersPerRow);

      if (label.length > 12) {
        isHeaderOversize = true;
      }
      // vertical tag
      if (x < y) {
        return `
        ^FO${625 - rowIndex * (225 + newLineGap)},${
          100 + columnIndex * (306 + labelGap)
        }^AAN,32,20^TBR,306,100^FD${label}:^FS
        ^FO${500 - rowIndex * (225 + newLineGap)},${100 + columnIndex * (306 + labelGap)}^AAN,44,24^TBR,306,${
          isHeaderOversize ? 135 : 160
        }^FD${value}^FS`;
      }

      // normal orientation tag
      return `
      ^FO${100 + columnIndex * columnWidth},${
        100 + rowIndex * rowHeight
      }^AAN,${headerFontSize}^TBN,${headerContainerSize}^FD${label}:^FS
      ^FO${100 + columnIndex * columnWidth},${
        100 + newLineGap + rowIndex * rowHeight + (isHeaderOversize ? 25 : 0)
      }^AAN,${dataFontSize}^TBN,${containerWidth},${containerHeight - (isHeaderOversize ? 25 : 0)}^FD${value}^FS`;
    })
    .join("");
};
