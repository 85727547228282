import React, { useMemo } from "react";
import Slides from "../../../../../../components/slides";
import PieChart from "../../../../components/pie-chart";
import useInventoryTrackPageDataSourceContext from "../../../../contexts/inventory-track-page-data-source-context";
import useInventoryTrackPageConfigContext from "../../../../contexts/inventory-track-page-config-context";
import useInventoryTrackPageStateContext from "../../../../contexts/inventory-track-page-state-context";
import Style from "./Charts.module.css";

export const Charts = () => {
  const { currentLocationMetrics, itemTypesByLocationState, lotsByLocationState, isLocationTreeMapLoading } =
    useInventoryTrackPageDataSourceContext();

  const { chartsControl } = useInventoryTrackPageConfigContext();

  const { selectedViewModeId, selectedViewTypeId } = useInventoryTrackPageStateContext();

  const chartsData = useMemo(() => {
    return chartsControl
      .filter((eachChartControl) => {
        const { viewMode, tableViewType } = eachChartControl || {};
        if (viewMode && !viewMode.includes(selectedViewModeId) && !viewMode.includes("all")) {
          return false;
        }

        if (tableViewType && !tableViewType.includes(selectedViewTypeId) && !tableViewType.includes("all")) {
          return false;
        }

        return true;
      })
      .map((eachChartControl) => {
        const { id, label, subLabel, dataControl } = eachChartControl || {};
        const data = dataControl.reduce(
          (accumulator, eachDataControl) => {
            return [...accumulator, [eachDataControl[0], currentLocationMetrics[eachDataControl[1]] || 0]];
          },
          [["Status", "Count"]]
        );
        return { id, label, subLabel, data };
      });
  }, [currentLocationMetrics, chartsControl, selectedViewModeId, selectedViewTypeId]);

  const isLoading = useMemo(() => {
    return (
      itemTypesByLocationState.isLoading ||
      itemTypesByLocationState.hasNextPage ||
      lotsByLocationState.isLoading ||
      lotsByLocationState.hasNextPage
    );
  }, [
    itemTypesByLocationState.isLoading,
    itemTypesByLocationState.hasNextPage,
    lotsByLocationState.isLoading,
    lotsByLocationState.hasNextPage
  ]);

  if (!chartsData.length) {
    return null;
  }

  return (
    <div className={Style.group}>
      <Slides
        id="inventory-track-page-charts-slides"
        numOfSlidesPerPage={1}
        numOfRowsPerPage={1}
        data={chartsData}
        renderSlideContent={(eachChartControl) => {
          return (
            <PieChart
              isLoading={isLocationTreeMapLoading || isLoading}
              key={eachChartControl.id}
              title={eachChartControl.label}
              subTitle={eachChartControl.subLabel}
              data={eachChartControl.data}
            />
          );
        }}
      />
    </div>
  );
};
