import React, { useEffect } from "react";
import Style from "./ViewMode.module.css";
import useInventoryTrackPageStateContext from "../../contexts/inventory-track-page-state-context";
import useInventoryTrackPageConfigContext from "../../contexts/inventory-track-page-config-context";

export const ViewMode = () => {
  const { selectedViewModeId, setSelectedViewModeId, isSearchParamReady } = useInventoryTrackPageStateContext();
  const { viewModes, isLoading: isConfigLoading } = useInventoryTrackPageConfigContext();

  useEffect(() => {
    if (
      !isConfigLoading &&
      isSearchParamReady &&
      !viewModes?.find(({ id: eachViewModeId }) => {
        return eachViewModeId === selectedViewModeId;
      })
    ) {
      setSelectedViewModeId(viewModes?.[0]?.id || "");
    }
  }, [selectedViewModeId, viewModes, isConfigLoading, isSearchParamReady]);

  return (
    <div className={Style.container}>
      {viewModes?.map(({ id: eachViewModeId, label }, index) => {
        return (
          <div
            key={eachViewModeId}
            className={Style.button_container}
          >
            <button
              className={`${Style.view_mode_button} ${
                selectedViewModeId === eachViewModeId && Style.view_mode_button_selected
              }`}
              type="button"
              onClick={() => {
                setSelectedViewModeId(eachViewModeId);
              }}
            >
              {label}
            </button>
            {viewModes.length - 1 > index && <div className={Style.divider} />}
          </div>
        );
      })}
    </div>
  );
};
