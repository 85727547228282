import React, { useContext, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { getCombinedConfiguration } from "../../side-filter/services/side-filter-configuration-provider/sideFilterConfigurationProvider";
import { REPORT_TYPES } from "../../report-detail-feature/data/constant";
import { BaseReportFilters } from "../data/side-filter-configurations/BaseReportFilters";
import { ApprovalReportFilters } from "../data/side-filter-configurations/ApprovalReportFilters";

const ReportsPageContext = React.createContext();

const baseReportFilters = new BaseReportFilters();
const approvalReportFilters = new ApprovalReportFilters();

const initialState = {
  selectedClassFilter: null,
  searchInputText: "",
  reportList: [],
  sideFilters: { startTime: null, endTime: null },
  selectedTypeFilters: {}
};

export const useReportsPageContext = () => {
  return useContext(ReportsPageContext);
};

export const ReportsPageContextProvider = ({ children }) => {
  const history = useHistory();
  const [selectedClassFilter, setSelectedClassFilter] = useState(initialState.selectedClassFilter);
  const [searchInputText, setSearchInputText] = useState(initialState.searchInputText);
  const [reportList, setReportList] = useState(initialState.reportList);
  const [sideFilter, setSideFilter] = useState(initialState.sideFilters);
  const [selectedTypeFilter, setSelectedTypeFilter] = useState(initialState.selectedTypeFilters);
  const [reportSideFilterConfiguration, setReportSideFilterConfiguration] = useState({});

  useEffect(() => {
    const baseConfig = baseReportFilters.getConfiguration();
    let reportSpecificConfig = {};
    switch (selectedTypeFilter.id) {
      case REPORT_TYPES.approval:
        const limitToStates = allowedReportStates;
        reportSpecificConfig = approvalReportFilters.getConfiguration({ reportList, limitToStates });
        break;
      default:
        reportSpecificConfig = {};
    }

    setReportSideFilterConfiguration(getCombinedConfiguration([baseConfig, reportSpecificConfig]));
  }, [selectedTypeFilter, reportList, sideFilter, allowedReportStates]);

  const allowedReportStates = useMemo(() => {
    switch (selectedTypeFilter.id) {
      case "Removed Report":
        if (history.location.pathname.includes("inventory/approvals")) {
          return ["PENDING"];
        }
        return ["APPROVED", "AUTO_APPROVED"];

      default:
        return null;
    }
  }, [selectedTypeFilter]);

  return (
    <ReportsPageContext.Provider
      value={{
        selectedClassFilter,
        setSelectedClassFilter,
        searchInputText,
        setSearchInputText,
        reportList,
        setReportList,
        sideFilter,
        setSideFilter,
        reportSideFilterConfiguration,
        selectedTypeFilter,
        setSelectedTypeFilter,
        allowedReportStates
      }}
    >
      {children}
    </ReportsPageContext.Provider>
  );
};
