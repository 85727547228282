import PropTypes from "prop-types";

const BATCH_SIZE = 50;

export const createItems = async (payloadsByTransferOrders, inventoryClient, itemClient) => {
  const addItemEntriesPayloadsByTransferOrders = {};

  for (const payloadsByItemTypes of Object.values(payloadsByTransferOrders)) {
    for (const payloadsByItemType of Object.values(payloadsByItemTypes)) {
      const promises = [];
      const { payloads, locationId, transferOrder, itemNumber } = payloadsByItemType;
      const clonedPayloads = [...payloads];

      while (clonedPayloads.length) {
        const currentSlice = clonedPayloads.splice(0, BATCH_SIZE);
        promises.push(inventoryClient.createItemSet(currentSlice, locationId));
      }

      const [result] = await Promise.all(promises);
      const { createItemSet } = result;
      const itemIds = (await itemClient.getItemsBySensorProfileVids(createItemSet)).map((item) => {
        return item.itemId;
      });

      if (!addItemEntriesPayloadsByTransferOrders[transferOrder.value]) {
        addItemEntriesPayloadsByTransferOrders[transferOrder.value] = {
          transferOrderId: transferOrder.id,
          itemEntries: {}
        };
      }

      addItemEntriesPayloadsByTransferOrders[transferOrder.value].itemEntries[itemNumber.value] = {
        id: itemNumber.id,
        quantity: itemIds.length,
        itemIds
      };
    }
  }
  return addItemEntriesPayloadsByTransferOrders;
};

const PayloadByTransferOrderType = PropTypes.shape({
  payloads: PropTypes.array.isRequired,
  locationId: PropTypes.string.isRequired,
  transferOrder: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  itemNumber: PropTypes.shape({
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired
});

const InventoryClientType = PropTypes.shape({
  createItemSet: PropTypes.func.isRequired
});

const ItemClientType = PropTypes.shape({
  getItemsBySensorProfileVids: PropTypes.func.isRequired
});
createItems.PropTypes = {
  payloadsByTransferOrders: PropTypes.objectOf(PropTypes.objectOf(PayloadByTransferOrderType)).isRequired,
  inventoryClient: InventoryClientType.isRequired,
  itemClient: ItemClientType.isRequired
};
