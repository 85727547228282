import React from "react";
import PropTypes from "prop-types";
import Style from "../../InventoryOrderDetailFeature.module.css";
import StatusBar from "../status-bar";

export const InventoryOrderDetailRow = ({ orderTableConfig, orderData }) => {

    const columns = orderTableConfig;

    const renderColumnData = (column, orders, index) => {
        const propertyBag = column.property.endsWith("_ts") ? orders.ts_properties : orders;
        const key = column.property + index;
        switch (column.type) {
            case "status":
                return (
                  <div key={key} className={Style.table_item} style={{ flex: column.flex ?? 1, minWidth: column.minWidth ?? "0px" }}>
                    <StatusBar status={propertyBag[column.property]} />
                  </div>
                );
            case "number":
                return (
                  <div key={key} className={`${Style.table_item} ${Style.table_item_text}`} style={{ flex: column.flex ?? 1, minWidth: column.minWidth ?? "0px" }}>
                    <p>{propertyBag[column.property] || "0"}</p>
                  </div>
                );
            default:
                return (
                  <div key={key} className={`${Style.table_item} ${Style.table_item_text}`} style={{ flex: column.flex ?? 1, minWidth: column.minWidth ?? "0px" }}>
                    <p>{propertyBag[column.property] || "-"}</p>
                  </div>
                );
        }
    };

    const renderColumn = (order) => {
        return columns.map((column, index) => {
            return renderColumnData(column, order, index);
        });
    };

    return (
      <div
        key={orderData.id}
        className={`${Style.flex_row}`}
      >
        {/* Order Table config will contain the header information */}
        {renderColumn(orderData)}
      </div>
    );
};

InventoryOrderDetailRow.defaultProp = {
    orderTableConfig: [],
    orderData: {}
};

InventoryOrderDetailRow.propTypes = {
    orderTableConfig: PropTypes.array.isRequired,
    orderData: PropTypes.object.isRequired
};
