import React from "react";
import Style from "./InventoryInfoTable.module.css";
import TableControlActions from "./features/table-control-actions";
import TableDisplay from "./features/table-display";

export const InventoryInfoTable = () => {
  return (
    <div className={Style.container}>
      <TableControlActions />
      <TableDisplay />
    </div>
  );
};
