import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../../../../../../../../../components/modal";
import Style from "./UnexpectedItemsModal.module.css";
import ScannedItemTable from "../scanned-item-table";
import { DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS } from "../../../../data/constants";

export const UnexpectedItemsModal = ({ onConfirm, items, headers }) => {
  const sortedItems = useMemo(() => {
    const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

    return items.sort((a, b) => {
      return collator.compare(a.identifier, b.identifier);
    });
  }, [items]);

  return (
    <Modal
      title="Unexpected Items Detected"
      onConfirm={onConfirm}
      showCancelButton={false}
      confirmButtonText="OK"
      modalDialogClassName={Style.modal_dialog}
    >
      <div>
        <span className={Style.bold_text}>{items.length}</span>
        {` item${items.length > 1 ? "s that were scanned are" : " that was scanned is"} not part of this order. `}
        Scan the QR code in the search tab to locate an item using your handheld device.
      </div>
      <ScannedItemTable
        headers={headers}
        items={sortedItems}
      />
    </Modal>
  );
};

UnexpectedItemsModal.defaultProps = {
  items: [],
  headers: DEFAULT_UNEXPECTED_ITEMS_TABLE_HEADERS,
  onConfirm: () => {}
};

UnexpectedItemsModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  onConfirm: PropTypes.func
};
