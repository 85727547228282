class TransferOrderItem {
  constructor(itemTypeIdentifier, itemTypeName, expectedQuantity, scannedQuantity, status, timestamp, tsProperties, flex) {
    this.itemTypeIdentifier = itemTypeIdentifier;
    this.itemTypeName = itemTypeName;
    this.expectedQuantity = expectedQuantity ?? 0;
    this.scannedQuantity = scannedQuantity ?? 0;
    this.status = status;
    this.timestamp = timestamp;
    this.ts_properties = tsProperties ?? {};
    this.flex = flex ?? 1;
  }
}

export { TransferOrderItem };