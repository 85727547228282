export const getDetectorResourceManager = (xemelgoClient) => {
  return {
    createDetectorAndAttachToLocation: async (payload) => {
      const { vid, class: detectorType, location: locationId, action: actionKey, protocol, vendor } = payload;
      let actions = null;

      // TODO: this should be moved into xemelgo client
      switch (actionKey) {
        case "endTrackingSession":
          actions = {
            endTrackingSession: {
              detachSensorProfiles: true
            }
          };
          break;
        case "sameDetectorEndTrackingSession":
          actions = {
            sameDetectorEndTrackingSession: true
          };
          break;
        case "shipping":
          actions = {
            shipment: {
              value: "In Transit"
            }
          };
          break;
        case "receiving":
          actions = {
            shipment: {
              value: "Received"
            }
          };
          break;
        case "increaseItemUsage":
          actions = {
            increaseItemUsage: true,
            resetRefreshDate: true
          };
          break;
        case "resetItemUsage":
          actions = {
            resetItemUsage: true
          };
          break;
        case "entryExit":
          actions = {
            flipHasExitState: true
          };
          break;
        default:
          break;
      }

      const detectorClient = xemelgoClient.getDetectorClient();
      let newDetector;
      try {
        if (locationId) {
          newDetector = await detectorClient.createAndAttachDetectorToLocation(
            locationId,
            detectorType,
            vendor,
            vid,
            protocol,
            actionKey === "endTrackingSession",
            vid,
            actions
          );
        } else {
          newDetector = await detectorClient.createDetector(
            detectorType,
            vendor,
            vid,
            protocol,
            actionKey === "endTrackingSession",
            vid,
            actions
          );
        }
      } catch (error) {
        throw error;
      }
      return newDetector;
    }
  };
};
