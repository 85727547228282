import { cloneDeep } from "lodash";

/**
 *  Asset type metrics are only at the leaf level so they need to be aggregated for parent locations
 */
export const aggregateAssetTypeCounts = (locationMap, assetTypeMetricsByLocation) => {
  const locationsToProcessSet = new Set(Object.keys(assetTypeMetricsByLocation));
  const processedLocationsSet = new Set();

  return recursiveAggregateAssetTypeCounts(
    locationMap,
    assetTypeMetricsByLocation,
    locationsToProcessSet,
    processedLocationsSet
  );
};

const recursiveAggregateAssetTypeCounts = (
  locationMap,
  assetTypeMetricsByLocation,
  locationsToProcessSet,
  processedLocationsSet
) => {
  if (!locationsToProcessSet.size) {
    return assetTypeMetricsByLocation;
  }

  const newAssetTypeMetricsByLocation = { ...assetTypeMetricsByLocation };
  const nextLocationsToProcessSet = new Set();
  locationsToProcessSet.forEach((locationId) => {
    if (processedLocationsSet.has(locationId)) {
      return;
    }
    processedLocationsSet.add(locationId);

    const { directParentId } = locationMap[locationId];
    if (directParentId) {
      nextLocationsToProcessSet.add(directParentId);
    }

    if (!newAssetTypeMetricsByLocation[directParentId]) {
      newAssetTypeMetricsByLocation[directParentId] = assetTypeMetricsByLocation[locationId];
      return;
    }

    const parentLocationAssetType = cloneDeep(newAssetTypeMetricsByLocation[directParentId]);

    Object.keys(assetTypeMetricsByLocation[locationId]).forEach((assetTypeId) => {
      const assetTypeCounts = assetTypeMetricsByLocation[locationId][assetTypeId].counts;

      if (!parentLocationAssetType[assetTypeId]) {
        parentLocationAssetType[assetTypeId] = {
          ...assetTypeMetricsByLocation[locationId][assetTypeId]
        };
        return;
      }

      Object.keys(assetTypeCounts).forEach((eachCount) => {
        parentLocationAssetType[assetTypeId].counts[eachCount] =
          (parentLocationAssetType[assetTypeId].counts[eachCount] || 0) + assetTypeCounts[eachCount];
      });
    });
    newAssetTypeMetricsByLocation[directParentId] = parentLocationAssetType;
  });

  return recursiveAggregateAssetTypeCounts(
    locationMap,
    newAssetTypeMetricsByLocation,
    nextLocationsToProcessSet,
    processedLocationsSet
  );
};
