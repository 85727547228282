import React, { useState, useRef, useMemo, useEffect } from "react";
import Style from "./WOSettingsMenu.module.css";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import useOnClickOutside from "../../../../hooks/use-on-click-outside";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageDataSourceContext from "../../contexts/work-order-track-page-data-source-context";
import useSettingsBuilder from "./hooks/use-settings-builder";

export const WOSettingsMenu = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownContainerRef = useRef(null);
  const [showModalMap, setShowModalMap] = useState({});

  const { settings } = useWorkOrderTrackPageConfigContext();
  const { selectedLocationId } = useWorkOrderTrackPageStateContext();
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();

  useOnClickOutside(dropdownContainerRef, () => {
    setIsDropdownOpen(false);
  });

  const currentLocationCategory = useMemo(() => {
    return locationTreeMap[selectedLocationId]?.category || "root";
  }, [selectedLocationId, locationTreeMap]);

  const { settingOptions, initialModalMapVisibilityControl, SettingModalsRenderer } = useSettingsBuilder(
    settings,
    currentLocationCategory
  );

  useEffect(() => {
    setShowModalMap(initialModalMapVisibilityControl);
  }, [initialModalMapVisibilityControl]);

  if (!settingOptions.length) {
    return null;
  }

  return (
    <>
      <div
        ref={dropdownContainerRef}
        className={Style.dropdown_container}
      >
        <button
          className={`${Style.change_data_view_button} ${isDropdownOpen && Style.change_data_view_button_active}`}
          onClick={() => {
            setIsDropdownOpen((currentValue) => {
              return !currentValue;
            });
          }}
          type="button"
        >
          <SettingsIcon className={Style.change_data_view_icon} />
          <p className={Style.change_data_view_text}>Settings</p>
        </button>
        {isDropdownOpen && (
          <div className={Style.data_view_dropdown_background}>
            {settingOptions.map((eachOption) => {
              return (
                <div
                  key={eachOption.id}
                  className={`${Style.data_view_dropdown_item}`}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setShowModalMap({ ...showModalMap, [eachOption.id]: true });
                  }}
                >
                  {eachOption.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <SettingModalsRenderer
        modalMapVisibilityControl={showModalMap}
        onClose={(modalId) => {
          setShowModalMap({ ...showModalMap, [modalId]: false });
        }}
      />
    </>
  );
};
