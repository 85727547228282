/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import TrackPageComponentStyle from "./TrackPageComponent.module.css";
import ScreenFrame from "../../ScreenFrame/ScreenFrame";

const TrackPageComponent = ({
  mainColor,
  secondaryColor,
  title,
  titleIcon,
  overviewComponent,
  groupbyComponent,
  sidebarFeatureButtons,
  buttonTitle,
  buttonOnClick,
  breadcrumbsComponent,
  dataViewComponent,
  history,
  fullScreenMode
}) => {
  return (
    <ScreenFrame
      title={title}
      color={mainColor}
      secondaryColor={secondaryColor}
      titleIconComponent={titleIcon}
    >
      {overviewComponent}
      {!fullScreenMode && <div className={TrackPageComponentStyle.seperator} />}
      <div className={TrackPageComponentStyle.bottom_component_style}>
        {groupbyComponent && (
          <div className={TrackPageComponentStyle.side_bar_style}>
            {buttonOnClick ? (
              <div
                className={TrackPageComponentStyle.create_button}
                onClick={buttonOnClick}
              >
                {buttonTitle}
              </div>
            ) : (
              buttonTitle && (
                <Link
                  className={TrackPageComponentStyle.create_button}
                  to={`${history.location.pathname}/create`}
                  data-cy-track-page__create-button
                >
                  {buttonTitle}
                </Link>
              )
            )}
            {sidebarFeatureButtons}
            {groupbyComponent}
          </div>
        )}
        {groupbyComponent && (
          <div className={`${TrackPageComponentStyle.seperator} ${TrackPageComponentStyle.seperator_vertical}`} />
        )}
        <div className={TrackPageComponentStyle.data_view_group}>
          {!fullScreenMode && breadcrumbsComponent}
          <div className={TrackPageComponentStyle.data_view_container}>{dataViewComponent}</div>
        </div>
      </div>
    </ScreenFrame>
  );
};

export default withRouter(TrackPageComponent);

TrackPageComponent.defaultProps = {
  mainColor: "",
  secondaryColor: "",
  title: "",
  titleIcon: undefined,
  overviewComponent: undefined,
  groupbyComponent: undefined,
  buttonTitle: "",
  sidebarFeatureButtons: null,
  breadcrumbsComponent: undefined,
  dataViewComponent: undefined,
  history: {}
};

TrackPageComponent.propTypes = {
  mainColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.object,
  overviewComponent: PropTypes.object,
  groupbyComponent: PropTypes.object,
  buttonTitle: PropTypes.string,
  sidebarFeatureButtons: PropTypes.array,
  breadcrumbsComponent: PropTypes.object,
  dataViewComponent: PropTypes.object,
  history: PropTypes.object
};
