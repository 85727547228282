import React, { useState, useEffect, useMemo } from "react";
import { Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleOutlined from "@material-ui/icons/CheckCircleOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import Delete from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import getReportDateString from "../../../../utils/get-report-date-string";
import useApprovalReportUpdater from "../../hooks/use-approval-report-updater";
import useGetReportCsv from "../../../../hooks/use-get-report-csv";
import { NO_REPORT_URL_KEYWORD } from "../../../../data/constant";
import { TableActionBar } from "../../../../../../components/table-action-bar/TableActionBar";
import { ReportHeader } from "../../../../components/report-header/ReportHeader";
import { ApprovalReportTable } from "../approval-report-table/ApprovalReportTable";
import { APPROVAL_STATE } from "../../data/constants";
import ApproveConsumptionModal from "../approve-consumption-modal";
import RemoveConsumptionModal from "../remove-consumption-modal";

import Style from "./ApprovalReportDetail.module.css";
import getItemTypeRows from "../../util/create-report-rows";

const XemelgoTooltip = withStyles({
  tooltip: {
    border: "2px solid #4a90ff",
    color: "black",
    backgroundColor: "white"
  }
})(Tooltip);

const ApprovalReportDetail = ({ reportDetail }) => {
  const [rowsForApproval, setRowsForApproval] = useState([]);
  const [removedRows, setRemovedRows] = useState([]);
  const [selectedSummary, setSelectedSummary] = useState("");
  const [showDeleteConsumptionModal, setShowDeleteConsumptionModal] = useState(false);
  const [showApproveConsumptionModal, setShowApproveConsumptionModal] = useState(false);

  const { approveConsumption, removeFromConsumption } = useApprovalReportUpdater(reportDetail);
  const { getReportCsv } = useGetReportCsv();

  useEffect(() => {
    setRowsForApproval(
      getItemTypeRows(reportDetail.result, [
        APPROVAL_STATE.pending,
        APPROVAL_STATE.approved,
        APPROVAL_STATE.autoApproved
      ])
    );
    setRemovedRows(getItemTypeRows(reportDetail.result, [APPROVAL_STATE.standby]));
  }, [reportDetail]);

  const reportTimeString = useMemo(() => {
    return getReportDateString(reportDetail.node_start_time);
  }, [reportDetail]);

  const selectedCount = useMemo(() => {
    const count = rowsForApproval
      .reduce((items, itemType) => {
        return items.concat(itemType.items || []);
      }, [])
      .filter((item) => {
        return item.isChecked;
      }).length;

    return count;
  }, [rowsForApproval]);

  useEffect(() => {
    setSelectedSummary(`${selectedCount} selected`);
  }, [selectedCount]);

  const isPendingReport = useMemo(() => {
    return reportDetail?.state === APPROVAL_STATE.pending;
  }, [reportDetail]);

  const onSelectClick = (ids, newState) => {
    const newApprovalRows = [...rowsForApproval];
    ids.forEach((id) => {
      const matchingRow = newApprovalRows.find((row) => {
        return row.id === id;
      });

      if (matchingRow) {
        matchingRow.isChecked = newState;
        matchingRow.items.filter(isRowSelectable).forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.isChecked = newState;
        });
      }
    });
    setRowsForApproval(newApprovalRows);
  };

  const onChildSelectClick = (ids, newState) => {
    const newApprovalRows = [...rowsForApproval];
    for (const parentRow of newApprovalRows) {
      for (const childRow of parentRow.items || []) {
        if (ids.includes(childRow.id)) {
          childRow.isChecked = newState;
        }
      }

      const allChildrenSelected = (parentRow.items || []).filter(isRowSelectable).every((child) => {
        return child.isChecked;
      });

      parentRow.isChecked = allChildrenSelected;
    }

    setRowsForApproval(newApprovalRows);
  };

  const updateExpandedStateForRows = (rows, rowIds, newExpandedState) => {
    const newRows = [...rows];
    for (const id of rowIds) {
      const matchingRow = newRows.find((row) => {
        return row.id === id;
      });

      if (matchingRow) {
        matchingRow.isExpanded = newExpandedState;
      }
    }

    return newRows;
  };

  const isRowSelectable = (row) => {
    return !row.isAutoApproved;
  };

  const renderStandbyTooltipContent = () => {
    return (
      <div className={`${Style.tooltip_content_container}`}>
        <p>Items removed from consumption will be listed here and added back to your inventory when found.</p>
        <p>Otherwise, they will be marked as consumed as part of the approval report after a set time.</p>
      </div>
    );
  };

  const renderStandbyTooltip = () => {
    return (
      <XemelgoTooltip
        title={renderStandbyTooltipContent()}
        placement="top"
      >
        <HelpOutlineIcon />
      </XemelgoTooltip>
    );
  };

  const renderDeleteConsumptionModal = () => {
    return (
      <RemoveConsumptionModal
        show={showDeleteConsumptionModal}
        onCancel={() => {
          setShowDeleteConsumptionModal(false);
        }}
        onDelete={async () => {
          await removeFromConsumption(rowsForApproval);
        }}
      />
    );
  };

  const renderApproveConsumptionModal = () => {
    return (
      <ApproveConsumptionModal
        show={showApproveConsumptionModal}
        onCancel={() => {
          setShowApproveConsumptionModal(false);
        }}
        onConfirm={async () => {
          await approveConsumption(rowsForApproval);
        }}
      />
    );
  };

  const renderTableActionBar = () => {
    return (
      <TableActionBar
        summary={selectedSummary}
        visible={selectedCount > 0}
        actions={[
          {
            id: "deselectAll",
            title: "Deselect All",
            onClick: () => {
              onSelectClick(
                rowsForApproval.map((row) => {
                  return row.id;
                }),
                false
              );
            },
            color: "#4A90FF"
          },
          {
            id: "removeFromConsumption",
            title: "Remove from Consumption",
            onClick: () => {
              setShowDeleteConsumptionModal(true);
            },
            color: "#FF3D46",
            iconComponent: <Delete />
          }
        ]}
      />
    );
  };

  return (
    <>
      <div className={`${Style.flex_row} ${Style.action_button_container}`}>
        {reportDetail?.result?.reportLink && !reportDetail.result.reportLink.includes(NO_REPORT_URL_KEYWORD) && (
          <button
            type="button"
            className={`${Style.flex_row} ${Style.action_button}`}
            onClick={() => {
              getReportCsv(reportDetail.result.reportLink);
            }}
          >
            <GetAppIcon />
            Export as .CSV
          </button>
        )}
      </div>
      <ReportHeader
        headerText={`Consumption - Approval Report - ${reportDetail.submittedAt?.[0]?.name || "Unknown Location"}`}
        subHeaderText={reportTimeString}
        reportStatus={reportDetail.state}
      />
      {isPendingReport && (
        <div className={Style.button_container}>
          <button
            type="button"
            className={`${Style.approval_report_button}`}
            onClick={() => {
              setShowApproveConsumptionModal(true);
            }}
          >
            <span className={`${Style.approval_report_button_icon}`}>
              <CheckCircleOutlined />
            </span>
            Approve
          </button>
        </div>
      )}
      <ApprovalReportTable
        selectable={isPendingReport}
        onSelectClick={onSelectClick}
        onExpandStateChange={(rowId, newExpandedState) => {
          const updatedApprovalRows = updateExpandedStateForRows(rowsForApproval, [rowId], newExpandedState);
          setRowsForApproval(updatedApprovalRows);
        }}
        isItemSelectableFunc={isRowSelectable}
        rows={rowsForApproval}
        onChildSelectClick={onChildSelectClick}
        actionBar={renderTableActionBar()}
        childSelectable={isPendingReport}
      />
      <div className={`${Style.standby_container}`}>
        <p>
          Standby Items
          <span className={Style.tooltip_icon_container}>{renderStandbyTooltip()}</span>
        </p>
        <ApprovalReportTable
          selectable={false}
          onExpandStateChange={(rowId, newExpandedState) => {
            const updatedRemovedRows = updateExpandedStateForRows(removedRows, [rowId], newExpandedState);
            setRemovedRows(updatedRemovedRows);
          }}
          columnsToExclude={["consumed", "approved"]}
          rows={removedRows}
          childSelectable={false}
        />
      </div>

      {showDeleteConsumptionModal && renderDeleteConsumptionModal()}
      {showApproveConsumptionModal && renderApproveConsumptionModal()}
    </>
  );
};

export default ApprovalReportDetail;

ApprovalReportDetail.propTypes = {
  reportDetail: PropTypes.shape({
    id: PropTypes.string,
    identifier: PropTypes.string,
    class: PropTypes.string,
    state: PropTypes.string,
    node_start_time: PropTypes.number,
    submittedAt: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        identifier: PropTypes.string
      })
    ),
    result: PropTypes.shape({
      reportLink: PropTypes.string
    })
  }).isRequired
};
