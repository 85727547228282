import { useEffect } from "react";

export const MODIFIER_KEYS = {
  Ctrl: "Ctrl",
  Alt: "Alt",
  Shift: "Shift",
  Cmd: "Cmd",
  Windows: "Windows"
};

const MODIFIER_KEY_EVENT_ID_MAP = {
  Ctrl: "ctrlKey",
  Alt: "altKey",
  Option: "altKey", // Mac only
  Shift: "shiftkey",
  Cmd: "metakey", // Mac only
  Windows: "metakey" // windows only
};

const useKeyPress = (callbackFn, keyCode, modifierKey) => {
  const handler = (event) => {
    if (event.repeat) {
      return;
    }

    if (event.code === keyCode && (!modifierKey || event[MODIFIER_KEY_EVENT_ID_MAP[modifierKey]])) {
      callbackFn();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [callbackFn, keyCode, modifierKey]);
};

export default useKeyPress;
