export const validateCsvHeaders = (headers, csvHeadersMap) => {
  let valid = true;
  let errorMessage = "";
  const hasDuplicates = new Set(headers).size !== headers.length;
  let hasRequiredHeaders = true;

  const requiredHeaders = getRequiredHeaders(csvHeadersMap);
  // check for required headers
  if (headers.length < requiredHeaders.length) {
    hasRequiredHeaders = false;
  } else {
    hasRequiredHeaders = checkRequiredHeaders(headers, requiredHeaders);
  }

  if (hasDuplicates) {
    valid = false;
    errorMessage = "Cannot import csv file: Please check the headers in the csv file for duplicates and upload again";
  } else if (!hasRequiredHeaders) {
    valid = false;
    errorMessage = "Cannot import csv file: Please provide the required headers in the csv file and upload again";
  }
  return { valid, errorMessage };
};

const getRequiredHeaders = (csvHeaderMap) => {
  const requiredHeadersList = [];
  Object.keys(csvHeaderMap).forEach((id) => {
    if (csvHeaderMap[id].isRequired && !csvHeaderMap[id].hidden) {
      requiredHeadersList.push(csvHeaderMap[id].label);
    }
  });
  return requiredHeadersList;
};

const checkRequiredHeaders = (headers, requiredHeaders) => {
  // Convert the headers array to a Set for faster lookup
  const headersSet = new Set(headers);

  // Check if every required header is present in the headers array
  const hasAllRequiredHeaders = requiredHeaders.every((header) => {
    return headersSet.has(header);
  });

  return hasAllRequiredHeaders;
};
