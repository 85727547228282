import React, { useEffect, useState } from "react";
import { ModalForm } from "../../../../components/modal-form";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
import { multipleSortComparison } from "../../../../utils";
import InputLabel from "@material-ui/core/InputLabel";
import Style from "./SetLocationModal.module.css";

const SetLocationModal = ({ orderIds, locationCategory, onClose }) => {
  const [client] = useState(useXemelgoClient());
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const LocationClient = await client.getLocationClient();
    const batchUpdateLocations = await LocationClient.getLocationsOfCategory(locationCategory);
    const filteredLocations = batchUpdateLocations.sort(multipleSortComparison([{ id: "name" }]));
    let formattedUpdateLocationList = [];
    filteredLocations.map((each) => {
      return formattedUpdateLocationList.push({
        key: each.id,
        label: each.name || each.identifier,
        value: each.id
      });
    });

    setLocationList(formattedUpdateLocationList);
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const PublishClient = client.getPublishClient();
    let message;
    let success = true;
    const location = locationList.find((loc) => loc.value === selectedLocation.value);
    try {
      await PublishClient.publishUserEvent(orderIds, selectedLocation.value);
      message = `${orderIds.length === 1 ? "Order" : "Orders"} successfully moved to ${
        location.label
      }. Please refresh the page in order to pull in the latest data`;
    } catch (e) {
      message = "Failed to move orders)";
      success = false;
    }
    onClose(true, success, message);
  };

  const getTitle = () => {
    if (!isSubmitting) {
      return <div>Set Location</div>;
    }
    return <div>{`Move ${orderIds.length} ${orderIds.length === 1 ? "Order" : "Orders"}?`}</div>;
  };

  const getBody = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!isSubmitting) {
      return (
        <div>
          <InputLabel>Select a location</InputLabel>
          <SearchDropdown
            selectedItem={selectedLocation || {}}
            showIcon
            options={locationList}
            onItemSelected={(event) => {
              setSelectedLocation(event);
            }}
          />
        </div>
      );
    }
    const location = locationList.find((location) => {
      return location.value === selectedLocation.value;
    });

    return (
      <div>{`The selected ${orderIds.length} ${orderIds.length === 1 ? "order" : "orders"} will be moved to ${
        location?.label
      }`}</div>
    );
  };

  const getFooter = () => {
    return (
      <div className={Style.modal_footer_container}>
        <button
          onClick={() => {
            onClose(false);
          }}
          className={`${Style.form_button} ${Style.cancel_button}`}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            if (!isSubmitting) {
              setIsSubmitting(true);
            } else {
              setIsLoading(true);
              onSubmit();
            }
          }}
          className={`${Style.form_button} ${Style.confirm_button}`}
          disabled={isLoading || Object.keys(selectedLocation).length === 0}
        >
          {isSubmitting ? "Confirm" : "Submit"}
        </button>
      </div>
    );
  };

  return (
    <ModalForm
      show
      title={getTitle()}
      body={getBody()}
      footer={getFooter()}
      centered
    />
  );
};

export { SetLocationModal };
