import React from "react";
import PropTypes from "prop-types";
import PurchaseOrderDetailStyle from "./PurchaseOrderDetailsComponent.module.css";
// import { ShareEmailComponent } from '../popper-forms/ShareEmailComponent';
// import { PopperFormComponent } from '../popper-forms/PopperFormComponent';

export const PurchaseOrderDetailFooter = ({
  onClose,
  // showShareForm,
  // toggleShareForm,
  hasEdits,
  setSubmitTicket,
  handleDownloadPDF,
  isNew
}) => {
  return (
    <div className={PurchaseOrderDetailStyle.footer_container}>
      <div className={PurchaseOrderDetailStyle.unsaved_changes}>{hasEdits && "Unsaved Changes"}</div>
      <div className={PurchaseOrderDetailStyle.button_container}>
        <div
          className={PurchaseOrderDetailStyle.gray_button}
          onClick={onClose}
        >
          {hasEdits ? "Close Without Saving" : "Close"}
        </div>
        {hasEdits && (
          <div
            onClick={() => {
              setSubmitTicket("save");
            }}
            className={PurchaseOrderDetailStyle.blue_button}
          >
            Save and Close
          </div>
        )}
        {!isNew && (
          <div
            onClick={() => {
              // setSubmitTicket('save');
              if (hasEdits) {
                setSubmitTicket("save_and_download");
              } else {
                handleDownloadPDF();
              }
            }}
            className={PurchaseOrderDetailStyle.blue_button}
          >
            {hasEdits ? "Save and Download" : "Download"}
          </div>
        )}
        {/* <PopperFormComponent
          buttonStyle={PurchaseOrderDetailStyle.blue_button}
          buttonText={hasEdits ? 'Save and Share' : 'Share'}
          formComponent={<ShareEmailComponent title="Share PO" closeFunc={toggleShareForm} />}
          showForm={showShareForm}
          toggleFunc={toggleShareForm}
          placement="bottom-end"
        /> */}
      </div>
    </div>
  );
};

PurchaseOrderDetailFooter.propTypes = {
  onClose: PropTypes.func,
  // showShareForm: PropTypes.bool,
  // toggleShareForm: PropTypes.func,
  hasEdits: PropTypes.bool,
  setSubmitTicket: PropTypes.func,
  handleDownloadPDF: PropTypes.func,
  isNew: PropTypes.bool
};
