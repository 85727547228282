import React, { useState, useEffect, useRef, Fragment } from "react";
import { Modal } from "react-bootstrap";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import add_object_img from "../../img/add_object.png";
import Style from "./BomManagementFeatureStyle.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useBOMManagementContext } from "../../context/BOMManagementContext/BOMManagementContext";
import { naturalSort } from "../../common/Utilities";
import BOMAdditionalInformation from "./BOMAdditionalInformation";
import BOMManageInputPartTable from "./BOMManageInputPartTable";
import BOMManageOperationTable from "./BOMManageOperationTable";

const BOMCreationMode_FromExisting = "existing";
const BOMCreationMode_New = "new";

const BOMManagementFeature = () => {
  const location = useLocation();

  const additionInfoRef = useRef();
  const inputPartTableRef = useRef();
  const operationTableRef = useRef();
  const bomIDMapRef = useRef({});

  const {
    additionalInformation,
    setAdditionalInformation,
    inputPartGroupInfo,
    setInputPartGroupInfo,
    operationInfo,
    setOperationInfo,
    itemTypeList,
    setItemTypeList
  } = useBOMManagementContext();

  const [bomCreationMode, setBomCreationMode] = useState(false);
  const [showSelectItemTypeModal, setShowSelectItemTypeModal] = useState(false);
  const [itemTypeIdToDuplicateBOM, setItemTypeIdToDuplicateBOM] = useState(null);
  const [selectItemTypeError, setSelectItemTypeError] = useState("");
  const [isSelectItemTypeModalLoading, setIsSelectItemTypeModalLoading] = useState(false);
  const [isBomCreating, setIsBomCreating] = useState(false);
  const [bomIDMap, setBomIDMap] = useState({});
  const [itemTypeId, setItemTypeId] = useState("");
  const [isAdditionalInfoLoading, setIsAdditionalInfoLoading] = useState(true);
  const [isInputPartTableLoading, setIsInputPartTableLoading] = useState(true);
  const [isOperationTableLoading, setIsOperationTableLoading] = useState(true);
  const [BOMClient] = useState(useXemelgoClient().getBomClient());
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());

  const fetchItemTypes = async () => {
    const newItemTypes = await itemTypeClient.listItemTypes(["id", "identifier", "unit", "description"]);
    setItemTypeList(naturalSort(newItemTypes, "identifier"));
  };

  useEffect(() => {
    fetchItemTypes();
    const { search } = location;
    const { id: newItemTypeId } = queryString.parse(search);
    setItemTypeId(newItemTypeId);
    fetchBasicBOMDataForItemTypeId(newItemTypeId);
    // eslint-disable-next-line
  }, []);

  const fetchBasicBOMDataForItemTypeId = (id) => {
    return BOMClient.getBomIdsMapUsingItemTypeId(id).then((newBomIDMap) => {
      if (newBomIDMap.bomId) {
        setBomIDMap(newBomIDMap);
        bomIDMapRef.current = newBomIDMap;
        return newBomIDMap;
      }
      setBomIDMap(null);
      bomIDMapRef.current = null;
      return null;
    });
  };

  const fetchAdditionalInformation = async () => {
    setIsAdditionalInfoLoading(true);
    const newAdditionalInformation = await BOMClient.getAdditionalInfoByBomId(bomIDMapRef.current.bomId);
    setAdditionalInformation(newAdditionalInformation);
    setIsAdditionalInfoLoading(false);
  };

  useEffect(() => {
    if (bomIDMap?.bomId) {
      fetchAdditionalInformation();
    }
  }, [bomIDMap]);

  const fetchInputParts = async () => {
    setIsInputPartTableLoading(true);

    const inputPartGroupList = await BOMClient.getInputPartsByBomId(bomIDMapRef.current.bomId);
    setInputPartGroupInfo(inputPartGroupList);

    setIsInputPartTableLoading(false);
  };

  useEffect(() => {
    if (bomIDMap?.bomId) {
      fetchInputParts();
    }
  }, [bomIDMap]);

  const fetchOperations = async () => {
    setIsOperationTableLoading(true);
    const newOperationInfo = await BOMClient.getRoutingsByBomId(bomIDMapRef.current.bomId);
    setOperationInfo(newOperationInfo);
    setIsOperationTableLoading(false);
  };
  useEffect(() => {
    if (bomIDMap?.bomId) {
      fetchOperations();
    }
  }, [bomIDMap]);

  useEffect(() => {
    if (bomCreationMode) {
      additionInfoRef.current.setEditingMode(true);
      inputPartTableRef.current.setEditingMode(true);
      operationTableRef.current.setEditingMode(true);

      if (bomCreationMode === BOMCreationMode_New) {
        setIsAdditionalInfoLoading(false);
        setIsInputPartTableLoading(false);
        setIsOperationTableLoading(false);
        setAdditionalInformation({ description: "", quantity: "", unit: "", status: "active" });
        inputPartTableRef.current.addItem();
        operationTableRef.current.addItem();
      }
    }
  }, [bomCreationMode]);

  const handleSubmit = async () => {
    setIsSelectItemTypeModalLoading(true);
    setIsAdditionalInfoLoading(true);
    setIsInputPartTableLoading(true);
    setIsAdditionalInfoLoading(true);
    setBomCreationMode(BOMCreationMode_FromExisting);
    const selectedItemTypeHasBOM = await fetchBasicBOMDataForItemTypeId(itemTypeIdToDuplicateBOM);
    if (!selectedItemTypeHasBOM) {
      setIsSelectItemTypeModalLoading(false);
      setSelectItemTypeError(
        "Selected part does not have a BOM associated with it. Please try selecting a different part."
      );
      setBomCreationMode(false);
    } else {
      setIsSelectItemTypeModalLoading(false);
    }
  };

  const renderSelectItemTypeModal = () => {
    return (
      <Modal
        show={showSelectItemTypeModal}
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header className={Style.modal_header}>
          <Modal.Title className={Style.modal_title}>Create BOM</Modal.Title>
        </Modal.Header>
        <Modal.Body className={Style.modal_body}>
          {isSelectItemTypeModalLoading ? (
            <span>Fetching BOM associated with selected part...</span>
          ) : (
            <>
              <InputLabel
                required
                style={{ color: "#343434", fontFamily: "Avenir" }}
              >
                Select the existing BOM you wish to copy:
              </InputLabel>
              <Select
                variant="outlined"
                style={{ fontFamily: "Avenir", color: "#000" }}
                className={Style.dropdown_style}
                value={itemTypeIdToDuplicateBOM}
                onChange={(event) => {
                  setItemTypeIdToDuplicateBOM(event.target.value);
                  setSelectItemTypeError("");
                }}
              >
                {itemTypeList.map((each) => {
                  return (
                    <MenuItem
                      key={each.id}
                      value={each.id}
                    >
                      {each.identifier}
                    </MenuItem>
                  );
                })}
              </Select>
              {selectItemTypeError && <span style={{ color: "red" }}>{selectItemTypeError}</span>}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className={Style.modal_footer}>
          <button
            type="button"
            className={`${Style.action_button} ${Style.action_button_cancel}`}
            onClick={() => {
              if (itemTypeList?.length > 1) {
                setItemTypeIdToDuplicateBOM(null);
              }
              setShowSelectItemTypeModal(false);
              setSelectItemTypeError("");
            }}
          >
            Cancel
          </button>
          <button
            disabled={!itemTypeIdToDuplicateBOM || isSelectItemTypeModalLoading}
            type="button"
            className={Style.action_button}
            onClick={handleSubmit}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (!bomIDMap && !bomCreationMode) {
    return (
      <div className={`${Style.create_container} ${Style.flex_column}`}>
        <img
          src={add_object_img}
          className={Style.creation_img}
          alt="Create BOM"
        />
        <div className={`${Style.creation_text_container} ${Style.flex_column}`}>
          <p>No BOM available.</p>
          <span>
            You may
            <button
              className={Style.text_button}
              onClick={() => {
                setBomCreationMode(BOMCreationMode_New);
              }}
            >
              create a BOM
            </button>
            or
            <button
              className={Style.text_button}
              onClick={() => {
                return setShowSelectItemTypeModal(true);
              }}
            >
              create from an existing BOM
            </button>
          </span>
          {renderSelectItemTypeModal()}
        </div>
      </div>
    );
  }

  const partsTableSaveFn_NewBOM = async (result) => {
    const inputPartTableInfo = { ...result };

    // clean up empty rows
    Object.keys(inputPartTableInfo).forEach((eachInputGroupId) => {
      Object.keys(inputPartTableInfo[eachInputGroupId]).forEach((eachInputPartId) => {
        if (!inputPartTableInfo[eachInputGroupId][eachInputPartId].itemTypeId) {
          delete inputPartTableInfo[eachInputGroupId][eachInputPartId];
        }
      });
      if (!Object.keys(inputPartTableInfo[eachInputGroupId]).length) {
        delete inputPartTableInfo[eachInputGroupId];
      }
    });

    // calculate rank for each input group and each input part
    Object.keys(inputPartTableInfo).forEach((eachInputGroupId) => {
      const eachInputGroup = inputPartTableInfo[eachInputGroupId];
      Object.keys(eachInputGroup)
        .filter((eachInputPartId) => {
          return eachInputPartId !== "createNew";
        })
        .sort((a, b) => {
          return eachInputGroup[a].rank * 1 - eachInputGroup[b].rank * 1;
        })
        .forEach((eachInputPartId, index) => {
          inputPartTableInfo[eachInputGroupId][eachInputPartId].rank = index + 1;
        });
    });

    const updateMap = { NEW_GROUPS: [] };

    // NEW Input Groups
    Object.keys(inputPartTableInfo)
      .filter((eachInputGroupId) => {
        return !inputPartGroupInfo.find((eachInputGroup) => {
          return eachInputGroup.id === eachInputGroupId;
        });
      })
      .forEach((eachInputGroupId) => {
        updateMap.NEW_GROUPS.push({ itemInputs: [] });
        const eachInputGroup = inputPartTableInfo[eachInputGroupId];
        Object.keys(eachInputGroup)
          .filter((eachItemInputId) => {
            return eachItemInputId !== "createNew";
          })
          .forEach((eachItemInputId) => {
            const eachItemInput = eachInputGroup[eachItemInputId];
            updateMap.NEW_GROUPS[updateMap.NEW_GROUPS.length - 1].itemInputs.push({
              fields: {
                quantity: (eachItemInput.quantity || 0) * 1,
                unit: eachItemInput.unit || "",
                rank: eachItemInput.rank
              },
              itemTypeId: eachItemInput.itemTypeId
            });
          });
      });

    Object.keys(inputPartTableInfo)
      .filter((eachInputGroupId) => {
        return inputPartGroupInfo.find((eachInputGroup) => {
          return eachInputGroup.id === eachInputGroupId;
        });
      })
      .forEach((eachInputGroupId) => {
        const eachInputGroup = inputPartTableInfo[eachInputGroupId];
        updateMap[eachInputGroupId] = { update: {}, create: [], delete: {} };
        Object.keys(eachInputGroup).forEach((eachItemInputId) => {
          const eachItemInput = eachInputGroup[eachItemInputId];
          // new created item type
          if (eachItemInput.createNew) {
            updateMap[eachInputGroupId].create.push({
              fields: {
                quantity: eachItemInput.quantity * 1,
                unit: eachItemInput.unit || "",
                rank: eachItemInput.rank
              },
              itemTypeId: eachItemInput.itemTypeId
            });
          } else {
            const oldItemInput = inputPartGroupInfo
              .find((eachInputGroup) => {
                return eachInputGroup.id === eachInputGroupId;
              })
              ?.itemTypes.find((eachItemType) => {
                return eachItemType.id === eachItemInputId;
              });
            // edited item type
            if (oldItemInput.quantity !== eachItemInput.quantity * 1 || oldItemInput.rank !== eachItemInput.rank) {
              updateMap[eachInputGroupId].update[eachItemInputId] = {
                quantity: eachItemInput.quantity * 1,
                rank: eachItemInput.rank
              };
            }
          }
        });
      });

    // delete item type
    inputPartGroupInfo.forEach((eachInputGroup) => {
      // if the whole input group is deleted
      if (!Object.keys(inputPartTableInfo).includes(eachInputGroup.id)) {
        updateMap[eachInputGroup.id] = { delete: { deleteGroup: true, itemInputs: [] } };
        eachInputGroup.itemTypes.forEach((eachItemType) => {
          updateMap[eachInputGroup.id].delete.itemInputs.push(eachItemType.id);
        });
      } else {
        eachInputGroup.itemTypes
          .filter((eachItemType) => {
            return !Object.keys(inputPartTableInfo[eachInputGroup.id]).includes(eachItemType.id);
          })
          .forEach((eachItemType) => {
            if (!updateMap[eachInputGroup.id].delete.itemInputs) {
              updateMap[eachInputGroup.id].delete.itemInputs = [];
            }
            updateMap[eachInputGroup.id].delete.itemInputs.push(eachItemType.id);
          });
      }
    });

    // cleanup
    if (!updateMap.NEW_GROUPS.length) {
      delete updateMap.NEW_GROUPS;
    }

    Object.keys(updateMap)
      .filter((eachItemGroupId) => {
        return eachItemGroupId !== "NEW_GROUPS";
      })
      .forEach((eachItemGroupId) => {
        if (!updateMap[eachItemGroupId].update || !Object.keys(updateMap[eachItemGroupId].update).length) {
          delete updateMap[eachItemGroupId].update;
        }
        if (!updateMap[eachItemGroupId].create?.length) {
          delete updateMap[eachItemGroupId].create;
        }
        if (!updateMap[eachItemGroupId].delete || !Object.keys(updateMap[eachItemGroupId].delete).length) {
          delete updateMap[eachItemGroupId].delete;
        }
        if (!Object.keys(updateMap[eachItemGroupId]).length) {
          delete updateMap[eachItemGroupId];
        }
      });

    if (Object.keys(updateMap).length) {
      setIsInputPartTableLoading(true);
      await BOMClient.updateInputParts(bomIDMapRef.current.bomId, updateMap);
      await fetchInputParts();
      setIsInputPartTableLoading(false);
    }
  };

  const partsTableSaveFn_FromExistingBOM = async (result) => {
    const inputPartTableInfo = { ...result };

    // clean up empty rows
    Object.keys(inputPartTableInfo).forEach((eachInputGroupId) => {
      Object.keys(inputPartTableInfo[eachInputGroupId]).forEach((eachInputPartId) => {
        if (!inputPartTableInfo[eachInputGroupId][eachInputPartId].itemTypeId) {
          delete inputPartTableInfo[eachInputGroupId][eachInputPartId];
        }
      });
      if (!Object.keys(inputPartTableInfo[eachInputGroupId]).length) {
        delete inputPartTableInfo[eachInputGroupId];
      }
    });

    // calculate rank for each input group and each input part
    Object.keys(inputPartTableInfo).forEach((eachInputGroupId) => {
      const eachInputGroup = inputPartTableInfo[eachInputGroupId];
      Object.keys(eachInputGroup)
        .filter((eachInputPartId) => {
          return eachInputPartId !== "createNew";
        })
        .sort((a, b) => {
          return eachInputGroup[a].rank * 1 - eachInputGroup[b].rank * 1;
        })
        .forEach((eachInputPartId, index) => {
          inputPartTableInfo[eachInputGroupId][eachInputPartId].rank = index + 1;
        });
    });

    const updateMap = { NEW_GROUPS: [] };

    // NEW Input Groups
    Object.keys(inputPartTableInfo).forEach((eachInputGroupId) => {
      updateMap.NEW_GROUPS.push({ itemInputs: [] });
      const eachInputGroup = inputPartTableInfo[eachInputGroupId];
      Object.keys(eachInputGroup)
        .filter((eachItemInputId) => {
          return eachItemInputId !== "createNew";
        })
        .forEach((eachItemInputId) => {
          const eachItemInput = eachInputGroup[eachItemInputId];
          updateMap.NEW_GROUPS[updateMap.NEW_GROUPS.length - 1].itemInputs.push({
            fields: {
              quantity: (eachItemInput.quantity || 0) * 1,
              unit: eachItemInput.unit || "",
              rank: eachItemInput.rank
            },
            itemTypeId: eachItemInput.itemTypeId
          });
        });
    });

    if (Object.keys(updateMap).length) {
      setIsInputPartTableLoading(true);

      await BOMClient.updateInputParts(bomIDMapRef.current.bomId, updateMap);
      await fetchInputParts();
      setIsInputPartTableLoading(false);
    }
  };

  const opTableSaveFn_NewBOM = async (operationTableInfo) => {
    // clean up empty rows
    Object.keys(operationTableInfo).forEach((eachOperation) => {
      if (!operationTableInfo[eachOperation].name && !operationTableInfo[eachOperation].description) {
        delete operationTableInfo[eachOperation];
      }
    });

    setIsOperationTableLoading(true);

    const updateMap = { update: [], delete: [] };
    updateMap.update = Object.keys(operationTableInfo)
      .sort((a, b) => {
        return operationTableInfo[a].rank - operationTableInfo[b].rank;
      })
      .map((eachOperationId) => {
        const { nextOp, previousOp, rank, name, description } = operationTableInfo[eachOperationId];

        const oldOperation = operationInfo.find((eachOperation) => {
          return eachOperation.id === eachOperationId;
        });
        if (oldOperation) {
          return {
            id: eachOperationId,
            nextOp,
            previousOp,
            fields:
              name !== oldOperation.name || description !== oldOperation.description ? { name, description } : null,
            rank
          };
        }

        return {
          id: eachOperationId,
          new: true,
          fields: { name, description },
          rank
        };
      });

    updateMap.delete = operationInfo.filter((eachOperation) => {
      return !Object.keys(operationTableInfo).includes(eachOperation.id);
    });

    await BOMClient.updateBomRouting(bomIDMapRef.current.routingId, updateMap);
    await fetchOperations();
    setIsOperationTableLoading(false);
  };

  const opTableSaveFn_FromExistingBOM = async (operationTableInfo) => {
    // clean up empty rows

    Object.keys(operationTableInfo).forEach((eachOperation) => {
      if (!operationTableInfo[eachOperation].name && !operationTableInfo[eachOperation].description) {
        delete operationTableInfo[eachOperation];
      }
    });

    setIsOperationTableLoading(true);

    const updateMap = { update: [], delete: [] };
    updateMap.update = Object.keys(operationTableInfo)
      .sort((a, b) => {
        return operationTableInfo[a].rank - operationTableInfo[b].rank;
      })
      .map((eachOperationId) => {
        const { nextOp, previousOp, rank, name, description } = operationTableInfo[eachOperationId];

        return {
          id: eachOperationId,
          new: true,
          fields: { name, description },
          rank
        };
      });
    await BOMClient.updateBomRouting(bomIDMapRef.current.routingId, updateMap);
    await fetchOperations();
    setIsOperationTableLoading(false);
  };

  return (
    <>
      <div className={`${Style.feature_container} ${Style.flex_column}`}>
        <BOMAdditionalInformation
          hideEditButton={bomCreationMode}
          loading={isAdditionalInfoLoading || isBomCreating}
          ref={additionInfoRef}
          onSaveClick={async (additionalInfo) => {
            const { quantity, description, unit } = additionalInfo;
            if (
              quantity.value * 1 !== additionalInformation.quantity ||
              description.value !== additionalInformation.description ||
              unit.value !== additionalInformation.unit ||
              bomCreationMode === BOMCreationMode_FromExisting
            ) {
              const updateFields = {
                quantity: (quantity.value || 0) * 1,
                description: description.value,
                unit: unit.value
              };
              setIsAdditionalInfoLoading(true);
              await BOMClient.updateAdditionalInformation(
                bomIDMapRef.current.bomId,
                bomIDMapRef.current.itemOutputId,
                updateFields
              );
              await fetchAdditionalInformation();
            }
          }}
        />
        <BOMManageInputPartTable
          hideEditButton={bomCreationMode}
          loading={isInputPartTableLoading || isBomCreating}
          ref={inputPartTableRef}
          onSaveClick={
            bomCreationMode === BOMCreationMode_FromExisting
              ? partsTableSaveFn_FromExistingBOM
              : partsTableSaveFn_NewBOM
          }
        />
        <BOMManageOperationTable
          hideEditButton={bomCreationMode}
          loading={isOperationTableLoading || isBomCreating}
          ref={operationTableRef}
          onSaveClick={
            bomCreationMode === BOMCreationMode_FromExisting ? opTableSaveFn_FromExistingBOM : opTableSaveFn_NewBOM
          }
        />
      </div>
      {bomCreationMode && !isBomCreating && (
        <div className={`${Style.action_buttons_container} ${Style.flex_row}`}>
          <button
            className={`${Style.action_button} ${Style.action_button_cancel} `}
            onClick={() => {
              setBomCreationMode(false);
              setAdditionalInformation({});
              setItemTypeIdToDuplicateBOM(null);
              setShowSelectItemTypeModal(false);
              setBomIDMap(null);
            }}
          >
            Discard
          </button>
          <button
            className={Style.action_button}
            onClick={async () => {
              setIsBomCreating(true);
              const newBomId = await BOMClient.createBomForItemType(itemTypeId, {
                /* hack to make create BOM feature work, needs to be re-done to account for
                 item output fields being made required */
                unit: "unit",
                quantity: 1
              });
              await BOMClient.createRoutingForBom(newBomId, itemTypeId, []);
              const newBomIDMap = await BOMClient.getBomIdsMapUsingItemTypeId(itemTypeId);
              bomIDMapRef.current = newBomIDMap;
              setBomIDMap(newBomIDMap);
              await Promise.all([
                additionInfoRef.current.onSaveClick(),
                inputPartTableRef.current.onSaveClick(),
                operationTableRef.current.onSaveClick()
              ]);
              setBomCreationMode(false);
              setIsBomCreating(false);
            }}
          >
            Create
          </button>
        </div>
      )}
    </>
  );
};

export default BOMManagementFeature;
