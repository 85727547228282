/**
 *
 * @param newTags { any[] }
 * @param existingItemByTag { any }
 * @param inventoryClient { any }
 * @param itemTypeQueryAttributes { string[] }
 * @return { Promise<Object.<string, ReducedItem> }
 */
export const queryItemsFromParsedTags = async (
  newTags,
  existingItemByTag,
  itemTypeClient,
  itemTypeQueryAttributes,
  upcNumberOfCharactersToTrim
) => {
  if (!newTags.length) {
    return Promise.resolve({});
  }

  const upcsToQuery = [
    ...new Set(
      newTags.reduce((acc, tag) => {
        if (tag.analytic.name === "SGTIN" && tag.analytic.upc) {
          acc.push(tag.analytic.upc.slice(upcNumberOfCharactersToTrim));
        }
        return acc;
      }, [])
    )
  ];

  const queriedUpcs = Object.values(existingItemByTag).map((item) => {
    return item.type;
  });
  const queriedTypeIdSet = new Set(
    queriedUpcs.map((type) => {
      return type.id;
    })
  );
  const newUpcsToQuery = upcsToQuery.filter((upc) => {
    return !queriedTypeIdSet.has(upc);
  });

  if (!newUpcsToQuery.length) {
    return existingItemByTag;
  }

  const newlyQueriedUpcs = await itemTypeClient.getItemTypeByIdentifiers(newUpcsToQuery, [
    "id",
    ...itemTypeQueryAttributes
  ]);

  // Process all tags based on newly retrieved UPC data
  return processItems(newTags, newlyQueriedUpcs, existingItemByTag, upcNumberOfCharactersToTrim);
};

export const processItems = (tagData, itemTypes = [], initialData, upcNumberOfCharactersToTrim) => {
  const firstSeenTimestamp = Date.now();
  return tagData.reduce((map, tag) => {
    const clonedMap = { ...map };

    const { upc, serial } = tag.analytic || {};

    const upcIdentifier = upc?.slice(upcNumberOfCharactersToTrim);
    const item = itemTypes.find((itemType) => {
      return itemType?.identifier === upcIdentifier;
    });

    // If upc doesn't exist, display just the identifier without the metadata
    // TODO: Maybe make whether the item is shown be config driven
    if (item || (upcIdentifier && !clonedMap[tag.Name])) {
      clonedMap[tag.Name] = {
        type: item || { identifier: upcIdentifier },
        vid: tag.Name,
        firstSeenTimestamp,
        identifier: serial
      };
    }

    return clonedMap;
  }, initialData);
};
