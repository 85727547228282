import React from "react";

export const UnauthorizedPage = () => {
  return (
    <div>
      <div>Not authorized to access this page</div>
      <div>
        <a href="/">Click </a> to return to home page.
      </div>
    </div>
  );
};
