import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Status from "../../../../../../../../../../../../components/paginated-list-table-with-types/components/status";
import xemelgoStyle from "../../../../../../../../../../../../styles/variable";
import Style from "./SubmissionConfirmationWorkflow.module.css";
import Modal from "../../../../../../../../../../../../components/modal";
import useKioskConfigContext from "../../../../../../../../../../contexts/kiosk-config-context";
import useKioskStateContext from "../../../../../../../../../../contexts/kiosk-state-context";
import { STATUS_OPTIONS } from "../../../../../../../../../../../../components/status-popup-component";
import useTransferOrderActionsConfigContext from "../../../../../../contexts/transfer-order-actions-config-context";

export const SubmissionConfirmationWorkflow = ({
  stageStatus,
  onCancel,
  onConfirm,
  transferOrderUpdates,
  containerIdentifier
}) => {
  const { action } = useKioskConfigContext();
  const { customText } = useTransferOrderActionsConfigContext();
  const { panelValues, submitStatus } = useKioskStateContext();

  const transferOrdersSummary = useMemo(() => {
    return transferOrderUpdates
      .map((transferOrderEvent) => {
        const { itemTypeEvents, identifier } = transferOrderEvent;
        const { expectedCount, scannedCount } = itemTypeEvents.reduce(
          (acc, itemTypeReport) => {
            acc.expectedCount += itemTypeReport.expectedCount;
            acc.scannedCount += itemTypeReport.scannedCount;
            return acc;
          },
          { expectedCount: 0, scannedCount: 0 }
        );

        return {
          identifier,
          scannedCount,
          expectedCount
        };
      })
      .sort((a, b) => {
        return a.identifier.localeCompare(b.identifier);
      });
  }, [transferOrderUpdates]);

  return (
    <Modal
      title={action.label}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmDisabled={submitStatus === STATUS_OPTIONS.LOADING}
    >
      <div className={Style.top_section}>
        <div className={Style.top_section_item}>
          <p className={Style.header_text}>{customText.transferOrderIdentifierLabel}</p>
          {panelValues.transferOrderIdentifiersString}
        </div>
        {containerIdentifier && (
          <div className={Style.top_section_item}>
            <p className={Style.header_text}>{customText.containerIdentifierLabel}</p>
            {containerIdentifier}
          </div>
        )}
      </div>
      <div className={Style.summary_container}>
        <p className={Style.header_text}>Summary</p>
        {transferOrdersSummary.map((transferOrder) => {
          const { identifier, scannedCount, expectedCount } = transferOrder;
          return (
            <div
              key={identifier}
              className={Style.summary_table_row}
            >
              <div>
                <p className={Style.main_row_text}>{identifier}</p>
                <div className={Style.row}>
                  Status:
                  <Status
                    statusList={[
                      {
                        label: stageStatus,
                        backgroundColor: xemelgoStyle.theme.APP_BLUE
                      }
                    ]}
                  />
                </div>
              </div>
              <p className={Style.secondary_text}>{`${scannedCount}/${expectedCount} items scanned`}</p>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

SubmissionConfirmationWorkflow.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  transferOrderUpdates: [],
  stageStatus: "",
  containerIdentifier: ""
};

SubmissionConfirmationWorkflow.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  transferOrderUpdates: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      itemTypeEvents: PropTypes.arrayOf(
        PropTypes.shape({
          expectedCount: PropTypes.number,
          scannedCount: PropTypes.number
        })
      )
    })
  ),
  stageStatus: PropTypes.string,
  containerIdentifier: PropTypes.string
};
