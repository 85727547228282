import { useCallback, useEffect, useReducer, useRef } from "react";
import { useXemelgoAppsyncClient } from "../../../../../../services/xemelgo-appsync-service";
import { naturalSort } from "../../../../../../common/Utilities";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";

export const ACTION_TYPE = {
  RESET: "reset",
  FETCHING: "fetching",
  FETCHING_NEXT_PAGE: "fetchingNextPage",
  PUBLISH_DATA: "publishData",
  PUBLISH_PARTIAL_DATA: "publishPartialData"
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return action.availableOptions.reduce((accumulator, eachOption) => {
        return { ...accumulator, [eachOption.id]: { dataList: [], isLoading: false, hasNextPage: false } };
      }, {});
    case ACTION_TYPE.FETCHING:
      return { ...state, [action.id]: { dataList: [], isLoading: true, hasNextPage: false } };
    case ACTION_TYPE.FETCHING_NEXT_PAGE:
      return { ...state, [action.id]: { ...state[action.id], hasNextPage: true } };
    case ACTION_TYPE.PUBLISH_PARTIAL_DATA:
      return {
        ...state,
        [action.id]: {
          dataList: [...state[action.id].dataList, ...action.dataList],
          isLoading: false,
          hasNextPage: false
        }
      };
    case ACTION_TYPE.PUBLISH_DATA:
      return {
        ...state,
        [action.id]: {
          dataList: [...action.dataList],
          isLoading: false,
          hasNextPage: false
        }
      };
    default:
      return state;
  }
};

export const useCustomSideFilterOptionFetcher = (availableOptions, optionParamMap) => {
  const xemelgoClient = useXemelgoClient();
  const xemelgoAppSyncClient = useXemelgoAppsyncClient();

  const startTimeRef = useRef();

  const [state, dispatch] = useReducer(
    reducer,
    availableOptions.reduce((accumulator, eachOption) => {
      return { ...accumulator, [eachOption.id]: { dataList: [], isLoading: false, hasNextPage: false } };
    }, {})
  );

  const fetchItemType = useCallback(
    async (optionParams) => {
      const { categories, attributeForLabel = "identifier", attributeForFilter = "identifier" } = optionParams || {};
      const itemTypeClient = xemelgoClient.getItemTypeClient();
      const itemTypes = await itemTypeClient.getItemTypes(["id", attributeForFilter, attributeForLabel], categories);
      return itemTypes
        .map((eachItemType) => {
          return { id: eachItemType[attributeForFilter], label: eachItemType[attributeForLabel] };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    [xemelgoClient]
  );

  const fetchLocation = useCallback(
    async (optionParams) => {
      const { categories } = optionParams || {};
      const locationClient = xemelgoClient.getLocationClient();
      const locationTreeMap = await locationClient.getLocationTree([], categories);
      return naturalSort(
        Object.values(locationTreeMap).map((eachLocation) => {
          return { id: eachLocation.id, label: eachLocation.name };
        }),
        "label"
      );
    },
    [xemelgoClient]
  );

  const fetchCustomerPartNumber = useCallback(async (optionParams) => {
    const {
      attributeForFilter = "identifier",
      attributeForLabel = "identifier",
      partnerIdentifiers = []
    } = optionParams || {};
    const inventoryClient = xemelgoClient.getInventoryClient();
    const customerPartNumbers = await inventoryClient.getCustomerPartNumbers(partnerIdentifiers || []);
    return naturalSort(
      customerPartNumbers.map((eachCustomerPartNumberNode) => {
        return {
          id: eachCustomerPartNumberNode[attributeForFilter],
          label: eachCustomerPartNumberNode[attributeForLabel]
        };
      }),
      "label"
    );
  });

  const fetchItemTypeMetrics = useCallback(
    async (optionParams, onFetchNextCallback) => {
      const { attributeForLabel = "identifier", attributeForFilter = "identifier" } = optionParams || {};

      const convertToOptions = (itemTypeList) => {
        return (itemTypeList || []).map((eachItemType) => {
          return { id: eachItemType[attributeForFilter], label: eachItemType[attributeForLabel] || "" };
        });
      };

      let newItemTypeList = [];

      const inventoryAppSyncClient = xemelgoAppSyncClient.getInventoryClient();

      const response = await inventoryAppSyncClient.getItemTypesByLocationIds(optionParams?.locationIds || [], "");

      newItemTypeList = [...newItemTypeList, ...response.itemTypes].filter((value, index, self) => {
        return (
          index ===
          self.findIndex((tartget) => {
            return tartget.id === value.id;
          })
        );
      });

      let { nextTokens } = response;

      while (
        nextTokens.filter((eachToken) => {
          return !!eachToken;
        }).length !== 0
      ) {
        onFetchNextCallback(convertToOptions(newItemTypeList));
        const { itemTypes, nextTokens: newNextTokens } = await inventoryAppSyncClient.getItemTypesByLocationIds(
          optionParams?.locationIds || [],
          "",
          nextTokens
        );
        newItemTypeList = [...newItemTypeList, ...itemTypes].filter((value, index, self) => {
          return (
            index ===
            self.findIndex((tartget) => {
              return tartget.id === value.id;
            })
          );
        });

        nextTokens = newNextTokens;
      }
      return convertToOptions(newItemTypeList);
    },
    [xemelgoClient]
  );

  const fetchLotMetrics = useCallback(
    async (optionParams, onFetchNextCallback) => {
      const { attributeForLabel = "lotNumber", attributeForFilter = "lotNumber" } = optionParams || {};

      const convertToOptions = (lotList) => {
        return (lotList || [])
          .map((eachLot) => {
            return { id: eachLot[attributeForFilter], label: eachLot[attributeForLabel] || "" };
          })
          .filter(({ id, label }) => {
            return id && label;
          });
      };

      let newLotList = [];

      const inventoryAppSyncClient = xemelgoAppSyncClient.getInventoryClient();

      const response = await inventoryAppSyncClient.getLotsByLocationIds(optionParams?.locationIds || [], "");

      newLotList = [...newLotList, ...response.lots].filter((value, index, self) => {
        return (
          index ===
          self.findIndex((tartget) => {
            return tartget.lotNumber === value.lotNumber;
          })
        );
      });

      let { nextTokens } = response;

      while (
        nextTokens.filter((eachToken) => {
          return !!eachToken;
        }).length !== 0
      ) {
        onFetchNextCallback(convertToOptions(newLotList));
        const { lots, nextTokens: newNextTokens } = await inventoryAppSyncClient.getLotsByLocationIds(
          optionParams?.locationIds || [],
          "",
          nextTokens
        );
        newLotList = [...newLotList, ...lots].filter((value, index, self) => {
          return (
            index ===
            self.findIndex((tartget) => {
              return tartget.id === value.id;
            })
          );
        });

        nextTokens = newNextTokens;
      }
      return convertToOptions(newLotList);
    },
    [xemelgoClient]
  );

  const fetchStatus = () => {
    return [
      { id: "Critical", label: "Critical" },
      { id: "Warning", label: "Warning" },
      { id: "Healthy", label: "Healthy" }
    ];
  };

  const getFetchFnByOptionId = (optionId) => {
    switch (optionId) {
      case "itemType":
        return fetchItemType;
      case "location":
        return fetchLocation;
      case "itemTypeMetrics":
        return fetchItemTypeMetrics;
      case "status":
        return fetchStatus;
      case "customerPartNumber":
        return fetchCustomerPartNumber;
      case "lotMetrics":
        return fetchLotMetrics;
      default:
        return null;
    }
  };

  useEffect(() => {
    const startTime = Date.now();
    startTimeRef.current = startTime;
    dispatch({ type: ACTION_TYPE.RESET, availableOptions });
    availableOptions.forEach(async (eachOption) => {
      const fetchFn = getFetchFnByOptionId(eachOption.id);
      if (fetchFn) {
        dispatch({ type: ACTION_TYPE.FETCHING, id: eachOption.id });
        const result = await fetchFn(optionParamMap?.[eachOption.id], (partialResult) => {
          if (startTimeRef.current === startTime) {
            dispatch({ type: ACTION_TYPE.PUBLISH_PARTIAL_DATA, id: eachOption.id, dataList: partialResult });
            dispatch({ type: ACTION_TYPE.FETCHING_NEXT_PAGE, id: eachOption.id });
          }
        });
        if (startTimeRef.current === startTime) {
          dispatch({ type: ACTION_TYPE.PUBLISH_DATA, id: eachOption.id, dataList: result });
        }
      }
    });
  }, [availableOptions, optionParamMap]);

  return state;
};
