import React from "react";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import { VIEW_MODE_MAP, TAB_OPTION_MAP } from "../../data/constants";
import useManagementDashboardStateContext from "../../contexts/management-dashboard-state-context";

export const ViewModeSelector = () => {
  const {
    mapRef,
    viewMode,
    setViewMode,
    siteLocationId,
    setSelectedMetricType,
    setSelectedLocationId,
    setSelectedTab
  } = useManagementDashboardStateContext();

  return (
    <SearchDropdown
      showIcon
      withoutOptionFilter
      options={Object.values(VIEW_MODE_MAP)}
      selectedItem={viewMode}
      onItemSelected={(mode) => {
        setSelectedMetricType(null);
        mapRef.current.selectLocation();
        setSelectedLocationId(siteLocationId);
        setSelectedTab(TAB_OPTION_MAP.jobStatusTab);
        setViewMode(mode);
      }}
    />
  );
};
