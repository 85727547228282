import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useAssetTrackPageConfigContext, {
  AssetTrackPageConfigContextProvider
} from "./contexts/asset-track-page-config-context";
import useAssetTrackPageDataSourceContext, {
  AssetTrackPageDataSourceContextProvider
} from "./contexts/asset-track-page-data-source-context";
import useAssetTrackPageStateContext, {
  AssetTrackPageStateContextProvider
} from "./contexts/asset-track-page-state-context";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import { ReactComponent as AssetIcon } from "../../assets/icons/asset.svg";
import xemelgoStyle from "../../styles/variable";
import AddAssetFeature from "../add-asset-feature-v2";
import Style from "./AssetTrackPageFeature.module.css";
import Breadcrumb from "../../components/breadcrumb";
import TrackPageOptions from "./features/track-page-options";
import useBreadcrumbBuilder from "../../components/breadcrumb/hooks/use-breadcrumb-builder";
import useMixpanelContext from "../../context/mixpanel-context";
import MultiSelectActionsBar from "../../components/multi-select-actions-bar";
import MultiSelectActionModal from "./components/multi-select-action-modal";
import StatusPopupComponent from "../../components/status-popup-component";
import AssetTrackPageTable from "./features/asset-track-page-table";
import { SideFilters } from "./features/side-filters/SideFilters";
import MetricsOverview from "./features/metrics-overview";
import FilterOverviewControl from "./features/filter-overview-control";
import { ASSET_TRACK_PAGE_V2, ASSET_TRACK_PAGE_V2_STEPS } from "../../constants/mixpanel-constant/assetTrackPageV2";
import { DEFAULT_POPUP_DURATION_MS, STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";

const mainColor = xemelgoStyle.theme.ASSET_PRIMARY;
const secondaryColor = xemelgoStyle.theme.ASSET_SECONDARY;

const AssetTrackPageFeature = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { sendMixPanelEvent } = useMixpanelContext();
  const { isLoading: isConfigLoading, initialURLParameters } = useAssetTrackPageConfigContext();
  const { locationTreeMap } = useAssetTrackPageDataSourceContext();
  const {
    enabledMultiSelectOptions,
    fetchLocationTree,
    selectedAssetIdMap,
    selectedLocationId,
    setShowCreateModal,
    fetchLocationMetrics,
    fetchCategories,
    setSelectedAssetIdMap,
    showCreateModal
  } = useAssetTrackPageStateContext();

  const [selectedMultiSelectAction, setSelectedMultiSelectAction] = useState(false);
  const [status, setStatus] = useState(STATUS_OPTIONS.SUCCESS);
  const [statusMessage, setStatusMessage] = useState("");

  const popupTimeoutRef = useRef(null);

  const { breadcrumbDataList } = useBreadcrumbBuilder({ selectedLocationId, locationTreeMap });

  useEffect(() => {
    sendMixPanelEvent(ASSET_TRACK_PAGE_V2, ASSET_TRACK_PAGE_V2_STEPS.ENTRY);
    return () => {
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isConfigLoading) {
      loadContent();
    }
  }, [isConfigLoading]);

  useEffect(() => {
    if (!search && !isConfigLoading) {
      history.replace(`${pathname}${initialURLParameters}`);
    }
  }, [search, initialURLParameters, isConfigLoading]);

  const loadContent = async () => {
    const locationTree = await fetchLocationTree();

    if (Object.keys(locationTree).length !== 0) {
      fetchLocationMetrics(locationTree);
      fetchCategories();
    }
  };

  return (
    <>
      <ScreenFrame
        title="Assets"
        color={mainColor}
        secondaryColor={secondaryColor}
        titleIconComponent={<AssetIcon style={{ color: mainColor }} />}
      >
        <div className={Style.track_page_container}>
          <SideFilters />
          <div className={Style.right_container}>
            <div className={Style.top_container}>
              <Breadcrumb dataList={breadcrumbDataList} />
              <FilterOverviewControl />
            </div>
            <div className={Style.main_content_container}>
              <MetricsOverview />
              <TrackPageOptions />
              {Object.keys(selectedAssetIdMap).length !== 0 && (
                <MultiSelectActionsBar
                  multiSelectOptions={enabledMultiSelectOptions}
                  numOfSelectedItem={Object.keys(selectedAssetIdMap).length}
                  onDeselectAllClick={() => {
                    setSelectedAssetIdMap({});
                  }}
                  onOptionClick={setSelectedMultiSelectAction}
                />
              )}
              <AssetTrackPageTable />
            </div>
          </div>
        </div>
      </ScreenFrame>
      {showCreateModal && (
        <AddAssetFeature
          onClose={() => {
            window.fcWidget.show();
            setShowCreateModal(false);
            // TODO: Automatically refresh page once the metrics are updated
          }}
        />
      )}
      {selectedMultiSelectAction && (
        <MultiSelectActionModal
          action={selectedMultiSelectAction}
          selectedItems={Object.values(selectedAssetIdMap)}
          onModalClose={(actionAttempted, success, message) => {
            setSelectedMultiSelectAction(false);
            if (actionAttempted) {
              setSelectedAssetIdMap({});
              clearTimeout(popupTimeoutRef.current);
              setStatusMessage(message);
              setStatus(success ? STATUS_OPTIONS.SUCCESS : STATUS_OPTIONS.ERROR);
              popupTimeoutRef.current = setTimeout(() => {
                setStatusMessage("");
              }, DEFAULT_POPUP_DURATION_MS);
            }
          }}
        />
      )}
      {statusMessage && (
        <div className={Style.status_popup}>
          <StatusPopupComponent
            showPopup={!!statusMessage}
            message={statusMessage}
            status={status}
          />
        </div>
      )}
    </>
  );
};

export default () => {
  return (
    <AssetTrackPageConfigContextProvider>
      <AssetTrackPageDataSourceContextProvider>
        <AssetTrackPageStateContextProvider>
          <AssetTrackPageFeature />
        </AssetTrackPageStateContextProvider>
      </AssetTrackPageDataSourceContextProvider>
    </AssetTrackPageConfigContextProvider>
  );
};
