import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import LoadingCircle from "components/loading/LoadingCircle";
import TextFilterDropDown from "../text-filter-dropdown/TextFilterDropDown";
import { naturalSort } from "../../common/Utilities";
import CheckBoxListComponentStyle from "./CheckBoxListComponent.module.css";

const CheckBoxListComponent = ({
  filterStructureMap,
  containerStyle,
  onChange,
  sortByLabel = true,
  InputComponent
}) => {
  return (
    <div className={containerStyle}>
      {Object.keys(filterStructureMap)
        .sort((a, b) => {
          if (
            !sortByLabel &&
            (filterStructureMap[a]?.index !== undefined || filterStructureMap[b]?.index !== undefined)
          ) {
            // try to sort by index
            return (filterStructureMap[a]?.index || 0) - (filterStructureMap[b]?.index || 0);
          }
          return filterStructureMap[a].label.localeCompare(filterStructureMap[b].label, undefined, {
            numeric: true,
            sensitivity: "base"
          });
        })
        .map((eachKey) => {
          const { id, label, checkboxList, type } = filterStructureMap[eachKey];

          if (type === "dropdown") {
            return (
              <div
                key={id}
                className={CheckBoxListComponentStyle.container}
              >
                <p className={CheckBoxListComponentStyle.title}>{label}</p>
                <TextFilterDropDown
                  sortSchema={checkboxList?.[0].dropdownOptions}
                  customStyle={checkboxList?.[0].style}
                  onClick={(it) => {
                    if (it?.id) {
                      // setFilterInputType(it?.id);
                      const newFilterStructureList = { ...filterStructureMap };
                      newFilterStructureList[eachKey].checkboxList[0].value = it?.id;
                      onChange(newFilterStructureList);
                    }
                  }}
                />
              </div>
            );
          }

          // Hide section if there are no options!
          if (!checkboxList?.length) {
            return null;
          }
          return (
            <CheckBoxGroup
              key={id}
              title={label}
              checkboxList={checkboxList}
              onChange={(boxId) => {
                const boxIndex = filterStructureMap[eachKey].checkboxList.findIndex((a) => {
                  return a.id === boxId;
                });
                const newFilterStructureList = { ...filterStructureMap };
                newFilterStructureList[eachKey].checkboxList[boxIndex].value =
                  !newFilterStructureList[eachKey].checkboxList[boxIndex].value;
                const index = newFilterStructureList[eachKey].idList.findIndex((eachId) => {
                  return eachId === boxId;
                });
                if (index !== -1) {
                  newFilterStructureList[eachKey].idList.splice(index, 1);
                } else {
                  newFilterStructureList[eachKey].idList.push(boxId);
                }
                onChange(newFilterStructureList);
              }}
              InputComponent={InputComponent}
            />
          );
        })}
    </div>
  );
};

const CheckBoxGroup = ({ title, checkboxList, onChange, InputComponent }) => {
  const [showAll, setShowAll] = useState(false);
  const [search, setSearch] = useState("");

  const sortedList = useMemo(() => {
    return naturalSort(checkboxList, "label");
  }, [checkboxList]);

  if (checkboxList?.[0]?.LOADING) {
    return (
      <div className={CheckBoxListComponentStyle.container}>
        <p className={CheckBoxListComponentStyle.title}>{title}</p>
        <LoadingCircle shouldCenter />
      </div>
    );
  }
  return (
    <div className={CheckBoxListComponentStyle.container}>
      <p className={CheckBoxListComponentStyle.title}>{title}</p>
      <div className={CheckBoxListComponentStyle.group_container}>
        {InputComponent ? (
          <InputComponent
            onChange={({ currentTarget }) => {
              setSearch(currentTarget.value);
            }}
          />
        ) : (
          <>
            <span className={`fa fa-search ${CheckBoxListComponentStyle.search_icon}`} />
            <input
              onChange={({ currentTarget }) => {
                setSearch(currentTarget.value);
              }}
              placeholder="Search"
              className={CheckBoxListComponentStyle.search_input}
            />
          </>
        )}
      </div>
      {sortedList.map((each, index) => {
        const { label = "", id, value } = each;
        if (index < 3 || search || showAll) {
          if (label.toLowerCase().includes(search.toLowerCase())) {
            return (
              <div
                key={id}
                onClick={() => {
                  onChange(id);
                }}
                className={CheckBoxListComponentStyle.item_container}
              >
                <div className={CheckBoxListComponentStyle.checkbox_container}>
                  <div className={`${value ? CheckBoxListComponentStyle.checkbox_filled : ""}`} />
                </div>
                <p className={CheckBoxListComponentStyle.checkbox_title}>{label}</p>
              </div>
            );
          }
        }
        return null;
      })}
      {!search && checkboxList.length > 3 && (
        <p
          className={CheckBoxListComponentStyle.more}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? "Collapse" : "See more"}
        </p>
      )}
    </div>
  );
};

export default CheckBoxListComponent;

CheckBoxGroup.defaultProps = {
  onChange: () => {},
  title: "",
  checkboxList: []
};

CheckBoxGroup.propTypes = {
  title: PropTypes.string,
  checkboxList: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func
};

CheckBoxListComponent.defaultProps = {
  filterStructureMap: {},
  onChange: () => {},
  containerStyle: ""
};

CheckBoxListComponent.propTypes = {
  filterStructureMap: PropTypes.object,
  onChange: PropTypes.func,
  containerStyle: PropTypes.string
};
