import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "@material-ui/core/styles";
import { StyledTextField } from "./InputField";

import theme from "./MaterialTheme";

/*
This component displays an input field with calendar

Inputs
  * Data - Data object containing checkbox relate data
  ** id - Identifier used to refer to group of checkboxes
  ** label - Title for this group of checkboxes
  ** value - Key / Value pair of each checkbox
  *** key - Identifier for this checkbox
  *** value - display name to the right of a checkbox

KeyboardDatePicker Properties
  * autoOk - Dismisses the calendar after date is selected
*/
export default class DateField extends PureComponent {
  render() {
    const { data, changeHandler } = this.props;

    return (
      <div key={data.id}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              TextFieldComponent={StyledTextField}
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              inputVariant="outlined"
              placeholder={data.placeholder}
              id={data.id}
              label={data.label}
              value={data.value}
              autoOk
              onChange={(date) => {
                return changeHandler(data.id, date);
              }}
              style={data.dateStyle}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
    );
  }
}

DateField.propTypes = {
  data: PropTypes.shape({
    render: PropTypes.func.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    valid: PropTypes.bool,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    validationRules: PropTypes.array
  }).isRequired,
  changeHandler: PropTypes.func.isRequired
};
