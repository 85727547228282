import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Div } from "../../../../../components/div";
import { XButton } from "../../../../../components/xemelgo-button";
import { pluralizeWord } from "../../../../../common/Utilities";
import "./style.css";

const AddAvatar = (
  <Avatar className="location-add-avatar">
    <Add />
  </Avatar>
);

export const LocationGroupHeader = ({ modelId, groupName, resourceCount, onAddClick }) => {
  const onClickCallback = (event) => {
    if (!event) {
      return;
    }

    event.stopPropagation();
    onAddClick(modelId);
  };

  return (
    <Div className="group-header">
      <Div className="group-header-label">
        <Div className="group-name">{groupName}</Div>
        <Div className="resource-count">
          {`${resourceCount} ${resourceCount === 1 ? "resource" : pluralizeWord("resource")}`}
        </Div>
      </Div>
      <Div className="group-operation">
        <XButton
          label={AddAvatar}
          onClick={onClickCallback}
        />
      </Div>
    </Div>
  );
};

LocationGroupHeader.defaultProps = {
  resourceCount: 0,
  onAddClick: () => {}
};

LocationGroupHeader.propTypes = {
  groupName: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  resourceCount: PropTypes.number,
  onAddClick: PropTypes.func
};
