import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useAssetMapStateContext from "../../contexts/asset-map-state-context";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import Style from "../../AssetMap.module.css";
import SearchDropdownStyle from "../../../../components/SearchDropdown/SearchDropdown.module.css";
import { SEARCH_TYPE_MAP } from "../../data/constants";
import useDebounce from "../../../../hooks/use-debounce";
import { naturalSort } from "../../../../common/Utilities";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

export const AssetSearch = () => {
  const xemelgoClient = useXemelgoClient();
  const [assetMapClient] = useState(xemelgoClient.getAssetMapClient());

  const {
    setSearchString,
    setSearchType,
    selectedSearchResult,
    setSelectedSearchResult,
    setShowAssetResults,
    setSearchAssetResults,
    searchType,
    searchString,
    isAssetResultsLoading,
    setIsAssetResultsLoading
  } = useAssetMapStateContext();

  const debouncedSearchString = useDebounce(searchString, 500);

  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    setSearchString("");
    setSearchResults([]);
    setSelectedSearchResult({});
  }, [searchType]);

  useEffect(() => {
    if (searchString) {
      setSearchLoading(true);
    }
  }, [searchString]);

  useEffect(() => {
    setSearchResults([]);
    if (debouncedSearchString) {
      searchFn(debouncedSearchString);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (selectedSearchResult.id) {
      fetchAssetSearchResults();
    }
  }, [selectedSearchResult, searchType]);

  const searchFn = async (searchText) => {
    let response = [];
    if (searchText) {
      switch (searchType) {
        case SEARCH_TYPE_MAP.asset_number:
          response = await assetMapClient.searchAssetNumberByIdentifier(searchText);
          break;
        case SEARCH_TYPE_MAP.asset_type:
          response = await assetMapClient.searchAssetTypeByIdentifier(searchText);
          break;
        default:
          break;
      }
    }

    setSearchResults(
      naturalSort(
        response.map(({ id, identifier }) => {
          return { id, label: identifier, value: id };
        }),
        "label"
      )
    );
    setSearchLoading(false);
  };

  const fetchAssetSearchResults = async () => {
    if (isAssetResultsLoading) {
      return;
    }
    setIsAssetResultsLoading(true);
    setShowAssetResults(true);
    let result = [];
    switch (searchType) {
      case SEARCH_TYPE_MAP.asset_number:
        result = await assetMapClient.getAssetByAssetNumber(selectedSearchResult.id);
        break;

      case SEARCH_TYPE_MAP.asset_type:
      default:
        result = await assetMapClient.getAssetByAssetTypeId(selectedSearchResult.id);
        break;
    }

    const resultGroupByLocation = Object.values(result).map((eachLocation) => {
      const { lastDetectedLocation = {}, assets = [] } = eachLocation;
      const { id = "", identifier = "" } = lastDetectedLocation;
      return {
        id,
        identifier,
        assets
      };
    });

    setSearchAssetResults(resultGroupByLocation);
    setIsAssetResultsLoading(false);
  };

  return (
    <>
      <div className={Style.search_type_container}>
        <SearchDropdown
          showIcon
          withoutOptionFilter
          options={Object.values(SEARCH_TYPE_MAP)}
          selectedItem={searchType}
          onItemSelected={(type) => {
            setSelectedSearchResult({});
            setShowAssetResults(false);
            setSearchAssetResults([]);
            setSearchResults([]);
            setSearchType(type);
          }}
          textareaClassName={Style.search_type_textarea}
        />
      </div>
      <div className={`${Style.search_container}`}>
        <SearchDropdown
          showIcon
          disableDropDownChevIcon
          placeholder={`Search for an ${searchType.label.toLowerCase()}`}
          options={searchResults}
          inputValue={selectedSearchResult.id ? selectedSearchResult.label : searchString}
          onChangeText={(text) => {
            if (!text) {
              setSearchString(text);
              setShowAssetResults(false);
              setSearchAssetResults([]);
            }
            if (text !== selectedSearchResult.label) {
              setSelectedSearchResult({});
              setSearchString(text);
              setShowAssetResults(false);
            }
          }}
          selectedItem={selectedSearchResult}
          textareaClassName={Style.search_textarea}
          renderContent={(data, blurFunction) => {
            if (searchLoading) {
              return (
                <div className={SearchDropdownStyle.dropdown_item}>
                  {[...Array(3)].map((_, index) => {
                    return <Skeleton key={index} />;
                  })}
                </div>
              );
            }
            if (data.length) {
              return data.map((item) => {
                const { id, label } = item;

                return (
                  <div
                    key={id}
                    className={SearchDropdownStyle.dropdown_item}
                    onClick={() => {
                      setSelectedSearchResult(item);
                      blurFunction();
                    }}
                  >
                    {label}
                  </div>
                );
              });
            }
            return (
              <li className={SearchDropdownStyle.dropdown_no_result_message}>
                {!searchString ? `Enter an ${searchType.label.toLowerCase()} to search` : "No matching results"}
              </li>
            );
          }}
        />
      </div>
    </>
  );
};
