import React, { useMemo, useState } from "react";
import { useAddTransferOrderFeatureConfigContext } from "../../contexts/add-transfer-order-feature-config-context/AddTransferOrderFeatureConfigContext";
import UploadCsvFeature from "../../../../../upload-csv-feature";
import { SOLUTIONS } from "../../../../../../data/constants";
import { useAddTransferOrderFeatureStateContext } from "../../contexts/add-transfer-order-feature-state-context/AddTransferOrderFeatureStateContext";
import Style from "./BulkCreateTransferOrderFeature.module.css";
import ErrorResolveModal from "../../../../../upload-csv-feature/features/error-resolve-modal";

export const BulkCreateTransferOrderFeature = () => {
  const [showErrorResolveModal, setShowErrorResolveModal] = useState(false);

  const { formFields, formSections } = useAddTransferOrderFeatureConfigContext();
  const { setFormData, formData, submitStatus } = useAddTransferOrderFeatureStateContext();

  const csvHeadersMap = useMemo(() => {
    const newCsvHeadersMap = Object.values(formFields).reduce((tempCsvHeadersMap, field) => {
      const clonedCsvHeadersMap = { ...tempCsvHeadersMap };
      const { id, isDisabled, isHidden, isReadOnly } = field;
      if (id && !isDisabled && !isHidden && !isReadOnly) {
        clonedCsvHeadersMap[id] = { ...field };
      }
      return clonedCsvHeadersMap;
    }, {});

    return newCsvHeadersMap;
  }, [formFields]);

  return (
    <div className={Style.upload_container}>
      <UploadCsvFeature
        key={String(submitStatus)}
        csvDataMap={formData}
        csvHeadersMap={csvHeadersMap}
        onCsvDataChange={setFormData}
        solution={SOLUTIONS.INVENTORY}
        errorResolveModal={() => {
          return (
            <ErrorResolveModal
              show={showErrorResolveModal}
              onClose={() => {
                setShowErrorResolveModal(false);
              }}
              formFields={formFields}
              formSections={formSections}
              formDataMap={formData}
              setFormDataMap={setFormData}
            />
          );
        }}
        onShowErrorResolveModal={setShowErrorResolveModal}
      />
    </div>
  );
};
