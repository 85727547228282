import { useRef, useCallback } from "react";

export const useConfirmation = () => {
  const resolverRef = useRef({ resolve: null });

  const setResolver = useCallback((newResolver) => {
    resolverRef.current = newResolver;
  }, []);

  const createPromise = () => {
    let tempResolver;
    return [
      new Promise((resolve) => {
        tempResolver = resolve;
      }),
      tempResolver
    ];
  };

  const getConfirmation = () => {
    const [promise, resolve] = createPromise();
    setResolver({ resolve });
    return promise;
  };

  const confirm = (status) => {
    if (!resolverRef.current.resolve) {
      throw new Error("Should call getConfirmation function before calling confirm function");
    }
    resolverRef.current.resolve(status);
  };

  return [confirm, getConfirmation];
};
