import React, { useEffect, useState } from "react";
import Style from "./StationSection.module.css";
import Selector from "../../../../../../../../components/selector/Selector";
import { queryForLatestDetectionsByReader } from "../../../../../../../../services/get-recent-tags-service";
import { useAddInventoryFeatureV2ConfigContext } from "../../../../contexts/add-inventory-feature-v2-config-context";
import { useXemelgoClient } from "../../../../../../../../services/xemelgo-service";
import { naturalSort } from "../../../../../../../../common/Utilities";
import xemelgoStyle from "../../../../../../../../styles/variable";
import AutoSizeTextArea from "../../../../../../../../components/AutoSizeTextArea/AutoSizeTextArea";
import { useAddInventoryFeatureV2StateContext } from "../../../../contexts/add-inventory-feature-v2-state-context";

export const StationSection = () => {
  const xemelgoClient = useXemelgoClient();
  const detectorClient = xemelgoClient.getDetectorClient();
  const locationClient = xemelgoClient.getLocationClient();

  const [stations, setStations] = useState([]);
  const [tag, setTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStation, setSelectedStation] = useState({});
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { getTagsApiUrl, isTagInputEditable } = useAddInventoryFeatureV2ConfigContext();
  const { setIsSubmitDisabled, formDataMap, setFormDataMap } = useAddInventoryFeatureV2StateContext();

  const onLoad = async () => {
    const newDetectorsPromise = detectorClient.listDetectors();
    const newEncodingStationsPromise = locationClient.getLocationsOfCategory(["Encoding Station"]);

    const [newEncodingStations = [], newDetectors = []] = await Promise.all([
      newDetectorsPromise,
      newEncodingStationsPromise
    ]);

    const updatedEncodingStations = newEncodingStations.map((station) => {
      const clonedStation = { ...station };
      newDetectors.forEach((detector) => {
        if (station.id === detector?.location?.id) {
          clonedStation.detectorSerial = detector.name;
        }
      });
      return clonedStation;
    });
    setStations(naturalSort(updatedEncodingStations, "label"));
    setIsLoading(false);
  };

  const queryForTags = async () => {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const response = await queryForLatestDetectionsByReader(getTagsApiUrl, selectedStation.detectorSerial);
      const message = await response.json();
      const { tags } = message;

      if (tags.length === 1) {
        handleOnTagChange(tags[0]);

        return;
      }

      if (tags.length > 1) {
        setErrorMessage(
          `Multiple tags detected.
          Ensure that there are no stray tags nearby.`
        );
      } else if (!tags.length) {
        setErrorMessage(`No tag found. Please try again`);
      }
      setIsSubmitDisabled(true);
    } catch (e) {
      setErrorMessage(`Could not scan tags, ${e}`);
    } finally {
      setLoadingMessage("");
      setIsLoading(false);
    }
  };

  const handleOnTagChange = (newTag) => {
    const clonedFormData = { ...formDataMap };
    if (clonedFormData?.id?.data) {
      clonedFormData.id.data.tracker_serial = newTag;
    }
    setTag(newTag);
    setFormDataMap(clonedFormData);
  };

  useEffect(() => {
    setIsLoading(true);
    onLoad();
  }, []);

  return (
    <div className={Style.section_container}>
      <p className={`${Style.header} ${xemelgoStyle.header}`}>Station Options</p>
      <div>
        <p className={Style.info_content_label}>
          Station
          <span className={Style.required_asterisk}>*</span>
        </p>
        <div className={Style.dropdown_input_container}>
          <Selector
            options={stations}
            onSelect={(station) => {
              if (!station) {
                setSelectedStation({});
                return;
              }
              setSelectedStation(station);
            }}
            value={Object.keys(selectedStation || {}).length ? selectedStation : undefined}
            placeholder="Select a reader"
            error={!stations.length}
            errorMessage="No Encoding Station found."
          />
        </div>
        <p className={Style.info_content_label}>
          Tag
          <span className={Style.required_asterisk}>*</span>
        </p>
        <div className={Style.add_content}>
          <div className={Style.text_input_container}>
            <AutoSizeTextArea
              id="tag"
              placeholder="RFID Tag #"
              value={tag}
              disabled={!isTagInputEditable}
              onChangeText={(value) => {
                handleOnTagChange(value);
              }}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          </div>

          <button
            type="button"
            className={Style.scan_button}
            disabled={isLoading}
            onClick={() => {
              setLoadingMessage("Scanning for RFID Tag...");
              queryForTags();
            }}
          >
            Scan
          </button>
        </div>
        <p className={Style.loading_message}>{loadingMessage}</p>
      </div>
    </div>
  );
};
