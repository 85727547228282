import { ClientConfigurationService } from "./ClientConfigurationService";
import { SessionStorageService } from "./session-storage-service";
import CONFIGURATION from "../configuration.json";

const ConfigurationExpirationTimeKey = "Configuration_Exp";
const ConfigurationValidityReductionTime = 1800000; // 30 minutes
const CustomerConfigurationKey = "CustomerConfigurationKey";
const CustomerConfigurationTestKey = "CustomerConfigurationTestKey";

class ConfigurationService {
  /*
   * This function is to be used to access the customer configuration.
   * This returns the configuration from cache if it exists in cache or from API call
   * 'allowCacheRefresh' is to prevent (allowCacheRefresh = false) cache from refreshing in pages that cannot
   * wait for the cached copy to be refreshed and need the configuration instantly.
   * (Ex: 'Add Detector/Location' modal in Configuration Page)
   *
   */
  // TODO: this method is so wrongly implemented and used and needs to be reviewed and addressed. The if condition in
  //  the code would result in the function to return a Promise, whereas the default result would return an object literal.
  getConfiguration(allowCacheRefresh) {
    const customerConfigFromCache = sessionStorage.getItem(CustomerConfigurationKey);
    const testCustomerConfigFromCache = sessionStorage.getItem(CustomerConfigurationTestKey);
    const configurationExpTime = parseInt(sessionStorage.getItem(ConfigurationExpirationTimeKey), 10);
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const cacheRefresh = allowCacheRefresh === false ? allowCacheRefresh : true;
    const testMode = SessionStorageService.getTestMode();
    if ((!customerConfigFromCache || currentTime >= configurationExpTime) && cacheRefresh) {
      return ClientConfigurationService.configure().then(() => {
        SessionStorageService.setTestMode(testMode || "Prod");
        if (SessionStorageService.getTestMode() === "Prod") {
          return JSON.parse(sessionStorage.getItem(CustomerConfigurationKey));
        }
        return JSON.parse(sessionStorage.getItem(CustomerConfigurationTestKey));
      });
    }
    const newConfigurationExpTime = configurationExpTime - ConfigurationValidityReductionTime;
    sessionStorage.setItem(ConfigurationExpirationTimeKey, newConfigurationExpTime.toString());
    if (SessionStorageService.getTestMode() === "Prod") {
      return JSON.parse(customerConfigFromCache);
    }
    return JSON.parse(testCustomerConfigFromCache);
  }

  async getSolutionType() {
    const configs = await this.getConfiguration();
    const { solutionType } = configs;
    return solutionType;
  }

  async getFullConfiguration() {
    const configs = await this.getConfiguration();
    return configs;
  }

  async getCustomerRules() {
    const configs = await this.getConfiguration();
    const { features } = configs;
    const { notifications } = features;
    return notifications;
  }

  async getTrackingFeature() {
    const configs = await this.getConfiguration();
    const { features } = configs;
    const { tracking } = features;
    return tracking;
  }

  async getSolutionConfiguration() {
    const customerConfig = await this.getConfiguration();
    const solutionConfig = customerConfig.solutionConfiguration;
    return solutionConfig;
  }

  async getTrackPageConfiguration() {
    const customerConfig = await this.getConfiguration();
    const trackPageConfig = customerConfig.solutionConfiguration.uiPreferences.trackPageConfiguration;
    return trackPageConfig;
  }

  async getTenantId() {
    const customerConfig = await this.getConfiguration();
    const { tenantId } = customerConfig;
    return tenantId;
  }

  async getTrackingConfiguration() {
    const customerConfig = await this.getConfiguration();
    const { components } = customerConfig;
    const trackingConfig = components.tracking;
    return trackingConfig;
  }

  async getCustomerConfigInfo() {
    const customerConfig = await this.getConfiguration();

    const { solutionConfiguration } = customerConfig;
    const { staticTiles } = solutionConfiguration;
    const { components } = customerConfig;
    const { tracking } = components;
    const missingThresholds = tracking.conditionsForMissing;
    const { possibleDetectorLocations } = tracking;

    return {
      staticTiles,
      missingThresholds,
      possibleDetectorLocations
    };
  }

  async getMissingThresholds() {
    const customerConfig = await this.getConfiguration();
    const { components } = customerConfig;
    const { tracking } = components;
    const missingThresholds = tracking.conditionsForMissing;

    return missingThresholds;
  }

  async getLocationConfiguration() {
    const customerConfig = await this.getConfiguration();
    const { components } = customerConfig;
    const locations = components.location;

    return locations;
  }

  async getItemConfiguration() {
    const customerConfig = await this.getConfiguration();
    const { components } = customerConfig;
    const { item } = components;
    return item;
  }

  async getTaskConfiguration() {
    const customerConfig = await this.getConfiguration();
    const { configData } = customerConfig;
    const { components } = configData;
    const { task = {} } = components;
    return task;
  }

  getDetectorConfig() {
    /* Don't allow customer config cache to be refreshed since it breaks the
     * 'Add Facility' and 'Add Detector' modal on the Configuration app.
     */
    const allowCacheRefresh = false;
    const customerConfig = this.getConfiguration(allowCacheRefresh);
    const { components } = customerConfig;
    const { tracking } = components;

    return tracking;
  }

  /**
   * Retrieve the REST service configuration
   * @return
    {
      API: {
        endpoints
      },
        imageBucket: {
          region: imageStorage.REGION,
          endpoint: imageStorage.URL
        },
        xGraph
    }

   */
  getRestServiceConfiguration() {
    return ClientConfigurationService.configure().then((customerConfig) => {
      const infrastructure = customerConfig.getInfrastructure();
      const apiGateway = infrastructure.getApiGateway();
      const xGraphConfig = infrastructure.getXgraph();
      const imageStorage = infrastructure.getImageStorage();

      const { tenantId } = customerConfig;
      let xGraph = null;
      const endpoints = [
        {
          name: "xemelgo",
          endpoint: apiGateway.URL,
          region: apiGateway.REGION
        }
      ];

      if (xGraphConfig) {
        endpoints.push({
          name: "XemelgoService",
          endpoint: xGraphConfig.endpoint,
          region: xGraphConfig.region
        });

        xGraph = {
          apiVersion: xGraphConfig.apiVersion,
          customerId: tenantId
        };
      }

      const configuration = {
        API: {
          endpoints
        },
        imageBucket: {
          region: imageStorage.REGION,
          endpoint: imageStorage.URL
        },
        xGraph
      };

      return configuration;
    });
  }

  /**
   * Retrieve the Auth Service from src/configuration.json
   *
   */
  getAuthConfiguration() {
    const authConfiguration = CONFIGURATION.AuthConfiguration;

    const configuration = {
      Auth: authConfiguration
    };

    return configuration;
  }

  async getCustomerName() {
    const customerConfig = await this.getConfiguration();
    const { customerName } = customerConfig;

    return customerName;
  }

  async defaultCustomerLogo() {
    const customerConfig = await this.getConfiguration();
    const { configData } = customerConfig;
    const { webClient } = configData;
    const { customerLogo } = webClient;
    return customerLogo;
  }

  async getStatusConfiguration(solution) {
    const customerConfig = await this.getConfiguration();
    const { configData = {} } = customerConfig;
    const { webClient = {} } = configData;
    const { appConfigurationMap = {} } = webClient;

    if (solution) {
      const solutionConfig = appConfigurationMap[solution];
      return solutionConfig.statusFlagMap;
    }
    return {
      asset: appConfigurationMap.asset ? appConfigurationMap.asset.statusFlagMap : {},
      inventory: appConfigurationMap.inventory ? appConfigurationMap.inventory.statusFlagMap : {},
      order: appConfigurationMap.order ? appConfigurationMap.order.statusFlagMap : {},
      part:
        appConfigurationMap.order && appConfigurationMap.order.part ? appConfigurationMap.order.part.statusFlagMap : {}
    };
  }

  async getSolutionAttributeMap(solution) {
    if (!solution) {
      return {};
    }
    const customerConfig = await this.getConfiguration();
    const { configData } = customerConfig;
    const { webClient } = configData;
    const { appConfigurationMap } = webClient;
    const solutionConfig = appConfigurationMap[solution];
    const { attributeMap } = solutionConfig;

    return attributeMap || {};
  }

  async getDetectorLocationCategoriesForSolution(solution) {
    if (!solution) {
      return [];
    }
    const customerConfig = await this.getConfiguration();
    const { configData } = customerConfig;
    const { webClient } = configData;
    const { appConfigurationMap } = webClient;
    const solutionConfig = appConfigurationMap[solution];
    const { possibleDetectorLocations } = solutionConfig;

    return possibleDetectorLocations || [];
  }

  async getSearchConfig() {
    const customerConfig = await this.getConfiguration();
    const { configData = {} } = customerConfig;
    const { webClient = {} } = configData;
    const { appConfigurationMap = {} } = webClient;
    const { globalSearch = {} } = appConfigurationMap;
    return globalSearch;
  }

  async getAssetItemType() {
    const customerConfig = await this.getConfiguration();
    const { configData = {} } = customerConfig;
    const { webClient = {} } = configData;
    const { appConfigurationMap = {} } = webClient;
    const { asset = {} } = appConfigurationMap;
    const { itemType = {} } = asset;
    return itemType;
  }
}

export default new ConfigurationService();
