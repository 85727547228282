/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useMemo, useState } from "react";
import "./ErrorResolveModal.css";
import _ from "lodash";
import PropTypes from "prop-types";
import { ModalForm } from "../../../../components/modal-form";
import SingleCreateForm from "../../../../components/single-create-form";
import { ReactComponent as ChevronRightIcon } from "../../../../assets/icons/chev-right.svg";
import { ReactComponent as ChevronLeftIcon } from "../../../../assets/icons/chev-left.svg";
import calculateDependentFormValue from "../../../../utils/calculate-dependent-form-value";
import xemelgoStyle from "../../../../styles/variable";
import validateCsvData from "../../utils/validate-csv-data";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import StatusPopupComponent from "../../../../components/status-popup-component";
import LoadingCircle from "../../../../components/loading/LoadingCircle";
import { FormFieldsType } from "../../../../types/onboardingPageTypes";
import { AddPageInputTypeMap } from "../../../../data/constants";

export const ErrorResolveModal = ({ show, onClose, formFields, formSections, formDataMap, setFormDataMap }) => {
  const xemelgoClient = useXemelgoClient();

  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentFormDataMap, setCurrentFormDataMap] = useState(_.cloneDeep(formDataMap));

  /**
   * Get csv headers map that needed for validation
   */
  const csvHeadersMap = useMemo(() => {
    const newCsvHeadersMap = Object.values(formFields).reduce((tempCsvHeadersMap, field) => {
      const clonedCsvHeadersMap = { ...tempCsvHeadersMap };
      const { id, isDisabled, isHidden } = field;
      if (id && !isDisabled && !isHidden) {
        clonedCsvHeadersMap[id] = { ...field };
      }
      return clonedCsvHeadersMap;
    }, {});

    return newCsvHeadersMap;
  }, [formFields]);

  /**
   * Get latest value from the current form data into the form sections to display on the UI
   */
  const processedFormSections = useMemo(() => {
    const errorDataList = Object.values(currentFormDataMap).filter((rowData) => {
      return rowData.isError;
    });
    const { data: currentRowData = {}, id } = errorDataList[currentPage] || {};

    const updatedFormSections = formSections.map((section) => {
      const sectionFieldList = section.map((field) => {
        const { errorMessage, value: fieldValue = "" } = currentRowData[field.id] || {};

        const newField = { ...field };
        const { subFieldsMap } = newField;

        // Structure value that will be passed in to CheckBoxGroup
        if (newField.type === AddPageInputTypeMap.CHECK_BOX_GROUP && newField.options) {
          const checkBoxValue = {};
          newField.options.forEach((option) => {
            checkBoxValue[option.id] = fieldValue === option.id;
          });
          newField.value = checkBoxValue;
        } else {
          newField.value = fieldValue;
        }

        // update subFields
        if (subFieldsMap) {
          newField.subFieldsMap = currentRowData[field.id]?.subFieldsMap || {};
        }

        newField.errorMessage = errorMessage;
        return newField;
      });
      return sectionFieldList;
    });
    return { data: updatedFormSections, id };
  }, [formSections, currentFormDataMap, currentPage]);

  const numberOfInvalidRows = useMemo(() => {
    return Object.values(formDataMap).reduce((numberOfInvalidItems, eachRow) => {
      return numberOfInvalidItems + (eachRow.isError ? 1 : 0);
    }, 0);
  }, [formDataMap]);

  /**
   * Validate the new input and update the formDataMap
   */
  const handleOnUpdateClick = async () => {
    setUpdating(true);
    const dataToValidate = Object.values(currentFormDataMap).map((rowData) => {
      const { data } = rowData;
      const updatedData = Object.keys(data).reduce(
        (updatedRowData, eachFieldId) => {
          const updatedField = { ...data[eachFieldId] };
          const { value } = updatedField;
          updatedRowData[eachFieldId].value = value?.value || value || "";
          return { ...updatedRowData, [eachFieldId]: { value: value?.value || value || "" } };
        },
        { ...data }
      );
      return updatedData;
    });
    const { validatedDataMap } = await validateCsvData(dataToValidate, csvHeadersMap, xemelgoClient);
    setFormDataMap(validatedDataMap);
    setUpdating(false);
    setUpdated(true);
  };

  /**
   * Update current form data with the new value
   */
  const onFormChange = useCallback(
    (fieldId, value) => {
      const newFormDataMap = { ...currentFormDataMap };
      const { data: newRowData, id: rowId } = newFormDataMap[processedFormSections.id];
      let newValue = value;

      // Parse value returned from CheckBoxGroup
      if (formFields[fieldId].type === AddPageInputTypeMap.CHECK_BOX_GROUP) {
        newValue = "";
        for (const option in value) {
          if (value[option] && newRowData[fieldId].value !== option) {
            newValue = option;
            break;
          }
        }
      }

      const newFormData = {
        ...newRowData,
        [fieldId]: { value: newValue }
      };
      // Looks for fields are dependent on other fields (eg. calibration date) and
      // calculates their values
      formSections.forEach((section) => {
        section.forEach((field) => {
          if (field.dependency && field.dependency.dependentOn.includes(fieldId)) {
            newFormData[field.id].value = calculateDependentFormValue(field, newFormData, formFields);
          }

          const { subFieldsMap = {} } = field;

          if (Object.keys(subFieldsMap).length > 0 && fieldId === field.id && Object.keys(value).length) {
            const updatedSubFieldsMap = Object.keys(subFieldsMap).reduce((newSubFieldsMap, eachFieldId) => {
              newSubFieldsMap[eachFieldId] = { ...subFieldsMap[eachFieldId], value: value[eachFieldId] };
              return { ...newSubFieldsMap, [eachFieldId]: { ...subFieldsMap[eachFieldId], value: value[eachFieldId] } };
            }, {});
            newFormData[field.id].subFieldsMap = updatedSubFieldsMap;
          }
        });
      });
      newFormDataMap[rowId].data = newFormData;
      setCurrentFormDataMap(newFormDataMap);
    },
    [formSections, currentFormDataMap, formFields, processedFormSections]
  );

  return (
    <ModalForm
      show={show && numberOfInvalidRows !== 0}
      scrollable
      className="modal_container"
      prefix="error-resolve"
      title={
        <div className="header_container">
          <div className="back_button_container">
            <ChevronLeftIcon color={xemelgoStyle.theme.APP_BLUE} />
            <button
              className="back_button"
              type="button"
              onClick={onClose}
            >
              Back
            </button>
          </div>
          <p className="title_text">Resolve Import Errors</p>
        </div>
      }
      body={
        <div className="body_container">
          <div className="body_container">
            <SingleCreateForm
              formSections={processedFormSections.data}
              onFormChange={onFormChange}
            />

            <div className="navigation_section">
              <ChevronLeftIcon
                color={currentPage + 1 === 1 ? xemelgoStyle.theme.APP_LIGHTBLUE : xemelgoStyle.theme.APP_BLUE}
                onClick={() => {
                  if (currentPage + 1 !== 1) {
                    setCurrentPage((previous) => {
                      return previous - 1;
                    });
                  }
                }}
              />
              <p className="paginator">
                {currentPage + 1}/{numberOfInvalidRows}
              </p>
              <ChevronRightIcon
                color={
                  currentPage + 1 === numberOfInvalidRows
                    ? xemelgoStyle.theme.APP_LIGHTBLUE
                    : xemelgoStyle.theme.APP_BLUE
                }
                onClick={() => {
                  if (currentPage + 1 !== numberOfInvalidRows) {
                    setCurrentPage((previous) => {
                      return previous + 1;
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="popup_modal_div">
            <StatusPopupComponent
              showPopup={updated}
              message="Assets are updated."
              status="success"
            />
          </div>
          {updating && <LoadingCircle />}
        </div>
      }
      footer={
        <div className="footer_container">
          <p className="footer_message">
            {`${numberOfInvalidRows}
             pending issues to be resolved for this import.
            `}
          </p>

          <button
            className="submit_button"
            type="button"
            onClick={handleOnUpdateClick}
          >
            Update
          </button>
        </div>
      }
    />
  );
};

ErrorResolveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formFields: PropTypes.shape({}).isRequired,
  formSections: PropTypes.arrayOf(FormFieldsType).isRequired,
  formDataMap: PropTypes.shape({}).isRequired,
  setFormDataMap: PropTypes.func.isRequired
};
