import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { getLongXemelgoLogo } from "../../common/Utilities";
import { useAppConfigProvider } from "../../services/soft-cache-service";

import ItemTypeDetailComponent from "../../components/item-type-detail-component/ItemTypeDetailComponent";
import LoadingCircle from "../../components/loading/LoadingCircle";

const APP_ID = "itemTypesManagement";
const FEATURE_ID = "detailsPage";

const ItemTypeDetailFeature = ({ history }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const detailsPageConfig = configProvider.getValue(FEATURE_ID, "object");
  const { itemTypeLabel = "Item Type" } = detailsPageConfig || {};
  const [editMode, setEditMode] = useState(false);
  const [itemTypeInfo, setItemTypeInfo] = useState({});
  const [ItemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [loading, setLoading] = useState(true);
  const [itemTypeToUpdate, setItemTypeToUpdate] = useState("");
  const [alert, setAlert] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [logo, setLogo] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const handleUpdateItemType = async () => {
    if (itemTypeToUpdate === "") {
      const alertMessage = "Please enter a value for the field Item Type Name.";
      setAlert(alertMessage);
      setAlertError(true);
      setShowAlert(true);
      setLoading(false);
    } else {
      setLoading(true);
      if (itemTypeToUpdate === itemTypeInfo.identifier) {
        setEditMode(false);
        onLoad();
      } else {
        const itemTypeOfIdentifier = await ItemTypeClient.getItemTypeByIdentifier(itemTypeToUpdate);
        if (itemTypeOfIdentifier) {
          const alertMessage = `Item type ${itemTypeToUpdate} already exists. Please use a different name.`;
          setAlert(alertMessage);
          setShowAlert(true);
          setAlertError(true);
          setLoading(false);
        } else {
          await ItemTypeClient.updateItemType(itemTypeInfo.id, {
            name: itemTypeToUpdate,
            identifier: itemTypeToUpdate
          });
          setEditMode(false);
          setShowAlert(false);
          setAlertError(false);
          setAlert("");
          onLoad();
        }
      }
    }
  };

  const onLoad = async () => {
    const payload = queryString.parse(history.location.search);
    const { id } = payload;
    const data = await ItemTypeClient.getItemTypeAndItems(id);
    // if the url provided is invalid, redirect back to the track page
    // this shouldn't really happen unless the user is trying to mess with the url
    if (!data) {
      const fullPath = history.location.pathname;
      const parentPath = fullPath.slice(0, fullPath.indexOf("/itemType/detail"));
      history.replace(parentPath);
    }
    const logo = getLongXemelgoLogo("dark");
    setLogo(logo);
    setItemTypeInfo(data);
    setItemTypeToUpdate(data.identifier);
    setLoading(false);
  };

  const handleCancel = () => {
    setItemTypeToUpdate(itemTypeInfo.identifier);
    setShowAlert(false);
    setAlertError(false);
    setAlert("");
    setEditMode(false);
  };

  const handleChange = ({ currentTarget }) => {
    const newValue = currentTarget.value.trim();
    setItemTypeToUpdate(newValue);
  };

  const handleDelete = async () => {
    const currentItemTypeInfo = await ItemTypeClient.getItemTypeAndItems(itemTypeInfo.id);
    if (currentItemTypeInfo.items !== null) {
      const alertMessage =
        "This item type is currently tracking items and cannot be deleted. Please ensure that all items are deleted prior to deleting this item type.";
      setAlert(alertMessage);
      setAlertError(true);
      setShowAlert(true);
    } else {
      await ItemTypeClient.removeItemType(itemTypeInfo.id);
      const fullPath = history.location.pathname;
      const parentPath = fullPath.slice(0, fullPath.indexOf("/itemType/detail"));
      history.replace(parentPath);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <ItemTypeDetailComponent
      itemTypeInfo={itemTypeInfo}
      itemTypeLabel={itemTypeLabel}
      editMode={editMode}
      image={logo}
      alert={alert}
      showAlert={showAlert}
      showDeleteModal={showDeleteModal}
      defaultValue={itemTypeToUpdate || itemTypeInfo.identifier}
      handleUpdateItemType={handleUpdateItemType}
      handleDelete={handleDelete}
      onCloseAlert={() => {
        setShowAlert(false);
      }}
      alertError={alertError}
      // set functions
      setEditMode={setEditMode}
      handleCancel={handleCancel}
      handleChange={handleChange}
      setShowAlert={setShowAlert}
      setShowDeleteModal={setShowDeleteModal}
    />
  );
};

export default withRouter(ItemTypeDetailFeature);
