import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { GpsPinHeadSvg } from "./gps-pin-head-svg";

export const GpsPinHead = ({ color, canClick, scale }) => {
  return (
    <div
      className="gps-pin-head"
      style={{
        color,
        position: "absolute"
      }}
    >
      <GpsPinHeadSvg
        color={color}
        className={canClick ? "pin-can-click" : "pin-cannot-click"}
        width={`${scale}em`}
        height={`${scale}em`}
      />
    </div>
  );
};

GpsPinHead.defaultProps = {
  color: "#000000",
  scale: 1,
  canClick: false
};

GpsPinHead.propTypes = {
  scale: PropTypes.number,
  color: PropTypes.string,
  canClick: PropTypes.bool
};
