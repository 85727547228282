import React from "react";
import PropTypes from "prop-types";
import Style from "./CollapsibleView.module.css";

export const CollapsibleView = ({ children, isCollapsed, width }) => {
  return (
    <div
      className={Style.information_container}
      style={{
        width: isCollapsed ? 0 : width,
        maxWidth: width
      }}
    >
      {children}
    </div>
  );
};

CollapsibleView.defaultProps = {
  children: null,
  isCollapsed: false
};

CollapsibleView.propTypes = {
  children: PropTypes.element,
  isCollapsed: PropTypes.bool,
  width: PropTypes.number.isRequired
};
