import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useQueuePriorityWorkflowControlContext from "../../contexts/queue-priority-workflow-control-context";
import Style from "./GroupedLocationList.module.css";
import { ListHeader } from "../../components/list-header/ListHeader";
import { ListItem } from "../../components/list-item/ListItem";
import ListTable from "../../../../../../../../components/ListTable/ListTable";
import { STEP_ID_MAP } from "../../data/constants";
import useWorkOrderTrackPageDataSourceContext from "../../../../../../contexts/work-order-track-page-data-source-context";
import { ListEmpty } from "../../components/list-empty/ListEmpty";

export const GroupedLocationList = ({ optionControl }) => {
  const { sortPreferenceKey } = optionControl || {};

  const { setModalHeader, setModalFooterButtons, setCurrentStep, setSelectedLocationIds, setPreviousStep } =
    useQueuePriorityWorkflowControlContext();
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();

  const header = () => {
    return (
      <div>
        <div className={Style.header_text}>Group Edit Sort Preferences</div>
        <p className={Style.header_subtext}>
          Locations with the same sort preferences can be individually or bulk edited
        </p>
      </div>
    );
  };

  useEffect(() => {
    setModalHeader(header);
    setModalFooterButtons([
      {
        id: "back",
        title: "Back",
        onClick: () => {
          setCurrentStep(STEP_ID_MAP.allLocationList);
        }
      }
    ]);
  }, []);

  const headersList = [
    { id: "allLocationName", label: "Location" },
    { id: "edit", label: "" },
    { id: "editBulk", label: "" }
  ];

  const groupedLocationList = useMemo(() => {
    const locationList = Object.values(locationTreeMap)
      .map((eachLocation) => {
        return eachLocation;
      })
      .filter((eachLocation) => {
        return eachLocation[sortPreferenceKey]?.length;
      })
      .sort((a, b) => {
        return (
          a.category.localeCompare(b.category, undefined, {
            numeric: true,
            sensitivity: "base"
          }) ||
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        );
      });

    return Object.values(
      locationList.reduce((accumulator, eachLocation) => {
        const { [sortPreferenceKey]: sortPreferenceAttributeListString } = eachLocation || {};
        if (sortPreferenceAttributeListString) {
          return {
            ...accumulator,
            [sortPreferenceAttributeListString]: {
              id: sortPreferenceAttributeListString,
              idList: [...(accumulator[sortPreferenceAttributeListString]?.idList || []), eachLocation.id],
              allLocationName: `${
                accumulator[sortPreferenceAttributeListString]?.allLocationName
                  ? `${accumulator[sortPreferenceAttributeListString].allLocationName}, `
                  : ""
              }${eachLocation.name}`
            }
          };
        }
        return accumulator;
      }, {})
    ).filter(({ idList }) => {
      return idList.length >= 2;
    });
  }, [locationTreeMap]);

  return (
    <div className={Style.container}>
      <div className={Style.list_container}>
        <ListTable
          header={headersList}
          headerContainerClassName={Style.table_header}
          renderEmptyList={() => {
            return (
              <div className={Style.list_empty_container}>
                <ListEmpty title="No sort preferences available" />
              </div>
            );
          }}
          renderHeader={(eachHeader) => {
            return (
              <ListHeader
                key={eachHeader.id}
                headerRowContainerClassName={Style.header_row_container}
                label={eachHeader.label}
                onClick={eachHeader.onClick}
              />
            );
          }}
          data={groupedLocationList}
          renderItem={(eachLocation) => {
            const displayList = headersList.map(({ id: headerId }) => {
              if (headerId === "edit") {
                return {
                  id: "edit",
                  label: (
                    <div
                      className={Style.edit_button}
                      onClick={() => {
                        setPreviousStep(STEP_ID_MAP.groupedLocationList);
                        setCurrentStep(STEP_ID_MAP.filteredLocationList);
                        setSelectedLocationIds(eachLocation.idList || []);
                      }}
                    >
                      Edit Individually
                    </div>
                  )
                };
              }
              if (headerId === "editBulk") {
                return {
                  id: "editBulk",
                  label: (
                    <div
                      className={Style.edit_button}
                      onClick={() => {
                        setPreviousStep(STEP_ID_MAP.groupedLocationList);
                        setCurrentStep(STEP_ID_MAP.editSortPreference);
                        setSelectedLocationIds(eachLocation.idList || []);
                      }}
                    >
                      Edit All
                    </div>
                  )
                };
              }
              return { id: `${eachLocation.id}${headerId}`, label: eachLocation[headerId] };
            });
            return (
              <ListItem
                key={eachLocation.id}
                itemContainerClassName={Style.header_row_container}
                item={displayList}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

GroupedLocationList.defaultProps = {
  optionControl: {},
  onModalClose: () => {}
};

GroupedLocationList.propTypes = {
  onModalClose: PropTypes.func,
  optionControl: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    locationCategories: PropTypes.arrayOf(PropTypes.string),
    sortPreferenceKey: PropTypes.string,
    sortPreferenceAttributeMap: PropTypes.object,
    sortPreferenceSections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        attributes: PropTypes.arrayOf(PropTypes.string)
      })
    )
  })
};
