export const generateBartenderPayload = (labelPropertiesMap, labelConfig) => {
  const bartenderPayload = {};
  const { propertiesToHeaderMap, templateFilePath, printerName } = labelConfig || {};
  Object.keys(propertiesToHeaderMap).forEach((property) => {
    const headerLabel = propertiesToHeaderMap[property];
    switch (property) {
      case "format_name":
        bartenderPayload[headerLabel] = templateFilePath;
        break;
      case "printer_name":
        bartenderPayload[headerLabel] = printerName;
        break;
      case "quantity":
        bartenderPayload[headerLabel] = 1;
        break;
      default:
        bartenderPayload[headerLabel] = labelPropertiesMap[property] || null;
        break;
    }
  });
  return bartenderPayload;
};
