import PropTypes from "prop-types";

export const printTags = async (
  payloadsByTransferOrders,
  generatePrintCommandFunc,
  selectedTemplateConfig,
  printFunc
) => {
  const printData = payloadsByTransferOrders.reduce((newPrintPayloads, itemTypeByTransferOrder) => {
    const payloads = Object.values(itemTypeByTransferOrder).flatMap((itemTypeInfo) => {
      const { payloads: payloadsByItemType, transferOrder } = itemTypeInfo;
      return payloadsByItemType.map((payload) => {
        return { ...payload, transfer_order: transferOrder.value };
      });
    });
    return [...newPrintPayloads, ...payloads];
  }, []);

  const printPayloads = await generatePrintCommandFunc(printData, selectedTemplateConfig);
  for (const payload of printPayloads) {
    await printFunc(payload);
  }
};

const generatePrintCommandFuncShape = PropTypes.func.isRequired;
const selectedTemplateConfigShape = PropTypes.object.isRequired;
const printFuncShape = PropTypes.func.isRequired;

printTags.PropTypes = {
  payloadsByTransferOrders: PropTypes.arrayOf({}).isRequired,
  generatePrintCommandFunc: generatePrintCommandFuncShape.isRequired,
  selectedTemplateConfig: selectedTemplateConfigShape.isRequired,
  printFunc: printFuncShape.isRequired
};
