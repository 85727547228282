export const multipleSortComparison = (orders) => {
  return (a, b) => {
    const sortDirection = { asc: 1, desc: -1 };
    const sortCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    const totalOrders = orders.length;

    for (let index = 0; index < totalOrders; index++) {
      const { id, direction = "desc" } = orders[index];

      const aValue = id ? a[id] : a;
      const bValue = id ? b[id] : b;
      const directionInt = sortDirection[direction];
      let compare = sortCollator.compare(aValue, bValue);

      if (!((aValue === undefined || aValue === null) && (bValue === undefined || bValue === null))) {
        if (aValue === undefined || aValue === null) {
          return 1;
        }
        if (bValue === undefined || bValue === null) {
          return -1;
        }
      } else {
        compare = 0;
      }

      if (compare < 0) {
        return directionInt;
      }
      if (compare > 0) {
        return -directionInt;
      }
    }
    return 0;
  };
};
