import { FILTER_TYPE } from "../../data/constants";

export const useSolutionMetricsFilterStringBuilder = () => {
  const getCheckBoxFilterString = (eachFilterKey, eachFilterValue) => {
    const property = `properties.${eachFilterKey}`;

    const selectedItems = (eachFilterValue || []).filter(({ value }) => {
      return value;
    });

    if (selectedItems.length) {
      return `(${selectedItems
        .map(({ id }) => {
          return `${property} == "${id}"`;
        })
        .join(" or ")})`;
    }
    return "";
  };

  const getDateRangeFilterString = (eachFilterKey, eachFilterValue) => {
    const property = `properties.${eachFilterKey}`;
    const { startTime, endTime } = eachFilterValue || {};
    const filters = [];
    if (startTime) {
      filters.push(`${property} >= ${startTime}`);
    }
    if (endTime) {
      filters.push(`${property} <= ${endTime}`);
    }

    if (filters.length) {
      return `(${filters.join(" and ")})`;
    }
    return "";
  };

  const getSearchDropdownFilterString = (eachFilterKey, eachFilterValue) => {
    const property = `properties.${eachFilterKey}`;
    const { value: selectedValue } = eachFilterValue || {};
    if (selectedValue) {
      const { id } = selectedValue || {};
      return `(${property} === "${id}")`;
    }
    return "";
  };

  const getInputFilterString = (eachFilterKey, eachFilterValue) => {
    const property = `properties.${eachFilterKey}`;
    const { value: valueStrings = [] } = eachFilterValue || {};
    if (valueStrings.length) {
      return `(${valueStrings
        .map((eachString) => {
          return `${property} == "${eachString}"`;
        })
        .join(" or ")})`;
    }
    return "";
  };

  const getFilterStringByType = (eachFilterKey, eachFilterValue, type) => {
    switch (type) {
      case FILTER_TYPE.checkboxes:
        return getCheckBoxFilterString(eachFilterKey, eachFilterValue);
      case FILTER_TYPE.dateRange:
        return getDateRangeFilterString(eachFilterKey, eachFilterValue);

      case FILTER_TYPE.searchDropdown:
        return getSearchDropdownFilterString(eachFilterKey, eachFilterValue);
      case FILTER_TYPE.input:
        return getInputFilterString(eachFilterKey, eachFilterValue);

      default:
        return "";
    }
  };

  const build = (filterValue, idToTypeMap) => {
    return Object.keys(filterValue || {})
      .map((eachFilterKey) => {
        return getFilterStringByType(eachFilterKey, filterValue[eachFilterKey], idToTypeMap[eachFilterKey]);
      })
      .filter((eachFilterString) => {
        return eachFilterString;
      })
      .join(" and ");
  };

  return { build };
};
