import getMapFeatureCenter from "../get-map-feature-center";

export const getTargetFeatureHelper = (features, currentZoom) => {
  let currentFeature = features?.[0];
  features.forEach((eachFeature) => {
    if (eachFeature) {
      const { zoom, minzoom } = eachFeature.properties || {};
      if ((!currentFeature || currentFeature.properties.zoom <= zoom) && currentZoom > minzoom) {
        currentFeature = eachFeature;
      }
    }
  });

  const center = getMapFeatureCenter(currentFeature);

  return [currentFeature, center];
};
