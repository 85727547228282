export const getAliasedHeaders = (defaultHeaders, reportsConfig, columnsToExcludeInResult) => {
  if (!reportsConfig?.removedReport?.headerAliases) {
    return defaultHeaders;
  }

  const aliasedHeaders = defaultHeaders.map((header) => {
    const configId = header.configId || header.id;
    const alias = reportsConfig.removedReport.headerAliases[configId];
    if (alias) {
      return { id: header.id, label: alias };
    }

    return header;
  });

  return aliasedHeaders.filter((header) => {
    return !(columnsToExcludeInResult || []).includes(header.id);
  });
};
