import React from "react";
import PropTypes from "prop-types";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Style from "./ScreenFrame.module.css";

const ScreenFrame = ({
  title,
  subTitle,
  titleIconComponent,
  color,
  secondaryColor,
  children,
  titleRightComponent,
  verticalExpandDisabled,
  hasBackButton,
  isLoading
}) => {
  const history = useHistory();

  const onBackButtonClick = () => {
    history.goBack();
  };

  const renderTitleIcon = () => {
    return isLoading ? (
      <Skeleton
        width={40}
        height={40}
      />
    ) : (
      <div
        className={Style.title_icon}
        style={{ color: secondaryColor || color }}
      >
        {typeof titleIconComponent === "function" ? titleIconComponent() : titleIconComponent}
      </div>
    );
  };

  const renderTitleText = () => {
    return isLoading ? (
      <Skeleton
        width={175}
        height={30}
      />
    ) : (
      <div className={Style.title}>{title}</div>
    );
  };

  const renderSubTitleText = () => {
    return isLoading ? (
      <Skeleton
        width={175}
        height={30}
      />
    ) : (
      <p className={Style.sub_title}>{subTitle}</p>
    );
  };

  const renderTitleRightComponent = () => {
    return isLoading ? (
      <Skeleton
        width={175}
        height={30}
      />
    ) : (
      <div className={Style.title_right_container}>
        {typeof titleRightComponent === "function" ? titleRightComponent() : titleRightComponent}
      </div>
    );
  };

  return (
    <div
      className={`${Style.grid} ${Style.page_frame} ${verticalExpandDisabled && Style.fixed_height}`}
      style={{ borderTopColor: color }}
      data-cy="screen-frame"
    >
      <div className={Style.title_container}>
        {hasBackButton && (
          <button
            type="button"
            className={Style.back_button}
            onClick={onBackButtonClick}
            style={{ color }}
          >
            <ArrowBackIosOutlinedIcon />
          </button>
        )}
        {titleIconComponent && renderTitleIcon()}
        {renderTitleText()}
        {renderSubTitleText()}
        {titleRightComponent && renderTitleRightComponent()}
      </div>
      <div className={`${Style.flex_column} ${verticalExpandDisabled && Style.overflow_hidden}`}>{children}</div>
    </div>
  );
};

ScreenFrame.defaultProps = {
  title: "",
  subTitle: "",
  titleIconComponent: null,
  titleRightComponent: null,
  color: "#C8CBD3",
  secondaryColor: "",
  verticalExpandDisabled: false,
  hasBackButton: false
};

ScreenFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleIconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  titleRightComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  verticalExpandDisabled: PropTypes.bool,
  hasBackButton: PropTypes.bool
};

export default ScreenFrame;
