import React, { useMemo } from "react";
import _ from "lodash";
import ListView from "../../../../components/TrackPageComponents/ListView";
import Style from "./ImportErrorsSummary.module.css";

export const ImportErrorSummary = ({ errorSummaryData, solutionType }) => {
  const headers = useMemo(() => {
    return [
      {
        id: "index",
        label: "#"
      },
      {
        id: "error_type",
        label: "Error Type"
      },
      {
        id: "num_of_affected_row",
        label: `Number of ${_.capitalize(solutionType)} Affected`
      },
      {
        id: "affected_row_list",
        label: "Row Number Affected in CSV"
      }
    ];
  });

  return (
    <div className={Style.container}>
      <p className={Style.title}>Import Errors</p>
      <ListView
        dataList={errorSummaryData}
        headerStructureList={headers}
      />
    </div>
  );
};
