import { XemelgoService } from "../../../../../../../../../../services/XemelgoService";

export const fetchReaderOptions = async (stageId) => {
  const locationClient = XemelgoService.getClient().getLocationClient();
  const detectorClient = XemelgoService.getClient().getDetectorClient();
  const locations = await locationClient.getLocationsByRoleAction(stageId);
  const locationIds = locations.map((location) => {
    return location.id;
  });
  const detectors = await detectorClient.getDetectorsAtLocationIds(locationIds, ["Mounted"], ["MQTT"]);

  const newReaderOptions = detectors
    .map((detector) => {
      const { id, name, vendor, location, vid } = detector;
      return {
        detectorId: id,
        detectorSerial: vid,
        detectorVendor: vendor,
        label: location?.name || name,
        location
      };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  return newReaderOptions;
};
