import React, { useState, Fragment } from "react";
import { Close } from "@material-ui/icons";
import FilterListComponentStyle from "./FilterListComponent.module.css";
import xemelgoStyle from "styles/variable";

export const FilterListComponent = ({ onClick, list = [] }) => {
  const [showMoreFilterTabs, setShowMoreFilterTabs] = useState(false);

  if (list.length) {
    return (
      <div className={FilterListComponentStyle.filter_tab_container}>
        <p className={FilterListComponentStyle.filter_tab_label}>Filters:</p>
        <div className={FilterListComponentStyle.filter_tab_list}>
          {list
            .sort((a, b) => {
              return a.label < b.label;
            })
            .map((each, index) => {
              if (index < 4 || showMoreFilterTabs) {
                const { id, label } = each;
                return (
                  <div
                    key={`${id}_${label}`}
                    className={FilterListComponentStyle.filter_tab_item_group}
                  >
                    <p className={FilterListComponentStyle.filter_tab_item_title}>{label}</p>
                    <Close
                      onClick={() => {
                        onClick([id]);
                      }}
                      className={FilterListComponentStyle.close_button}
                      style={{ height: "15px" }}
                    />
                  </div>
                );
              }
            })}
          {list.length > 4 && !showMoreFilterTabs && (
            <div className={FilterListComponentStyle.filter_tab_more_count}>{`+ ${list.length - 4} filters`}</div>
          )}
          {list.length > 4 && (
            <div
              onClick={() => {
                setShowMoreFilterTabs(!showMoreFilterTabs);
              }}
              className={FilterListComponentStyle.filter_action_btn}
            >
              {`Show ${showMoreFilterTabs ? "less" : "more"}`}
            </div>
          )}
          <div
            onClick={() => {
              console.log(list);
              const ids = list.map((each) => {
                return each.id;
              });
              onClick(ids);
            }}
            className={FilterListComponentStyle.filter_action_btn}
          >
            Clear Filters
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
