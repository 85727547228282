import React from "react";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import PropTypes from "prop-types";
import ClearIcon from "@material-ui/icons/Clear";
import { useInputStyle, userInputLabelStyle, useInputIconStyle } from "./AddPageInputsStyle";
import { makeStyles } from "@material-ui/core/styles";
import "./Asterisk.css";

const TextInput = React.forwardRef(
  (
    {
      id,
      label,
      value,
      transformInput,
      onChange,
      error,
      required,
      onBlur,
      disabled,
      numberOnly,
      InputProps,
      size,
      inputProps,
      variant,
      errorMessage,
      type,
      multiline,
      FormHelperTextProps,
      placeholder
    },
    ref
  ) => {
    const inputStyle = useInputStyle();
    const inputLabelStyle = userInputLabelStyle();
    const inputIconStyle = useInputIconStyle();
    return (
      <TextField
        inputRef={ref}
        multiline={multiline}
        type={type}
        style={disabled ? { borderRadius: 5, backgroundColor: "#F5F5F5" } : {}}
        disabled={disabled}
        id={id}
        onBlur={onBlur || null}
        variant={variant}
        size={size}
        placeholder={placeholder}
        value={value || ""}
        label={label}
        error={error}
        required={required}
        onChange={
          onChange
            ? (event) => {
                // the reason why we check for '.' is because we want to allow the user to start with a decimal otherwise (which by itself isNaN)
                if (!numberOnly || (numberOnly && (!isNaN(event.target.value) || event.target.value === "."))) {
                  onChange(id, event.target.value, transformInput);
                }
              }
            : null
        }
        FormHelperTextProps={FormHelperTextProps}
        InputLabelProps={{ classes: inputLabelStyle, disabled }}
        InputProps={
          InputProps || {
            classes: inputStyle,
            endAdornment: !disabled && (
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  classes={inputIconStyle}
                  size="small"
                  onClick={() => {
                    return onChange(id, "");
                  }}
                  tabIndex={-1}
                >
                  <ClearIcon
                    fontSize="small"
                    hidden={false}
                  />
                </IconButton>
              </InputAdornment>
            )
          }
        }
        inputProps={inputProps}
        helperText={errorMessage}
        data-cy-add-page-inputs__text-field
      />
    );
  }
);
export default TextInput;

TextInput.defaultProps = {
  id: "",
  label: "",
  value: null,
  onChange: () => {},
  error: false,
  required: false,
  onBlur: () => {},
  transformInput: false,
  disabled: false,
  numberOnly: false,
  variant: "outlined",
  multiline: false,
  placeholder: ""
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
  transformInput: PropTypes.bool,
  disabled: PropTypes.bool,
  numberOnly: PropTypes.bool,
  InputProps: PropTypes.object,
  size: PropTypes.string,
  inputProps: PropTypes.object,
  variant: PropTypes.string,
  errorMessage: PropTypes.string,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string
};
