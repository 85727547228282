import React, { useMemo, useState } from "react";
import { STATUS_COLOR_MAP } from "../../data/constants";
import useAssetMapStateContext from "../../contexts/asset-map-state-context";
import LocationSelectionContent from "../../components/location-selection-content";
import AssetStatusContent from "./features/asset-status-content";
import useAssetMapConfigContext from "../../contexts/asset-map-config-context";
import TabBar from "../../../../components/tab-bar";
import AssetTypeCountsContent from "./features/asset-type-counts-content";
import Style from "../../AssetMap.module.css";

const TAB_OPTION_MAP = {
  ASSETS_STATUS: "assetsStatus",
  LOCATION_SELECTION: "locationSelection",
  ASSET_TYPE_COUNTS: "assetTypeCounts"
};

export const SelectedLocationContent = () => {
  const { mapRef, selectedLocationId, setSelectedLocationId, locationMap, locationToCounts } =
    useAssetMapStateContext();
  const { showAssetStatus, locationSelectionTableOptions } = useAssetMapConfigContext();

  const [currentTab, setCurrentTab] = useState(
    showAssetStatus ? TAB_OPTION_MAP.ASSETS_STATUS : TAB_OPTION_MAP.LOCATION_SELECTION
  );

  const selectedLocation = useMemo(() => {
    return locationMap[selectedLocationId] || { childLocations: [] };
  }, [selectedLocationId, locationMap]);

  const tabList = useMemo(() => {
    const newTabList = [];
    if (showAssetStatus) {
      newTabList.push({
        id: TAB_OPTION_MAP.ASSETS_STATUS,
        label: "Assets"
      });
    }

    if (selectedLocation.childLocations.length > 1) {
      newTabList.push({
        id: TAB_OPTION_MAP.LOCATION_SELECTION,
        label: "Locations"
      });
    } else {
      newTabList.push({
        id: TAB_OPTION_MAP.ASSET_TYPE_COUNTS,
        label: "Asset Types"
      });
    }

    setCurrentTab(newTabList[0].id);

    return newTabList;
  }, [showAssetStatus, selectedLocation]);

  const locations = useMemo(() => {
    return selectedLocation.childLocations.reduce((childLocations, locationId) => {
      if (locationId === selectedLocationId || locationMap[locationId].directParentId !== selectedLocationId) {
        return childLocations;
      }

      return [
        ...childLocations,
        {
          ...locationMap[locationId],
          totalCount: locationToCounts[locationId]?.totalCount || 0
        }
      ];
    }, []);
  }, [selectedLocation, locationMap, locationToCounts, selectedLocationId]);

  return (
    <div>
      <div className={Style.information_header}>
        <div className={`${Style.flex_row} ${Style.information_title_container}`}>
          <p className={Style.information_title}>{locationMap[selectedLocationId]?.name}</p>
          {showAssetStatus && selectedLocation?.status !== "EmptyLocation" && (
            <div
              className={Style.status_pill_header}
              style={{
                backgroundColor: STATUS_COLOR_MAP[selectedLocation?.status]
              }}
            >
              {selectedLocation?.status}
            </div>
          )}
        </div>
      </div>
      <div className={Style.flex_row}>
        {tabList.length > 1 && (
          <TabBar
            containerClassName={Style.tab_bar}
            tabList={tabList}
            currentTabId={currentTab}
            onTabClick={(newTab) => {
              setCurrentTab(newTab);
            }}
          />
        )}
      </div>
      {currentTab === TAB_OPTION_MAP.ASSETS_STATUS ? (
        <AssetStatusContent />
      ) : currentTab === TAB_OPTION_MAP.ASSET_TYPE_COUNTS ? (
        <AssetTypeCountsContent />
      ) : (
        <LocationSelectionContent
          mapRef={mapRef}
          headers={locationSelectionTableOptions.headers}
          pageSize={locationSelectionTableOptions.pageSize}
          locations={locations}
          onLocationClick={async (location) => {
            setSelectedLocationId(location.id);
          }}
        />
      )}
    </div>
  );
};
