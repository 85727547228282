import PropTypes from "prop-types";
import React from "react";

export const LabelWithOptionalText = ({ label, isOptional }) => {
  return (
    <div className="input-label-group">
      <div className="input-label-name">{label}</div>
      {isOptional && <div className="input-label-optional-label">Optional</div>}
    </div>
  );
};

LabelWithOptionalText.defaultProps = {
  isOptional: false
};

LabelWithOptionalText.propTypes = {
  label: PropTypes.string.isRequired,
  isOptional: PropTypes.bool
};
