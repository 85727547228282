/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  DisplayBannerContextProvider,
  useDisplayBannerContext
} from "./context/DisplayBannerContext/DisplayBannerContext";
import Routes from "./routes/Routes";
import MainAppFeature from "./features/main-app-feature/MainAppFeature";
import { XemelgoService } from "./services/XemelgoService";
import { MixpanelContextProvider } from "./context/mixpanel-context";
import "./App.css";
import useUserLocationContext, { UserLocationContextProvider } from "./context/user-location-context";

const MainApp = () => {
  const [isLoading, setLoading] = useState(true);
  const { isLoading: isUserLocationContextLoading } = useUserLocationContext();
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();

  /**
   * @type {./domains/user-profile.UserProfile}
   */
  // check if the scenario is prod (0) or dev (1)

  useEffect(() => {
    const onLoad = async () => {
      setLoading(true);
      try {
        await XemelgoService.getClient().setScenario(sessionStorage.getItem("scenario") || "0");
      } catch {
        setShowBanner(true);
        setBannerHasError(true);
        setBannerTitle("An error has occured. Please contact Xemelgo Support for assistance.");
      }
      setLoading(false);
    };
    if (!isUserLocationContextLoading) {
      onLoad();
    }
  }, [isUserLocationContextLoading]);

  if (isLoading) {
    return null;
  }
  return <MainAppFeature render={<Routes />} />;
};

export default withRouter(() => {
  return (
    <MixpanelContextProvider>
      <DisplayBannerContextProvider>
        <UserLocationContextProvider>
          <MainApp />
        </UserLocationContextProvider>
      </DisplayBannerContextProvider>
    </MixpanelContextProvider>
  );
});
