/**
 * A filter to be used in the SideFilter component. Defines the
 * component to render, filter to apply, and title of filter.
 * @typedef {Object} SideFilter
 * @property {string} id - The id for this filter
 * @property {string} title - The name to be displayed as the title for this category
 * @property {(sideFilterValues: {}, updateFilterValues: () => {}) => JSX.Element} renderComponent - Function which renders
 * the React component to be used as the filter. The function receives the current values for the SideFilter
 * and a callback to update the SideFilter values as arguments
 * @property {(sideFilterValues: {}, item: {}) => boolean} filterToApply - Function which, given the
 * current SideFilter values and an individual item to apply the filter to, will determine whether
 * or not that item passes the filter
 */

/**
 * Defines a filter category which appears as an individual
 * section within the side filter. Contains the definitions for
 * each filter within the category
 * @typedef {Object} SideFilterCategory
 * @property {string} id - The id for this filter category
 * @property {string} title - The name to be displayed as the title for this category
 * @property {SideFilter[]} filters - Array of filters for this category
 */

/**
 * The configuration object used by SideFilter in order
 * to render filter categories and filters.
 * @typedef {Object} SideFilterConfiguration
 * @property {string} id - A unique Id for this configuration
 * @property {SideFilterCategory} categories - Array containing all filter categories for this configuration
 */

/**
 * "Abtract" base class for defining SideFilter configurations
 */
export class FilterConfigurationBase {
  /**
   *
   * @param {any} [configurationOptions] - Optional configuration object provided for uses
   * when building the configuration
   * @returns {SideFilterConfiguration} A {@link SideFilterConfiguration} capturing all categories and filters
   * for the configuration. For a reference on the SideFilterConfiguration, @see {@link FilterConfigurationBase}
   */
  // eslint-disable-next-line no-unused-vars
  getConfiguration(configurationOptions) {
    throw new Error("getConfiguration() is not implemented");
  }
}
