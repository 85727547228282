import React from "react";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import Style from "./ScannedItemTable.module.css";
import ListTable from "../../../../../../../../../../components/ListTable/ListTable";
import { QR_CODE_COLUMN_TYPE } from "../../../../data/constants";
import Tooltip from "../../../../../../../../../../components/tooltip";

export const ScannedItemTable = ({ headers, items }) => {
  return (
    <ListTable
      tableClassName={Style.table_container}
      headerContainerClassName={Style.header_container}
      itemsContainerClassName={Style.items_container}
      header={headers}
      renderHeader={(header) => {
        return (
          <div
            key={header.id}
            className={`${Style.table_header}`}
          >
            {header.label}
          </div>
        );
      }}
      data={items}
      renderItem={(item) => {
        return (
          <div className={Style.table_row}>
            {headers.map((header) => {
              return (
                <div
                  key={header.id}
                  className={`${Style.table_cell}`}
                >
                  {header.type === QR_CODE_COLUMN_TYPE ? (
                    <Tooltip
                      trigger="click"
                      arrow
                      animation={false}
                      containerClassName={Style.qr_code_tooltip}
                      content={
                        <QRCodeSVG
                          value={item[header.id]}
                          size={85}
                        />
                      }
                    >
                      <p className={Style.button_text}>View QR Code</p>
                    </Tooltip>
                  ) : (
                    item[header.id] || "-"
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};

ScannedItemTable.defaultProps = {
  headers: [],
  items: []
};

ScannedItemTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  items: PropTypes.arrayOf(PropTypes.shape({}))
};
