import React from "react";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import { ReactComponent as PrintIcon } from "../../../../assets/icons/printer.svg";
import { ReactComponent as LocationIcon } from "../../../../assets/icons/location.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/icons/checkmark.svg";
import Style from "./MultiSelectActionsBar.module.css";

const multiSelectOptionIcons = {
  printTags: PrintIcon,
  setLocation: LocationIcon,
  markAsComplete: CheckMarkIcon
};

export const MultiSelectActionsBar = () => {
  const { enabledMultiSelectOptions, selectedOrders, setSelectedOrders, setSelectedMultiSelectAction } =
    useWorkOrderTrackPageStateContext();

  return (
    <div className={Style.action_bar}>
      <div className={Style.selection_info}>
        <div className={Style.selected_amount}>
          {
            Object.values(selectedOrders).filter((value) => {
              return value;
            }).length
          }
        </div>
        <div className={Style.selected_text}>selected</div>
        <div
          className={`${Style.action_bar_button} ${Style.deselect_all}`}
          onClick={() => {
            setSelectedOrders({});
          }}
        >
          Deselect All
        </div>
      </div>
      <div className={Style.multi_select_options}>
        {enabledMultiSelectOptions.map((option) => {
          const { id, label } = option;
          const IconComponent = multiSelectOptionIcons[id];

          return (
            <div
              className={`${Style.multi_select_option} ${Style.action_bar_button}`}
              key={id}
              onClick={() => {
                setSelectedMultiSelectAction(id);
              }}
            >
              <IconComponent
                data-cy-multi-select-action-button
                width="23px"
                height="23px"
                className={Style.action_icon}
              />
              <div className={`${Style.multi_select_option_text}`}>{label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
