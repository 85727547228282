import React, { useMemo } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { DateTimePicker as DTPicker, DatePicker as DPicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { EventRounded, Clear } from "@material-ui/icons";
import PropTypes from "prop-types";
import moment from "moment";
import Style from "./DateTimePicker.module.css";
import xemelgoStyle from "../../styles/variable";

const TimePicker = ({
  error,
  errorMessage,
  onTimeChange,
  value,
  placeholder,
  readOnly,
  pickerMode,
  format,
  inputClassName,
  inputContainerClassName,
  showIcon,
  color,
  ...restProps
}) => {
  const Picker = (props) => {
    switch (pickerMode) {
      case "datetime":
        return <DTPicker {...props} />;

      case "date":
      default:
        return <DPicker {...props} />;
    }
  };

  const finalTheme = useMemo(() => {
    const themJSON = {
      palette: {
        primary: {
          main: xemelgoStyle.theme.APP_BLUE
        },
        secondary: {
          main: xemelgoStyle.theme.APP_WHITE
        }
      }
    };
    if (color) {
      themJSON.palette.primary.main = color;
    }
    return createTheme(themJSON);
  }, [color]);

  return (
    <div>
      <ThemeProvider theme={finalTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Picker
            value={value && moment(value)}
            onChange={(newDate) => {
              if (pickerMode === "date") {
                /* set time to fixed value to make sure the same date has same
                 * timestamp which makes it easy to query on the backend
                 */
                newDate.setHours(9);
                newDate.setMinutes(0);
                newDate.setSeconds(0);
                newDate.setMilliseconds(0);
              }
              onTimeChange(newDate.valueOf());
            }}
            inputVariant="outlined"
            format={format}
            TextFieldComponent={({ onClick, onKeyDown, value: inputValue, onChange = () => {} }) => {
              if (readOnly) {
                return <p className={`${Style.read_only_text} ${inputClassName}`}>{!value ? "--" : inputValue}</p>;
              }
              return (
                <div className={`${Style.input_container} ${inputContainerClassName}`}>
                  <input
                    placeholder={placeholder}
                    onChange={onChange}
                    className={`${Style.input} ${!showIcon && Style.input_with_no_icon} ${
                      error && Style.input_error
                    } ${inputClassName}`}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    value={!value ? "" : inputValue}
                  />
                  {showIcon && (
                    <div className={Style.input_icon_container}>
                      {!!value && (
                        <Clear
                          onClick={() => {
                            onTimeChange(undefined);
                          }}
                        />
                      )}
                      <EventRounded
                        style={{ color }}
                        onClick={onClick}
                      />
                    </div>
                  )}
                </div>
              );
            }}
            {...restProps}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      {error && <p className={Style.error_message}>{errorMessage}</p>}
    </div>
  );
};

TimePicker.defaultProps = {
  error: false,
  errorMessage: "",
  onTimeChange: () => {},
  value: 0,
  readOnly: false,
  pickerMode: "date",
  format: "MM/dd/yyyy",
  inputClassName: "",
  inputContainerClassName: "",
  showIcon: true,
  placeholder: "",
  color: ""
};

TimePicker.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onTimeChange: PropTypes.func,
  value: PropTypes.number,
  readOnly: PropTypes.bool,
  pickerMode: PropTypes.string,
  format: PropTypes.string,
  inputClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  showIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  color: PropTypes.string
};

export const DateTimePicker = (props) => {
  return (
    <TimePicker
      pickerMode="datetime"
      format="MM/dd/yyyy hh:mm a"
      {...props}
    />
  );
};

export const DatePicker = (props) => {
  return (
    <TimePicker
      pickerMode="date"
      format="MM/dd/yyyy"
      {...props}
    />
  );
};

export default DateTimePicker;
