export const ITEM_TYPE_MANAGEMENT_PAGE = "Item Type Management Page";
export const ITEM_TYPE_MANAGEMENT_PAGE_STEPS = {
  ENTRY: "Entry",
  ITEM_TYPE_ONBOARD_SUCCESS: "Item Type Onboard Success",
  ITEM_TYPE_ONBOARD_FAILED: "Item Type Onboard Failed",
  BULK_CREATE_ENTRY: "Bulk Create Entry",
  BULK_CREATE_SUCCESS: "Bulk Create Success",
  BULK_CREATE_FAILURE: "Bulk Create Failure",
  BULK_UPDATE_ENTRY: "Bulk Update Entry",
  BULK_UPDATE_SUCCESS: "Bulk Update Success",
  BULK_UPDATE_FAILURE: "Bulk Update Failure",
  DETAIL_PAGE_ENTRY: "Detail Page Entry"
};
