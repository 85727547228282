import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Style from "./Comment.module.css";
import Tooltip from "../../../tooltip";

export const Comment = ({ comment }) => {
  const commentRef = useRef(null);
  const [commentText, setCommentText] = useState(comment);
  const [isExpanded, setIsExpanded] = useState(false);
  const [lineCount, setLineCount] = useState(0);

  useEffect(() => {
    setCommentText(comment);
  }, [comment]);

  useEffect(() => {
    if (commentRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(commentRef.current).lineHeight, 10);
      const height = commentRef.current.scrollHeight;
      setLineCount(Math.ceil(height / lineHeight));
    }
  }, [commentText, isExpanded]);

  const renderComment = (isExpandedComment) => {
    return (
      <div className={isExpandedComment ? Style.expanded_text_container : ""}>
        {commentText.split("\n").map((line, index) => {
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <p
        ref={commentRef}
        className={Style.truncated}
      >
        {renderComment()}
      </p>
      {lineCount > 3 && (
        <Tooltip
          containerClassName={Style.tooltip_container}
          onHidden={() => {
            setIsExpanded(false);
          }}
          placement="top-start"
          animation={false}
          trigger="click"
          interactive
          interactiveDebounce={75}
          content={renderComment(true)}
        >
          <span
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            className={Style.expand_text}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </span>
        </Tooltip>
      )}
    </div>
  );
};

Comment.defaultProps = {
  comment: ""
};

Comment.propTypes = {
  comment: PropTypes.string
};
