import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import _ from "lodash";
import update from "immutability-helper";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import BasicAddForm, { useInputField, useCheckboxGroupField, useDateField } from "../../components/Forms/BasicAddForm";
import ConfigurationService from "../../services/ConfigurationService";
import { isRequired, validateDate } from "../../components/Forms/Validation";

import theme from "../../components/Forms/MaterialTheme";

export default class AddObjectForm extends PureComponent {
  constructor(props) {
    super(props);

    // Data schema for Order info
    const basicAddFormControls = this.props.formControl
      ? this.props.formControl
      : {
          name: {
            tabIndex: 1,
            render: useInputField,
            id: "name",
            label: "Order Number",
            value: "",
            valid: true,
            errorMessage: "",
            helperText: "",
            isRequired: true,
            validationRules: [isRequired]
          },
          rfid: {
            tabIndex: 2,
            render: useInputField,
            id: "rfid",
            label: "RFID Tag Number",
            value: "",
            valid: true,
            errorMessage: "",
            helperText: "",
            isRequired: false,
            validationRules: []
          },
          status: {
            tabIndex: 2,
            render: useCheckboxGroupField,
            id: "status",
            label: "Status",
            possibleValues: ["Expedited"],
            value: [],
            valid: true,
            errorMessage: "",
            helperText: "",
            validationRules: []
          },
          date: {
            tabIndex: 2,
            render: useDateField,
            id: "date",
            label: "Due Date",
            value: null,
            valid: true,
            placeholder: "MM/DD/YYYY",
            errorMessage: "",
            validationRules: [validateDate]
          }
        };
    this.state = {
      basicAddFormControls,
      searchList: [],
      MUISearchLists: {}
    };

    props.changeHandler(basicAddFormControls);
  }

  componentDidMount() {
    const { type } = this.props;
    ConfigurationService.getFullConfiguration().then((customerConfiguration) => {
      const { basicAddFormControls } = this.state;
      const { rfidRequirement = "required" } = customerConfiguration.features.tracking;
      const { task = {} } = customerConfiguration.configData.components;
      const { taskMetaInformation = {}, categoryOption = [] } = task;

      if (basicAddFormControls.rfid) {
        if (rfidRequirement === "required") {
          basicAddFormControls.rfid.validationRules.push(isRequired);
        } else if (rfidRequirement === "none") {
          delete basicAddFormControls.rfid;
        } else {
          basicAddFormControls.rfid.isRequired = false;
        }
      }
      if (type === "work-order") {
        Object.keys(taskMetaInformation).length > 0 &&
          Object.keys(taskMetaInformation).forEach((infoField) => {
            const additionalField = {
              tabIndex: 2,
              render: useInputField,
              id: infoField,
              label: taskMetaInformation[infoField].label,
              value: null,
              valid: true,
              errorMessage: "",
              helperText: "",
              isRequired: taskMetaInformation[infoField].isRequired,
              validationRules: taskMetaInformation[infoField].isRequired ? [isRequired] : []
            };
            basicAddFormControls[infoField] = additionalField;
          });
        if (categoryOption.length) {
          basicAddFormControls.category = {
            tabIndex: 2,
            render: (fieldParams, changeHandler) => {
              return (
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <ThemeProvider theme={theme}>
                    <InputLabel htmlFor="work-order-form-category">Category</InputLabel>
                    <Select
                      fullWidth
                      value={fieldParams.value}
                      onChange={(event) => {
                        changeHandler("category", event.target.value);
                      }}
                      label="Category"
                      inputProps={{
                        name: "Category",
                        id: "work-order-form-category"
                      }}
                    >
                      <MenuItem value={null}>{` `}</MenuItem>
                      {categoryOption.map((each) => {
                        return (
                          <MenuItem
                            key={each}
                            value={each}
                          >
                            {each}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              );
            },
            id: "category",
            label: "Category",
            value: "",
            valid: true,
            errorMessage: "",
            helperText: "",
            isRequired: false,
            validationRules: []
          };
        }
      }
      this.setState(basicAddFormControls);
    });
  }

  componentDidUpdate(prevProps) {
    const { basicAddFormControls, searchList } = this.state;
    // the second if (the one below this) resets the basicAddForm. Therefore,
    // the searchList in itemTypeIdentifier keeps reseting to its initilzation (empty array).
    // So, I save the searchList, and add it to itemTypeIdentifier.searchList
    if (
      this.props.formControl &&
      this.props.formControl.itemTypeIdentifier &&
      Array.isArray(this.props.formControl.itemTypeIdentifier.searchList) &&
      searchList !== this.props.formControl.itemTypeIdentifier.searchList
    ) {
      this.setState({ searchList: this.props.formControl.itemTypeIdentifier.searchList });
    }

    const { info } = this.props;
    // Typical usage (don't forget to compare props):
    if (info !== prevProps.orderInfo) {
      if (info !== undefined) {
        this.setState({
          basicAddFormControls: info
        });
      } else {
        this.resetForm(info);
      }
    }

    // add saved searchList to state
    if (basicAddFormControls.itemTypeIdentifier) {
      const newFormControl = update(basicAddFormControls, {
        itemTypeIdentifier: {
          searchList: { $set: searchList }
        }
      });
      this.setState({ basicAddFormControls: newFormControl });
    }
    this.setState({ MUISearchLists: this.props.MUISearchLists });
  }

  // *** Basic Form Handlers *** //
  changeBasicFormHandler = (name, value) => {
    const { changeHandler } = this.props;
    const { basicAddFormControls } = this.state;
    const updateAddFormControls = _.cloneDeep(basicAddFormControls);

    const updatedFormObject = {
      ...updateAddFormControls[name],
      value
    };

    updateAddFormControls[name] = updatedFormObject;
    this.setState({
      basicAddFormControls: updateAddFormControls
    });

    changeHandler(updateAddFormControls);
  };

  resetForm = () => {
    const { changeHandler } = this.props;
    const { basicAddFormControls } = this.state;
    const resetBasicAddFormControls = _.cloneDeep(basicAddFormControls);

    Object.keys(resetBasicAddFormControls).forEach((key) => {
      const resetBasicFormEntry = resetBasicAddFormControls[key];

      switch (key) {
        case "name": {
          resetBasicAddFormControls[key] = {
            ...resetBasicFormEntry,
            value: ""
          };
          break;
        }
        case "number": {
          resetBasicAddFormControls[key] = {
            ...resetBasicFormEntry,
            value: ""
          };
          break;
        }
        case "rfid": {
          resetBasicAddFormControls[key] = {
            ...resetBasicFormEntry,
            value: ""
          };
          break;
        }
        case "status": {
          resetBasicAddFormControls[key] = {
            ...resetBasicFormEntry,
            value: []
          };
          break;
        }
        case "date": {
          resetBasicAddFormControls[key] = {
            ...resetBasicFormEntry,
            value: null
          };
          break;
        }
        default: {
          break;
        }
      }

      resetBasicAddFormControls[key] = {
        ...resetBasicAddFormControls[key],
        valid: true,
        errorMessage: ""
      };
    });

    this.setState({
      basicAddFormControls: resetBasicAddFormControls
    });

    changeHandler(resetBasicAddFormControls);
  };

  render() {
    const { basicAddFormControls, MUISearchLists } = this.state;
    return (
      <BasicAddForm
        formControls={basicAddFormControls}
        changeHandler={this.changeBasicFormHandler}
        MUISearchLists={MUISearchLists}
      />
    );
  }
}

AddObjectForm.propTypes = {
  info: PropTypes.any.isRequired,
  changeHandler: PropTypes.func.isRequired
};
