import React from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import Style from "./CreateWorkOrderButton.module.css";

const BUTTON_TITLE = "+ Add Order";
export const CreateWorkOrderButton = ({ onClick, useAddOrderV2 }) => {
  const history = useHistory();
  if (useAddOrderV2) {
    return (
      <button
        type="button"
        className={`${Style.create_button}`}
        onClick={onClick}
      >
        {BUTTON_TITLE}
      </button>
    );
  }
  return (
    <Link
      className={`${Style.create_button}`}
      to={`${history.location.pathname}/create`}
    >
      {BUTTON_TITLE}
    </Link>
  );
};

CreateWorkOrderButton.defaultProps = {
  onClick: () => {},
  useAddOrderV2: false
};

CreateWorkOrderButton.propTypes = {
  onClick: PropTypes.func,
  useAddOrderV2: PropTypes.bool
};
