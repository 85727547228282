import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import Style from "./ImageWithEnlargedModalView.module.css";

export const ImageWithEnlargedModalView = ({ imageUri, classOverrides }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const renderModalWithEnlargedImage = () => {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        dialogClassName={Style.react_bootstrap_modal_style_override}
        size="lg"
      >
        <div className={Style.header_container}>
          <CloseIcon
            data-testid="modal-close-icon"
            onClick={handleClose}
            className={Style.close_icon}
          />
        </div>
        <img
          src={imageUri}
          className={`${Style.enlarged_image} 
             ${classOverrides?.enlargedImageStyle || ""} 
             `}
          alt="enlarged asset"
        />
      </Modal>
    );
  };

  return (
    <>
      {renderModalWithEnlargedImage()}
      <div
        onClick={handleOpen}
        className={`${classOverrides?.imageContainerStyle ?? Style.image_container}`}
      >
        <img
          src={imageUri}
          className={`${Style.image} 
             ${classOverrides?.imageStyle || ""} 
             `}
          alt="asset"
        />
      </div>
    </>
  );
};

ImageWithEnlargedModalView.defaultProps = {
  classOverrides: {
    imageContainerStyle: "",
    imageStyle: "",
    enlargedImageStyle: ""
  }
};

ImageWithEnlargedModalView.propTypes = {
  imageUri: PropTypes.string.isRequired,
  classOverrides: PropTypes.shape({
    imageContainerStyle: PropTypes.string,
    imageStyle: PropTypes.string,
    enlargedImageStyle: PropTypes.string
  })
};
