import React, { useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from "recharts";
import "./BarGraph.css";

import PropTypes from "prop-types";

const BarGraph = ({
  data,
  xAxisDataKey,
  yAxisDataKey,
  onBarClick,
  xAxisLabel,
  yAxisLabel,
  defaultColor,
  allowDecimalsYAxis
}) => {
  const [selectedBar, setSelectedBar] = useState(null);

  return (
    <div className="bar_graph_container">
      <ResponsiveContainer
        width="100%"
        height="100%"
      >
        <BarChart
          data={data}
          onClick={(state) => {
            if (state) {
              const { activeTooltipIndex, activePayload } = state;
              const [{ payload }] = activePayload;
              if (selectedBar === activeTooltipIndex) {
                setSelectedBar(null);
                onBarClick();
              } else {
                setSelectedBar(activeTooltipIndex);
                onBarClick(payload, activeTooltipIndex);
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            tickMargin={5}
            interval="preserveStartEnd"
            height={60}
            dataKey={xAxisDataKey}
            style={{ fill: "#343434", fontSize: 14 }}
          >
            {xAxisLabel && (
              <Label
                style={{ fontWeight: "bold" }}
                position="insideBottom"
              >
                {xAxisLabel}
              </Label>
            )}
          </XAxis>
          <YAxis
            width={100}
            domain={[
              0,
              (dataMax) => {
                return dataMax + (Math.floor(dataMax / 10) || 1);
              }
            ]}
            allowDecimals={allowDecimalsYAxis}
            style={{ fill: "#343434", fontSize: 14 }}
          >
            {yAxisLabel && (
              <Label
                style={{ textAnchor: "middle", fontWeight: "bold" }}
                position="insideLeft"
                angle={-90}
              >
                {yAxisLabel}
              </Label>
            )}
          </YAxis>
          <Tooltip />
          <Bar
            radius={[2, 2, 0, 0]}
            maxBarSize={150}
            dataKey={yAxisDataKey}
            minPointSize={3}
            label={{ fill: "#343434", fontSize: 14, position: "top" }}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color || defaultColor}
                  opacity={selectedBar === null ? 1 : selectedBar === index ? 1 : 0.3}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarGraph.defaultProps = {
  onBarClick: () => {},
  xAxisLabel: "",
  yAxisLabel: "",
  defaultColor: "#4a90ff",
  allowDecimalsYAxis: true
};

BarGraph.propTypes = {
  data: PropTypes.array.isRequired,
  xAxisDataKey: PropTypes.string.isRequired,
  yAxisDataKey: PropTypes.string.isRequired,
  onBarClick: PropTypes.func,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  defaultColor: PropTypes.string,
  allowDecimalsYAxis: PropTypes.bool
};

export default BarGraph;
