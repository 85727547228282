import React, { useMemo, useEffect, useState } from "react";
import useWorkOrderTrackPageDataSourceContext from "../../contexts/work-order-track-page-data-source-context";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import OrderTrackPageListTable from "../../components/order-track-page-list-table";
import { STATUS_COLOR_MAP, TAB_OPTION_MAP } from "../../data/constants";
import { LOCATION_STATUS_PRIORITY_MAP } from "./data/constants";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import { getValueByType } from "../../components/order-track-page-list-table/OrderTrackPageListTable";
import useDebounce from "../../../../hooks/use-debounce";

export const LocationListTable = () => {
  const {
    selectedTab,
    selectedLocationId,
    getURLByState,
    freeTextSearchInputText,
    isLocationListLoading,
    setExportCsvFn
  } = useWorkOrderTrackPageStateContext();

  const { locationTreeMap, childrenLocationMetricMap } = useWorkOrderTrackPageDataSourceContext();

  const {
    listTable: {
      locations: { headers, pageSize }
    },
    unknownLocationControl,
    useBacklogStatusOnly
  } = useWorkOrderTrackPageConfigContext();

  const [exportCSVStateFn, setExportCSVStateFn] = useState(() => {});
  const [sortIdReplacementMap] = useState({ status: "statusPriority" });
  const freeTextSearchInputTextDebounced = useDebounce(freeTextSearchInputText, 250);

  const filterStringList = useMemo(() => {
    return freeTextSearchInputTextDebounced
      .toLowerCase()
      .split(" ")
      .filter((eachString) => {
        return eachString;
      });
  }, [freeTextSearchInputTextDebounced]);

  const filteredLocationList = useMemo(() => {
    const locations = Object.values(locationTreeMap)
      .map((eachLocation) => {
        const { status, backlogStatus } = childrenLocationMetricMap[eachLocation.id] || {};
        return {
          ...eachLocation,
          ...childrenLocationMetricMap[eachLocation.id],
          status:
            childrenLocationMetricMap[eachLocation.id] &&
            !(unknownLocationControl?.enabled && unknownLocationControl?.properties?.id === eachLocation.id) // do not show status for unknown location
              ? [
                  {
                    title: useBacklogStatusOnly ? backlogStatus : status,
                    severity: useBacklogStatusOnly ? backlogStatus : status,
                    color: STATUS_COLOR_MAP[useBacklogStatusOnly ? backlogStatus : status]
                  }
                ]
              : [],
          statusPriority: LOCATION_STATUS_PRIORITY_MAP[useBacklogStatusOnly ? backlogStatus : status]
        };
      })
      .filter((eachLocation) => {
        let shouldInclude = true;
        if (filterStringList.length) {
          const displayString = headers.reduce((accumulator, eachHeader) => {
            return (
              accumulator + getValueByType(eachLocation[eachHeader.id], eachHeader.type, eachHeader.timeFormat, true)
            );
          }, "");

          filterStringList.forEach((eachFilterString) => {
            if (!displayString.toString().toLowerCase().includes(eachFilterString.toLowerCase())) {
              shouldInclude = false;
            }
          });
        }
        return eachLocation.directParentId === selectedLocationId && shouldInclude;
      });
    return locations;
  }, [selectedLocationId, locationTreeMap, filterStringList, childrenLocationMetricMap, useBacklogStatusOnly, headers]);

  useEffect(() => {
    if (selectedTab === TAB_OPTION_MAP.locationTab) {
      setExportCsvFn(() => {
        return exportCSVStateFn;
      });
    }
  }, [exportCSVStateFn, selectedTab]);

  const csvFileName = useMemo(() => {
    return `${locationTreeMap[selectedLocationId]?.name || "All"}-location-metrics`;
  }, [selectedLocationId, locationTreeMap]);

  return (
    <OrderTrackPageListTable
      numItemsPerPage={pageSize}
      isLoading={isLocationListLoading}
      headers={headers}
      dataList={filteredLocationList}
      getTitleURLCallbackFn={(eachItem) => {
        const param = { selectedLocationId: eachItem.id };
        if (eachItem?.childLocations.length <= 1) {
          param.selectedTab = TAB_OPTION_MAP.orderTab;
        }
        return getURLByState(param);
      }}
      sortIdReplacementMap={sortIdReplacementMap}
      getExportCsvFn={(newFn) => {
        setExportCSVStateFn(() => {
          return newFn;
        });
      }}
      csvFileName={csvFileName}
    />
  );
};
