import React from "react";
import PropTypes from "prop-types";
import { TransferOrderActionsConfigContextProvider } from "./contexts/transfer-order-actions-config-context";
import { ACTION_OPTIONS_MAP } from "../../../../data/constants";
import OrderCreation from "./features/order-creation";
import PicklistVerification from "./features/picklist-verification";

export const TransferOrderActions = ({ actionType }) => {
  return (
    <TransferOrderActionsConfigContextProvider>
      {actionType === ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION ? <PicklistVerification /> : <OrderCreation />}
    </TransferOrderActionsConfigContextProvider>
  );
};

TransferOrderActions.propTypes = {
  actionType: PropTypes.string.isRequired
};
