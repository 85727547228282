import React from "react";
import { Route } from "react-router-dom";
import { CheckCircleOutline } from "@material-ui/icons";
import InventoryOrderDetailFeature from "../../features/inventory-order-detail-feature";
import InventoryTrackPageFeatureV3 from "../../features/inventory-track-page-feature-v3";
import InventoryTrackPageFeature from "../../features/inventory-track-page-feature/InventoryTrackPageFeature";
import AddInventoryFeature from "../../features/inventory-track-page-feature/AddInventoryFeature";
import ItemTypeDetailFeature from "../../features/inventory-track-page-feature/ItemTypeDetailFeature";
import ItemDetailPage from "../../components/TrackPageComponents/Inventory/ItemDetailPage";
import BulkUpdateItemsFeature from "../../features/inventory-track-page-feature/BulkUpdateItemsFeature";
import { useConfigurationProvider } from "../../services/soft-cache-service";
import { useRoleAuthorization, DEFAULT_ROLES } from "../../apps/hooks/useRoleAuthorization";
import ReportsPageFeatureV2 from "../../features/reports-page-feature";
import ReportDetailFeature from "../../features/report-detail-feature";

export const InventoryStackNavigation = () => {
  const { config } = useConfigurationProvider();
  const { disableAddButton } = config.webClient.appConfigurationMap.inventory.trackPage;
  const addButton = config.webClient.appConfigurationMap.inventory.trackPage.addButton || {
    id: "add-button",
    roles: disableAddButton ? [] : DEFAULT_ROLES
  };

  const useV3TrackPage = config.webClient.appConfigurationMap.inventory.trackPage.useV3 || false;
  const { sidebarFeatureButtons } = config.webClient.appConfigurationMap.inventory.trackPage;
  const { solutionOptions } = config.webClient;

  const { isAuthorized } = useRoleAuthorization({
    config: [addButton, ...sidebarFeatureButtons, ...solutionOptions]
  });

  return (
    <>
      {isAuthorized("inventory") && (
        <Route
          exact
          path="/inventory"
          component={useV3TrackPage ? InventoryTrackPageFeatureV3 : InventoryTrackPageFeature}
        />
      )}
      {isAuthorized("add-button") && (
        <Route
          path="/inventory/create"
          component={AddInventoryFeature}
        />
      )}
      {isAuthorized("bulk-update-items") && (
        <Route
          path="/inventory/bulk-update"
          component={BulkUpdateItemsFeature}
        />
      )}
      {isAuthorized("item-type-management") && (
        <Route
          path="/inventory/itemType/detail"
          component={ItemTypeDetailFeature}
        />
      )}
      {isAuthorized("inventory") && (
        <Route
          path="/inventory/transfer-order-detail/detail"
          component={InventoryOrderDetailFeature}
        />
      )}
      {isAuthorized("inventory") && (
        <Route
          path="/inventory/item/detail"
          component={ItemDetailPage}
        />
      )}
      {isAuthorized("inventory") && (
        <Route
          exact
          path="/inventory/approvals"
          render={() => {
            return (
              <ReportsPageFeatureV2
                screenFrameTitle="Approval Management"
                screenFrameTitleIcon={<CheckCircleOutline style={{ color: "#4A90FF" }} />}
                singleReportMode
                reportClassId="Removed Report"
              />
            );
          }}
        />
      )}
      {isAuthorized("inventory") && (
        <Route
          exact
          path="/inventory/approvals/detail"
          render={() => {
            return (
              <ReportDetailFeature
                screenFrameTitle="Approval Management"
                screenFrameTitleIcon={<CheckCircleOutline style={{ color: "#4A90FF" }} />}
              />
            );
          }}
        />
      )}
    </>
  );
};
