import { useMemo } from "react";
import { useAppConfigProvider } from "../../../../../../services/soft-cache-service";
import { toFixedNumber } from "../../../../../../utils";
import { getFormattedDate } from "../../../../../../common/Utilities";
import { TableHeaderMap } from "../../data/constant";

const APP_ID = "reports";

const usePartVelocityMetric = (reportRecords = [], reportDetail) => {
  const configProvider = useAppConfigProvider(APP_ID);

  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  const reportTitle = useMemo(() => {
    const { param, class: reportClass } = reportDetail;
    const { locationName, operationName, itemTypeIdentifier } = param || {};

    let title = "";

    Object.values(reportGroups).forEach((eachGroup) => {
      eachGroup.forEach((eachReport) => {
        if (eachReport.id === reportClass) {
          title += eachReport.label;
        }
      });
    });
    title += ` - ${locationName || "All"}`;
    title += ` - ${operationName || "All"}`;
    if (itemTypeIdentifier) {
      title += ` - Part # ${itemTypeIdentifier}`;
    }
    return title;
  }, [reportDetail]);

  const reportSubTitle = useMemo(() => {
    const { param } = reportDetail;
    const { numberOfRuns, startDateTime, endDateTime } = param || {};

    if (startDateTime && endDateTime) {
      return `Start Date & Time Range: ${getFormattedDate(startDateTime, "MMMM D, YYYY hh:mm A")} - ${getFormattedDate(
        endDateTime,
        "MMMM D, YYYY hh:mm A"
      )}`;
    }
    return `number of runs: ${isNaN(numberOfRuns) ? "N/A" : numberOfRuns}`;
  }, [reportDetail]);

  const workOrderWithPartInfoMap = useMemo(() => {
    return reportRecords.reduce((accumulator, eachRecord) => {
      const {
        [TableHeaderMap.workOrder]: WorkOrder,
        [TableHeaderMap.partNumber]: PartNumber,
        [TableHeaderMap.quantity]: Quantity,
        [TableHeaderMap.totalTouchTime]: TotalTouchTime,
        [TableHeaderMap.unitTouchTime]: TouchTimePerPart
      } = eachRecord;
      accumulator[WorkOrder] = {
        WorkOrder,
        PartNumber,
        Quantity: +Quantity,
        TotalTouchTime:
          accumulator[WorkOrder] && accumulator[WorkOrder].TotalTouchTime > TotalTouchTime
            ? accumulator[WorkOrder].TotalTouchTime
            : +TotalTouchTime,
        TouchTimePerPart:
          accumulator[WorkOrder] && accumulator[WorkOrder]?.TouchTimePerPart > TouchTimePerPart
            ? accumulator[WorkOrder].TouchTimePerPart
            : +TouchTimePerPart
      };
      return accumulator;
    }, {});
  }, [reportRecords]);

  const totalNumOfWorkOrders = useMemo(() => {
    return Object.keys(workOrderWithPartInfoMap).length;
  }, [workOrderWithPartInfoMap]);

  const averageUnitTouchTime = useMemo(() => {
    return (
      toFixedNumber(
        Object.values(workOrderWithPartInfoMap).reduce((sum, eachWorkOrder) => {
          const { TouchTimePerPart } = eachWorkOrder;
          sum += TouchTimePerPart;
          return sum;
        }, 0) / Object.keys(workOrderWithPartInfoMap).length,
        2
      ) || 0
    );
  }, [workOrderWithPartInfoMap]);

  const totalNumOfPartNumbers = useMemo(() => {
    return Object.values(workOrderWithPartInfoMap).reduce((sum, eachWorkOrder) => {
      const { Quantity } = eachWorkOrder;
      return sum + Quantity;
    }, 0);
  }, [workOrderWithPartInfoMap]);

  const barGraphData = useMemo(() => {
    const unitTouchTimeWithWOMap = Object.values(workOrderWithPartInfoMap).reduce((accumulator, eachWorkOrder) => {
      const { TouchTimePerPart, WorkOrder } = eachWorkOrder;
      accumulator[TouchTimePerPart] = [...(accumulator[TouchTimePerPart] || []), WorkOrder];
      return accumulator;
    }, {});

    const touchTimeList = Object.keys(unitTouchTimeWithWOMap)
      .map((eachTouchTime) => {
        return +eachTouchTime;
      })
      .sort((a, b) => {
        return a - b;
      });

    let finalBarGraphData = [];
    if (touchTimeList.length > 10) {
      for (let i = 0; i < 10; i++) {
        finalBarGraphData.push({
          unitTouchTime: null,
          workOrderList: [],
          "# of Work Orders": 0
        });
      }

      const batchSize = Math.ceil(touchTimeList.length / 10);
      let batchNumber = 0;
      let currentBatchCount = 0;
      for (let i = 0; i < touchTimeList.length; i++) {
        const eachTouchTime = touchTimeList[i];
        finalBarGraphData[batchNumber].unitTouchTime = eachTouchTime;
        finalBarGraphData[batchNumber].workOrderList = [
          ...finalBarGraphData[batchNumber].workOrderList,
          ...unitTouchTimeWithWOMap[eachTouchTime]
        ];
        finalBarGraphData[batchNumber]["# of Work Orders"] += 1;
        currentBatchCount += 1;
        if (currentBatchCount >= batchSize) {
          batchNumber += 1;
          currentBatchCount = 0;
        }
      }
      finalBarGraphData = finalBarGraphData.filter((eachData) => {
        return eachData.workOrderList.length && eachData["# of Work Orders"];
      });
    } else {
      finalBarGraphData = touchTimeList.map((eachTouchTime) => {
        return {
          unitTouchTime: eachTouchTime,
          workOrderList: unitTouchTimeWithWOMap[eachTouchTime],
          "# of Work Orders": unitTouchTimeWithWOMap[eachTouchTime].length
        };
      });
    }

    return finalBarGraphData;
  }, [workOrderWithPartInfoMap]);

  return {
    reportTitle,
    reportSubTitle,
    totalNumOfWorkOrders,
    averageUnitTouchTime,
    totalNumOfPartNumbers,
    barGraphData
  };
};

export default usePartVelocityMetric;
