import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../../components/modal";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import AutoSizeTextArea from "../../../../components/AutoSizeTextArea/AutoSizeTextArea";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component";

export const UpdateCommentModal = ({ title, issue, onClose, onConfirm }) => {
  const issueClient = useXemelgoClient().getIssueClient();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const updateComment = async () => {
    setIsLoading(true);
    try {
      await issueClient.appendToIssueComment(issue.id, comment);
      setIsLoading(false);
      onConfirm(STATUS_OPTIONS.SUCCESS, "Successfully updated issue comment");
    } catch (error) {
      onConfirm(STATUS_OPTIONS.ERROR, "Failed to update issue comment");
    }
  };

  return (
    <Modal
      title={title}
      onCancel={onClose}
      onConfirm={updateComment}
      confirmDisabled={!comment || isLoading}
    >
      <AutoSizeTextArea
        autoFocus
        maxRows={4}
        minRows={4}
        newLineAllowed
        disabled={isLoading}
        value={comment}
        data-cy="update-comment-input"
        onChangeText={(newText) => {
          setComment(newText);
        }}
      />
    </Modal>
  );
};

UpdateCommentModal.defaultProps = {
  title: "Add a Comment"
};

UpdateCommentModal.propTypes = {
  title: PropTypes.string,
  issue: PropTypes.shape({
    id: PropTypes.string.isRequired,
    issueTypesString: PropTypes.string,
    locationsString: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};
