import React from "react";
import { Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { CheckCircleOutlined, WarningOutlined } from "@material-ui/icons";
import Style from "./DisplayBanner.module.css";

const DisplayBanner = ({
  bannerError,
  onCloseBanner,
  bannerMessage,
  bannerTitle,
  rightComponent,
  showCloseButton = false
}) => {
  return (
    <Alert
      variant={bannerError ? "danger" : "success"}
      onClose={onCloseBanner}
      dismissible={showCloseButton}
      data-cy-display-banner
    >
      <div className={`${Style.flex} ${Style.main_container}`}>
        <div className={Style.flex}>
          <div className={Style.icon_container}>
            {bannerError ? (
              <WarningOutlined fontSize={bannerTitle ? "large" : "medium"} />
            ) : (
              <CheckCircleOutlined fontSize={bannerTitle ? "large" : "medium"} />
            )}
          </div>
          <div className={Style.flex_column}>
            {bannerTitle && (
              <h4
                className={Style.message}
                data-cy-display-banner__title
              >
                {bannerTitle}
              </h4>
            )}
            <p className={Style.message}>{bannerMessage}</p>
          </div>
          <button
            className={`${Style.closeButton}`}
            onClick={onCloseBanner}
          >
            &times;
          </button>
        </div>
        {rightComponent}
      </div>
    </Alert>
  );
};

export default DisplayBanner;

DisplayBanner.propTypes = {
  bannerError: PropTypes.bool.isRequired,
  onCloseBanner: PropTypes.func.isRequired,
  bannerMessage: PropTypes.string.isRequired,
  bannerTitle: PropTypes.string,
  rightComponent: PropTypes.object,
  showCloseButton: PropTypes.bool
};
