import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

const ManagementDashboardDataSourceContext = React.createContext();

const initialState = {
  locationTreeMap: {},
  filteredLocationTreeMap: {},
  searchResults: [],
  searchWorkOrderResults: [],
  workOrdersFilteredByLocationAndMetrics: [],
  lastUpdatedTime: null,
  allWorkOrders: [],
  isAllWorkOrdersLoading: true
};

export const useManagementDashboardDataSourceContext = () => {
  return useContext(ManagementDashboardDataSourceContext);
};

export const ManagementDashboardDataSourceContextProvider = ({ children }) => {
  const [locationTreeMap, setLocationTreeMap] = useState(initialState.locationTreeMap);
  const [filteredLocationTreeMap, setFilteredLocationTreeMap] = useState(initialState.filteredLocationTreeMap);

  const [searchResults, setSearchResults] = useState(initialState.searchResults);
  const [searchWorkOrderResults, setSearchWorkOrderResults] = useState(initialState.searchWorkOrderResults);
  const [workOrdersFilteredByLocationAndMetrics, setWorkOrdersFilteredByLocationAndMetrics] = useState(
    initialState.workOrdersFilteredByLocationAndMetrics
  );
  const [lastUpdatedTime, setLastUpdatedTime] = useState(initialState.lastUpdatedTime);
  const [allWorkOrders, setAllWorkOrders] = useState(initialState.allWorkOrders);
  const [isAllWorkOrdersLoading, setIsAllWorkOrdersLoading] = useState(initialState.isAllWorkOrdersLoading);
  return (
    <ManagementDashboardDataSourceContext.Provider
      value={{
        locationTreeMap,
        setLocationTreeMap,
        filteredLocationTreeMap,
        setFilteredLocationTreeMap,
        searchResults,
        setSearchResults,
        searchWorkOrderResults,
        setSearchWorkOrderResults,
        workOrdersFilteredByLocationAndMetrics,
        setWorkOrdersFilteredByLocationAndMetrics,
        lastUpdatedTime,
        setLastUpdatedTime,
        allWorkOrders,
        setAllWorkOrders,
        isAllWorkOrdersLoading,
        setIsAllWorkOrdersLoading
      }}
    >
      {children}
    </ManagementDashboardDataSourceContext.Provider>
  );
};

ManagementDashboardDataSourceContextProvider.defaultProps = {
  children: null
};

ManagementDashboardDataSourceContextProvider.propTypes = {
  children: PropTypes.element
};
