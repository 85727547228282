import React, { useMemo, useState } from "react";
import { useAddInventoryFeatureV2StateContext } from "../../contexts/add-inventory-feature-v2-state-context";
import UploadCsvComponent from "../../../../../upload-csv-feature";
import Style from "./BulkCreateTabFeature.module.css";
import ErrorResolveModal from "../../../../../upload-csv-feature/features/error-resolve-modal";
import { SOLUTIONS } from "../../../../../../data/constants";

export const BulkCreateTabFeature = () => {
  const [showErrorResolveModal, setShowErrorResolveModal] = useState(false);

  const { setFormDataMap, formDataMap, formFields, fetchedFormSections, submitStatus } =
    useAddInventoryFeatureV2StateContext();

  const csvHeadersMap = useMemo(() => {
    const newCsvHeadersMap = Object.values(formFields).reduce((tempCsvHeadersMap, field) => {
      const clonedCsvHeadersMap = { ...tempCsvHeadersMap };
      const { id, isDisabled, isHidden, isReadOnly } = field;
      if (id && !isDisabled && !isHidden && !isReadOnly) {
        clonedCsvHeadersMap[id] = { ...field };
      }
      return clonedCsvHeadersMap;
    }, {});

    return newCsvHeadersMap;
  }, [formFields]);

  return (
    <div className={Style.bulk_create_container}>
      <div className={Style.upload_container}>
        <UploadCsvComponent
          key={String(submitStatus)}
          csvHeadersMap={csvHeadersMap}
          csvDataMap={formDataMap}
          onCsvDataChange={setFormDataMap}
          solution={SOLUTIONS.INVENTORY}
          errorResolveModal={() => {
            return (
              <ErrorResolveModal
                show={showErrorResolveModal}
                onClose={() => {
                  setShowErrorResolveModal(false);
                }}
                formFields={formFields}
                formSections={fetchedFormSections}
                formDataMap={formDataMap}
                setFormDataMap={setFormDataMap}
              />
            );
          }}
          onShowErrorResolveModal={setShowErrorResolveModal}
        />
      </div>
    </div>
  );
};
