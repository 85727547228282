import React, { useState, useEffect, useMemo } from "react";
import { AddCircleOutlineTwoTone, WarningOutlined } from "@material-ui/icons";
import uuid from "uuid";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import Style from "./BomManagementFeatureStyle.module.css";
import { useBOMManagementContext } from "../../context/BOMManagementContext/BOMManagementContext";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import { genericSort } from "../../common/Utilities";

const BOMCreateOrderInputPartTable = React.forwardRef(({ onSaveClick, onChange, loading }, ref) => {
  const { inputPartGroupInfo, itemTypeList } = useBOMManagementContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputPartTableInfo, setInputPartTableInfo] = useState({});
  const itemTypeOptions = useMemo(() => {
    return genericSort(itemTypeList, "identifier").map((eachItemType) => {
      return {
        id: eachItemType.id,
        value: {
          itemTypeId: eachItemType.id,
          ...eachItemType.data,
          itemTypeIdentifier: eachItemType.identifier,
          itemTypeDescription: eachItemType.description
        },
        label: eachItemType.identifier
      };
    });
  }, [itemTypeList]);

  const Tooltip = ({ children, text, ...rest }) => {
    const [show, setShow] = useState(false);

    return (
      <div className={`${Style.popover_container}`}>
        <div
          className={`${Style.warning_icon_container}`}
          onMouseOver={() => {
            return setShow(true);
          }}
          onMouseOut={() => {
            return setShow(false);
          }}
          {...rest}
        >
          {children}
        </div>

        <div className={show ? `${Style.tooltip_box_visible}` : `${Style.tooltip_box}`}>
          <div className={`${Style.arrow}`} />
          <div className={`${Style.tooltip_text_box}`}>{text}</div>
        </div>
      </div>
    );
  };

  const warningPopper = () => {
    return (
      <Tooltip text="You do not have the required quantity available in your inventory">
        <WarningOutlined className={`${Style.table_warning_icon}`} />
      </Tooltip>
    );
  };

  const resetInputPartTableInfo = () => {
    const newInputPartTableInfo = inputPartGroupInfo.reduce((accumulator, eachInputGroup) => {
      const { id, itemTypes } = eachInputGroup;
      accumulator[id] = itemTypes
        .sort((a, b) => {
          return a.rank - b.rank;
        })
        .reduce((itemTypeAccumulator, eachItemType, index) => {
          const { id } = eachItemType;
          itemTypeAccumulator[id] = {
            ...eachItemType,
            rank: (index + 1).toString()
          };
          return itemTypeAccumulator;
        }, {});
      accumulator[id].rank = "1"; // value for user selected rank of the input group
      return accumulator;
    }, {});
    setInputPartTableInfo(newInputPartTableInfo);
    onChange && onChange(_.cloneDeep(newInputPartTableInfo));
  };

  useEffect(() => {
    resetInputPartTableInfo();
  }, [inputPartGroupInfo]);

  const handleSaveClick = () => {
    onSaveClick && onSaveClick(inputPartTableInfo);
    setIsEditMode(false);
    resetInputPartTableInfo();
    return inputPartTableInfo;
  };

  const addItem = () => {
    const newInputPartTableInfo = { ...inputPartTableInfo };
    const newID = uuid.v4();
    newInputPartTableInfo[newID] = {
      [uuid.v4()]: { createNew: true, rank: "1" },
      createNew: true
    };
    setInputPartTableInfo(newInputPartTableInfo);
  };

  const renderTableRows = (data = {}) => {
    if (loading) {
      return (
        <ul className={`${Style.table_fields} ${Style.flex_column}`}>
          {[...Array(2)].map((_, rowIndex) => {
            return (
              <li key={`${rowIndex}`}>
                <ul>
                  {[...Array(5)].map((_, columnIndex) => {
                    return (
                      <li key={`${rowIndex} ${columnIndex}`}>
                        <Skeleton height={28} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul className={`${Style.table_fields} ${Style.flex_column}`}>
        {Object.keys(data).length ? (
          Object.keys(data)
            .filter((eachInputGroupId) => {
              return eachInputGroupId !== "rank";
            })
            .map((eachInputGroupId) => {
              const { rank } = data[eachInputGroupId];
              return Object.keys(data[eachInputGroupId])
                .filter((eachInputPartId) => {
                  return (
                    eachInputPartId !== "createNew" && data[eachInputGroupId][eachInputPartId].rank === rank // only show the item type matches the rank
                  );
                })
                .sort((aKey, bKey) => {
                  return data[eachInputGroupId][aKey].rank * 1 - data[eachInputGroupId][bKey].rank * 1;
                })
                .map((eachInputPartId) => {
                  const { itemTypeIdentifier, itemTypeDescription, itemTypeId, quantity, onHandQuantity, createNew } =
                    data[eachInputGroupId][eachInputPartId];

                  return (
                    <li key={eachInputPartId}>
                      <ul>
                        <li>
                          {createNew && isEditMode ? (
                            <SearchDropdown
                              selectedItem={itemTypeOptions.find((eachOption) => {
                                return eachOption.value.itemTypeId === itemTypeId;
                              })}
                              options={itemTypeOptions}
                              onItemSelected={({ value }) => {
                                const newInputPartTableInfo = {
                                  ...inputPartTableInfo
                                };
                                newInputPartTableInfo[eachInputGroupId][value.itemTypeId] = {
                                  ...newInputPartTableInfo[eachInputGroupId][eachInputPartId],
                                  ...value
                                };
                                if (value.itemTypeId !== eachInputPartId) {
                                  delete newInputPartTableInfo[eachInputGroupId][eachInputPartId];
                                }
                                setInputPartTableInfo(newInputPartTableInfo);
                              }}
                            />
                          ) : (
                            <AutoSizeTextArea
                              value={itemTypeIdentifier}
                              readOnly
                            />
                          )}
                        </li>
                        <li>
                          <AutoSizeTextArea
                            value={itemTypeDescription || "--"}
                            readOnly
                            maxRows={3}
                          />
                        </li>
                        <li>
                          <SearchDropdown
                            withoutOptionFilter
                            selectedItem={{ id: eachInputPartId, label: rank, value: rank }}
                            options={Object.keys(data[eachInputGroupId])
                              .filter((eachInputPartId) => {
                                return eachInputPartId !== "rank";
                              })
                              .map((eachInputPartId, index) => {
                                return {
                                  id: eachInputPartId,
                                  label: (index + 1).toString(),
                                  value: (index + 1).toString()
                                };
                              })}
                            onItemSelected={({ value }) => {
                              const newInputPartTableInfo = { ...inputPartTableInfo };
                              newInputPartTableInfo[eachInputGroupId].rank = value;
                              onChange && onChange(_.cloneDeep(newInputPartTableInfo));
                              setInputPartTableInfo(newInputPartTableInfo);
                            }}
                          />
                        </li>
                        <li>
                          <AutoSizeTextArea
                            numberOnly
                            readOnly={!isEditMode}
                            value={quantity}
                            onChangeText={(newText) => {
                              const newInputPartTableInfo = {
                                ...inputPartTableInfo
                              };
                              newInputPartTableInfo[eachInputGroupId][eachInputPartId].quantity = newText;
                              setInputPartTableInfo(newInputPartTableInfo);
                            }}
                          />
                        </li>
                        <li className={`${Style.flex_row}`}>
                          <div>
                            <p
                              className={`${Style.text_area}`}
                              style={{ color: quantity > onHandQuantity ? "red" : null }}
                            >
                              {onHandQuantity}
                            </p>
                          </div>
                          <div>{quantity > onHandQuantity && warningPopper()}</div>
                        </li>
                      </ul>
                    </li>
                  );
                });
            })
        ) : (
          <div className={`${Style.empty_list_row} ${Style.flex_row}`}>
            <p>No input part information</p>
          </div>
        )}
      </ul>
    );
  };

  ref.current = {
    onSaveClick: handleSaveClick,
    addItem,
    setEditingMode: (newIsEditMode) => {
      setIsEditMode(newIsEditMode);
    }
  };

  return (
    <div className={Style.additional_info_container}>
      <div className={`${Style.title_container} ${Style.flex_row}`}>
        <p className={Style.title_text}>Input Parts</p>
      </div>
      <div className={`${Style.table_container}`}>
        <ul className={`${Style.table_headers} ${Style.flex_row}`}>
          <li>Part #</li>
          <li>Description</li>
          <li>Option</li>
          <li>Qty</li>
          <li>On-hand Qty</li>
        </ul>
        {renderTableRows(inputPartTableInfo)}
        {isEditMode && (
          <button
            className={`${Style.table_button} ${Style.flex_row}`}
            onClick={addItem}
          >
            <AddCircleOutlineTwoTone className={Style.table_button_add_icon} />
            <p>Add another part</p>
          </button>
        )}
      </div>
    </div>
  );
});

export default BOMCreateOrderInputPartTable;
