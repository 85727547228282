import Amplify from "aws-amplify";

export class UserProfile {
  constructor(profile) {
    let profileObj = profile;
    if (typeof profile === "string") {
      profileObj = JSON.parse(profile);
    }

    this.profile = profileObj;
    const context = this;
    Object.keys(profileObj).forEach((key) => {
      context[key] = profileObj[key];
    });
  }

  static parse(authenticatedUserInfo) {
    const { username, attributes, signInUserSession } = authenticatedUserInfo;
    const isSuperAdmin = attributes["custom:admin"] === "true";

    const { idToken, refreshToken, accessToken } = signInUserSession;
    const {
      sub: userId,
      "custom:tenantId": tenantId,
      "custom:partner": partnerId,
      "custom:role": partnerRoleId,
      family_name: lastName,
      given_name: firstName,
      email,
      phone_number: phoneNumber
    } = attributes;

    const role = partnerRoleId || (isSuperAdmin ? "admin" : "user");

    const profile = {
      userId,
      username,
      tenantId,
      lastName,
      firstName,
      email,
      phoneNumber,
      isSuperAdmin,
      userGroups: accessToken.payload["cognito:groups"],
      attributes,
      partnerId,
      role,
      tokens: { idToken: idToken.jwtToken, refreshToken, accessToken: accessToken.jwtToken }
    };

    return new UserProfile(profile);
  }

  async refreshIdToken() {
    const cognitoUser = await Amplify.Auth.currentAuthenticatedUser();
    const currentSession = await Amplify.Auth.currentSession();
    return cognitoUser.refreshSession(currentSession.refreshToken, () => {});
  }

  getIdToken() {
    return this.tokens?.idToken;
  }

  getUsername() {
    return this.username;
  }

  getUserId() {
    return this.userId;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getTenantId() {
    return this.tenantId;
  }

  getEmail() {
    return this.email;
  }

  isUserSuperAdmin() {
    return this.isSuperAdmin;
  }

  getUserGroups() {
    return this.userGroups;
  }

  getAttributes() {
    return this.attributes;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getPartnerId() {
    return this.partnerId;
  }

  getRole() {
    return this.role;
  }

  toJson() {
    return JSON.stringify(this.profile);
  }
}
