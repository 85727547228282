import { TIME_IN_MS } from "../../../common/Utilities";
import xemelgoStyle from "../../../styles/variable";

export const MAP_REFRESH_INTERVAL = TIME_IN_MS.MINUTES * 30;

export const SEARCH_TYPE_MAP = {
  asset_number: { id: "asset_number", label: "Asset #", value: "asset_number" },
  asset_type: { id: "asset_type", label: "Asset Type", value: "asset_type" }
};

export const STATUS_COLOR_MAP = {
  EmptyLocation: xemelgoStyle.theme.APP_BLUE,
  Healthy: xemelgoStyle.theme.STATUS_GREEN,
  Warning: xemelgoStyle.theme.STATUS_YELLOW,
  Critical: xemelgoStyle.theme.STATUS_RED,
  TrackedLocation: xemelgoStyle.theme.STATUS_GREEN,
  expiringSoonCount: xemelgoStyle.theme.STATUS_YELLOW,
  expiredCount: xemelgoStyle.theme.STATUS_RED
};

export const TILE_TITLE_MAP = {
  expiringSoonCount: "Warning - Calibration Due Soon",
  expiredCount: "Critical - Calibration Overdue"
};
