/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

interface OptionalLinkProps {
  active?: boolean;
  route?: string;
  isReplace?: boolean;
  openLinkInNewTab?: boolean;
  children?: React.ReactNode;
}

const OptionalLink = ({
  active = true,
  route = null,
  isReplace = false,
  openLinkInNewTab = false,
  children = null,
  ...elementProps
}: OptionalLinkProps) => {
  if (!active) {
    return <div {...elementProps}>{children}</div>;
  }
  return (
    <Link
      to={route}
      replace={isReplace}
      target={openLinkInNewTab ? "_blank" : ""}
      {...elementProps}
    >
      {children}
    </Link>
  );
};

export default OptionalLink;
