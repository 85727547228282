export const getHierarchyTransferOrderPayloads = (childrenTransferOrders) => {
  const hierarchyTransferOrderPayloadByParentIdLookup = childrenTransferOrders.reduce((acc, trackingNumberResponse) => {
    const { childOf, fromLocationId, toLocationId, response } = trackingNumberResponse;

    const childTransferOrderId = response?.createTransferOrder?.id;

    if (!acc[childOf] && childOf) {
      acc[childOf] = {
        identifier: childOf,
        fromLocationId,
        toLocationId,
        contains: []
      };
    }
    if (childTransferOrderId && childOf) {
      acc[childOf].contains.push(childTransferOrderId);
    }
    return acc;
  }, {});
  return Object.values(hierarchyTransferOrderPayloadByParentIdLookup);
};
