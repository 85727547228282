import React, { useState, useEffect, useMemo } from "react";
import { MoreHoriz, AddCircleOutlineTwoTone } from "@material-ui/icons";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "mdbreact";
import uuid from "uuid";
import Skeleton from "react-loading-skeleton";
import AutoSizeTextArea from "../../components/AutoSizeTextArea/AutoSizeTextArea";
import Style from "./BomManagementFeatureStyle.module.css";
import { useBOMManagementContext } from "../../context/BOMManagementContext/BOMManagementContext";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import { genericSort } from "../../common/Utilities";
import EditButton from "../../components/EditButton/EditButton";

const BOMManageInputPartTable = React.forwardRef(({ onSaveClick, loading, hideEditButton }, ref) => {
  const { inputPartGroupInfo, itemTypeList } = useBOMManagementContext();

  const [isEditMode, setIsEditMode] = useState(false);
  const [inputPartTableInfo, setInputPartTableInfo] = useState({});
  const itemTypeOptions = useMemo(() => {
    return genericSort(itemTypeList, "identifier").map((eachItemType) => {
      return {
        id: eachItemType.id,
        value: {
          itemTypeId: eachItemType.id,
          ...eachItemType.data,
          itemTypeIdentifier: eachItemType.identifier,
          itemTypeDescription: eachItemType.description
        },
        label: eachItemType.identifier
      };
    });
  }, [itemTypeList]);

  const resetInputPartTableInfo = () => {
    const newInputPartTableInfo = inputPartGroupInfo.reduce((accumulator, eachInputGroup) => {
      const { id, itemTypes } = eachInputGroup;
      accumulator[id] = itemTypes
        .sort((a, b) => {
          return a.rank - b.rank;
        })
        .reduce((itemTypeAccumulator, eachItemType, index) => {
          const { id } = eachItemType;
          itemTypeAccumulator[id] = {
            ...eachItemType,
            rank: (index + 1).toString()
          };

          return itemTypeAccumulator;
        }, {});
      return accumulator;
    }, {});
    setInputPartTableInfo(newInputPartTableInfo);
  };

  useEffect(() => {
    resetInputPartTableInfo();
  }, [inputPartGroupInfo]);

  const handleSaveClick = () => {
    onSaveClick(inputPartTableInfo);
    setIsEditMode(false);
    resetInputPartTableInfo();
  };

  const addItem = () => {
    const newInputPartTableInfo = { ...inputPartTableInfo };
    const newID = uuid.v4();
    newInputPartTableInfo[newID] = {
      [uuid.v4()]: { createNew: true, rank: "1" },
      createNew: true
    };
    setInputPartTableInfo(newInputPartTableInfo);
  };

  const renderTableRows = (data = {}) => {
    if (loading) {
      return (
        <ul className={`${Style.table_fields} ${Style.flex_column}`}>
          {[...Array(2)].map((_, rowIndex) => {
            return (
              <li key={`${rowIndex}`}>
                <ul>
                  {[...Array(7)].map((_, columnIndex) => {
                    return (
                      <li
                        key={`${rowIndex} ${columnIndex}`}
                        className={
                          columnIndex === 6
                            ? Style.more_button_column
                            : columnIndex === 0
                            ? Style.row_number_column
                            : ""
                        }
                      >
                        <Skeleton height={28} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <ul className={`${Style.table_fields} ${Style.flex_column}`}>
        {Object.keys(data).map((eachInputGroupId, inputGroupIndex) => {
          return Object.keys(data[eachInputGroupId])
            .filter((eachInputPartId) => {
              return eachInputPartId !== "createNew";
            })
            .sort((aKey, bKey) => {
              return data[eachInputGroupId][aKey].rank * 1 - data[eachInputGroupId][bKey].rank * 1;
            })
            .map((eachInputPartId, index) => {
              const { itemTypeIdentifier, itemTypeDescription, itemTypeId, rank, quantity, unit, createNew } =
                data[eachInputGroupId][eachInputPartId];
              return (
                <li key={eachInputPartId}>
                  <ul>
                    <li className={Style.row_number_column}>{index === 0 && inputGroupIndex + 1}</li>
                    <li className={`${!isEditMode && Style.table_field_link}`}>
                      {createNew && isEditMode ? (
                        <SearchDropdown
                          selectedItem={itemTypeOptions.find((eachOption) => {
                            return eachOption.value.itemTypeId === itemTypeId;
                          })}
                          options={itemTypeOptions}
                          onItemSelected={({ value }) => {
                            const newInputPartTableInfo = {
                              ...inputPartTableInfo
                            };
                            newInputPartTableInfo[eachInputGroupId][value.itemTypeId] = {
                              ...newInputPartTableInfo[eachInputGroupId][eachInputPartId],
                              ...value
                            };
                            if (value.itemTypeId !== eachInputPartId) {
                              delete newInputPartTableInfo[eachInputGroupId][eachInputPartId];
                            }
                            setInputPartTableInfo(newInputPartTableInfo);
                          }}
                        />
                      ) : (
                        <AutoSizeTextArea
                          value={itemTypeIdentifier}
                          readOnly
                        />
                      )}
                    </li>
                    <li>
                      <AutoSizeTextArea
                        value={itemTypeDescription || "--"}
                        readOnly
                        maxRows={3}
                      />
                    </li>
                    <li>
                      <AutoSizeTextArea
                        readOnly={!isEditMode}
                        value={rank}
                        numberOnly
                        onChangeText={(newText) => {
                          const newInputPartTableInfo = { ...inputPartTableInfo };
                          newInputPartTableInfo[eachInputGroupId][eachInputPartId].rank = newText;
                          setInputPartTableInfo(newInputPartTableInfo);
                        }}
                      />
                    </li>
                    <li>
                      <AutoSizeTextArea
                        numberOnly
                        readOnly={!isEditMode}
                        value={quantity}
                        onChangeText={(newText) => {
                          const newInputPartTableInfo = {
                            ...inputPartTableInfo
                          };
                          newInputPartTableInfo[eachInputGroupId][eachInputPartId].quantity = newText;
                          setInputPartTableInfo(newInputPartTableInfo);
                        }}
                      />
                    </li>
                    <li>
                      <AutoSizeTextArea
                        readOnly
                        value={unit || "--"}
                      />
                    </li>
                    <li className={Style.more_button_column}>
                      {isEditMode && (
                        <Dropdown>
                          <DropdownToggle
                            nav
                            className={Style.toggle}
                          >
                            <MoreHoriz className={Style.icon} />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              className={Style.blue_text}
                              onClick={() => {
                                const newInputPartTableInfo = { ...inputPartTableInfo };
                                Object.keys(newInputPartTableInfo[eachInputGroupId]).forEach((eachId) => {
                                  if (newInputPartTableInfo[eachInputGroupId][eachId].rank * 1 > rank * 1) {
                                    newInputPartTableInfo[eachInputGroupId][eachId].rank = (
                                      newInputPartTableInfo[eachInputGroupId][eachId].rank * 1 +
                                      1
                                    ).toString();
                                  }
                                });
                                newInputPartTableInfo[eachInputGroupId][uuid.v4()] = {
                                  createNew: true,
                                  rank: (rank * 1 + 1).toString()
                                };

                                setInputPartTableInfo(newInputPartTableInfo);
                              }}
                            >
                              Insert Option
                            </DropdownItem>
                            <DropdownItem
                              className={Style.red_text}
                              onClick={() => {
                                const newInputPartTableInfo = { ...inputPartTableInfo };
                                Object.keys(newInputPartTableInfo[eachInputGroupId]).forEach((eachId) => {
                                  if (newInputPartTableInfo[eachInputGroupId][eachId].rank * 1 > rank * 1) {
                                    newInputPartTableInfo[eachInputGroupId][eachId].rank = (
                                      newInputPartTableInfo[eachInputGroupId][eachId].rank * 1 -
                                      1
                                    ).toString();
                                  }
                                });
                                delete newInputPartTableInfo[eachInputGroupId][eachInputPartId];
                                if (
                                  !Object.keys(newInputPartTableInfo[eachInputGroupId]).filter((eachKey) => {
                                    return eachKey !== "createNew";
                                  }).length
                                ) {
                                  delete newInputPartTableInfo[eachInputGroupId];
                                }
                                setInputPartTableInfo(newInputPartTableInfo);
                              }}
                            >
                              Delete Part
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </li>
                  </ul>
                </li>
              );
            });
        })}
      </ul>
    );
  };

  ref.current = {
    onSaveClick: handleSaveClick,
    addItem,
    setEditingMode: (newIsEditMode) => {
      setIsEditMode(newIsEditMode);
    }
  };

  return (
    <div className={Style.additional_info_container}>
      <div className={`${Style.title_container} ${Style.flex_row}`}>
        <p className={Style.title_text}>Input Parts</p>
        <div className={`${Style.edit_button_container} ${Style.flex_row}`}>
          <EditButton
            show={!loading && !hideEditButton}
            editMode={isEditMode}
            onEditClick={() => {
              setIsEditMode(true);
            }}
            onDiscardClick={() => {
              resetInputPartTableInfo();
              setIsEditMode(false);
            }}
            onSaveClick={handleSaveClick}
          />
        </div>
      </div>
      <div className={`${Style.table_container}`}>
        <ul className={`${Style.table_headers} ${Style.flex_row}`}>
          <li className={Style.row_number_column}>#</li>
          <li>Part #</li>
          <li>Description</li>
          <li>Option</li>
          <li>Qty</li>
          <li>Unit of Measure</li>
          <li className={Style.more_button_column} />
        </ul>
        {renderTableRows(inputPartTableInfo)}
        {isEditMode && (
          <button
            className={`${Style.table_button} ${Style.flex_row}`}
            onClick={addItem}
          >
            <AddCircleOutlineTwoTone className={Style.table_button_add_icon} />
            <p>Add another part</p>
          </button>
        )}
      </div>
    </div>
  );
});

export default BOMManageInputPartTable;
