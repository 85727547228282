import React, { useMemo } from "react";
import PropTypes from "prop-types";
import OrderTrackPageListTable from "../../../../components/order-track-page-list-table";
import { getValueByType } from "../../../../components/order-track-page-list-table/OrderTrackPageListTable";
import Style from "./LiveQueueWOTable.module.css";
import OptionalLink from "../../../../../../components/optional-link/OptionalLink";

export const LiveQueueWOTable = ({ headers, dataList, getTitleURLCallbackFn, ...restProps }) => {
  const headersList = useMemo(() => {
    return [{ id: "index", label: "Priority" }, ...headers];
  }, [headers]);

  const finalWorkOrderList = useMemo(() => {
    // sort by status priority first then start date (whichever is starting first) then identifier
    return dataList
      .sort((a, b) => {
        return (
          b.statusPriority - a.statusPriority ||
          a.startDate - b.startDate ||
          a.identifier.localeCompare(b.identifier, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        );
      })
      .map((eachData, index) => {
        return { ...eachData, index: index + 1 };
      });
  }, [dataList, headers]);

  const getRowBackgroundColorByStatus = (status) => {
    return `${status[0]?.color || "#FFFFFF"}33`;
  };

  return (
    <OrderTrackPageListTable
      {...restProps}
      headers={headersList}
      dataList={finalWorkOrderList}
      columnSortDisabled
      headerRowContainerClassName={Style.header_with_color_indicator}
      renderHeader={(eachHeader) => {
        return (
          <div
            key={eachHeader.id}
            className={`${Style.flex_row} ${
              eachHeader.id === "index" ? Style.priority_number_column : Style.table_column
            }`}
          >
            <p className={Style.table_header_text}>{eachHeader.label}</p>
          </div>
        );
      }}
      renderItem={(eachItem) => {
        return (
          <div
            key={eachItem.identifier}
            style={{ backgroundColor: getRowBackgroundColorByStatus(eachItem.status) }}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            <div
              className={Style.color_indicator}
              style={{ backgroundColor: eachItem?.status[0]?.color || "white" }}
            />
            {headersList.map((eachHeader, subIndex) => {
              return (
                <OptionalLink
                  key={`${eachItem[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${
                    eachHeader.id === "index" ? `${Style.priority_number_column}` : Style.table_column
                  }`}
                  active={!!eachHeader.isNavLink}
                  route={getTitleURLCallbackFn(eachItem)}
                >
                  <div
                    className={`${Style.table_item_text} ${eachHeader.isNavLink && Style.table_item_text_clickable} ${
                      eachHeader.id === "index" && `${Style.priority_number_text}`
                    }`}
                  >
                    {getValueByType(eachItem[eachHeader.id], eachHeader.type, eachHeader.timeFormat)}
                  </div>
                </OptionalLink>
              );
            })}
          </div>
        );
      }}
    />
  );
};

LiveQueueWOTable.defaultProps = {
  dataList: [],
  headers: [],
  getTitleURLCallbackFn: () => {
    return "";
  }
};

LiveQueueWOTable.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  headers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  getTitleURLCallbackFn: PropTypes.func
};
