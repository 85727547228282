import { useMemo, useCallback } from "react";
import _ from "lodash";
import formatText from "../../../../utils/format-text";

export const useCalculateLocationMetricsHook = ({
  itemTypesByLocation,
  lotsByLocation,
  selectedLocationId,
  locationTreeMap,
  combinedDuplicatesDataSources
}) => {
  const childLocations = useMemo(() => {
    if (!selectedLocationId) {
      return Object.values(locationTreeMap).filter((eachLocation) => {
        return !eachLocation.directParentId;
      });
    }
    return (
      locationTreeMap[selectedLocationId]?.childLocations
        ?.filter((eachChildrenLocationId) => {
          if (locationTreeMap[selectedLocationId].childLocations.length === 1) {
            return true;
          }
          return locationTreeMap[eachChildrenLocationId].directParentId === selectedLocationId;
        })
        .map((eachChildrenLocationId) => {
          return _.cloneDeep(locationTreeMap[eachChildrenLocationId]);
        }) || []
    );
  }, [selectedLocationId, locationTreeMap]);

  const getCombinedLotList = useCallback((lotList) => {
    return Object.values(
      lotList.reduce((accumulator, eachLot) => {
        const { lotNumber: eachLotNumber, itemTypeId: eachItemTypeId } = eachLot;

        const lotIdentifier = `${eachLotNumber}-${eachItemTypeId}`;

        // Accumulate all count attributes directly
        if (!accumulator[lotIdentifier]) {
          accumulator[lotIdentifier] = { ...eachLot };
        } else {
          Object.keys(eachLot).forEach((key) => {
            if (key.toLowerCase().includes("count")) {
              accumulator[lotIdentifier][key] = (accumulator[lotIdentifier][key] || 0) + eachLot[key];
            }
            if (key.toLowerCase().includes("threshold") && accumulator[lotIdentifier][key]) {
              accumulator[lotIdentifier][key] = [
                Array.isArray(accumulator[lotIdentifier][key])
                  ? [...accumulator[lotIdentifier][key], eachLot[key]]
                  : [accumulator[lotIdentifier][key], eachLot[key]]
              ];
            }
            if (["isExpired", "isExpiringSoon"].includes(key)) {
              accumulator[lotIdentifier][key] = accumulator[lotIdentifier][key] || eachLot[key];
            }
            if (["isNormalExpiry"].includes(key)) {
              accumulator[lotIdentifier][key] = accumulator[lotIdentifier][key] && eachLot[key];
            }
          });
        }

        return accumulator;
      }, {})
    );
  }, []);

  const getLotsSummaryCount = useCallback((lotList) => {
    const result = lotList.reduce((accumulator, eachItemType) => {
      accumulator.totalLotCount = (accumulator.totalLotCount || 0) + 1;

      Object.keys(eachItemType).forEach((eachKey) => {
        if (eachKey.toLowerCase().includes("count")) {
          accumulator[`item${formatText(eachKey, ["capitalize"])}ByLot`] =
            (accumulator[`item${formatText(eachKey, ["capitalize"])}ByLot`] || 0) + eachItemType[eachKey];
        }

        if (["isExpired", "isExpiringSoon", "isNormalExpiry"].includes(eachKey)) {
          accumulator[`lot${formatText(eachKey, ["capitalize"])}Count`] =
            (accumulator[`lot${formatText(eachKey, ["capitalize"])}Count`] || 0) + (eachItemType[eachKey] ? 1 : 0);
        }
      });
      return accumulator;
    }, {});
    result.itemNormalExpiryCountByLot =
      (result.itemTotalCountByLot || 0) -
      (result.itemExpiredCountByLot || 0) -
      (result.itemExpiringSoonCountByLot || 0);
    return result;
  }, []);

  const getCombinedItemTypeList = useCallback((itemTypeList) => {
    return Object.values(
      itemTypeList.reduce((accumulator, eachItemType) => {
        const { id: itemTypeId } = eachItemType;

        // Accumulate all count attributes directly
        if (!accumulator[itemTypeId]) {
          accumulator[itemTypeId] = { ...eachItemType };
        } else {
          Object.keys(eachItemType).forEach((key) => {
            if (key.toLowerCase().includes("count")) {
              accumulator[itemTypeId][key] = (accumulator[itemTypeId][key] || 0) + eachItemType[key];
            }
            if (key.toLowerCase().includes("threshold") && accumulator[itemTypeId][key]) {
              accumulator[itemTypeId][key] = [
                Array.isArray(accumulator[itemTypeId][key])
                  ? [...accumulator[itemTypeId][key], eachItemType[key]]
                  : [accumulator[itemTypeId][key], eachItemType[key]]
              ];
            }
            if (["isLowStock", "isOutOfStock"].includes(key)) {
              accumulator[itemTypeId][key] = accumulator[itemTypeId][key] || eachItemType[key];
            }
            if (["isNormalStock"].includes(key)) {
              accumulator[itemTypeId][key] = accumulator[itemTypeId][key] && eachItemType[key];
            }
          });
        }

        return accumulator;
      }, {})
    );
  }, []);

  const getItemTypesSummaryCount = useCallback((itemTypeList) => {
    return itemTypeList.reduce((accumulator, eachItemType) => {
      accumulator.totalItemTypeCount = (accumulator.totalItemTypeCount || 0) + 1;

      Object.keys(eachItemType).forEach((eachKey) => {
        if (eachKey.toLowerCase().includes("count")) {
          accumulator[`item${formatText(eachKey, ["capitalize"])}ByItemType`] =
            (accumulator[`item${formatText(eachKey, ["capitalize"])}ByItemType`] || 0) + eachItemType[eachKey];
        }

        if (["isLowStock", "isOutOfStock", "isNormalStock"].includes(eachKey)) {
          accumulator[`itemType${formatText(eachKey, ["capitalize"])}Count`] =
            (accumulator[`itemType${formatText(eachKey, ["capitalize"])}Count`] || 0) + (eachItemType[eachKey] ? 1 : 0);
        }
      });
      return accumulator;
    }, {});
  }, []);

  const getStatusesByItemTypesAndLotsSummary = useCallback((itemTypesSummary, lotsSummary) => {
    const statusMap = { itemExpirationStatus: "Healthy", lotExpirationStatus: "Healthy", stockStatus: "Healthy" };
    if (!!itemTypesSummary?.itemExpiredCountByItemType || !!lotsSummary?.itemExpiredCountByLot) {
      statusMap.itemExpirationStatus = "Critical";
    } else if (!!itemTypesSummary?.itemExpiringSoonCountByItemType || !!lotsSummary?.itemExpiringSoonCountByLot) {
      statusMap.itemExpirationStatus = "Warning";
    }

    if (lotsSummary?.lotIsExpiredCount) {
      statusMap.lotExpirationStatus = "Critical";
    } else if (lotsSummary?.lotIsExpiringSoonCount) {
      statusMap.lotExpirationStatus = "Warning";
    }

    if (itemTypesSummary?.itemTypeIsOutOfStockCount) {
      statusMap.stockStatus = "Critical";
    } else if (itemTypesSummary?.itemTypeIsLowStockCount) {
      statusMap.stockStatus = "Warning";
    }
    return statusMap;
  }, []);

  const childrenLocationsMetrics = useMemo(() => {
    const itemTypeToLocationIdMap = itemTypesByLocation.reduce((accumulator, eachItemType) => {
      return {
        ...accumulator,
        [eachItemType.locationId]: [...(accumulator[eachItemType.locationId] || []), eachItemType]
      };
    }, {});

    const lotsToLocationIdMap = lotsByLocation.reduce((accumulator, eachLot) => {
      return {
        ...accumulator,
        [eachLot.locationId]: [...(accumulator[eachLot.locationId] || []), eachLot]
      };
    }, {});

    return childLocations.map((eachLocation) => {
      const { itemTypes: itemTypesInEachLocation, lots: lotsInEachLocation } = eachLocation.childLocations.reduce(
        (accumulator, eachLocationId) => {
          return {
            itemTypes: [...accumulator.itemTypes, ...(itemTypeToLocationIdMap[eachLocationId] || [])],
            lots: [...accumulator.lots, ...(lotsToLocationIdMap[eachLocationId] || [])]
          };
        },
        { itemTypes: [], lots: [] }
      );
      const itemTypeList = combinedDuplicatesDataSources?.includes("itemType")
        ? getCombinedItemTypeList(itemTypesInEachLocation)
        : itemTypesInEachLocation;

      const itemTypesSummary = getItemTypesSummaryCount(itemTypeList);

      const lotList = combinedDuplicatesDataSources?.includes("lot")
        ? getCombinedLotList(lotsInEachLocation)
        : lotsInEachLocation;

      const lotsSummary = getLotsSummaryCount(lotList);
      const statusMap = getStatusesByItemTypesAndLotsSummary(itemTypesSummary, lotsSummary);
      return { ...eachLocation, ...itemTypesSummary, ...lotsSummary, ...statusMap };
    });
  }, [itemTypesByLocation, lotsByLocation, childLocations, combinedDuplicatesDataSources]);

  const currentLocationMetrics = useMemo(() => {
    const itemTypeList = combinedDuplicatesDataSources?.includes("itemType")
      ? getCombinedItemTypeList(itemTypesByLocation)
      : itemTypesByLocation;
    const itemTypesSummary = getItemTypesSummaryCount(itemTypeList);

    const lotList = combinedDuplicatesDataSources?.includes("lot")
      ? getCombinedLotList(lotsByLocation)
      : lotsByLocation;
    const lotsSummary = getLotsSummaryCount(lotList);

    const statusMap = getStatusesByItemTypesAndLotsSummary(itemTypesSummary, lotsSummary);

    return {
      ..._.cloneDeep(locationTreeMap[selectedLocationId] || {}),
      ...itemTypesSummary,
      ...lotsSummary,
      ...statusMap
    };
  }, [itemTypesByLocation, lotsByLocation, combinedDuplicatesDataSources, combinedDuplicatesDataSources]);

  const result = useMemo(() => {
    return {
      currentLocationMetrics,
      childrenLocationsMetrics
    };
  }, [childrenLocationsMetrics, currentLocationMetrics]);

  return result;
};
