import React from "react";
import Skeleton from "react-loading-skeleton";
import DepartmentsOrdersGraph from "../../pages/report/DepartmentsOrdersGraph";
import OrderOverviewComponentStyle from "./OrderOverviewComponent.module.css";
import "react-loading-skeleton/dist/skeleton.css";

const HealthColor = {
  Healthy: "#00B200",
  Warning: "#FFCF0F",
  Critical: "#F62227"
};

export const OverviewDisplay = ({ health, numOfOrders, numOfExpedited }) => {
  return (
    <div className={OrderOverviewComponentStyle.right_container}>
      <p className={OrderOverviewComponentStyle.header_style}>Overview</p>
      <div className={OrderOverviewComponentStyle.group}>
        <p className={OrderOverviewComponentStyle.label_group}>
          Plant Health
          <br />
          <b
            className={OrderOverviewComponentStyle.value}
            style={{ color: HealthColor[health] }}
          >
            {health !== undefined ? health : <Skeleton />}
          </b>
        </p>
        <p className={OrderOverviewComponentStyle.label_group}>
          Total No. of Orders
          <br />
          <b className={OrderOverviewComponentStyle.value}>{numOfOrders !== undefined ? numOfOrders : <Skeleton />}</b>
        </p>
        <p className={OrderOverviewComponentStyle.label_group}>
          No. of Expedited Orders
          <br />
          <b className={OrderOverviewComponentStyle.value}>
            {numOfExpedited !== undefined ? numOfExpedited : <Skeleton />}
          </b>
        </p>
      </div>
    </div>
  );
};

export const MetricsDisplay = ({ numOfOnTime, numOfDelayed, averageVelocity, velocityLabel }) => {
  return (
    <div className={OrderOverviewComponentStyle.right_container}>
      <p className={OrderOverviewComponentStyle.header_style}>Metrics</p>
      <div className={OrderOverviewComponentStyle.group}>
        <p className={OrderOverviewComponentStyle.label_group}>
          {velocityLabel || "Velocity Rate (orders/day)"}
          <br />
          <b className={OrderOverviewComponentStyle.value}>
            {averageVelocity !== undefined ? averageVelocity : <Skeleton />}
          </b>
        </p>
        <p className={OrderOverviewComponentStyle.label_group}>
          Orders On-Time
          <br />
          <b className={OrderOverviewComponentStyle.value}>{numOfOnTime !== undefined ? numOfOnTime : <Skeleton />}</b>
        </p>
        <p className={OrderOverviewComponentStyle.label_group}>
          Orders Delayed
          <br />
          <b className={OrderOverviewComponentStyle.value}>
            {numOfDelayed !== undefined ? numOfDelayed : <Skeleton />}
          </b>
        </p>
      </div>
    </div>
  );
};

export const BacklogGraphDisplay = ({ graphDataList }) => {
  return (
    <div className={OrderOverviewComponentStyle.backlog_container}>
      <div className={OrderOverviewComponentStyle.backlog_title}>Department Backlog</div>
      <div className={OrderOverviewComponentStyle.graph_container}>
        {graphDataList === undefined ? (
          <div className={OrderOverviewComponentStyle.graph_skeleton}>
            <Skeleton
              containerClassName={OrderOverviewComponentStyle.graph_skeleton_container}
              className={OrderOverviewComponentStyle.graph_skeleton_bar1}
              inline
            />
            <Skeleton
              containerClassName={OrderOverviewComponentStyle.graph_skeleton_container}
              className={OrderOverviewComponentStyle.graph_skeleton_bar2}
              inline
            />
            <Skeleton
              containerClassName={OrderOverviewComponentStyle.graph_skeleton_container}
              className={OrderOverviewComponentStyle.graph_skeleton_bar3}
              inline
            />
            <Skeleton
              containerClassName={OrderOverviewComponentStyle.graph_skeleton_container}
              className={OrderOverviewComponentStyle.graph_skeleton_bar4}
              inline
            />
          </div>
        ) : (
          <DepartmentsOrdersGraph departmentOrders={graphDataList} />
        )}
      </div>
    </div>
  );
};

export const LogoDisplay = ({ logoImage }) => {
  return (
    <img
      className={OrderOverviewComponentStyle.image}
      src={logoImage}
      alt="failed to load img"
    />
  );
};
