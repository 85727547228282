import { getFieldValue } from "../get-field-value/getFieldValue";

/**
 * Check if all the fields in the form are completed
 * @param {Array} formFields - An array of form fields
 * @returns {boolean}
 */
export const validateFormFields = (formFields) => {
  for (const field of formFields) {
    if (field.isError) {
      return false;
    }

    if (!field.isRequired) {
      continue;
    }

    const fieldValue = getFieldValue(field);
    if (
      fieldValue === null ||
      fieldValue === undefined ||
      fieldValue === "" ||
      (Array.isArray(fieldValue) && fieldValue.length === 0)
    ) {
      return false;
    }
  }

  return true;
};
