import { STATUS_SEVERITY_MAP } from "../../../data/constants";

export const LATE_TO_START_WEIGHT = {
  [STATUS_SEVERITY_MAP.warning]: 5,
  [STATUS_SEVERITY_MAP.critical]: 16
};

export const TIME_EXCEEDED_WEIGHT = {
  [STATUS_SEVERITY_MAP.warning]: 10,
  [STATUS_SEVERITY_MAP.critical]: 20
};

export const WO_TABLE_ID_MAP = {
  defaultWOTable: "defaultWOTable",
  liveQueueWOTable: "liveQueueWOTable",
  prioritizedQueueWOTable: "prioritizedQueueWOTable"
};
