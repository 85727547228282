import React from "react";
import { UpdateTransferOrdersFeature } from "./UpdateTransferOrderFeature";
import UpdateTransferOrderFeatureConfigContextProvider from "./context/update-transfer-order-feature-config-context";
import UpdateTransferOrderFeatureStateContextProvider from "./context/update-transfer-order-feature-state-context";

export default (props) => {
  return (
    <UpdateTransferOrderFeatureConfigContextProvider>
      <UpdateTransferOrderFeatureStateContextProvider>
        <UpdateTransferOrdersFeature {...props} />
      </UpdateTransferOrderFeatureStateContextProvider>
    </UpdateTransferOrderFeatureConfigContextProvider>
  );
};
