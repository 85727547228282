import React from "react";
import PropTypes from "prop-types";
import { CLASS_OVERRIDE_SHAPE } from "../../data/constant";
import Style from "./PasswordRequirementStrip.module.css";

export const PasswordRequirementStrip = ({ label, icon, classOverrides }) => {
  return (
    <div
      className={Style.password_requirement_strip}
      key={label}
    >
      <div className={Style.password_requirement_icon}>{icon}</div>
      <div
        className={`${Style.password_requirement_label} ${
          classOverrides?.passwordRequirementLabelColor || Style.password_requirement_label_color
        }`}
      >
        {label}
      </div>
    </div>
  );
};

PasswordRequirementStrip.defaultProps = {
  classOverrides: null
};

PasswordRequirementStrip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  classOverrides: CLASS_OVERRIDE_SHAPE
};
