import React, { useMemo, useCallback } from "react";
import PartVelocityReportDetail from "../../features/part-velocity-report-detail/PartVelocityReportDetail";
import ApprovalReportDetail from "../../features/approval-report-detail/components/approval-report-detail/ApprovalReportDetail";
import { REPORT_TYPES } from "../../data/constant";

const useReportDetailFactory = (reportDetail, reportHeaders, reportRecords) => {
  const ReportDetailComponent = useCallback(
    (props) => {
      const { class: reportClass } = reportDetail || {};
      switch (reportClass) {
        case REPORT_TYPES.partVelocity:
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <PartVelocityReportDetail {...props} />;
        case REPORT_TYPES.approval:
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <ApprovalReportDetail {...props} />;
        default:
          return null;
      }
    },
    [reportDetail]
  );

  const reportFormInitialValues = useMemo(() => {
    const { class: reportClass } = reportDetail || {};
    switch (reportClass) {
      case REPORT_TYPES.partVelocity:
        const {
          itemTypeId,
          itemTypeIdentifier,
          locationId,
          locationName,
          operationName,
          startDateTime,
          endDateTime,
          numOfRuns
        } = reportDetail.param || {};
        return {
          category: { id: "Labor Tracking", label: "Labor Tracking" },
          type: { id: REPORT_TYPES.partVelocity, label: REPORT_TYPES.partVelocity },
          part: {
            id: itemTypeId,
            label: itemTypeIdentifier
          },
          location: { id: locationId || "all-locations", label: locationName || "All" },
          operation: { id: operationName || "all-operations", label: operationName || "All" },
          startDate: startDateTime,
          endDate: endDateTime,
          numOfRuns: numOfRuns?.toString() || ""
        };
      default:
        return null;
    }
  }, [reportDetail]);

  return {
    ReportDetailComponent: () => {
      return (
        <ReportDetailComponent
          reportDetail={reportDetail}
          headers={reportHeaders}
          records={reportRecords}
        />
      );
    },
    reportFormInitialValues
  };
};

export default useReportDetailFactory;
