import React from "react";
import Style from "../../PackageList.module.css";

export const getStatusRenderComponent = (statusList) => {
  return (
    <div className={`${Style.flex_row} ${Style.status_container}`}>
      {statusList.map((eachStatus) => {
        return (
          <div
            key={eachStatus.title}
            className={Style.status}
            style={{ backgroundColor: eachStatus.color }}
          >
            {eachStatus.title}
          </div>
        );
      })}
    </div>
  );
};
