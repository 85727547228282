import React, { useContext, useState, useMemo } from "react";
import usePackageTrackPageDataSourceContext from "../package-track-page-data-source-context";
import usePackageTrackPageConfigContext from "../package-track-page-config-context";

const PackageTrackPageStateContext = React.createContext();

const initialState = {
  selectedLocationId: undefined,
  freeTextSearchInputText: "",
  applySideFilterFn: () => {},
  exportCsvFn: () => {},
  isLocationExpandedMap: {}
};

export const usePackageTrackPageStateContext = () => {
  return useContext(PackageTrackPageStateContext);
};

export const PackageTrackPageStateContextProvider = ({ children }) => {
  const [selectedLocationId, setSelectedLocationId] = useState(initialState.selectedLocationId);
  const [freeTextSearchInputText, setFreeTextSearchInputText] = useState(initialState.freeTextSearchInputText);
  const [applySideFilterFn, setApplySideFilterFn] = useState(initialState.applySideFilterFn);
  const [exportCsvFn, setExportCsvFn] = useState(initialState.exportCsvFn);
  const [isLocationExpandedMap, setIsLocationExpandedMap] = useState(initialState.isLocationExpandedMap);
  const { locationTreeMap } = usePackageTrackPageDataSourceContext();

  const { rootLocationCategories } = usePackageTrackPageConfigContext();

  const filteredLocationTreeMap = useMemo(() => {
    let newLocationTreeMap = {};
    if (!rootLocationCategories?.length) {
      newLocationTreeMap = { ...locationTreeMap };
    } else {
      newLocationTreeMap = Object.fromEntries(
        Object.entries(locationTreeMap).filter(([, eachLocation]) => {
          return rootLocationCategories.includes(eachLocation.category);
        })
      );

      Object.values(newLocationTreeMap).forEach((eachLocation) => {
        newLocationTreeMap[eachLocation.id] = {
          ...eachLocation,
          directParentId: undefined,
          directParentIdentifier: undefined,
          directParentName: undefined
        };
        eachLocation.childLocations.forEach((eachChildLocationId) => {
          if (eachChildLocationId !== eachLocation.id) {
            newLocationTreeMap[eachChildLocationId] = {
              ...(newLocationTreeMap[eachChildLocationId] || locationTreeMap[eachChildLocationId])
            };
          }
        });
      });
    }

    const finalLocationTreeMap = {};

    Object.values(newLocationTreeMap).forEach((eachLocation) => {
      finalLocationTreeMap[eachLocation.id] = { ...eachLocation };
      eachLocation.childLocations.forEach((eachChildLocationId) => {
        if (newLocationTreeMap[eachChildLocationId].directParentId === eachLocation.id) {
          finalLocationTreeMap[eachChildLocationId] = newLocationTreeMap[eachChildLocationId];
        }
      });
    });

    return finalLocationTreeMap;
  }, [locationTreeMap, rootLocationCategories]);

  return (
    <PackageTrackPageStateContext.Provider
      value={{
        selectedLocationId,
        setSelectedLocationId,
        freeTextSearchInputText,
        setFreeTextSearchInputText,
        applySideFilterFn,
        setApplySideFilterFn,
        exportCsvFn,
        setExportCsvFn,
        isLocationExpandedMap,
        setIsLocationExpandedMap,
        filteredLocationTreeMap
      }}
    >
      {children}
    </PackageTrackPageStateContext.Provider>
  );
};
