import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Papa from "papaparse";
import { useXemelgoClient } from "../../services/xemelgo-service";
import Style from "./ReportDetailFeature.module.css";
import { parseQueryString } from "../../common/Utilities";
import { ReportPageScreenFrame } from "../reports-page-feature";
import RunReportModalFeature from "../run-report-modal-feature";
import useReportDetailFactory from "./hooks/use-report-detail-factory/useReportDetailFactory";
import { useDisplayBannerContext } from "../../context/DisplayBannerContext/DisplayBannerContext";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import useGetReportCsv from "./hooks/use-get-report-csv";

const APP_ID = "reports";

const ReportDetailFeature = ({ screenFrameTitle, screenFrameTitleIcon }) => {
  const [isRunReportModalOpen, setIsRunReportModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const xemelgoClient = useXemelgoClient();
  const [reportId, setReportId] = useState("");
  const [reportDetail, setReportDetail] = useState({});
  const [headers, setHeaders] = useState([]);
  const [records, setRecords] = useState([]);
  const history = useHistory();

  const { reportFormInitialValues, ReportDetailComponent } = useReportDetailFactory(reportDetail, headers, records);
  const { setShowBanner, setBannerHasError, setBannerTitle } = useDisplayBannerContext();
  const { getReportCsv } = useGetReportCsv();
  const configProvider = useAppConfigProvider(APP_ID);
  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  useEffect(() => {
    const { id: newReportId } = parseQueryString(history.location.search);
    setReportId(newReportId);
  }, [history.location.search]);

  const canRunReport = useMemo(() => {
    const matchingReportConfig = Object.values(reportGroups)
      .reduce((reports, reportGroup) => {
        return reports.concat(reportGroup);
      }, [])
      .find((report) => {
        return report.class === reportDetail.class;
      });
    return !matchingReportConfig?.disableRun;
  }, [reportGroups, reportDetail]);

  const processReportCsv = async (url) => {
    await getReportCsv(url, false, (data) => {
      const rawContent = data.Body.toString("utf-8");
      const {
        data: newRecords,
        meta: { fields: newHeaders }
      } = Papa.parse(rawContent, { header: true });

      setHeaders(newHeaders);
      setRecords(newRecords);
    });
  };

  const getReportById = async () => {
    setIsLoading(true);
    const reportClient = xemelgoClient.getReportClient();
    const report = await reportClient.getReportById(reportId);
    setReportDetail(report);
    if (report.result?.reportLink) {
      await processReportCsv(report.result.reportLink);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (reportId) {
      getReportById();
    }
  }, [reportId]);

  return (
    <>
      <ReportPageScreenFrame
        hasBackButton
        isLoading={isLoading}
        title={screenFrameTitle}
        titleIcon={screenFrameTitleIcon}
        sideContent={() => {
          return canRunReport ? (
            <div className={`${Style.flex_row} ${Style.run_report_button_container}`}>
              <button
                type="button"
                className={`${Style.run_report_button}`}
                to="/reports/create"
                onClick={() => {
                  setIsRunReportModalOpen(true);
                }}
              >
                Edit and Re-run
              </button>
            </div>
          ) : null;
        }}
        mainContent={() => {
          return (
            <div className={`${Style.flex_column} ${Style.content}`}>
              <ReportDetailComponent />
            </div>
          );
        }}
      />
      {isRunReportModalOpen && (
        <RunReportModalFeature
          initialValues={reportFormInitialValues}
          onSubmitClick={(success) => {
            if (success) {
              setBannerHasError(false);
              setBannerTitle(`Successfully created the report.`);
              setShowBanner(true);
              history.push("/reports");
            } else {
              setBannerHasError(true);
              setBannerTitle("Can't not create the report. Please try again later");
              setShowBanner(true);
            }
          }}
          onCloseClick={() => {
            setIsRunReportModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ReportDetailFeature;
