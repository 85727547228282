const overrideObject = (source, overridingObj) => {
  if (source === undefined || source === null) {
    return overridingObj;
  }

  const cloned = { ...source };
  // base case: return cloned of source if there is nothing to be overridden
  if (overridingObj === undefined || overridingObj === null) {
    return cloned;
  }

  Object.keys(overridingObj).forEach((propertyName) => {
    if (propertyName.startsWith("__")) {
      return; // skipping property that starts with '_', it is an indication that such property cannot be overridden
    }

    const overridingValue = overridingObj[propertyName];
    if (typeof overridingValue === "object" && !Array.isArray(overridingValue)) {
      cloned[propertyName] = overrideObject(source[propertyName], overridingValue);
    } else {
      cloned[propertyName] = overridingValue;
    }
  });

  return cloned;
};

export class ModelDefinition {
  constructor(modelId, definitionObj, overridingObj) {
    this.modelId = modelId;
    this.def = overrideObject(definitionObj, overridingObj);
  }

  getModelId() {
    return this.modelId;
  }

  getDisplayName() {
    return this.def.displayName;
  }

  getPropertyMap() {
    return { ...this.def.properties };
  }

  getProperties() {
    const propertyMap = this.def.properties;
    return Object.keys(propertyMap).map((propertyId) => {
      const property = propertyMap[propertyId];
      return { id: propertyId, ...property };
    });
  }

  getPropertyValue(propertyName) {
    return this.def.properties[propertyName];
  }

  getValue(fieldName, expectType = "string", defaultValue = null) {
    const value = this.def[fieldName];

    if (expectType === "array") {
      if (!Array.isArray(value)) {
        return defaultValue;
      }
      return value;
    }

    const actualType = typeof value;
    if (expectType === "object") {
      if (Array.isArray(value)) {
        return defaultValue;
      }
    }

    if (expectType !== actualType) {
      return defaultValue;
    }
    return value;
  }

  getDefinitionObject(defaultConfig = {}) {
    const def = { ...defaultConfig, ...this.def };
    return def;
  }

  toJson() {
    return JSON.stringify(this.def);
  }
}
