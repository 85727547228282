import { naturalSort } from "common/Utilities";

const getOptionsByApiKey = async (xemelgoClient, apiKey, fields) => {
  switch (apiKey) {
    case "getItemTypes":
      const itemTypeClient = xemelgoClient.getItemTypeClient();

      const itemTypes = await itemTypeClient.listItemTypes(["id", "identifier", "image_path", ...fields]);
      return naturalSort(
        itemTypes.map((eachItemType) => {
          return {
            ...eachItemType,
            ...eachItemType.data,
            label: eachItemType.identifier || "",
            value: eachItemType.identifier
          };
        }),
        "label"
      );
    case "getLocations":
      const locationClient = xemelgoClient.getLocationClient();
      const locations = await locationClient.getLocationsOfCategory(fields);
      return locations.map((location) => {
        return {
          id: location.id,
          value: location.identifier,
          label: location.name
        };
      });
    default:
      return [];
  }
};

export const getInitialValue = async (type, currentAttribute, xemelgoClient) => {
  let initialValue;
  switch (type) {
    case "date":
    case "datetime":
      initialValue = undefined;
      break;
    case "api-dropdown":
      initialValue = {};
      const { apiKey, apiFields } = currentAttribute;
      currentAttribute.options = await getOptionsByApiKey(xemelgoClient, apiKey, apiFields);
      break;
    case "dropdown":
      initialValue = {};
      break;
    case "checkbox":
      initialValue = false;
      break;
    default:
      initialValue = "";
      break;
  }
  return currentAttribute.defaultValue || initialValue;
};
