import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import Style from "./ChangeDataViewDropdown.module.css";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import { ReactComponent as SwitchIcon } from "../../assets/icons/switch.svg";
import { ReactComponent as GraphsIcon } from "../../assets/icons/data-view-graphs.svg";
import { ReactComponent as MetricsIcon } from "../../assets/icons/data-view-metrics.svg";
import { ReactComponent as NoneIcon } from "../../assets/icons/data-view-none.svg";
import { ReactComponent as GraphsAndMetricsIcon } from "../../assets/icons/data-view-graph&metric.svg";
import { DATA_VIEW_TYPE_MAP, DATA_VIEW_TYPE_TITLE_MAP } from "./data/constants";

export const ChangeDataViewDropdown = ({ selectedDataViewId, options, onOptionClick }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dataViewDropdownContainerRef = useRef(null);

  const availableOptions = useMemo(() => {
    return options.filter((eachOptionId) => {
      return !!DATA_VIEW_TYPE_MAP[eachOptionId];
    });
  }, [options, DATA_VIEW_TYPE_MAP]);

  useOnClickOutside(dataViewDropdownContainerRef, () => {
    setIsDropdownOpen(false);
  });

  const getDataViewIcon = (dataViewKey) => {
    switch (dataViewKey) {
      case DATA_VIEW_TYPE_MAP.graphs:
        return <GraphsIcon />;
      case DATA_VIEW_TYPE_MAP.metrics:
        return <MetricsIcon />;
      case DATA_VIEW_TYPE_MAP.graphsAndMetrics:
        return <GraphsAndMetricsIcon />;
      case DATA_VIEW_TYPE_MAP.none:
        return <NoneIcon />;
      default:
        return null;
    }
  };

  return (
    <div
      ref={dataViewDropdownContainerRef}
      className={Style.data_view_dropdown_container}
    >
      <button
        type="button"
        className={`${Style.change_data_view_button} ${isDropdownOpen && Style.change_data_view_button_active}`}
        onClick={() => {
          setIsDropdownOpen((currentValue) => {
            return !currentValue;
          });
        }}
      >
        <SwitchIcon className={Style.change_data_view_icon} />
        <p className={Style.change_data_view_text}>Change Data View</p>
      </button>
      {isDropdownOpen && (
        <div
          data-testid="data-view-dropdown-background"
          className={Style.data_view_dropdown_background}
        >
          {availableOptions.map((eachDataViewKey) => {
            return (
              <div
                key={eachDataViewKey}
                className={`${Style.data_view_dropdown_item} ${
                  selectedDataViewId === eachDataViewKey && Style.data_view_dropdown_item_active
                }`}
                onClick={() => {
                  onOptionClick(eachDataViewKey);
                  setIsDropdownOpen(false);
                }}
              >
                <div
                  className={`${Style.data_view_dropdown_item_icon_container} ${
                    selectedDataViewId === eachDataViewKey && Style.data_view_dropdown_item_icon_container_active
                  }`}
                >
                  {getDataViewIcon(eachDataViewKey)}
                </div>
                <p
                  className={`${Style.data_view_dropdown_item_text} ${
                    selectedDataViewId === eachDataViewKey && Style.data_view_dropdown_item_text_active
                  }`}
                >
                  {DATA_VIEW_TYPE_TITLE_MAP[eachDataViewKey]}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

ChangeDataViewDropdown.defaultProps = {
  selectedDataViewId: "",
  options: [
    DATA_VIEW_TYPE_MAP.graphs,
    DATA_VIEW_TYPE_MAP.metrics,
    DATA_VIEW_TYPE_MAP.graphsAndMetrics,
    DATA_VIEW_TYPE_MAP.none
  ],
  onOptionClick: () => {}
};

ChangeDataViewDropdown.propTypes = {
  selectedDataViewId: PropTypes.oneOf([...Object.values(DATA_VIEW_TYPE_MAP), null, undefined, ""]),
  options: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DATA_VIEW_TYPE_MAP))),
  onOptionClick: PropTypes.func
};
