import React from "react";
import Switch from "react-switch";

import "./TabWithSwitchControlComponent.css";

export default class TabWithSwitchControlComponent extends React.Component {
  constructor(props) {
    super(props);

    const { tabName, toggleSwitchFn, isOn, handleOnClick, enableRuleSubscription, ...restProps } = { ...props };

    this.state = {
      tabName,
      toggleSwitchFn,
      on: isOn,
      handleOnClick,
      enableRuleSubscription,
      additionalProperties: restProps
    };

    this.toggleSwitch = this.toggleSwitch.bind(this);
  }

  toggleSwitch(checked, event) {
    const context = this;
    this.state.toggleSwitchFn(checked).then(() => {
      context.setState({ on: checked });
    });

    // this.setState({on: checked});
    event.stopPropagation();
  }

  render() {
    let className = "tab-with-control-component";
    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    const { enableRuleSubscription, on, tabName } = this.state;

    const switchId = `${tabName}-switch-control`;

    return (
      <div
        className={className}
        onClick={this.state.handleOnClick}
      >
        <div className="tab-name">{tabName}</div>
        {enableRuleSubscription && (
          <div className="tab-control">
            <Switch
              onChange={this.toggleSwitch}
              checked={on}
              id={switchId}
              disabled={this.props.switchDisabled}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#4d8605"
            />
          </div>
        )}
      </div>
    );
  }
}
