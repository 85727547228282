import React, { memo, useState } from "react";
import { useStore } from "react-hookstore";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./LoginPage.css";
import { useAuthenticationContext } from "../../context/authentication-context/AuthenticationContext";
import { loginStateStore, LoginStateAction } from "../../state-managements/stores/login-store";
import { getStackedXemelgoLogo } from "../../common/Utilities";
import { LoginForm } from "./login-form";
import { NewPasswordForm } from "./new-password-form";
import ControlspanLogo from "../../img/controlspan-white.png";
import poweredByLogo from "../../img/powered-by.png";
import CONFIGURATION from "../../configuration.json";

import { ForgotPasswordForm } from "./forgot-password-form";

import { ForgotPasswordVerificationForm } from "./forgot-password-verification-form";

const Index = memo(() => {
  const [loginState, loginStateDispatcher] = useStore(loginStateStore);

  const { signInWithEmailPassword, completeNewPassword, forgotPassword, forgotPasswordVerification, federatedSignIn } =
    useAuthenticationContext();
  const [forgotPasswordUser, setForgotPasswordUser] = useState(null);

  const handleForgotPassword = () => {
    loginStateDispatcher({ type: LoginStateAction.IS_FORGOT_PASSWORD_STATE });
  };

  const handleSubmit = async (username, password, isFederatedSignIn, customProvider) => {
    if (isFederatedSignIn) {
      await federatedSignIn(customProvider);
    } else {
      const userInfo = await signInWithEmailPassword(username.toLowerCase(), password);

      const { challengeName } = userInfo;
      if (challengeName) {
        setForgotPasswordUser(userInfo);
        loginStateDispatcher({ type: LoginStateAction.IS_NEW_PASSWORD_STATE });
      }
    }
  };

  const handleSubmitNewPassword = async (_, newPassword) => {
    try {
      await completeNewPassword(forgotPasswordUser, newPassword);
    } catch (error) {
      alert(`Oops! Something is wrong: ${error.message}`);
    }
  };

  const handleSubmitForgotPassword = async (username) => {
    await forgotPassword(username);
    loginStateDispatcher({ type: LoginStateAction.IS_FORGOT_PASSWORD_VERIFICATION_STATE });
  };

  const handleSubmitForgotPasswordVerification = async (username, verificationCode, newPassword) => {
    await forgotPasswordVerification(username, verificationCode, newPassword);
    loginStateDispatcher({ type: LoginStateAction.IS_LOGIN_STATE });
  };

  if (loginState.newPassword) {
    return (
      <NewPasswordForm
        onSubmit={handleSubmitNewPassword}
        onCancel={() => {
          return loginStateDispatcher({ type: LoginStateAction.IS_LOGIN_STATE });
        }}
        logo={CONFIGURATION.controlspan ? ControlspanLogo : getStackedXemelgoLogo("light")}
        poweredByLogo={CONFIGURATION.controlspan && poweredByLogo}
      />
    );
  }
  if (loginState.forgotPassword) {
    return (
      <ForgotPasswordForm
        onSubmit={handleSubmitForgotPassword}
        onCancel={() => {
          return loginStateDispatcher({ type: LoginStateAction.IS_LOGIN_STATE });
        }}
        logo={CONFIGURATION.controlspan ? ControlspanLogo : getStackedXemelgoLogo("light")}
        poweredByLogo={CONFIGURATION.controlspan && poweredByLogo}
      />
    );
  }
  if (loginState.forgotPasswordVerification) {
    return (
      <ForgotPasswordVerificationForm
        onSubmit={handleSubmitForgotPasswordVerification}
        logo={CONFIGURATION.controlspan ? ControlspanLogo : getStackedXemelgoLogo("light")}
        poweredByLogo={CONFIGURATION.controlspan && poweredByLogo}
      />
    );
  }

  return (
    <LoginForm
      logo={CONFIGURATION.controlspan ? ControlspanLogo : getStackedXemelgoLogo("light")}
      poweredByLogo={CONFIGURATION.controlspan && poweredByLogo}
      fnSubmit={handleSubmit}
      fnForgotPassword={handleForgotPassword}
    />
  );
});

Index.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withRouter(Index);
