import React, { useContext, useEffect, useState } from "react";
import useOnboardingForm from "../../../../hooks/use-onboarding-form";
import { useUpdateTransferOrderFeatureConfigContext } from "../update-transfer-order-feature-config-context";

const UpdateTransferOrdersFeatureStateContext = React.createContext({});

export const useUpdateTransferOrderFeatureStateContext = () => {
  return useContext(UpdateTransferOrdersFeatureStateContext);
};

export const UpdateTransferOrderFeatureStateContextProvider = ({ children }) => {
  const [formFields, setFormFields] = useState({});
  const [fetchedFormSections, setFetchedFormSections] = useState([]);
  const [formDataMap, setFormDataMap] = useState({});

  const { fetchFormOptions } = useOnboardingForm();
  const { formSections } = useUpdateTransferOrderFeatureConfigContext();
  const processFormInfo = async (newFormSections) => {
    const { fetchedFormSections: newFetchedFormSections, fetchedFormFields } = await fetchFormOptions(
      newFormSections,
      "Inventory"
    );
    const updatedFormSections = newFetchedFormSections.map((section) => {
      const updatedSection = section.map((field) => {
        const updatedField = { ...field };

        return updatedField;
      });
      return updatedSection;
    });

    setFetchedFormSections(updatedFormSections);
    setFormFields(fetchedFormFields);
  };

  useEffect(() => {
    processFormInfo(formSections);
  }, [formSections]);

  return (
    <UpdateTransferOrdersFeatureStateContext.Provider
      value={{
        formDataMap,
        setFormDataMap,
        formFields,
        fetchedFormSections
      }}
    >
      {children}
    </UpdateTransferOrdersFeatureStateContext.Provider>
  );
};
