import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { LOCATION_SORT_OPTIONS } from "../../data/constants";

const AssetTrackPageConfigContext = React.createContext();

const initialState = {
  sideFilter: {
    asset: [],
    assetType: []
  },
  listTableOptions: {
    asset: {
      headers: [
        {
          id: "identifier",
          label: "Asset Number",
          isNavLink: true,
          defaultSort: true,
          defaultDirection: "desc",
          defaultColumn: true
        },
        {
          id: "sensorProfileVid",
          label: "RFID Tag (EPC)",
          defaultColumn: true
        },
        { id: "trackingSessionUpdatedTime", label: "Last Updated", type: "dateTime", defaultColumn: true }
      ],
      pageSize: 10
    },
    assetType: {
      headers: [
        {
          id: "identifier",
          label: "Asset Type",
          defaultSort: true,
          defaultDirection: "desc",
          defaultColumn: true
        },
        { id: "totalCount", label: "Quantity", isMetricsField: true, defaultColumn: true },
        { id: "missingCount", label: "Assets Missing", isMetricsField: true }
      ],
      pageSize: 10
    }
  },
  multiSelectOptions: [],
  initialURLParameters: "?locationId&tab=asset",
  locationMetricsFields: [{ id: "totalCount", label: "Total Assets" }],
  unknownLocationControl: {},
  maxTableColumns: 8,
  locationCategories: [],
  ignoredItemStates: ["removed", "inactive"]
};

const SOLUTION_ID = "asset";
const TRACK_PAGE_FEATURE_ID = "trackPage";

export const useAssetTrackPageConfigContext = () => {
  return useContext(AssetTrackPageConfigContext);
};

export const AssetTrackPageConfigContextProvider = ({ children }) => {
  const assetSolutionConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const trackPageConfig = assetSolutionConfigProvider.getValue(TRACK_PAGE_FEATURE_ID, "object") || {};

  const [listTableOptions, setListTableOptions] = useState(initialState.listTableOptions);
  const [sideFilter, setSideFilter] = useState(initialState.sideFilter);
  const [locationMetricsFields, setLocationMetricsFields] = useState(initialState.locationMetricsFields);
  const [initialURLParameters, setInitialURLParameters] = useState(initialState.initialURLParameters);
  const [multiSelectOptions, setMultiSelectOptions] = useState(initialState.multiSelectOptions);
  const [locationSortOptions, setLocationSortOptions] = useState(LOCATION_SORT_OPTIONS);
  const [unknownLocationControl, setUnknownLocationControl] = useState(initialState.unknownLocationControl);
  const [maxTableColumns, setMaxTableColumns] = useState(initialState.maxTableColumns);
  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [ignoredItemStates, setIgnoredItemStates] = useState(initialState.ignoredItemStates);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const { v2: trackPageConfigV2 = {} } = trackPageConfig;
    const {
      listTableOptions: newTableOptions = initialState.listTableOptions,
      sideFilter: newSideFilter = initialState.sideFilter,
      locationMetricsFields: newLocationMetricsFields = initialState.locationMetricsFields,
      initialURLParameters: newInitialURLParameters = initialState.initialURLParameters,
      multiSelectOptions: newMultiSelectOptions = initialState.multiSelectOptions,
      unknownLocationControl: newUnknownLocationControl = initialState.unknownLocationControl,
      maxTableColumns: newMaxTableColumns = initialState.maxTableColumns,
      locationCategories: newLocationCategories = initialState.locationCategories,
      ignoredItemStates: newIgnoredItemStates = initialState.ignoredItemStates
    } = trackPageConfigV2;

    const newLocationSortOptions = { ...LOCATION_SORT_OPTIONS };
    newLocationMetricsFields.forEach((field) => {
      if (!field.hiddenOnTable) {
        newLocationSortOptions[field.id] = field.label;
      }
    });

    setListTableOptions(newTableOptions);
    setSideFilter(newSideFilter);
    setLocationMetricsFields(newLocationMetricsFields);
    setInitialURLParameters(newInitialURLParameters);
    setMultiSelectOptions(newMultiSelectOptions);
    setUnknownLocationControl(newUnknownLocationControl);
    setLocationSortOptions(newLocationSortOptions);
    setMaxTableColumns(newMaxTableColumns);
    setLocationCategories(newLocationCategories);
    setIgnoredItemStates(newIgnoredItemStates);

    setIsLoading(false);
  }, [assetSolutionConfigProvider]);

  return (
    <AssetTrackPageConfigContext.Provider
      value={{
        listTableOptions,
        sideFilter,
        locationMetricsFields,
        initialURLParameters,
        multiSelectOptions,
        locationSortOptions,
        unknownLocationControl,
        maxTableColumns,
        locationCategories,
        ignoredItemStates,
        isLoading
      }}
    >
      {children}
    </AssetTrackPageConfigContext.Provider>
  );
};
