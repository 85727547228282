import React from "react";
import PropTypes from "prop-types";
import GridCardComponentStyle from "./GridCardComponent.module.css";

const GridCardComponent = ({ containerStyle, color, id, onClick, children }) => {
  return (
    <div
      onClick={onClick}
      id={id}
      className={`${GridCardComponentStyle.default} ${containerStyle}`}
      style={{
        borderColor: color || "lightgray"
      }}
    >
      {children}
    </div>
  );
};

export default GridCardComponent;

GridCardComponent.defaultProps = {
  containerStyle: null,
  color: "lightgray",
  id: null
};

GridCardComponent.propTypes = {
  id: PropTypes.string,
  containerStyle: PropTypes.string,
  color: PropTypes.string
};
