/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "../checkbox-component/Checkbox";
import Style from "./CheckboxLabel.module.css";

export const CheckboxLabel = ({ label, ...checkboxProps }) => {
  return (
    <div className={Style.checkbox_container}>
      <Checkbox {...checkboxProps} />
      <div className={Style.checkbox_label_text}>{label}</div>
    </div>
  );
};

CheckboxLabel.defaultProps = {
  label: "",
  rounded: false,
  isChecked: false,
  onClick: () => {},
  color: ""
};

CheckboxLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  rounded: PropTypes.bool,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string
};
