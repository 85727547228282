/**
 * This function generates a CSV template file with pre-defined headers
 * @param {string[]} headers
 */
export const generateCsvTemplate = (headers) => {
  const csvHeaders = headers.join(",");
  const blob = new Blob([csvHeaders], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = "template.csv";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
