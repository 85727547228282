/**
 * @typedef Location
 * @property { string } id
 * @property { string } name
 */

/**
 * @typedef Detector
 * @property { string } class
 * @property { string } name
 * @property { Location } location
 */

/**
 * @typedef LocationWithDetector
 * @property { string } id
 * @property { string } name
 * @property { string } identifier
 * @property { string } detectorSerial
 * @property { string } detectorVendidentifier
 * @property { string } detectorVendor
 * @property { string } label
 */

/**
 *
 * @param array { object[] }
 * @param idField { Function }
 * @return {Object.<string, object>}
 */
const toMap = (array, getIdField) => {
  return array.reduce((map, element) => {
    const clonedMap = { ...map };

    const identity = getIdField(element);
    clonedMap[identity] = element;
    return clonedMap;
  }, {});
};

/**
 *
 * @param locations { Location[] }
 * @param detectors { Detector[] }
 * @param { [identifier: string]: LocationWithDetector }
 */
export const getLocationWithDetector = (locationTreeMap, userLocation, detectors) => {
  const { id: userLocationId } = userLocation;
  const filteredLocations = Object.keys(locationTreeMap).reduce((newFilteredLocations, eachLocationId) => {
    let matched = false;

    const { childLocations = [] } = locationTreeMap[userLocationId] || {};
    if (childLocations.includes(eachLocationId)) {
      matched = true;
    }
    if (matched) {
      newFilteredLocations.push(locationTreeMap[eachLocationId]);
    }
    return newFilteredLocations;
  }, []);

  const mountedDetectors = detectors.filter((detector) => {
    return detector.class === "Mounted";
  });

  const detectorByLocationIdMap = toMap(mountedDetectors, (detector) => {
    return detector?.location?.id || "unknown";
  });

  // register detectorSerial to location
  const locationWithDetectors = (Object.keys(userLocation).length ? filteredLocations : Object.values(locationTreeMap))
    .map((location) => {
      const { id: locId, identifier, name } = location;
      const foundDetector = detectorByLocationIdMap[locId];
      const clonedLocation = { ...location, value: identifier, label: name };
      clonedLocation.detectorSerial = foundDetector?.vid;
      clonedLocation.detectorVendor = foundDetector?.vendor;
      clonedLocation.detectorId = foundDetector?.id;

      return clonedLocation;
    })
    .filter((location) => {
      return location.detectorSerial;
    });

  const locations = (Object.keys(userLocation).length ? filteredLocations : Object.values(locationTreeMap)).map(
    (location) => {
      const { identifier, name } = location;
      return { ...location, value: identifier, label: name };
    }
  );

  return {
    locationOptionsMap: locations.reduce((locationMap, location) => {
      const { id } = location;
      return { ...locationMap, [id]: location };
    }, {}),
    readerLocationOptionsMap: locationWithDetectors.reduce((locationMap, location) => {
      const { id } = location;
      return { ...locationMap, [id]: location };
    }, {})
  };
};
