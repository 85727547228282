import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import OnboardItemModal from "../../components/onboard-item-modal";
import SingleCreateForm from "../../components/single-create-form";
import useOnboardingForm from "../../hooks/use-onboarding-form";
import { useUpdateTransferOrderFeatureStateContext } from "./context/update-transfer-order-feature-state-context";
import { useUpdateTransferOrderFeatureConfigContext } from "./context/update-transfer-order-feature-config-context";
import useMixpanelContext from "../../context/mixpanel-context";
import usePrintService from "../../hooks/use-print-service";
import StatusPopupComponent from "../../components/status-popup-component";
import PrinterSelectionComponent from "../../components/printer-selection-component";
import Style from "./Styles.module.css";
import { useXemelgoClient } from "../../services/xemelgo-service";
import {
  UPDATE_TRANSFER_ORDER,
  UPDATE_TRANSFER_ORDER_STEPS
} from "../../constants/mixpanel-constant/updateTransferOrderFeature";
import { SOLUTIONS } from "../../data/constants";
import useGeneratePrintTagPayload from "../../hooks/use-generate-print-tag-payload";
import printTags from "./utils/print-tags";
import getCreateItemPayloads from "./utils/get-create-item-payloads";
import createItems from "./utils/create-items";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import { STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";

const FEATURE_ID = "Inventory_TransferOrder";
const POPUP_DURATION_MS = 5000;

export const UpdateTransferOrdersFeature = ({ onClose }) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(STATUS_OPTIONS.NONE);
  const [statusMessage, setStatusMessage] = useState("");
  const [popupExpired, setPopupExpired] = useState(false);
  const [inventoryClient] = useState(useXemelgoClient().getInventoryClient());
  const [itemClient] = useState(useXemelgoClient().getItemClient());
  const [transferClient] = useState(useXemelgoClient().getTransferClient());
  const popupTimeoutRef = useRef(null);

  const { isPrintEnabled, printType, palletTagPrefix, formSections, title } =
    useUpdateTransferOrderFeatureConfigContext();
  const { fetchedFormSections, formFields, formDataMap, setFormDataMap } = useUpdateTransferOrderFeatureStateContext();
  const { onFormSectionsChange, updateFormSectionsWithFormData, validateFormDataForSubmit } = useOnboardingForm();
  const { sendMixPanelEvent } = useMixpanelContext();

  const { generateMultiplePrintCommands } = useGeneratePrintTagPayload(SOLUTIONS.INVENTORY, printType);
  const printService = usePrintService(isPrintEnabled, printType, null, FEATURE_ID, true);
  const { print, selectedTemplateConfig, isPrintReady } = printService;

  /**
   *  Since formDataMap could hold data for multiple form(bulk use case).In this case, access the first element
   *  of formDataMap, we retrieved data of the single form
   * */
  const singleFormData = useMemo(() => {
    const { data = {} } = Object.values(formDataMap)[0] || {};
    return data;
  }, [formDataMap]);

  const processedFormSections = useMemo(() => {
    return updateFormSectionsWithFormData(singleFormData, fetchedFormSections);
  }, [fetchedFormSections, singleFormData]);

  const onFormChange = useCallback(
    (fieldId, value) => {
      const newFormData = onFormSectionsChange(fieldId, value, formSections, formFields, singleFormData);
      setFormDataMap({ id: { data: newFormData } });
    },
    [formSections, singleFormData, formFields]
  );

  const resetFormData = () => {
    if (formFields) {
      const newFormData = {};
      Object.keys(formFields).forEach((fieldName) => {
        newFormData[fieldName] = formFields[fieldName].defaultValue;
      });

      if (Object.keys(newFormData).length) {
        setFormDataMap({ id: { data: newFormData } });
      }
    }
  };

  const handleOnSubmit = async () => {
    setIsSubmitDisabled(true);
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("The tags are being added...");
    setPopupExpired(false);
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }

    try {
      const formData = Object.values(formDataMap).map((eachForm) => {
        return eachForm.data;
      });
      const payloadsByTransferOrders = getCreateItemPayloads(formData, palletTagPrefix);
      // ++++++++++++++++ GENERATE ITEMS ++++++++++++++++
      const addItemEntriesPayloadsByTransferOrders = await createItems(
        payloadsByTransferOrders,
        inventoryClient,
        itemClient
      );
      // ++++++++++++++++ UPDATE TRANSFER ORDER ++++++++++++++++
      const addItemEntriesPromises = Object.values(addItemEntriesPayloadsByTransferOrders).map(
        (payloadByTransferOrder) => {
          const { transferOrderId, itemEntries } = payloadByTransferOrder;
          return transferClient.addItemEntriesToTransferOrder(transferOrderId, Object.values(itemEntries));
        }
      );
      await Promise.all(addItemEntriesPromises);

      // ++++++++++++++++ PRINT ++++++++++++++++
      if (isPrintEnabled) {
        await printTags(
          Object.values(payloadsByTransferOrders),
          generateMultiplePrintCommands,
          selectedTemplateConfig,
          print
        );
        setStatusMessage("The tags have been submitted for printing.");
      }
      setStatusMessage("The tags have been added successfully.");
      sendMixPanelEvent(UPDATE_TRANSFER_ORDER, UPDATE_TRANSFER_ORDER_STEPS.SUBMISSION_SUCCESSFUL);
      setFormDataMap({});
      setSubmitStatus(STATUS_OPTIONS.SUCCESS);
    } catch (err) {
      setStatusMessage("Failed to generate tags. Please contact Xemelgo Support for assistance.");
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      sendMixPanelEvent(UPDATE_TRANSFER_ORDER, UPDATE_TRANSFER_ORDER_STEPS.SUBMISSION_FAILED, {
        error_message: typeof err === "string" ? err : err.message
      });
    }
    popupTimeoutRef.current = setTimeout(() => {
      setPopupExpired(true);
    }, POPUP_DURATION_MS);
  };

  useEffect(() => {
    // hide fc_chat widget
    window.fcWidget.hide();

    return () => {
      // show fc_chat widget
      window.fcWidget.show();
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const formData = Object.values(formDataMap).map((eachForm) => {
      return eachForm.data;
    });
    const isFormReady = validateFormDataForSubmit(formData, formFields);
    setIsSubmitDisabled((isPrintEnabled && !isPrintReady) || !isFormReady);
  }, [formDataMap, formFields, selectedTemplateConfig, isPrintReady]);

  useEffect(() => {
    resetFormData();
  }, [formFields]);

  useEffect(() => {
    if (submitStatus === STATUS_OPTIONS.SUCCESS) {
      resetFormData();
    }
  }, [submitStatus]);

  return (
    <OnboardItemModal
      title={title}
      titleIconComponent={
        <div className={Style.title_icon}>
          <InventoryIcon
            width={25}
            height={25}
          />
        </div>
      }
      onClose={onClose}
      onSubmit={handleOnSubmit}
      submitDisabled={isSubmitDisabled}
      submitLoading={submitStatus === STATUS_OPTIONS.LOADING}
      modalContainerClassName={Style.modal_container}
      popupComponent={
        <StatusPopupComponent
          showPopup={!popupExpired && submitStatus !== STATUS_OPTIONS.NONE && statusMessage}
          message={statusMessage}
          status={submitStatus}
        />
      }
    >
      <div className={submitStatus === STATUS_OPTIONS.LOADING ? Style.modal_disabled : ""}>
        <SingleCreateForm
          title="Order Information"
          formSections={processedFormSections}
          onFormChange={onFormChange}
        />
        {isPrintEnabled && (
          <div className={Style.printer_component}>
            <PrinterSelectionComponent
              printService={printService}
              solutionType={FEATURE_ID}
            />
          </div>
        )}
      </div>
    </OnboardItemModal>
  );
};

UpdateTransferOrdersFeature.defaultProps = {
  onClose: () => {}
};

UpdateTransferOrdersFeature.propTypes = {
  onClose: PropTypes.func
};
