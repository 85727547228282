import { camelCase } from "lodash";

export type TextFormatOption = "uppercase" | "lowercase" | "capitalize" | "trim" | "camelcase";

export const formatText = (text: string, textFormatOptions: TextFormatOption[]): string => {
  if (!textFormatOptions || typeof text !== "string") {
    return text;
  }
  let formattedText = text;
  textFormatOptions.forEach((option) => {
    switch (option) {
      case "uppercase":
        formattedText = formattedText.toUpperCase();
        break;
      case "lowercase":
        formattedText = formattedText.toLowerCase();
        break;
      case "capitalize":
        formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
        break;
      case "trim":
        formattedText = formattedText.trim();
        break;
      case "camelcase":
        formattedText = camelCase(formattedText);
        break;
      default:
        break;
    }
  });
  return formattedText;
};
