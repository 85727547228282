import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import Style from "./Filters.module.css";

export const Filters = ({ onFilterChange, filterConfiguration, initialFilterValues, isLoading }) => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  useEffect(() => {
    setFilterValues(initialFilterValues);
  }, [initialFilterValues]);

  const updateFilterValues = (filterValueUpdates) => {
    const allCurrentValues = { ...filterValues, ...filterValueUpdates };
    setFilterValues(allCurrentValues);
    onFilterChange({ updates: filterValueUpdates, allValues: allCurrentValues });
  };

  return (
    <div className={Style.side_filter_container}>
      {filterConfiguration.categories?.map((filterCategory) => {
        return (
          <div
            key={filterCategory.id}
            className={Style.filter_group}
          >
            <p className={`${Style.filter_group_title}`}>{filterCategory.title}</p>
            <div className={Style.filter_group_filter}>
              {filterCategory.filters.map((filter) => {
                return (
                  <div
                    className={Style.filter_container}
                    key={`${filterCategory.id}-${filter.id}`}
                  >
                    <p className={Style.filter_title}>{filter.title}</p>
                    {isLoading ? (
                      <Skeleton
                        height={20}
                        count={4}
                      />
                    ) : (
                      filter.renderComponent(filterValues, updateFilterValues)
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

Filters.defaultProps = {
  initialFilterValues: {},
  isLoading: false
};

Filters.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filterConfiguration: PropTypes.shape({
    id: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        filters: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            renderComponent: PropTypes.func,
            filterToApply: PropTypes.func
          })
        )
      })
    )
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialFilterValues: PropTypes.object,
  isLoading: PropTypes.bool
};
