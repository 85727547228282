import React from "react";
import { SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import Style from "./ChartSlide.module.css";

export const ChartSlide = ({ title, children }) => {
  return (
    <SwiperSlide className={`${Style.flex_column} ${Style.slide}`}>
      <p className={Style.title}>{title}</p>
      {children}
    </SwiperSlide>
  );
};

// displayName is needed do to the bug (https://github.com/nolimits4web/swiper/issues/4413)
ChartSlide.displayName = "SwiperSlide";

ChartSlide.defaultProps = {
  title: "",
  children: null
};

ChartSlide.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object])
};
