import React from "react";
import useAssetTrackPageStateContext from "../../../../contexts/asset-track-page-state-context";
import { TAB_OPTION_MAP } from "../../../../data/constants";
import Style from "./TabSelector.module.css";

export const TabSelector = () => {
  const { selectedTab, setSelectedTab } = useAssetTrackPageStateContext();

  return (
    <div className={Style.view_buttons_container}>
      <p className={Style.view_text}>View:</p>
      <button
        type="button"
        className={`${Style.button} ${Style.white_button} ${
          selectedTab === TAB_OPTION_MAP.assetTab ? Style.selected : Style.unselected
        }`}
        onClick={() => {
          setSelectedTab(TAB_OPTION_MAP.assetTab);
        }}
        data-cy="asset-tab-button"
      >
        Asset
      </button>
      <button
        type="button"
        className={`${Style.button} ${Style.white_button} ${Style.asset_type_button} ${
          selectedTab === TAB_OPTION_MAP.assetTypeTab ? Style.selected : Style.unselected
        }`}
        onClick={() => {
          setSelectedTab(TAB_OPTION_MAP.assetTypeTab);
        }}
        data-cy="asset-type-tab-button"
      >
        Asset Type
      </button>
    </div>
  );
};
