import React, { useState, useEffect, useCallback } from "react";
import { ChevronRight, KeyboardArrowDown } from "@material-ui/icons";
import PropTypes from "prop-types";
import Style from "../WorkCenter.module.css";

/**
 *
 * @param {bool} expanded
 * @param {func} onStateChanged
 * @param {bool} expanded
 * @returns {JSX.Element}
 */
export const CollapsibleButton = ({ expanded, onStateChanged }) => {
  const COLOR = "#E7E7E7";
  const [collapsed, setCollapsed] = useState(!expanded);

  useEffect(() => {
    setCollapsed(!expanded);
  }, [expanded]);

  const setCollapseState = useCallback(
    (collapse) => {
      setCollapsed(collapse);
      onStateChanged(collapse);
    },
    [onStateChanged]
  );

  return (
    <div
      onClick={() => {
        return setCollapseState(!collapsed);
      }}
    >
      {!collapsed ? (
        <KeyboardArrowDown
          className={`${Style.accordion_title_icon}`}
          style={{
            color: COLOR
          }}
        />
      ) : (
        <ChevronRight
          className={`${Style.accordion_title_icon}`}
          style={{
            color: COLOR
          }}
        />
      )}
    </div>
  );
};

CollapsibleButton.propTypes = {
  expanded: PropTypes.bool,
  onStateChanged: PropTypes.func
};

CollapsibleButton.defaultProps = {
  expanded: false,
  onStateChanged: () => {}
};
