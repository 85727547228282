import React, { useEffect, useState } from "react";
import AddPageComponent from "../../components/add-page-component/AddPageComponent";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { processOnboardingPageAttributes, AddItemTypeFeatureFunctionMap } from "../../common/commonAPICalls";
import { validCSVHeaderCheck, validCSVDataCheck, TIME_IN_MS } from "../../common/Utilities";
import { useXemelgoClient } from "../../services/xemelgo-service";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  ITEM_TYPE_MANAGEMENT_PAGE,
  ITEM_TYPE_MANAGEMENT_PAGE_STEPS
} from "../../constants/mixpanel-constant/itemTypeManagementPage";
import { AddPageInputTypeMap } from "../../data/constants";

const FEATURE_ID = "addItemType";
const APP_ID = "itemTypesManagement";

const AddItemTypeFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const addItemTypeConfiguration = configProvider.getValue(FEATURE_ID, "object");
  const [itemTypeLabel, setItemTypeLabel] = useState("");
  const [attributeMap, setAttributeMap] = useState({});
  const [isUploadCsvEnabled, setIsUploadCsvEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bannerMessage, setBannerMessage] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Item Type");
  const { sendMixPanelEvent } = useMixpanelContext();

  const onLoad = async () => {
    const {
      attributeMap: newDefaultAttributeMap = {},
      uploadCsv: newUploadCsv,
      itemTypeLabel: newItemTypeLabel = "Item Type"
    } = addItemTypeConfiguration || {};

    const { defaultAttributeMap } = await processOnboardingPageAttributes(
      newDefaultAttributeMap,
      {},
      AddItemTypeFeatureFunctionMap
    );

    setItemTypeLabel(newItemTypeLabel);
    setAttributeMap(defaultAttributeMap);
    setIsUploadCsvEnabled(newUploadCsv);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onSubmit = async (formData) => {
    setLoading(true);
    setLoadingMessage("Creating Item Type");

    const itemTypePayload = {};

    Object.keys(formData).forEach((id) => {
      const { value, type, skipForCreation, millisecondsConversionUnit } = formData[id];
      if (!skipForCreation && value) {
        let finalValue;

        switch (type) {
          case AddPageInputTypeMap.DATE_PICKER:
            finalValue = value ? value.getTime() : undefined;
            break;
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          case AddPageInputTypeMap.SEARCH_DROP_DOWN:
            finalValue = value && typeof value === "object" ? value.value : value;
            break;
          case AddPageInputTypeMap.CHECK_BOX_GROUP:
          case AddPageInputTypeMap.INPUT:
          default:
            finalValue = value;
            break;
        }

        if (millisecondsConversionUnit && TIME_IN_MS[millisecondsConversionUnit]) {
          finalValue *= TIME_IN_MS[millisecondsConversionUnit];
        }
        itemTypePayload[id] = finalValue;
      }
    });

    if (!itemTypePayload.name) {
      itemTypePayload.name = itemTypePayload.identifier;
    }
    const { category, vendorClientCustomerPartNumber } = itemTypePayload;
    delete itemTypePayload.category;
    delete itemTypePayload.vendorClientCustomerPartNumber;
    try {
      if (vendorClientCustomerPartNumber && !formData?.partner?.value?.value) {
        throw "Partner is required for customer part number";
      }
      const response = await itemTypeClient.createItemTypeV2(itemTypePayload);

      if (response.name === "Error") {
        setBannerError(true);
        setBannerMessage(response.message);
        sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.ITEM_TYPE_ONBOARD_FAILED, {
          error_message: response.message
        });
      } else {
        if (category) {
          await itemTypeClient.attachItemTypeToCategory(response.id, category);
        }

        if (vendorClientCustomerPartNumber) {
          await itemTypeClient.createVendorClientInfo(
            vendorClientCustomerPartNumber,
            formData.partner.value.label,
            response.id,
            formData.partner.value.value
          );
        }

        setBannerError(false);
        setBannerMessage("Item Type(s) created");
      }
      sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.ITEM_TYPE_ONBOARD_SUCCESS);
    } catch (err) {
      setBannerMessage(err.message);
      setBannerError(true);
      sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.ITEM_TYPE_ONBOARD_FAILED, {
        error_message: err.message
      });
    }
    setLoading(false);
    setLoadingMessage("");
    setShowBanner(true);
  };

  const onUploadCSVSubmit = async (dataList) => {
    setLoading(true);

    const dataListWithStatus = [];
    let hasError = false;
    let telemetryErrMessage;
    for (let i = 0; i < dataList.length; i++) {
      setLoadingMessage(`Creating Item Type(s) ${i + 1} of ${dataList.length}`);
      const itemTypePayload = {};
      Object.keys(dataList[i]).forEach((id) => {
        const attribute = attributeMap[id];
        const value = dataList[i][id];
        if (value) {
          const { type, transformInput, numberOnly, options = [] } = attribute;
          let finalValue;
          switch (type) {
            case AddPageInputTypeMap.DATE_PICKER:
              finalValue = value ? Date.parse(value) : undefined;
              break;
            case AddPageInputTypeMap.INPUT:
              if (numberOnly) {
                finalValue = parseInt(value);
                break;
              } else if (transformInput) {
                if (transformInput === "toUpperCase") {
                  finalValue = value.toUpperCase();
                  break;
                }
              }
            // eslint-disable-next-line
            case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
            case AddPageInputTypeMap.SEARCH_DROP_DOWN:
              const option = options.find((each) => {
                if (typeof each.label === "string" && typeof value === "string") {
                  return each.label.toLowerCase() === value.toLowerCase();
                }
                return each.label === value;
              });
              finalValue = option?.value || value;
              break;
            case AddPageInputTypeMap.CHECK_BOX_GROUP:
            default:
              finalValue = value;
              break;
          }

          itemTypePayload[id] = finalValue;
        }
      });
      if (!itemTypePayload.name) {
        itemTypePayload.name = itemTypePayload.identifier;
      }
      const processedData = { ...dataList[i] };
      try {
        const { category } = itemTypePayload;
        delete itemTypePayload.category;
        const response = await itemTypeClient.createItemTypeV2(itemTypePayload);
        if (category) {
          await itemTypeClient.attachItemTypeToCategory(response.id, category);
        }
      } catch (err) {
        hasError = true;
        if (!telemetryErrMessage) {
          telemetryErrMessage = err.message;
        }
        processedData.errorMessage = err.message ? err.message.toString() : err.toString();
      }
      dataListWithStatus.push(processedData);
    }

    await onLoad();
    setBannerError(hasError);
    setBannerMessage(
      hasError
        ? bannerMessage ||
            "One or more item types could not be created: Please check the following item type(s) and retry."
        : "Item Type(s) created successfully."
    );
    if (hasError) {
      sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.BULK_CREATE_FAILURE, {
        error_message: telemetryErrMessage
      });
    } else {
      sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.BULK_CREATE_SUCCESS, {
        item_type_onboarded_count: dataList.length
      });
    }
    setShowBanner(true);
    setLoading(false);
    setLoadingMessage("");

    return dataListWithStatus;
  };

  return (
    <AddPageComponent
      title={itemTypeLabel}
      loading={loading}
      loadingMessage={loadingMessage}
      validCSVHeaderCheck={(data) => {
        sendMixPanelEvent(ITEM_TYPE_MANAGEMENT_PAGE, ITEM_TYPE_MANAGEMENT_PAGE_STEPS.BULK_CREATE_ENTRY);
        const { valid, errorMessage } = validCSVHeaderCheck(data, attributeMap, {});
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={(data) => {
        const { valid, errorMessage } = validCSVDataCheck(data, attributeMap, {});
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      setShowBanner={setShowBanner}
      bannerMessage={bannerMessage}
      onCloseBanner={() => {
        setBannerError(false);
        setBannerMessage("");
        setShowBanner(false);
      }}
      defaultAttributeMap={attributeMap}
      uploadCsv={isUploadCsvEnabled}
      onSubmit={onSubmit}
      onUploadCSVSubmit={onUploadCSVSubmit}
    />
  );
};

export default AddItemTypeFeature;
