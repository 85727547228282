export const VALUE_TYPE_MAP = {
  boolean: "boolean",
  dateTime: "dateTime",
  date: "date",
  status: "status",
  duration: "duration",
  comment: "comment",
  number: "number"
};

export const OPTIONS_BUTTON_HEADER_ID = "optionButton";
