import React, { Component, Fragment } from "react";
import SearchBarAndResults from "components/SearchBarAndResultsOriginal";
import "./SearchAndSubmit.css";

const searchTimeout = 0;

export default class SearchAndSubmit2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchList: undefined,
      searchItem: undefined,
      value: props.value
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.getPostAction = this.getPostAction.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value) {
      this.setState({
        value: newProps.value
      });
    }
  }

  handleInputChange = async (eventValue) => {
    const context = this;
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }

    const timer = setTimeout(async function () {
      await context.filterResults(eventValue);
      context.setState({
        timer: null
      });
    }, searchTimeout);

    this.setState({
      timer,
      value: eventValue
    });

    if (this.props.handleInputChange) {
      this.props.handleInputChange(eventValue);
    }
  };

  filterResults(eventValue) {
    let filteredMap = null;
    if (eventValue.length > 0) {
      const keyword = eventValue.replace(/[^\w\s]/gi, "");
      const suggestionToQueryParam = this.props.searchMap;
      const searchPhaseRegex = new RegExp(keyword, "gi");
      filteredMap = suggestionToQueryParam.filter((object) => {
        const toJsonString = JSON.stringify(object.key);
        return toJsonString.search(searchPhaseRegex) !== -1;
      });
    }

    this.setState({
      searchList: filteredMap
    });
  }

  selectItem(selectedItem) {
    if (selectedItem) {
      this.setState({
        searchList: undefined
      });

      this.props.selectItem(selectedItem);
    }
  }

  getPostAction() {
    return this.props.postAction(() => {
      this.setState({
        searchList: undefined
      });
    });
  }

  render() {
    const { showSearchList, searchStyle, postAction, placeholder, name, label, containerStyle, styledTextField } =
      this.props;
    const { searchList, value } = this.state;
    return (
      <Fragment>
        <SearchBarAndResults
          handleInputChange={this.handleInputChange}
          searchResults={showSearchList && searchList}
          searchFn={this.selectItem}
          value={value}
          searchStyle={searchStyle}
          postAction={postAction && this.getPostAction}
          enableSearchButton={false}
          placeholder={placeholder}
          name={name}
          label={label}
          containerStyle={containerStyle}
          styledTextField={styledTextField}
        />
      </Fragment>
    );
  }
}
