/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../styles/variable";
import { ReactComponent as AssetIcon } from "../../assets/icons/asset.svg";
import { ReactComponent as AssetTrackingIcon } from "../../assets/icons/asset-tracking.svg";
import { ReactComponent as AssetMapIcon } from "../../assets/icons/asset-map.svg";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import { ReactComponent as InventoryTrackingIcon } from "../../assets/icons/inventory-tracking.svg";
import { ReactComponent as GoodsReceiptIcon } from "../../assets/icons/goods-receipt.svg";
import { ReactComponent as ShipmentsIcon } from "../../assets/icons/shipments.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/reports.svg";
import { ReactComponent as PackageIcon } from "../../assets/icons/package.svg";
import { ReactComponent as PurchaseOrderIcon } from "../../assets/icons/purchase-order.svg";
import { ReactComponent as SchedulingManagementIcon } from "../../assets/icons/scheduling-management.svg";
import { ReactComponent as ManagementDashboardIcon } from "../../assets/icons/management-dashboard.svg";
import { ReactComponent as ApprovalManagementIcon } from "../../assets/icons/approval-management.svg";
import { ReactComponent as OrderIcon } from "../../assets/icons/order.svg";
import { ReactComponent as OrderTrackingIcon } from "../../assets/icons/order-tracking.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/alerts.svg";
import { ReactComponent as MyFacilityIcon } from "../../assets/icons/my-facility.svg";
import { ReactComponent as ItemTypesMangementIcon } from "../../assets/icons/item-types-management.svg";

import { SOLUTION_ID, SETTING_ID } from "../../constants/tabConfigConstants";

const SideNavIcon = ({ appName, isFocused, classOverride }) => {
  const color = useMemo(() => {
    return isFocused ? xemelgoStyle.theme.APP_BLUE : xemelgoStyle.theme.APP_SIDENAV_ICON_DARK;
  }, [isFocused]);

  switch (appName) {
    case "work-order":
      return (
        <OrderIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.ORDER_TRACKING:
      return (
        <OrderTrackingIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.INVENTORY:
      return (
        <InventoryIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.CHECK_OUT:
    case SOLUTION_ID.GOODS_RECEIPT:
      return (
        <GoodsReceiptIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.ASSET:
      return (
        <AssetIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.CREATE_ASSET:
      return (
        <AssetIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.ASSET_TRACKING:
      return (
        <AssetTrackingIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.ASSET_MAP:
      return (
        <AssetMapIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.SHIPMENTS:
      return (
        <ShipmentsIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.INVENTORY_TRACKING:
      return (
        <InventoryTrackingIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.INVENTORY_APPROVAL_MANAGEMENT:
      return (
        <ApprovalManagementIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.PURCHASE_ORDER:
      return (
        <PurchaseOrderIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.MANAGEMENT_DASHBOARD:
      return (
        <ManagementDashboardIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.SCHEDULING_MANAGEMENT:
      return (
        <SchedulingManagementIcon
          className={classOverride}
          color={color}
        />
      );
    case SETTING_ID.REPORT:
      return (
        <ReportsIcon
          className={classOverride}
          color={color}
        />
      );
    case SETTING_ID.ITEM_TYPES_MANAGEMENT:
      return (
        <ItemTypesMangementIcon
          className={classOverride}
          color={color}
        />
      );
    case SETTING_ID.MY_FACILITY:
      return (
        <MyFacilityIcon
          className={classOverride}
          color={color}
        />
      );
    case SETTING_ID.USERS:
      return (
        <UsersIcon
          className={classOverride}
          color={color}
        />
      );
    case SETTING_ID.ALERTS:
      return (
        <AlertIcon
          className={classOverride}
          color={color}
        />
      );
    case "approval-management":
      return (
        <ApprovalManagementIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.PACKAGE:
      return (
        <PackageIcon
          className={classOverride}
          color={color}
        />
      );
    case SOLUTION_ID.INVENTORY_DASHBOARD:
      return (
        <InventoryTrackingIcon
          className={classOverride}
          color={color}
        />
      );
    default:
      return null;
  }
};

export default SideNavIcon;

SideNavIcon.defaultProps = {
  appName: "",
  isFocused: false,
  classOverride: ""
};

SideNavIcon.propTypes = {
  appName: PropTypes.string,
  isFocused: PropTypes.bool,
  classOverride: PropTypes.string
};
