import { REPORT_TYPES } from "../../../report-detail-feature/data/constant";

export const formatReportName = (reportDetail, reportGroupsConfiguration) => {
  const reportClass = reportDetail.class;
  const reportConfig = getMatchingReportConfig(reportGroupsConfiguration, reportClass);
  switch (reportClass) {
    case REPORT_TYPES.approval:
      return `${reportConfig.label} Report - ${reportDetail.submittedAt?.[0]?.name}`;
    default:
      return reportDetail.identifier || "-";
  }
};

const getMatchingReportConfig = (reportGroupsConfiguration, reportId) => {
  return Object.values(reportGroupsConfiguration)
    .reduce((reports, reportGroup) => {
      return reports.concat(reportGroup);
    }, [])
    .find((report) => {
      return report.id === reportId;
    });
};
