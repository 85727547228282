export const REPORT_TYPES = {
  partVelocity: "Part Velocity",
  approval: "Removed Report",
  detectorCoverage: "Detector Coverage"
};

export const SUPPORTED_REPORTS = [REPORT_TYPES.partVelocity, REPORT_TYPES.approval];

export const NO_REPORT_URL_KEYWORD = "will not upload to s3";

export const REPORT_STATE = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  AUTO_APPROVED: "AUTO_APPROVED",
  RETURNED: "RETURNED",
  STANDBY: "STANDBY"
};

export const REPORT_STATE_DISPLAY_STRING = {
  [REPORT_STATE.PENDING]: "Pending",
  [REPORT_STATE.APPROVED]: "Approved",
  [REPORT_STATE.AUTO_APPROVED]: "Auto Approved",
  [REPORT_STATE.RETURNED]: "Returned",
  [REPORT_STATE.STANDBY]: "Stand-by"
};
