import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import LoadingCircle from "../../components/loading/LoadingCircle";
import { listItemsFromS3, getItemFromS3, getFormattedDate } from "../../common/Utilities";
import useConfigurationInfo from "../../hooks/use-configuration-info";
import ReportsPageComponent from "../../components/reports-page-component/ReportsPageComponent";
import { CreateReportModalFeature } from "./CreateReportModalFeature";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { LocalCacheService } from "../../services/local-cache-service";
import { SessionStorageService } from "../../services/session-storage-service";

const APP_ID = "reports";
const ENABLE_CREATE_REPORT = "createReportEnabled";
const REPORT_PREFIX_EXCLUSION_LIST = "reportExclusionPrefixList";
const REPORT_TYPE_MAP = "reportTypeMap";
const VENDOR_CLIENT_PATH = "/Vendor Client/";

const ReportsPageFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const CreateReportEnabled = configProvider.getValue(ENABLE_CREATE_REPORT, "boolean");
  const ReportPrefixExclusionList = configProvider.getValue(REPORT_PREFIX_EXCLUSION_LIST, "array", []);
  const ReportTypeMap = configProvider.getValue(REPORT_TYPE_MAP, "object", {});
  const [reports, setReports] = useState({});
  const [reportsList, setReportsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const { stage, bucketName } = useConfigurationInfo();

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (Object.keys(reports).length) {
      const userProfile = LocalCacheService.loadUserProfile();
      const partnerId = userProfile.getPartnerId();

      const { IsTruncated, NextContinuationToken, Contents } = reports;
      const tempReportsList = [
        ...reportsList,
        ...Contents.filter((report) => {
          return (
            partnerId ||
            (!partnerId &&
              !report.Key.includes(VENDOR_CLIENT_PATH) &&
              !hasPrefix(ReportPrefixExclusionList, report.Key))
          );
        })
      ];
      setReportsList(tempReportsList);
      setLoading(IsTruncated);
      if (IsTruncated) {
        onLoad(NextContinuationToken);
      }
    }
  }, [reports]);

  const hasPrefix = (exclusionList, reportKey) => {
    for (const prefix of exclusionList) {
      if (reportKey.includes(prefix)) {
        return true;
      }
    }
    return false;
  };

  const onLoad = async (continuationToken) => {
    const userProfile = LocalCacheService.loadUserProfile();
    let tenantId = userProfile.getTenantId();
    const partnerId = userProfile.getPartnerId();

    const isTestingMode = SessionStorageService.getTestMode();
    isTestingMode === "Test" && (tenantId += `-test`);

    if (partnerId) {
      tenantId = `${tenantId}${VENDOR_CLIENT_PATH}${partnerId}`;
    }

    await listItemsFromS3(bucketName, setReports, `${stage}/${tenantId}/`, continuationToken);
  };

  const getReport = async (key) => {
    await getItemFromS3(bucketName, key);
  };

  const getListViewHeaderStructure = () => {
    return [
      {
        id: "Key",
        title: true,
        index: 0,
        label: "Report",
        renderComponent: (data) => {
          return data;
        }
      },
      {
        id: "type",
        index: 1,
        label: "Type",
        renderComponent: (data) => {
          return data;
        }
      },
      {
        id: "lastModified",
        default: true,
        desc: true,
        index: 2,
        label: "Date",
        renderComponent: (data) => {
          return getFormattedDate(data, "hh:mm A MMM D YYYY");
        }
      }
    ];
  };

  const toggleModal = async (action) => {
    setShowModal(!showModal);
    if (action === "submit") {
      setLoading(true);
      setReportsList([]);
      setReports({});
      await onLoad();
    }
  };

  const handleListViewClick = ({ id }) => {
    getReport(id);
  };

  const renderReports = () => {
    const filesToRender = [];
    const userProfile = LocalCacheService.loadUserProfile();
    const partnerId = userProfile.getPartnerId();

    reportsList.forEach((file) => {
      const { Key, LastModified } = file;

      // Based on the document the file path should follow the format of
      // File Path: `${deployment - stage}/${tenant}/${Report Type}/${UTC Date}/${ UTC DateTime }_${ additional attribute }.csv`
      // Example: dev/multi-solutions-dev/On-Hand Report/2021_04_09/on_hand_report_2021_04_09.txt
      const filePathList = Key.split("/");
      const date = new Date(Date.parse(LastModified));

      // File name should follow format of tenantID_fileType_otherData.csv
      const formattedKey = filePathList[filePathList.length - 1];
      const formattedType = filePathList[partnerId ? 4 : 2];
      const mappedType = ReportTypeMap[formattedType] || formattedType;

      filesToRender.push({
        id: Key,
        Key: formattedKey,
        lastModified: date.getTime(),
        type: mappedType
      });
    });

    return (
      <>
        <ReportsPageComponent
          listViewHeaders={getListViewHeaderStructure()}
          listViewData={filesToRender}
          listViewClick={handleListViewClick}
          toggleModal={toggleModal}
          enableCreateReport={CreateReportEnabled}
        />
        <CreateReportModalFeature
          toggleModal={toggleModal}
          show={showModal}
        />
      </>
    );
  };

  return <>{loading ? <LoadingCircle /> : renderReports()}</>;
};

export default withRouter(ReportsPageFeature);
