const CacheKeys = {
  shipmentsDashboardStateKey: "Xemelgo-shipments-dashboard-state",
  customerTestModeKey: "CustomerTestMode",
  scenario: "scenario"
};

const retrieveCachedData = (keyName) => {
  const content = sessionStorage.getItem(keyName);

  if (content) {
    return JSON.parse(content);
  }

  return null;
};

const cacheData = (cacheKey, data) => {
  const stringifiedData = JSON.stringify(data);

  sessionStorage.setItem(cacheKey, stringifiedData);
};

export const SessionStorageService = {
  saveShipmentsDashboardState(dashboardState) {
    cacheData(CacheKeys.shipmentsDashboardStateKey, dashboardState);
  },

  getSavedShipmentsDashboardState() {
    return retrieveCachedData(CacheKeys.shipmentsDashboardStateKey);
  },

  clearAllCaches() {
    sessionStorage.clear();
  },

  getTestMode() {
    return sessionStorage.getItem(CacheKeys.customerTestModeKey);
  },

  setTestMode(testMode) {
    sessionStorage.setItem(CacheKeys.customerTestModeKey, testMode);
    if (testMode === "Test") {
      sessionStorage.setItem(CacheKeys.scenario, "1");
    } else {
      sessionStorage.setItem(CacheKeys.scenario, "0");
    }
  },

  getScenario() {
    return sessionStorage.getItem(CacheKeys.scenario);
  },

  setScenario(scenario) {
    sessionStorage.setItem(CacheKeys.scenario, scenario);
  }
};
