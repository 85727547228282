const generatePayload = (method, payload) => {
  return {
    method,
    mode: "cors",
    headers: {
      ContentType: "application/json"
    },
    body: JSON.stringify(payload)
  };
};

export const bartenderService = (endpoint) => {
  const printTag = (payload) => {
    const requestPayload = generatePayload("POST", payload);
    return fetch(endpoint, requestPayload);
  };

  const listAvailablePrinters = () => {
    // temporary
    return Promise.resolve(null);
  };

  return {
    printTag,
    listAvailablePrinters
  };
};
