import React, { useState } from "react";
import { ModalForm } from "../../../../components/modal-form";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import Style from "./MarkAsCompleteModal.module.css";

const MarkAsCompleteModal = ({ orderIds, onClose }) => {
  const [client] = useState(useXemelgoClient());
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    const WorkOrderClient = client.getWorkOrderClient();
    let message;
    let success = true;
    try {
      const promises = orderIds.map((orderId) => {
        return WorkOrderClient.markWorkOrderAsComplete(orderId);
      });
      await Promise.all(promises);
      message = `Item(s) successfully marked as complete. Please refresh the page in order to pull in the latest data`;
    } catch (e) {
      message = "Failed to mark item(s) as complete";
      success = false;
    }
    onClose(true, success, message);
  };

  return (
    <ModalForm
      show
      title={<div>{`Mark ${orderIds.length} Item(s) as Complete?`}</div>}
      body={<div>{`The ${orderIds.length} selected item(s) will be marked as complete`}</div>}
      footer={
        <div className={Style.modal_footer_container}>
          <button
            onClick={() => {
              onClose(false);
            }}
            className={`${Style.form_button} ${Style.cancel_button}`}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setIsLoading(true);
              onSubmit();
            }}
            className={`${Style.form_button} ${Style.confirm_button}`}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      }
      centered
    />
  );
};

export { MarkAsCompleteModal };
