export const PARTNER_MODEL_ID = "partner";

export const getPartnerDisplayValue = (partner, displayProperty, provideDefaultValue = true) => {
  // Want to consider a null value as intentional and therefore not
  // fallback to the 'name' property default
  const displayPropertyHasIntentionalValue = partner[displayProperty] || partner[displayProperty] === null;

  const resolvedValue = displayPropertyHasIntentionalValue
    ? partner[displayProperty]
    : partner.name || (provideDefaultValue ? "[Not Provided]" : null);

  return resolvedValue;
};

export const getPartnerPropertyFromConfig = (configProvider, propertyName, type, defaultValue) => {
  const modelConfigProvider = configProvider.getModel(PARTNER_MODEL_ID);
  return modelConfigProvider?.getValue(propertyName, type, defaultValue) || null;
};
