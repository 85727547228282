import { AddPageInputTypeMap } from "../../../data/constants";

export const DEFAULT_REPORT_ISSUE_FORM_FIELDS = [
  {
    id: "location",
    label: "Location",
    type: AddPageInputTypeMap.SEARCH_DROP_DOWN
  },
  {
    id: "issue_types",
    label: "Issue Types",
    type: AddPageInputTypeMap.MULTI_SELECT_DROP_DOWN,
    optionName: "issue type",
    isRequired: true
  },
  {
    id: "comment",
    label: "Comments",
    type: AddPageInputTypeMap.COMMENT,
    isRequired: true
  }
];
