import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSubFeatureConfigProvider } from "../../../../services/soft-cache-service";
import { ModalForm } from "../../../../components/modal-form";
import { FormFooter } from "./footer";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { EditLocationFormBody } from "./edit-form-body";

const SubFeatureId = "editLocationForm";
export const EditLocationForm = ({ appId, featureId, location, show, onFormClose, onSave, modelId }) => {
  const [showModal, setShowModal] = useState(show);
  const [categoryDisplayName, setCategoryDisplayName] = useState("");
  const [updatableProperties, setUpdatableProperties] = useState([]);
  const [innerLocations, setInnerLocations] = useState([]);
  const [client] = useState(useXemelgoClient());
  const [configProvider] = useState(useSubFeatureConfigProvider(appId, featureId, SubFeatureId));
  // This map is to register all the pin request for each location
  const [submitTicket, setSubmitTicket] = useState(null);
  const [editError, setEditError] = useState(false);

  useEffect(() => {
    let cancelled = false;
    const unsubscriptionCallback = () => {
      cancelled = true;
    };

    if (!location) {
      return unsubscriptionCallback;
    }

    const id = location.getId();
    const locationClient = client.getLocationClient();
    locationClient.getLocationChildrenByParentId(id).then((locations) => {
      const sorted = locations.sort((loc1, loc2) => {
        return loc1.name.localeCompare(loc2.name);
      });

      if (!cancelled) {
        setInnerLocations(sorted);
      }
    });

    return unsubscriptionCallback;
  }, [location, client]);

  // use category to query for configuration model, filter out all the properties that can be updated
  useEffect(() => {
    let cancelled = false;
    const unsubscriptionCallback = () => {
      cancelled = true;
    };

    if (!location) {
      return unsubscriptionCallback;
    }

    const model = configProvider.getModel(modelId);
    const displayName = model.getValue("displayName", "string", location.getCategory());

    const properties = model.getProperties();

    if (!cancelled) {
      setCategoryDisplayName(displayName);
      setUpdatableProperties(properties);
    }

    return unsubscriptionCallback;
  }, [location, configProvider, modelId, categoryDisplayName]);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onClose = useCallback(() => {
    onFormClose();
    setShowModal(false);
  }, [onFormClose]);

  const onSignalSaveCallback = useCallback(() => {
    setSubmitTicket(Date.now().toString());
  }, [setSubmitTicket]);

  const onSaveCallback = useCallback(
    async (fieldPayload, updateRequests = [], removeRequests = []) => {
      // check again for payload verification
      const locationClient = client.getLocationClient();
      // call backend service to update pin
      const promises = updateRequests.map((pinRequest) => {
        const { id, x, y, color } = pinRequest;
        return locationClient.updatePin(id, x, y, color);
      });

      const removePromises = removeRequests.map((pinRequest) => {
        const { id } = pinRequest;
        return locationClient.removePin(id);
      });
      promises.push(...removePromises);

      // call backend service to update other location properties.
      if (Object.keys(fieldPayload).length !== 0) {
        const locationId = location.getId();
        const { name, description, imagePath } = fieldPayload;
        let promise;
        try {
          promise = await locationClient.updateLocation(locationId, name, categoryDisplayName, description, imagePath);
        } catch (error) {
          setEditError(error);
          return;
        }
        if (promise) {
          promises.push(promise);
        }
      }

      // TODO: need to handle the case when randomly request fail.
      Promise.all(promises).then(() => {
        onSave();
        onFormClose();
      });
    },
    [client, location, onSave, onFormClose, categoryDisplayName]
  );

  return (
    <ModalForm
      show={showModal}
      title={`Editing ${location.getName()}`}
      body={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <EditLocationFormBody
          location={location}
          categoryDisplayName={categoryDisplayName}
          properties={updatableProperties}
          innerLocations={innerLocations}
          onNewSubmitTicket={onSaveCallback}
          submitTicket={submitTicket}
          editError={editError}
        />
      }
      size="lg"
      footer={
        <FormFooter
          onCancel={onClose}
          onSave={onSignalSaveCallback}
        />
      }
    />
  );
};

EditLocationForm.defaultProps = {
  show: false,
  onFormClose: () => {},
  onSave: () => {}
};

EditLocationForm.propTypes = {
  modelId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  featureId: PropTypes.string.isRequired,
  // locationId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    getId: PropTypes.func.isRequired,
    getName: PropTypes.func.isRequired,
    getCategory: PropTypes.func.isRequired
  }).isRequired,
  show: PropTypes.bool,
  onFormClose: PropTypes.func,
  onSave: PropTypes.func
};
