import React, { useState } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DisplayBanner from "../display-banner/DisplayBanner";
import { Checkbox } from "../checkbox-component/Checkbox";
import ReportPageStyle from "./ReportsPageComponent.module.css";
import { naturalSort } from "../../common/Utilities";

const useDatePickerStyle = makeStyles(() => {
  return {
    root: {
      width: "215px",
      height: "40px"
    }
  };
});

const useSelectStyle = makeStyles(() => {
  return {
    root: {
      width: "164.5px",
      maxWidth: "164.5px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 18.5px"
    }
  };
});

const useInputIconStyle = makeStyles(() => {
  return {
    root: {
      paddingRight: "0px",
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  };
});

export const CreateReportComponent = ({
  locations,
  handleChange,
  errors,
  locationConfig,
  bannerMessage,
  onCloseBanner
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState("");
  const [parentLocation, setParentLocation] = useState([]);
  const [childLocation, setChildLocation] = useState([]);

  const datePickerStyle = useDatePickerStyle();
  const selectStyle = useSelectStyle();
  const inputIconStyle = useInputIconStyle();

  const onEndChange = (value) => {
    if (!value) {
      setEndDate(null);
    } else {
      const date = new Date(value);
      handleChange("endDate", date.getTime());
      setEndDate(date.getTime());
    }
  };

  const onStartChange = (value) => {
    if (!value) {
      setStartDate(null);
    } else {
      const date = new Date(value);
      handleChange("startDate", date.getTime());
      setStartDate(date.getTime());
    }
  };
  let childLocationList = locations.filter((location) => {
    return location.category === locationConfig[1];
  });
  if (parentLocation.length) {
    childLocationList = childLocationList.filter((location) => {
      return location.data.isLocatedIn.length && parentLocation.includes(location.data.isLocatedIn[0].id);
    });
  }
  let parentLocationList = locations.filter((location) => {
    return location.category === locationConfig[0];
  });
  parentLocationList = naturalSort(parentLocationList, "name");
  childLocationList = naturalSort(childLocationList, "name");
  return (
    <div className={ReportPageStyle.create_modal_body}>
      {bannerMessage && (
        <DisplayBanner
          bannerError={!!bannerMessage}
          bannerMessage={bannerMessage}
          onCloseBanner={onCloseBanner}
        />
      )}
      <div className={!errors.type ? ReportPageStyle.create_input_group : ReportPageStyle.create_input_group_error}>
        <TextField
          id="reportType"
          variant="outlined"
          select
          label="Type *"
          onChange={(event) => {
            handleChange("type", event.target.value);
            setType(event.target.value);
          }}
          focused={false}
          value={type}
          InputLabelProps={{ margin: "dense" }}
          SelectProps={{ classes: selectStyle }}
          error={!!errors.type}
          helperText={errors.type || ""}
        >
          <MenuItem
            key="Work Orders"
            value="Work Orders"
          >
            Work Orders
          </MenuItem>
          <MenuItem
            key="Persons"
            value="Persons"
          >
            Persons
          </MenuItem>
          <MenuItem
            key="Time Tracking"
            value="Time Tracking"
          >
            Time Tracking
          </MenuItem>
        </TextField>
      </div>
      <div
        className={
          !errors.childLocation ? ReportPageStyle.create_input_group : ReportPageStyle.create_input_group_error
        }
      >
        <TextField
          id="parentLocation"
          variant="outlined"
          select
          label={locationConfig[0]}
          onChange={(event) => {
            handleChange("parentLocation", event.target.value);
            if (event.target.value.length > parentLocation.length) {
              handleChange("childLocation", []);
              setChildLocation([]);
            }
            setParentLocation(event.target.value);
          }}
          focused={false}
          value={parentLocation}
          InputLabelProps={{ margin: "dense" }}
          SelectProps={{
            classes: selectStyle,
            multiple: true,
            renderValue: (selected) => {
              let text = selected.map((location) => {
                return parentLocationList.find((element) => {
                  return element.id === location;
                }).name;
              });
              text = text.join(", ");
              if (text.length > 20) {
                text = `${text.substring(0, 19)}...`;
              }
              return text;
            },
            MenuProps: { variant: "menu" }
          }}
        >
          {parentLocationList.map((each) => {
            const selected = parentLocation.includes(each.id);
            return (
              <MenuItem
                key={each.id}
                value={each.id}
              >
                <div style={{ marginRight: 10 }}>
                  <Checkbox isChecked={selected} />
                </div>
                {each.name}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="childLocation"
          variant="outlined"
          label={`${locationConfig[1]} *`}
          select
          onChange={(event) => {
            handleChange("childLocation", event.target.value);
            setChildLocation(event.target.value);
          }}
          focused={false}
          value={childLocation}
          InputLabelProps={{ margin: "dense" }}
          SelectProps={{
            classes: selectStyle,
            multiple: true,
            renderValue: (selected) => {
              let text = selected.map((location) => {
                return childLocationList.find((element) => {
                  return element.id === location;
                }).name;
              });
              text = text.join(", ");
              if (text.length > 20) {
                text = `${text.substring(0, 19)}...`;
              }
              return text;
            },
            MenuProps: { variant: "menu" }
          }}
          error={!!errors.childLocation}
          helperText={errors.childLocation || ""}
        >
          {childLocationList.map((each) => {
            const selected = childLocation.includes(each.id);
            return (
              <MenuItem
                key={each.id}
                value={each.id}
              >
                <div style={{ marginRight: 10 }}>
                  <Checkbox isChecked={selected} />
                </div>
                {each.name}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
      <div className={ReportPageStyle.create_input_group}>
        <div style={{ width: "215px" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              disableFuture
              label="Start Date *"
              size="small"
              id="startTime"
              variant="inline"
              format="MM/dd/yyyy HH:mm"
              inputVariant="outlined"
              placeholder="MM/DD/YYYY HH:mm"
              autoOk
              value={startDate}
              onChange={onStartChange}
              maxDateMessage={null}
              minDateMessage={null}
              invalidDateMessage={null}
              KeyboardButtonProps={{ classes: inputIconStyle }}
              error={!!errors.startDate}
              helperText={errors.startDate || ""}
              InputProps={{ classes: datePickerStyle }}
              shouldDisableDate={(value) => {
                if (endDate) {
                  const date = new Date(value);
                  date.setHours(0, 0, 0, 0);
                  const endDateVar = new Date(endDate);
                  endDateVar.setHours(0, 0, 0, 0);
                  return date.getTime() > endDateVar.getTime();
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ width: "215px" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              disableFuture
              label="End Date *"
              size="small"
              id="endTime"
              variant="inline"
              format="MM/dd/yyyy HH:mm"
              inputVariant="outlined"
              placeholder="MM/DD/YYYY HH:mm"
              autoOk
              value={endDate}
              onChange={onEndChange}
              maxDateMessage={null}
              minDateMessage={null}
              invalidDateMessage={null}
              KeyboardButtonProps={{ classes: inputIconStyle }}
              error={!!errors.endDate}
              helperText={errors.endDate || ""}
              InputProps={{ classes: datePickerStyle }}
              shouldDisableDate={(value) => {
                if (startDate) {
                  const date = new Date(value);
                  date.setHours(0, 0, 0, 0);
                  const startDateVar = new Date(startDate);
                  startDateVar.setHours(0, 0, 0, 0);
                  return date.getTime() < startDateVar.getTime();
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};

CreateReportComponent.propTypes = {
  locations: PropTypes.array,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  locationConfig: PropTypes.array,
  bannerMessage: PropTypes.string,
  onCloseBanner: PropTypes.func
};
