import React from "react";
import PropTypes from "prop-types";
import ProgressBarStyle from "./ProgressBar.module.css";
import xemelgoStyle from "../../styles/variable";

export const ProgressBar = ({ percentage, subText, color, containerClassName }) => {
  return (
    <div className={containerClassName || ProgressBarStyle.progress_bar_container}>
      <div
        className={ProgressBarStyle.progress_bar}
        style={{ width: `${percentage}%`, background: color }}
      />
      <div className={ProgressBarStyle.subtext}>{subText}</div>
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  subText: PropTypes.string,
  color: PropTypes.string,
  containerClassName: PropTypes.string
};

ProgressBar.defaultProps = {
  percentage: 0,
  subText: null,
  color: xemelgoStyle.theme.APP_BLUE,
  containerClassName: ""
};
