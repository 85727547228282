/* eslint-disable radix */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Style from "./Metrics.module.css";
import MetricsComponent from "../../components/metrics";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageDataSourceContext from "../../contexts/work-order-track-page-data-source-context";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import { useMetricsGroupConfigBuilder } from "./hooks/use-metrics-group-configuration/useMetricsGroupConfiguration";
import { STATUS_SEVERITY_MAP } from "../../data/constants";
import { SUPPORTED_SETTING_ID_MAP } from "../wo-settings-menu/hooks/use-settings-builder/data/constants";

export const Metrics = ({ horizontalMode }) => {
  const { childrenLocationMetricMap, locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const { isMetricsLoading, selectedLocationId } = useWorkOrderTrackPageStateContext();
  const {
    metricsGroup,
    useBacklogStatusOnly,
    settings: settingsConfig,
    unknownLocationControl
  } = useWorkOrderTrackPageConfigContext();

  const currentLocationMetric = useMemo(() => {
    const final = Object.values(childrenLocationMetricMap).reduce((accumulator, eachMetric) => {
      Object.keys(eachMetric).forEach((eachKey) => {
        accumulator[eachKey] =
          (accumulator[eachKey] || (typeof eachMetric[eachKey] === "string" ? "" : 0)) + (eachMetric[eachKey] || 0);
      });
      return accumulator;
    }, {});

    let status;

    if (useBacklogStatusOnly) {
      if (final.backlogStatus?.includes(STATUS_SEVERITY_MAP.critical)) {
        status = STATUS_SEVERITY_MAP.critical;
      } else if (final.backlogStatus?.includes(STATUS_SEVERITY_MAP.warning)) {
        status = STATUS_SEVERITY_MAP.warning;
      } else {
        status = STATUS_SEVERITY_MAP.healthy;
      }
    } else {
      const statusCount = { critical: 0, warning: 0 };
      Object.keys(final).forEach((eachKey) => {
        if (eachKey.toLowerCase().includes(`${STATUS_SEVERITY_MAP.critical.toLowerCase()}count`) && final[eachKey]) {
          statusCount.critical += final[eachKey];
        } else if (
          eachKey.toLowerCase().includes(`${STATUS_SEVERITY_MAP.warning.toLowerCase()}count`) &&
          final[eachKey]
        ) {
          statusCount.warning += final[eachKey];
        }
      });

      const { percentageThresholdKeyMap = {} } =
        settingsConfig.optionControl?.[SUPPORTED_SETTING_ID_MAP.locationThresholdSettingModal] || {};

      const {
        [percentageThresholdKeyMap.critical]: criticalPercentageThreshold,
        [percentageThresholdKeyMap.warning]: warningPercentageThreshold
      } = locationTreeMap[selectedLocationId] || {};

      if (
        criticalPercentageThreshold
          ? parseInt((final.totalCriticalCount / final.totalCount) * 100) >= criticalPercentageThreshold
          : statusCount.critical
      ) {
        status = STATUS_SEVERITY_MAP.critical;
      } else if (
        warningPercentageThreshold
          ? parseInt((final.totalWarningCount / final.totalCount) * 100) >= warningPercentageThreshold
          : statusCount.warning
      ) {
        status = STATUS_SEVERITY_MAP.warning;
      } else if (unknownLocationControl?.enabled && selectedLocationId === unknownLocationControl?.properties?.id) {
        status = "-";
      } else {
        status = STATUS_SEVERITY_MAP.healthy;
      }
    }
    return { ...final, status, ...locationTreeMap[selectedLocationId] };
  }, [
    childrenLocationMetricMap,
    locationTreeMap,
    selectedLocationId,
    useBacklogStatusOnly,
    settingsConfig,
    unknownLocationControl
  ]);

  const { metricsGroupConfiguration } = useMetricsGroupConfigBuilder(metricsGroup, currentLocationMetric);

  if (!metricsGroupConfiguration.length) {
    return null;
  }

  return (
    <div className={`${horizontalMode ? Style.flex_row : Style.flex_column} ${Style.metrics}`}>
      {metricsGroupConfiguration.map(({ groupTitle, groupSubtitle, metrics }, index) => {
        return (
          <MetricsComponent
            key={`${groupTitle}${index}`}
            title={groupTitle}
            subtitle={groupSubtitle}
            metrics={metrics}
            isLoading={isMetricsLoading}
          />
        );
      })}
    </div>
  );
};

Metrics.defaultProps = {
  horizontalMode: false
};

Metrics.propTypes = {
  horizontalMode: PropTypes.bool
};
