import React, { useState } from "react";
import PropTypes from "prop-types";
import { XemelgoService } from "../../../../../../../../../../../../services/XemelgoService";
import Selector from "../../../../../../../../../../../../components/selector";
import SelectedOptionsList from "../../../../../../../../../../../../components/selected-options-list";
import Style from "./TransferOrderDropdown.module.css";
import useAvoidRacingAPIHelper from "../../../../../../../../../../../../hooks/use-avoid-racing-api-helper";

export const TransferOrderDropdown = ({ selectedOptionsMap, onSelectedOptionsMapChange, autoFocus }) => {
  const executeSearchFn = useAvoidRacingAPIHelper();
  const [searchInput, setSearchInput] = useState({});

  const searchFn = async (searchString) => {
    const transferOrderClient = XemelgoService.getClient().getTransferClient();

    const transferOrderOptions = (await transferOrderClient.getTransferOrdersFullTextContaining(searchString)).map(
      (order) => {
        return {
          label: order.identifier,
          value: order
        };
      }
    );

    return transferOrderOptions;
  };

  return (
    <div>
      <Selector
        onSelect={(newItem) => {
          setSearchInput(newItem);
        }}
        searchFn={(input) => {
          return executeSearchFn(searchFn(input));
        }}
        value={searchInput}
        resetOnClose
        autoFocus={autoFocus}
        renderRow={(item) => {
          const { label, value } = item;
          const { id, contains = [] } = value;

          const isSelected = !!selectedOptionsMap[id];
          return (
            <div
              key={id}
              className={Style.dropdown_option_container}
              onClick={() => {
                if (isSelected) {
                  const newSelectedOptionsMap = { ...selectedOptionsMap };
                  delete newSelectedOptionsMap[id];
                  onSelectedOptionsMapChange(newSelectedOptionsMap);
                } else {
                  onSelectedOptionsMapChange({
                    ...selectedOptionsMap,
                    [id]: item
                  });
                }
              }}
            >
              <div>
                <div className={Style.dropdown_option_text}>{label}</div>
                <div className={Style.dropdown_option_secondary_text}>
                  {contains.length ? "Main Order" : "Structure"}
                </div>
              </div>
              <div
                className={`${Style.dropdown_option_text} ${
                  isSelected ? Style.dropdown_selected_text : Style.dropdown_blue_text
                }`}
              >
                {isSelected ? "Added" : "Add"}
              </div>
            </div>
          );
        }}
      />
      {Object.keys(selectedOptionsMap).length > 0 && (
        <div className={Style.selected_items_list_container}>
          <SelectedOptionsList
            options={Object.values(selectedOptionsMap)}
            onRemoveOption={(option) => {
              const { value = {} } = option;
              const newSelectedOptionsMap = { ...selectedOptionsMap };
              delete newSelectedOptionsMap[value?.id];
              onSelectedOptionsMapChange(newSelectedOptionsMap);
            }}
          />
        </div>
      )}
    </div>
  );
};

TransferOrderDropdown.defaultProps = {
  selectedOptionsMap: {},
  onSelectedOptionsMapChange: () => {},
  autoFocus: false
};

TransferOrderDropdown.propTypes = {
  selectedOptionsMap: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({})
    })
  ),
  onSelectedOptionsMapChange: PropTypes.func,
  autoFocus: PropTypes.bool
};
