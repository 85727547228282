import { ModelDefinition } from "./model-definition";

/**
 * Refer to @Link { https://docs.google.com/document/d/1OcPxKXSpDqynXukP66snffGz08Gu98Qr5JCmBNdneRc/edit#heading=h.d8l9h3690gsj } to identify
 * whether a model is inheriting from the parent definitions.
 * @param modelDefinitionObjectOrValue
 * @returns {boolean}
 */
const isInheritingModel = (modelDefinitionObjectOrValue) => {
  // case 1: [modelId-1]: true
  if (modelDefinitionObjectOrValue === true) {
    return true;
  }

  // case 2: [modelId1]: 'modelId-1'
  if (typeof modelDefinitionObjectOrValue === "string") {
    return true;
  }

  // case 3, 4: [modelId1]: { __ref__: 'modelId-1' }
  // case 5: new model
  const { __ref__: ref } = modelDefinitionObjectOrValue;
  return ref && typeof ref === "string";
};

/**
 * Retrieve the referencing parent modelId from the current model
 * @param modelId { string } - current model id
 * @param definition { any } - model definition
 * @returns {string|*}
 */
const getBaseModelId = (modelId, definition) => {
  // case 1: 'modelId': true
  if (definition === true) {
    return modelId;
  }

  // case 2: 'modelId': 'alternative-modelId'
  if (typeof definition === "string") {
    return definition;
  }

  // case 3, 4: 'modelId': { '__ref__': 'alternative-modelId' }
  const { __ref__: ref } = definition;
  if (typeof ref === "string") {
    return ref;
  }

  return modelId;
};

const getModelDefinitionDomain = (modelId, definitionObject, definitionDomainMap) => {
  const baseModelId = getBaseModelId(modelId, definitionObject);
  if (isInheritingModel(definitionObject)) {
    const defDomain = definitionDomainMap[baseModelId];
    if (!defDomain) {
      return null;
    }

    const baseDefinitionObject = defDomain.getDefinitionObject();

    const { override: overridingDefinitionObject } = definitionObject;
    return new ModelDefinition(modelId, baseDefinitionObject, overridingDefinitionObject);
  }

  // case 1: when definition is boolean, and has 'false' value, then skip such model.
  if (definitionObject === false) {
    return null;
  }

  return new ModelDefinition(modelId, definitionObject);
};

const toModelDefinitionDomainMap = (modelDefinitionObjectMap) => {
  if (!modelDefinitionObjectMap) {
    return {};
  }

  return Object.keys(modelDefinitionObjectMap).reduce((map, modelId) => {
    const newMap = { ...map };
    const defObj = modelDefinitionObjectMap[modelId];

    const modelDefDomain = new ModelDefinition(modelId, defObj);
    newMap[modelId] = modelDefDomain;
    return newMap;
  }, {});
};

/**
 *
 * @param modelObjectMap
 * @param parentModelDomainMap { Object.<string, ModelDefinition> }
 * @returns {{}}
 */
const buildModelDefinitionDomainMap = (modelObjectMap, parentModelDomainMap) => {
  if (!parentModelDomainMap) {
    return toModelDefinitionDomainMap(modelObjectMap);
  }

  if (!modelObjectMap) {
    return { ...parentModelDomainMap };
  }

  return Object.keys(modelObjectMap).reduce((map, modelId) => {
    const newMap = { ...map };
    const defObj = modelObjectMap[modelId];

    const definitionDomain = getModelDefinitionDomain(modelId, defObj, parentModelDomainMap);
    if (definitionDomain) {
      newMap[modelId] = definitionDomain;
    }

    return newMap;
  }, {});
};

export class ModelConfigurationProvider {
  constructor(modelDefinitionObjectMap, parentModelDefinitionDomainMap) {
    this.defDomainMap = buildModelDefinitionDomainMap(modelDefinitionObjectMap, parentModelDefinitionDomainMap);
  }

  static parse(modelDefinitionObjectMap, parentModelDefinitionDomainMap) {
    return new ModelConfigurationProvider(modelDefinitionObjectMap, parentModelDefinitionDomainMap);
  }

  /**
   * @returns { Object.<string, ModelDefinition> }
   */
  getModelDefinitionDomainMap() {
    return this.defDomainMap;
  }
}
