import onboardItems from "./utils/onboard-items";
import createTransferOrder from "./utils/create-transfer-order";
import updateTransferOrder from "./utils/update-transfer-order";
import createContainer from "./utils/create-container";
import publishDetectorEvents from "./utils/publish-detector-events";
import { XemelgoService } from "../../../../../../../../services/XemelgoService";
import { ACTION_OPTIONS_MAP } from "../../../../../../data/constants";
import publishMultiTransferOrderDetectorEvents from "../../features/picklist-verification/utils/publish-multi-transfer-order-detector-event";
import { ITEM_SCAN_STATUS_MAP } from "../../data/constants";
import { PRINT_TIMES } from "../../../../../../../../data/constants";

export const submitTransferOrderAction = async (params) => {
  const {
    existingTransferOrders,
    newTransferOrderIdentifier,
    existingContainer,
    newContainer,
    stage,
    itemTypeReports,
    detectorId,
    printOptions,
    printTime,
    printItems,
    actionType,
    containerTypeIdentifier
  } = params;

  const transferClient = XemelgoService.getClient().getTransferClient();

  // Onboard new items and get existing items
  const completeItemTypeReports = await onboardItems(itemTypeReports, "Inventory");

  // Create container
  let selectedContainer = existingContainer;
  if (newContainer?.identifier) {
    selectedContainer = await createContainer(newContainer, containerTypeIdentifier);
  }

  // Attach items to container
  if (selectedContainer) {
    const { itemId, itemTypeId, sensorProfileId } = selectedContainer;
    const itemsIds = completeItemTypeReports.reduce((acc, report) => {
      const scannedItemIds = report.items
        .filter((item) => {
          // Do not include items that are associated with the order but not scanned (for picklist verification)
          return !item.isAssociatedWithOrder || item.scanStatus.id === ITEM_SCAN_STATUS_MAP.SCANNED.id;
        })
        .map((item) => {
          return item.itemId;
        });

      return [...acc, ...scannedItemIds];
    }, []);
    await transferClient.attachItemsToContainer(itemId, itemsIds);

    completeItemTypeReports.push({
      itemTypeId,
      items: [{ itemId, sensorProfileId }]
    });
  }

  if (actionType === ACTION_OPTIONS_MAP.ORDER_CREATION) {
    let transferOrder;
    if (!existingTransferOrders.length && newTransferOrderIdentifier) {
      transferOrder = await createTransferOrder(
        newTransferOrderIdentifier,
        completeItemTypeReports,
        selectedContainer,
        "Inventory"
      );
    } else if (existingTransferOrders.length) {
      transferOrder = await updateTransferOrder(existingTransferOrders[0], completeItemTypeReports, selectedContainer);
    }

    await publishDetectorEvents(transferOrder, completeItemTypeReports, stage.submitStatus, detectorId);
  } else if (ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION) {
    await publishMultiTransferOrderDetectorEvents(detectorId, existingTransferOrders, completeItemTypeReports, stage);
  }

  const transferOrderIdentifiers =
    newTransferOrderIdentifier ||
    existingTransferOrders
      .map((order) => {
        return order.identifier;
      })
      .sort()
      .join(", ");

  // Print container tags
  if (printOptions.enabled && printTime === PRINT_TIMES.now.id) {
    const printPayload = {
      containerIdentifier: selectedContainer.identifier,
      containerTypeIdentifier: selectedContainer.itemTypeIdentifier,
      transferOrderIdentifier: transferOrderIdentifiers,
      rfidTag: selectedContainer.vid
    };
    await printItems([printPayload]);
  }
};
