/**
 * @typedef Location
 * @property { string } id
 * @property { string } name
 */

/**
 * @typedef Detector
 * @property { string } class
 * @property { string } name
 * @property { Location } location
 */

/**
 * @typedef LocationWithDetector
 * @property { string } id
 * @property { string } name
 * @property { string } detectorSerial
 * @property { string } detectorVendor
 * @property { string } label
 */

import { naturalSort } from "../../../common/Utilities";

/**
 *
 * @param array { object[] }
 * @param idField { Function }
 * @return {Object.<string, object>}
 */
const toMap = (array, getIdField) => {
  return array.reduce((map, element) => {
    const clonedMap = { ...map };

    const identity = getIdField(element);
    clonedMap[identity] = element;
    return clonedMap;
  }, {});
};

/**
 *
 * @param locations { Location[] }
 * @param detectors { Detector[] }
 * @param { LocationWithDetector[] }
 */
export const getLocationWithDetector = (locations, detectors) => {
  const mountedDetectors = detectors.filter((detector) => {
    return detector.class === "Mounted";
  });

  const detectorByLocationIdMap = toMap(mountedDetectors, (detector) => {
    return detector?.location?.id || "unknown";
  });

  // register detectorSerial to location
  const locationWithDetectors = locations
    .map((location) => {
      const { id: locId } = location;
      const foundDetector = detectorByLocationIdMap[locId];
      const clonedLocation = { ...location, label: location.name };
      clonedLocation.detectorSerial = foundDetector?.name;
      clonedLocation.detectorVendor = foundDetector?.vendor;

      return clonedLocation;
    })
    .filter((location) => {
      return location.detectorSerial;
    });

  return naturalSort(locationWithDetectors, "label");
};
