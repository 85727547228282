import React, { useState, useEffect, useMemo } from "react";
import { CheckboxListFilter } from "../../../../../../components/checkbox-list-filter/CheckboxListFilter";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";
import { DatePicker } from "../../../../../../components/DateTimePicker/DateTimePicker";
import SearchDropdown from "../../../../../../components/SearchDropdown/SearchDropdown";
import { applySideFilters } from "../../../../../side-filter/services/side-filter-configuration-provider/sideFilterConfigurationProvider";
import { naturalSort } from "../../../../../../common/Utilities";
import { TEMPLATE_OPTIONS, FILTER_TYPE } from "../../data/constants";

export const useSideFilterConfigBuilder = (filterConfig) => {
  const [initialFilterValue, setInitialFilterValue] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const xemelgoClient = useXemelgoClient();

  const getDateRangeConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          id: "fromDateTime",
          title: "From:",
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <DatePicker
                color="#4A90FF"
                maxDate={sideFilterValues[eachFilter.id]?.endTime}
                placeholder="Select start date"
                value={sideFilterValues[eachFilter.id]?.startTime}
                onTimeChange={(timestamp) => {
                  updateFilterValues({ [eachFilter.id]: { ...sideFilterValues[eachFilter.id], startTime: timestamp } });
                }}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            return (
              !sideFilterValues[eachFilter.id] ||
              !sideFilterValues[eachFilter.id].startTime ||
              (eachItem[eachFilter.id] && sideFilterValues[eachFilter.id].startTime <= eachItem[eachFilter.id])
            );
          }
        },
        {
          id: "toDateTime",
          title: "To:",
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <DatePicker
                color="#4A90FF"
                minDate={sideFilterValues[eachFilter.id]?.startTime}
                placeholder="Select end date"
                value={sideFilterValues[eachFilter.id]?.endTime}
                onTimeChange={(timestamp) => {
                  updateFilterValues({ [eachFilter.id]: { ...sideFilterValues[eachFilter.id], endTime: timestamp } });
                }}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            return (
              !sideFilterValues[eachFilter.id] ||
              !sideFilterValues[eachFilter.id].endTime ||
              (eachItem[eachFilter.id] && sideFilterValues[eachFilter.id]?.endTime >= eachItem[eachFilter.id])
            );
          }
        }
      ]
    };
  };

  const getCheckboxesConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <CheckboxListFilter
                onChange={(newCheckboxList) => {
                  updateFilterValues({ [eachFilter.id]: [...newCheckboxList] });
                }}
                checkboxList={sideFilterValues[eachFilter.id]}
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            switch (eachFilter.id) {
              case TEMPLATE_OPTIONS.status:
                const selectedStatusList = (sideFilterValues[eachFilter.id] || []).filter((eachCheckbox) => {
                  return eachCheckbox.value;
                });
                let hasStatus = !selectedStatusList.length || false;
                eachItem[eachFilter.id].forEach((eachStatus) => {
                  const { status, severity } = eachStatus;
                  selectedStatusList.forEach((eachSelectedStatus) => {
                    const { status: selectedStatus, severity: selectedSeverity } = eachSelectedStatus;
                    if (status === selectedStatus && severity === selectedSeverity) {
                      hasStatus = true;
                    }
                  });
                });
                return hasStatus;
              default:
                const selectedList = [];
                (sideFilterValues[eachFilter.id] || []).forEach((eachCheckbox) => {
                  if (eachCheckbox.value) {
                    selectedList.push(eachCheckbox.id?.toLowerCase?.());
                  }
                });
                return !selectedList.length || selectedList.includes(eachItem[eachFilter.id]?.toLowerCase?.());
            }
          }
        }
      ]
    };
  };

  const getSearchDropdownConfigStructure = (eachFilter) => {
    return {
      id: eachFilter.id,
      title: eachFilter.title,
      filters: [
        {
          renderComponent: (sideFilterValues, updateFilterValues) => {
            return (
              <SearchDropdown
                placeholder={`Select a ${eachFilter.title.toLowerCase() || "option"}`}
                options={sideFilterValues[eachFilter.id]?.options || []}
                onItemSelected={(newSelectedItem) => {
                  updateFilterValues({
                    [eachFilter.id]: { value: newSelectedItem, options: sideFilterValues[eachFilter.id]?.options || [] }
                  });
                }}
                selectedItem={sideFilterValues[eachFilter.id]?.value || {}}
                showIcon
              />
            );
          },
          filterToApply: (sideFilterValues, eachItem) => {
            return (
              sideFilterValues[eachFilter.id]?.value === null ||
              !sideFilterValues[eachFilter.id]?.value?.id ||
              sideFilterValues[eachFilter.id].value.id === eachItem[eachFilter.id]
            );
          }
        }
      ]
    };
  };

  const getFilterStructureBuilderByType = (type) => {
    switch (type) {
      case FILTER_TYPE.checkboxes:
        return getCheckboxesConfigStructure;
      case FILTER_TYPE.dateRange:
        return getDateRangeConfigStructure;
      case FILTER_TYPE.searchDropdown:
        return getSearchDropdownConfigStructure;
      default:
        return () => {};
    }
  };

  const filterConfiguration = useMemo(() => {
    const config = { categories: [] };
    filterConfig.forEach((eachFilter) => {
      const filterStructureBuilder = getFilterStructureBuilderByType(eachFilter.type);
      const newCategory = filterStructureBuilder(eachFilter);
      if (newCategory) {
        config.categories.push(newCategory);
      }
    });
    return config;
  }, [filterConfig]);

  const getTemplateOptionsByType = (optionId, filterOptions) => {
    switch (optionId) {
      case TEMPLATE_OPTIONS.location:
        return fetchLocation(filterOptions.locationCategories);
      case TEMPLATE_OPTIONS.itemType:
        return fetchItemType();
      default:
        return [];
    }
  };

  const fetchLocation = async (locationCategories) => {
    const locationClient = xemelgoClient.getLocationClient();
    const locationTreeMap = await locationClient.getLocationTree([]);
    return naturalSort(
      Object.values(locationTreeMap)
        .filter((eachLocation) => {
          return !locationCategories.length || locationCategories.includes(eachLocation.category);
        })
        .map((eachLocation) => {
          return { id: eachLocation.id, label: eachLocation.name };
        }),
      "label"
    );
  };

  const fetchItemType = async () => {
    const itemTypeClient = xemelgoClient.getItemTypeClient();
    const itemTypes = await itemTypeClient.getItemTypes(["id", "identifier"]);
    return itemTypes.map((eachItemType) => {
      return { id: eachItemType.identifier, label: eachItemType.identifier };
    });
  };

  const updateInitialFilterValue = async () => {
    setIsLoading(true);
    const initialFilterValueObj = {};
    await Promise.all(
      filterConfig.map(async (eachFilter) => {
        switch (eachFilter.type) {
          case FILTER_TYPE.checkboxes:
            initialFilterValueObj[eachFilter.id] = eachFilter.optionTemplate
              ? await getTemplateOptionsByType(eachFilter.optionTemplate, {
                  locationCategories: eachFilter.locationCategories || []
                })
              : [...(eachFilter.options || [])];
            break;
          case FILTER_TYPE.searchDropdown:
            initialFilterValueObj[eachFilter.id] = {
              options: eachFilter.optionTemplate
                ? await getTemplateOptionsByType(eachFilter.optionTemplate)
                : [...(eachFilter.options || [])],
              value: eachFilter.defaultValue || null
            };
            break;
          case FILTER_TYPE.dateRange:
            initialFilterValueObj[eachFilter.id] = { startTime: null, endTime: null };
            break;
          default:
            break;
        }
      })
    );
    setInitialFilterValue(initialFilterValueObj);
    setIsLoading(false);
  };

  useEffect(() => {
    updateInitialFilterValue();
  }, [filterConfig]);

  return { filterConfiguration, initialFilterValue, applySideFilters, isLoading };
};
