import React from "react";
import { Route } from "react-router-dom";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import AssetTrackPageFeature from "../../features/asset-track-page-feature/AssetTrackPageFeature";
import BulkUpdateAssetsFeature from "../../features/asset-track-page-feature/BulkUpdateAssetsFeature";
import AssetTrackPageFeatureV2 from "../../features/asset-track-page-feature-v2";
import AssetDetailPage from "../../features/asset-details-feature/AssetDetailPage";
import AddAssetFeature from "../../features/asset-track-page-feature/AddAssetFeature";
import AssetTypeDetailPage from "../../features/asset-details-feature/AssetTypeDetailPage";

const APP_ID = "asset";
const TRACK_PAGE_FEATURE = "trackPage";

export const AssetStackNavigation = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const { useV2: useTrackPageV2 } = configProvider.getValue(TRACK_PAGE_FEATURE, "object");

  return (
    <>
      <Route
        exact
        path="/asset"
        component={useTrackPageV2 ? AssetTrackPageFeatureV2 : AssetTrackPageFeature}
      />
      <Route
        path="/asset/create"
        component={AddAssetFeature}
      />
      <Route
        path="/asset/detail"
        component={AssetDetailPage}
      />
      <Route
        path="/asset/bulk-update"
        component={BulkUpdateAssetsFeature}
      />
      <Route
        path="/asset/itemType/detail"
        component={AssetTypeDetailPage}
      />
    </>
  );
};
