import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Tooltip } from "@material-ui/core";

const pinTypeAdjustment = {
  pinpoint: (x, y, width, height) => {
    return {
      x: x - width / 2,
      y: y - height
    };
  },
  badge: (x, y, width, height) => {
    return {
      x: x - width / 2,
      y: y - height / 2
    };
  }
};

const generatePinTemplate = (tooltip, bodyComponent) => {
  if (!tooltip) {
    return <>{bodyComponent}</>;
  }

  return (
    <Tooltip
      title={tooltip}
      interactive
    >
      {bodyComponent}
    </Tooltip>
  );
};

export const CustomPin = ({
  x,
  y,
  nameReferencingColor,
  pinComponent,
  onClick,
  canClick,
  tooltip,
  pinType,
  adjustTicket,
  name,
  boundary
}) => {
  const [centerPoint, setCenterPoint] = useState({ x, y });
  const [namePoint, setNamePoint] = useState({ x, y });
  const [dimension, setDimension] = useState({ width: -1, height: -1 });
  const [needAdjust, setNeedAdjust] = useState(false);
  const [pinHeadRef, setPinHeadRef] = useState(null);
  const [nameRef, setNameRef] = useState(null);
  const [referencingColor, setReferencingColor] = useState(nameReferencingColor);

  const adjustCoordinate = useCallback(
    (pinAdjustmentType) => {
      if (!pinHeadRef) {
        return;
      }

      const { firstElementChild } = pinHeadRef;
      const { offsetWidth: width, offsetHeight: height, offsetLeft, offsetTop } = firstElementChild;
      const { width: registeredWidth, height: registeredHeight } = dimension;

      if (registeredWidth === width && registeredHeight === height) {
        return;
      }

      const { x: px, y: py } = centerPoint;
      const adjustmentFn = pinTypeAdjustment[pinAdjustmentType] || pinTypeAdjustment.pinpoint;
      const coordinate = adjustmentFn(px, py, width, height);
      setCenterPoint({ x: coordinate.x - offsetLeft, y: coordinate.y - offsetTop });
      setDimension({ width, height });
    },
    [pinHeadRef, dimension, centerPoint]
  );

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (adjustTicket && !cancelled) {
      setNeedAdjust(true);
    }

    return cancelCallback;
  }, [adjustTicket]);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!needAdjust) {
      return cancelCallback;
    }

    if (!cancelled) {
      adjustCoordinate(pinType);
      setNeedAdjust(false);
    }

    return cancelCallback;
  }, [needAdjust, pinType, adjustCoordinate]);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!nameRef) {
      return cancelCallback;
    }

    const { offsetWidth: width, offsetHeight: height } = nameRef;

    const halfWidth = width / 2;
    let coordinateX = x - halfWidth;
    let coordinateY = y + 1;

    if (boundary) {
      const { width: bWidth, height: bHeight, offsetLeft, offsetTop } = boundary;
      const boundaryWidth = bWidth + offsetLeft;
      const boundaryHeight = bHeight + offsetTop;
      if (coordinateX < 0) {
        coordinateX = 2;
      } else if (coordinateX + width > boundaryWidth) {
        coordinateX = boundaryWidth - width;
      }

      // text always display on the bottom of pin so it cannot get below 0 boundary.
      //  Therefore we only need to handle the case there is not enough vertical room to
      //  show. When this happens, we will either move the text to left or right of pin
      if (coordinateY + height > boundaryHeight) {
        coordinateY = boundaryHeight - height;
        if (coordinateX * 2 < boundaryWidth) {
          coordinateX = x + 5;
        } else {
          coordinateX = x - width - 5;
        }
      }
    }

    const coordinate = {
      x: coordinateX,
      y: coordinateY
    };

    if (!cancelled) {
      setNamePoint(coordinate);
    }

    return cancelCallback;
  }, [nameRef, adjustTicket, boundary, x, y]);

  useEffect(() => {
    let cancelled = false;

    if (!cancelled) {
      setReferencingColor(nameReferencingColor);
    }

    return () => {
      cancelled = true;
    };
  }, [nameReferencingColor]);

  // let this re-render at all time
  const onRef = (e) => {
    if (!e) {
      return;
    }

    setPinHeadRef(e);
    adjustCoordinate(pinType);
  };

  const onClickCallback = () => {
    if (!canClick) {
      return;
    }

    onClick();
  };

  return (
    <span>
      {generatePinTemplate(
        tooltip,
        <div
          className="custom-pin"
          ref={onRef}
          style={{
            position: "absolute",
            top: `${centerPoint.y}px`,
            left: `${centerPoint.x}px`
          }}
        >
          {canClick && (
            <button
              type="button"
              onClick={onClickCallback}
            >
              {pinComponent}
            </button>
          )}
          {!canClick && pinComponent}
        </div>
      )}
      {name && (
        <div
          ref={setNameRef}
          className="custom-pin-name"
          style={{
            position: "absolute",
            top: `${namePoint.y}px`,
            left: `${namePoint.x}px`
          }}
        >
          <span
            className="pin-referencing-dot"
            style={{ color: referencingColor, paddingRight: "0.5em" }}
          />
          {name}
        </div>
      )}
    </span>
  );
};

CustomPin.defaultProps = {
  onClick: () => {},
  canClick: false,
  tooltip: null,
  pinType: "pinpoint",
  adjustTicket: null,
  name: null,
  boundary: null,
  nameReferencingColor: "#ffffff"
};

CustomPin.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  pinComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  nameReferencingColor: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  canClick: PropTypes.bool,
  pinType: PropTypes.oneOf(["pinpoint", "badge"]),
  adjustTicket: PropTypes.string,
  name: PropTypes.string,
  boundary: PropTypes.shape({
    offsetLeft: PropTypes.number,
    offsetTop: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number
  })
};
