import React, { Fragment, useState, useEffect } from "react";
import { getFormattedDate, getTimezone } from "common/Utilities";
import Spinner from "react-bootstrap/Spinner";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { CollapsibleListView } from "../../components/collapsible-list-view";

export const RefreshRuleContent = ({ ruleConditionsMap, ruleId, ruleConfig, onLoad }) => {
  const [loading, setLoading] = useState(false);
  const [timeToTrigger, setTimeToTrigger] = useState("00:00");
  const [daysEarly, setDaysEarly] = useState(5);
  const [editedTime, setEditedTime] = useState(undefined);
  const [editedDays, setEditedDays] = useState(undefined);
  const [RulePageClient] = useState(useXemelgoClient().getRulePageClient());

  const parseConditionData = (ruleConditionsMap) => {
    const { refreshConditions = [], refreshSoonConditions = [] } = ruleConditionsMap;
    if (refreshConditions.length) {
      const refreshCondition = refreshConditions[0];
      const { tags = {} } = refreshCondition;
      const { timeToTrigger } = tags;
      if (timeToTrigger) {
        setTimeToTrigger(getFormattedDate(timeToTrigger, "HH:mm"));
      } else {
        setTimeToTrigger("00:00");
      }
    }

    if (refreshSoonConditions.length) {
      const refreshSoonCondition = refreshSoonConditions[0];
      const { daysBeforeDue } = refreshSoonCondition.tags || {};
      setDaysEarly(daysBeforeDue);
    }
    setLoading(false);
  };

  useEffect(() => {
    parseConditionData(ruleConditionsMap);
  }, [ruleConditionsMap]);

  const handleSubmit = async () => {
    const { refreshConditions = [], refreshSoonConditions = [] } = ruleConditionsMap;
    const { refresh, refreshSoon } = ruleId;
    const time = editedTime ? editedTime.split(":") : timeToTrigger.split(":");
    const date = new Date();
    date.setHours(+time[0]);
    date.setMinutes(+time[1]);

    // Getting offset from datetime
    const minutesOffSet = +time[0] * 60 + +time[1];
    const formattedTime = date.getTime();
    const tags = {
      timeToTrigger: formattedTime,
      minutesOffSet
    };
    const conditionList = [{ property: "refreshCount", op: ">", value: "0" }];
    const name = `${ruleConfig.displayName.toLowerCase()}`;
    if (refreshConditions.length) {
      const currentRefreshCondition = refreshConditions[0];
      const { id } = currentRefreshCondition;
      await RulePageClient.updateRuleCondition(id, name, tags);
    } else {
      await RulePageClient.createRuleCondition(name, tags, refresh, conditionList);
    }

    if (ruleConfig.refreshSoon) {
      const refreshSoonTags = {
        timeToTrigger: formattedTime,
        daysBeforeDue: editedDays || daysEarly,
        minutesOffSet
      };
      const refreshSoonConditionList = [{ property: "refreshSoonCount", op: ">", value: "0" }];
      const refreshSoonName = `${ruleConfig.displayName.toLowerCase()}_soon`;
      if (refreshSoonConditions.length) {
        const currentRefreshSoonCondition = refreshSoonConditions[0];
        const { id } = currentRefreshSoonCondition;
        await RulePageClient.updateRuleCondition(id, refreshSoonName, refreshSoonTags);
      } else {
        await RulePageClient.createRuleCondition(
          refreshSoonName,
          refreshSoonTags,
          refreshSoon,
          refreshSoonConditionList
        );
      }
    }
    editedDays && setDaysEarly(editedDays);
    editedTime && setTimeToTrigger(editedTime);
    handleCancel();
    await onLoad();
  };

  const handleCancel = () => {
    setEditedTime(undefined);
    setEditedDays(undefined);
  };

  const renderSwitches = () => {
    return (
      <>
        <div className="alerts-settings-container">
          <div className="alerts-settings-text">
            Notification Time
            <div className="switch-helper-text">When should the notification be sent?</div>
          </div>
          <div className="early-expiration-container">
            <div>
              <input
                className={editedTime === "" ? "time_input input-error" : "time_input"}
                onChange={(event) => {
                  setEditedTime(event.target.value);
                }}
                id="notfication-time"
                type="time"
                value={editedTime !== undefined ? editedTime : timeToTrigger}
              />
              {`(${getTimezone()})`}
            </div>
            {editedTime === "" && <p className="error-text">Enter a time ex. 12:00 AM</p>}
          </div>
        </div>
        {ruleConfig.refreshSoon && (
          <div className="alerts-settings-container">
            <div className="alerts-settings-text">
              Early Notification
              <div className="switch-helper-text">
                Receive notifications when items will expire in the selected number of days
              </div>
            </div>
            <div className="early-expiration-container">
              <div>
                <input
                  className={editedDays === "" || editedDays < 1 ? "early-expiration input-error" : "early-expiration"}
                  type="number"
                  onChange={(event) => {
                    setEditedDays(event.target.value);
                  }}
                  min="1"
                  value={editedDays !== undefined ? editedDays : daysEarly}
                />
                days
              </div>
              {(editedDays === "" || editedDays < 1) && (
                <p className="error-text">Enter a number greater than or equal to 1</p>
              )}
            </div>
          </div>
        )}
        {(editedTime !== undefined || editedDays !== undefined) && (
          <div className="button-container">
            <button
              className="cancel-button"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="default-button"
              disabled={editedTime === "" || (ruleConfig.refreshSoon && (editedDays === "" || editedDays < 1))}
              onClick={() => {
                setLoading(true);
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <div>
        <p className="tabbedSectionComponent-contentTitle">{ruleConfig.displayName}</p>
        <p style={{ color: "#343434" }}>
          {ruleConfig.pageMessage ||
            "Sends a notification if any item needs to be refreshed. Select a time to check for items that need refreshing."}
        </p>
      </div>
      <div>
        {loading ? (
          <div className="loading-circle">
            <Spinner animation="border" />
          </div>
        ) : (
          <CollapsibleListView
            className="alert-switches"
            key="settings"
            title="Alert Settings"
            content={renderSwitches()}
            openAtStart
          />
        )}
      </div>
    </div>
  );
};
