import React, { Component } from "react";

export default class DetectorOfflineContent extends Component {
  render() {
    return (
      <div>
        <div>
          <p className="tabbedSectionComponent-contentTitle">Detector Offline</p>
          <p style={{ color: "#343434" }}>Sends a notification if a detector goes offline</p>
        </div>
      </div>
    );
  }
}
