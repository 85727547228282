const { AddPageInputTypeMap } = require("../../../../../../data/constants");

export const getCategoryDropdowns = (flatCategoryTrees, selectedCategory, selectedSubcategory) => {
  const categoryDropdown = {
    id: "category",
    type: AddPageInputTypeMap.SEARCH_DROP_DOWN,
    label: "Category",
    value: selectedCategory?.label,
    options: Object.values(flatCategoryTrees)
      .reduce((acc, category) => {
        if (category.depth === 0) {
          acc.push({
            id: category.id,
            value: category.id,
            label: category.identifier
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
  };

  const subcategoryDropdown = {
    id: "subcategory",
    type: AddPageInputTypeMap.SEARCH_DROP_DOWN,
    value: selectedSubcategory?.label,
    label: "Subcategory",
    options: Object.values(flatCategoryTrees)
      .reduce((acc, category) => {
        if (category.depth === 1 && (!selectedCategory.id || selectedCategory.id === category.directParentId)) {
          acc.push({
            id: category.id,
            value: category.id,
            label: category.identifier
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
  };

  return {
    categoryDropdown,
    subcategoryDropdown
  };
};
