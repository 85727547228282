import {
  binaryToHex,
  decimalToBinary,
  getCurrentTimestamp,
  getFormattedDate
} from "../../../../../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";
import formatText from "../../../../../../utils/format-text";
import generateCustomIdentifier from "../../../../utils/generate-custom-identifier";
import { useAddInventoryFeatureV2ConfigContext } from "../../contexts/add-inventory-feature-v2-config-context";
import { useAddInventoryFeatureV2StateContext } from "../../contexts/add-inventory-feature-v2-state-context";

const NO_LOCATION_ID = "no_location";
const TAG_BLOCK_SIZE = 8;

export const useInventoryFormDataParser = () => {
  const {
    encodeTag,
    targetLocationEnabled,
    singlePrint,
    customItemIdentifierTemplate,
    generateIdentiferOptions,
    useVendorClientInfo,
    epcPrefix
  } = useAddInventoryFeatureV2ConfigContext();
  const { formFields } = useAddInventoryFeatureV2StateContext();
  const xemelgoClient = useXemelgoClient();
  const appsyncItemClient = useXemelgoAppsyncClient().getItemClient();
  const locationClient = xemelgoClient.getLocationClient();

  const getFieldValue = (fieldData) => {
    return typeof fieldData === "object" ? fieldData?.value : fieldData;
  };

  const generateSGTINPayload = (itemTypeIdentifier, selectedLocation, quantity = 1) => {
    const payloads = [];

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const timeString = getFormattedDate(Date.now(), "MMDDHHmm");
      const paddedString = i.toString().padStart(10 - timeString.length, "0");
      // arbitrary 2 as it lets us get more bits
      const serial = `2${timeString}${paddedString}`;
      const tag = generateSGTIN96Tag(itemTypeIdentifier, serial);
      const payload = {
        item_number: itemTypeIdentifier,
        tracker_serial: tag,
        name: serial,
        item_identifier: serial
      };
      if (targetLocationEnabled && Object.keys(selectedLocation).length) {
        payload.initial_location = selectedLocation.identifier;
      }
      payloads.push(payload);
    }

    return payloads;
  };

  const generateSGTIN96Tag = (UPC, serialToGenerate) => {
    // this is a header for a SGTIN96, Point of Sale tag with a partition of 5
    const prefix = "00110000001101";
    const paddedUPC = `0${UPC}`;
    const companyPrefix = decimalToBinary(paddedUPC.substring(0, 7)).padStart(24, "0");
    const itemNumber = decimalToBinary(paddedUPC.substring(7, paddedUPC.length - 1)).padStart(20, "0");
    const binarySerial = decimalToBinary(serialToGenerate).padStart(38, "0");
    return binaryToHex(`${prefix}${companyPrefix}${itemNumber}${binarySerial}`).toUpperCase();
  };

  const generateTags = (quantity = 1) => {
    // to make sure every vid is unique
    const tags = [];

    const timestamp = getCurrentTimestamp();
    const date = getFormattedDate(timestamp, "MMDD");

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const paddingLength = TAG_BLOCK_SIZE - (`${epcPrefix}${date}${timestamp}${i}`.length % TAG_BLOCK_SIZE);
      const paddedString = "0".repeat(paddingLength) + i;
      tags.push(`${epcPrefix}${date}${timestamp}${paddedString}`);
    }
    return tags;
  };

  const generatePayload = async (formDataList) => {
    const payloadsByLocationIdMap = {};

    const augmentedFormDataList = formDataList.map((formData) => {
      const augmentedFormData = { ...formData };
      const missingFields = Object.keys(formFields).filter((fieldId) => {
        const field = formFields[fieldId];
        return (field.isHidden || field.isDisabled) && !formData[fieldId] && field.defaultValue;
      });

      missingFields.forEach((field) => {
        const value = formFields[field].defaultValue.value ?? formFields[field].defaultValue;
        augmentedFormData[field] = value;
      });

      Object.keys(augmentedFormData).forEach((fieldId) => {
        const field = formFields[fieldId];

        if (field.textFormatOptions) {
          let fieldText = augmentedFormData[fieldId];
          if (typeof fieldText === "object") {
            fieldText = fieldText?.value;
            augmentedFormData[fieldId].value = formatText(fieldText, field.textFormatOptions);
          } else if (typeof fieldText === "string") {
            augmentedFormData[fieldId] = formatText(fieldText, field.textFormatOptions);
          }
        }
      });

      return augmentedFormData;
    });

    const locationIdentifierList = augmentedFormDataList.map((eachForm) => {
      const { location } = eachForm;
      return getFieldValue(location);
    });
    const locationInfoMap = (await locationClient.getLocationsByIdentifiers(locationIdentifierList)).reduce(
      (locationMap, eachLocation) => {
        const { identifier } = eachLocation;
        locationMap[identifier] = eachLocation;
        return locationMap;
      },
      {}
    );

    for (let rowIndex = 0; rowIndex < augmentedFormDataList.length; rowIndex++) {
      let payloads = [];
      const item = augmentedFormDataList[rowIndex];
      const {
        item_number: itemNumber,
        tracker_serial: trackerSerial,
        item_quantity: itemQuantity = 1,
        location,
        ...additionalProperties
      } = item;

      const itemNumberValue = getFieldValue(itemNumber);
      const locationValue = getFieldValue(location);
      const trackerSerialValue = getFieldValue(trackerSerial);
      const itemQuantityValue = getFieldValue(itemQuantity) || 1;
      let newVids = [];
      const quantityToSubmit = singlePrint ? 1 : itemQuantityValue;
      // await 1ms for each row to ensure vids are unique
      await new Promise((resolve) => {
        return setTimeout(resolve, 1);
      });

      // generate vid
      if (encodeTag) {
        payloads = generateSGTINPayload(itemNumberValue, locationInfoMap[locationValue], quantityToSubmit);
      } else if (trackerSerialValue) {
        const itemPayload = {
          item_number: itemNumberValue,
          tracker_serial: trackerSerialValue,
          name: trackerSerialValue
        };

        if (useVendorClientInfo && itemNumber?.vendorClientCustomerPartNumber) {
          itemPayload.customer_part_number = itemNumber?.vendorClientCustomerPartNumber;
        }
        payloads.push(itemPayload);
      } else {
        newVids = generateTags(quantityToSubmit);
        newVids.forEach((tag) => {
          const itemPayload = {
            item_number: itemNumberValue,
            tracker_serial: tag,
            name: tag
          };
          if (useVendorClientInfo && itemNumber?.vendorClientCustomerPartNumber) {
            itemPayload.customer_part_number = itemNumber?.vendorClientCustomerPartNumber;
          }
          payloads.push(itemPayload);
        });
      }

      // add target location and other custom fields to the payload
      payloads = payloads.map((eachPayload) => {
        if (targetLocationEnabled && locationValue) {
          eachPayload.initial_location = locationInfoMap[locationValue].identifier;
        }
        const newItemPayload = Object.keys(additionalProperties).reduce(
          (tempItemPayload, propName) => {
            const { id, type, numberOnly, metaDataOf } = formFields[propName] || {};

            if (id && additionalProperties[propName] && !metaDataOf) {
              switch (type) {
                case "date":
                case "datepicker":
                  // parse date if it is not parsed
                  const date =
                    typeof additionalProperties[propName] !== "number"
                      ? Date.parse(additionalProperties[propName])
                      : additionalProperties[propName];

                  tempItemPayload[id] = date;
                  break;
                default:
                  const value = numberOnly
                    ? parseFloat(additionalProperties[propName])
                    : additionalProperties[propName];

                  // If the input field was a seletor, get the value
                  if (id === "item_description") {
                    tempItemPayload.description = getFieldValue(value);
                  } else {
                    tempItemPayload[id] = getFieldValue(value);
                  }
                  break;
              }
            }
            return tempItemPayload;
          },

          { ...eachPayload }
        );
        if (customItemIdentifierTemplate) {
          const generatedIdentifier = generateCustomIdentifier(newItemPayload, customItemIdentifierTemplate);
          newItemPayload.item_identifier = generatedIdentifier;
        }
        return newItemPayload;
      });

      if (!getFieldValue(item.item_identifier) && generateIdentiferOptions.enabled) {
        const { separator, prefix: customerPrefix, paddedLength } = generateIdentiferOptions;
        const prefixList = [itemNumberValue];
        if (customerPrefix) {
          prefixList.unshift(customerPrefix);
        }

        const generatedIdentifiers = await appsyncItemClient.createIdentifiers(
          itemNumberValue,
          quantityToSubmit,
          prefixList.join(separator),
          separator,
          paddedLength
        );

        payloads = payloads.map((eachPayload, index) => {
          return {
            ...eachPayload,
            item_identifier: generatedIdentifiers[index]
          };
        });
      }

      // map payloads by location
      const locationId = locationInfoMap[locationValue]?.id || NO_LOCATION_ID;
      if (!payloadsByLocationIdMap[locationId]) {
        payloadsByLocationIdMap[locationId] = [];
      }
      payloadsByLocationIdMap[locationId].push(...payloads);
    }
    return Object.keys(payloadsByLocationIdMap).map((locationId) => {
      const payloads = payloadsByLocationIdMap[locationId];

      const newLocationId = locationId === NO_LOCATION_ID ? null : locationId;
      return { payloads, locationId: newLocationId };
    });
  };

  return {
    generatePayload
  };
};
