import React from "react";
import { ReactComponent as ChartSkeletonLoading } from "../../../../../../assets/icons/chart-skeleton-loading.svg";
import Style from "./ChartLoadingIcon.module.css";

export const ChartLoadingIcon = () => {
  return (
    <ChartSkeletonLoading
      data-testid="chart-skeleton-loading"
      className={Style.icon_color}
      width={350}
      height={350}
    />
  );
};
