import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu } from "mdbreact";
import { KeyboardArrowDown } from "@material-ui/icons";
import Style from "./TextFilterDropDown.module.css";

const TextFilterDropDown = ({ sortSchema = [], onClick, customStyle = null }) => {
  const [focusedId, setFocusedId] = useState("");
  const [selection, setSelection] = useState("");
  const [reverse, setReverse] = useState(false);
  const [oldSchema, setOldSchema] = useState([]);

  useEffect(() => {
    if (!focusedId || !focusedId?.length) {
      setSelection(sortSchema[0]?.value);
      return;
    }

    let x = null;
    sortSchema.forEach((each) => {
      if (each.id === focusedId) {
        x = each;
      }
    });
    setSelection(x?.value);
  }, [focusedId]);
  useEffect(() => {
    if (JSON.stringify(oldSchema) !== JSON.stringify(sortSchema)) {
      sortSchema.forEach((each) => {
        if (each.default) {
          setFocusedId(each.id);
          setReverse(false);
          if (each?.compareFunc) {
            onClick(each.compareFunc);
          }
        }
      });
    } else {
      sortSchema.forEach((each) => {
        if (each.id === focusedId && each?.compareFunc) {
          onClick((a, b) => {
            return reverse ? -each.compareFunc(a, b, reverse) : each.compareFunc(a, b, reverse);
          });
        }
      });
    }
    setOldSchema(sortSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortSchema]);
  return (
    <MDBDropdown
      style={{
        minWidth: "181px",
        marginRight: "10px"
      }}
    >
      <MDBDropdownToggle className={customStyle !== null ? Style.dropdown_toggle_filter_panel : Style.dropdown_toggle}>
        <div className={`${customStyle !== null ? Style.dropdown_container_filter_panel : Style.dropdown_container}`}>
          <div className={`${Style.dropdown_text_wrapper} ${Style.dropdown_text} ${Style.primary_color}`}>
            {selection}
          </div>
          <div className={Style.dropdown_text_wrapper}>
            <KeyboardArrowDown className={`${Style.dropdown_arrow} ${Style.primary_color}`} />
          </div>
        </div>
      </MDBDropdownToggle>
      <MDBDropdownMenu basic>
        {sortSchema &&
          sortSchema.map((each) => {
            return (
              <MDBDropdownItem
                id={each.id}
                key={each.id}
                className={`${Style.dropdown_item} ${focusedId === each.id && "active"}`}
                onClick={() => {
                  setFocusedId(each.id);
                  onClick(each);
                }}
                toggle
              >
                <div className={Style.title}>{each.value}</div>
              </MDBDropdownItem>
            );
          })}
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};

export default TextFilterDropDown;

TextFilterDropDown.defaultProps = {
  sortSchema: [],
  onClick: () => {}
};

TextFilterDropDown.propTypes = {
  sortSchema: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func
};
