import React from "react";
import { Route } from "react-router-dom";
import AssetMap from "../../features/asset-map";

export const AssetMapStackNavigation = () => {
  return (
    <Route
      exact
      path="/asset-map"
      component={AssetMap}
    />
  );
};
