import React, { useCallback, useState, useMemo, useEffect } from "react";
import usePackageTrackPageStateContext from "../../contexts/package-track-page-state-context";
import PackageList from "../package-list";
import LocationGroup from "./components/location-group";
import Style from "./LocationTree.module.css";
import { naturalSort } from "../../../../common/Utilities";

export const LocationTree = () => {
  const { selectedLocationId, applySideFilterFn, setIsLocationExpandedMap, filteredLocationTreeMap } =
    usePackageTrackPageStateContext();

  const [sortDirection, setSortDirection] = useState("asc");
  const [expandAll, setExpandAll] = useState(false);

  const finalFilteredLocationTreeMap = useMemo(() => {
    return Object.values(filteredLocationTreeMap)
      .filter((eachLocation) => {
        return applySideFilterFn(eachLocation);
      })
      .reduce((accumulator, eachLocation) => {
        return { ...accumulator, [eachLocation.id]: eachLocation };
      }, {});
  }, [filteredLocationTreeMap, applySideFilterFn]);

  const renderLocationTree = useCallback(
    (locationId) => {
      const childrenLocationList = Object.values(finalFilteredLocationTreeMap).filter((eachLocation) => {
        return eachLocation.directParentId === locationId;
      });

      return childrenLocationList?.length ? (
        naturalSort(childrenLocationList, "name", sortDirection).map((eachChildrenLocation) => {
          return (
            <LocationGroup
              key={eachChildrenLocation.id}
              location={eachChildrenLocation}
            >
              {eachChildrenLocation.childLocations?.length > 1 ? (
                renderLocationTree(eachChildrenLocation.id)
              ) : (
                <PackageList location={eachChildrenLocation} />
              )}
            </LocationGroup>
          );
        })
      ) : (
        <PackageList location={finalFilteredLocationTreeMap[selectedLocationId] || {}} />
      );
    },
    [finalFilteredLocationTreeMap, selectedLocationId, sortDirection, expandAll]
  );

  const isLeafLocation = useMemo(() => {
    return !Object.values(finalFilteredLocationTreeMap).filter((eachLocation) => {
      return eachLocation.directParentId === selectedLocationId;
    }).length;
  }, [finalFilteredLocationTreeMap, selectedLocationId]);

  useEffect(() => {
    const newIsLocationExpandedMap = Object.values(finalFilteredLocationTreeMap)
      .filter((eachLocation) => {
        if (eachLocation.directParentId === selectedLocationId) {
          return true;
        }
        if (eachLocation.childLocations.length === 1 && eachLocation.childLocations.includes(selectedLocationId)) {
          return true;
        }
        return false;
      })
      .reduce((accumulator, eachLocation) => {
        return { ...accumulator, [eachLocation.id]: isLeafLocation || expandAll };
      }, {});

    setIsLocationExpandedMap(newIsLocationExpandedMap);
  }, [expandAll, finalFilteredLocationTreeMap, selectedLocationId]);

  return (
    <div className={`${Style.flex_column}`}>
      {!isLeafLocation && (
        <div className={Style.buttons_group}>
          <button
            type="button"
            className={Style.clickable_text}
            onClick={() => {
              if (sortDirection === "asc") {
                setSortDirection("desc");
              } else {
                setSortDirection("asc");
              }
            }}
          >
            {`Sort by: ${sortDirection === "desc" ? "Z-A" : "A-Z"}`}
          </button>
          <button
            type="button"
            className={Style.clickable_text}
            onClick={() => {
              setExpandAll((current) => {
                return !current;
              });
            }}
          >
            {`${expandAll ? "Collapse" : "Expand"} all`}
          </button>
        </div>
      )}
      {renderLocationTree(selectedLocationId)}
    </div>
  );
};
