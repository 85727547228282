import React, { useMemo, useState } from "react";
import { SOLUTION_ID } from "../../../../constants/tabConfigConstants";
import Style from "./AssetBulkCreateTabFeature.module.css";
import { useAddAssetFeatureConfigContext } from "../../contexts/add-asset-feature-config-context";
import UploadCsvComponent from "../../../upload-csv-feature";
import { useAddAssetFeatureStateContext } from "../../contexts/add-asset-feature-state-context";
import ErrorResolveModal from "../../../upload-csv-feature/features/error-resolve-modal";

export const AssetBulkCreateTabFeature = () => {
  const [showErrorResolveModal, setShowErrorResolveModal] = useState(false);
  const { formFields, formSections } = useAddAssetFeatureConfigContext();
  const { formDataMap, setFormDataMap, submitStatus } = useAddAssetFeatureStateContext();

  const csvHeadersMap = useMemo(() => {
    const newCsvHeadersMap = Object.values(formFields).reduce((tempCsvHeadersMap, field) => {
      const clonedCsvHeadersMap = { ...tempCsvHeadersMap };
      const { id, isDisabled, isHidden, isReadOnly } = field;
      if (id && !isDisabled && !isHidden && !isReadOnly) {
        clonedCsvHeadersMap[id] = { ...field };
      }
      return clonedCsvHeadersMap;
    }, {});

    return newCsvHeadersMap;
  }, [formFields]);

  return (
    <div className={Style.bulk_create_container}>
      <div className={Style.upload_container}>
        <UploadCsvComponent
          key={String(submitStatus)}
          csvHeadersMap={csvHeadersMap}
          csvDataMap={formDataMap}
          onCsvDataChange={setFormDataMap}
          solution={SOLUTION_ID.ASSET}
          errorResolveModal={() => {
            return (
              <ErrorResolveModal
                show={showErrorResolveModal}
                onClose={() => {
                  setShowErrorResolveModal(false);
                }}
                formFields={formFields}
                formSections={formSections}
                formDataMap={formDataMap}
                setFormDataMap={setFormDataMap}
              />
            );
          }}
          onShowErrorResolveModal={setShowErrorResolveModal}
        />
      </div>
    </div>
  );
};
