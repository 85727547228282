import React, { useContext, useEffect, useState } from "react";
import { useXemelgoAppsyncClient } from "../../services/xemelgo-appsync-service";
import { useDisplayBannerContext } from "../DisplayBannerContext/DisplayBannerContext";

const UserLocationContext = React.createContext();

export const useUserLocationContext = () => {
  return useContext(UserLocationContext);
};

export const UserLocationContextProvider = ({ children }) => {
  const xemelgoClientAppSync = useXemelgoAppsyncClient();
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();

  const [isLoading, setIsLoading] = useState(true);
  const [userLocation, setUserLocation] = useState({});
  const [userInformation, setUserInformation] = useState({});
  const [userClient] = useState(xemelgoClientAppSync.getUserClient());

  const configureUserLocationsContext = async () => {
    try {
      const result = (await userClient.queryUser()).queryUser;

      const { location, ...newUserInfo } = { ...(result || {}) };
      setUserInformation(newUserInfo);
      if (location) {
        setUserLocation(location);
      }
    } catch {
      setShowBanner(true);
      setBannerHasError(true);
      setBannerTitle("An error has occured. Please contact Xemelgo Support for assistance.");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    configureUserLocationsContext();
  }, []);

  return (
    <UserLocationContext.Provider
      value={{
        isLoading,
        userLocation,
        setUserLocation,
        userInformation,
        configureUserLocationsContext
      }}
    >
      {children}
    </UserLocationContext.Provider>
  );
};
