import { ReactComponent as ExitFullScreenIcon } from "../../../../assets/icons/exit-fullscreen.svg";
import React, { useEffect } from "react";
import LocationOverview from "../location-overview";
import FreeTextSearchInput from "../../../../components/free-text-search-input";
import MultiSelectActionsBar from "../multi-select-actions-bar";
import WorkOrderListTable from "../work-order-list-table";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import Style from "./FullScreenOrderTrackPage.module.css";
import xemelgoStyle from "../../../../styles/variable";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import PropTypes from "prop-types";
import { useStore } from "react-hookstore";
import { fullScreenModeStore } from "../../../../state-managements/stores/full-screen-mode-store";

export const FullScreenOrderTrackPage = ({ fullScreenHandle, refreshInterval, refreshFn }) => {
  const { freeTextSearchInputText, setFreeTextSearchInputText, selectedOrders } = useWorkOrderTrackPageStateContext();
  const [, setFullScreenMode] = useStore(fullScreenModeStore);

  useEffect(() => {
    setFullScreenMode(true);
    return () => {
      setFullScreenMode(false);
    };
  });

  return (
    <div className={`${Style.full_screen_container}`}>
      <div className={`${Style.main_app_component}`}>
        <LocationOverview />
        <div className={`${Style.filter_bar_group}`}>
          <div className={Style.free_text_search_container}>
            <FreeTextSearchInput
              value={freeTextSearchInputText}
              onChangeText={setFreeTextSearchInputText}
            />
          </div>
          <ExitFullScreenIcon
            className={Style.full_screen_button}
            onClick={async () => {
              await fullScreenHandle.exit();
            }}
          />
          <CountdownCircleTimer
            size={24}
            strokeWidth={3.5}
            isPlaying={true}
            duration={refreshInterval / 1000}
            colors={xemelgoStyle.theme.APP_BLUE}
            onComplete={() => {
              refreshFn();
              return { shouldRepeat: true };
            }}
          />
        </div>
        {Object.values(selectedOrders).filter((value) => {
          return value;
        }).length !== 0 && <MultiSelectActionsBar />}
        <WorkOrderListTable />
      </div>
    </div>
  );
};

FullScreenOrderTrackPage.propTypes = {
  fullScreenHandle: PropTypes.shape({
    active: PropTypes.bool,
    enter: PropTypes.func,
    exit: PropTypes.func,
    node: PropTypes.shape({
      current: PropTypes.instanceOf(HTMLDivElement)
    })
  }).isRequired,
  refreshInterval: PropTypes.number.isRequired,
  refreshFn: PropTypes.func.isRequired
};
