import React, { useMemo } from "react";
import { STATUS_SEVERITY_MAP, STATUS_COLOR_MAP } from "../../../../data/constants";
import StatusColorLegend from "../../../../components/status-color-legend";

export const useMetricsGroupConfigBuilder = (filterConfig, metricData = {}) => {
  const getSubtitleTemplate = (templateId) => {
    switch (templateId) {
      case "statusColorLegend":
        return (
          <StatusColorLegend
            legendList={[
              { label: STATUS_SEVERITY_MAP.critical, color: STATUS_COLOR_MAP[STATUS_SEVERITY_MAP.critical] },
              { label: STATUS_SEVERITY_MAP.warning, color: STATUS_COLOR_MAP[STATUS_SEVERITY_MAP.warning] }
            ]}
          />
        );
      default:
        return null;
    }
  };

  const getSubtitle = (metricsGroup) => {
    const { groupSubtitle, groupSubtitleTemplate } = metricsGroup;
    return groupSubtitleTemplate ? getSubtitleTemplate(groupSubtitleTemplate) : groupSubtitle;
  };

  const getMetricLabelTemplate = (templateId) => {
    switch (templateId) {
      case "locationHealth":
        return `${metricData.category ? `${metricData.category} ` : ""}Health`;
      default:
        return null;
    }
  };

  const getMetricList = (metrics) => {
    return metrics.map(({ id, color, colorTemplate = {}, label, labelTemplate, defaultValue }) => {
      const tempColorCode = colorTemplate[metricData[id]] || color;
      const colorCode = STATUS_COLOR_MAP[STATUS_SEVERITY_MAP[tempColorCode]] || tempColorCode || "#343434";
      const metricsValue = Array.isArray(id)
        ? id.reduce((accumulator, eachId) => {
            return accumulator + metricData[eachId];
          }, 0)
        : metricData[id];
      return {
        label: labelTemplate ? getMetricLabelTemplate(labelTemplate) : label,
        value: (
          <div
            style={{
              color: colorCode
            }}
          >
            {metricsValue || defaultValue || 0}
          </div>
        )
      };
    });
  };
  const metricsGroupConfiguration = useMemo(() => {
    return filterConfig.map((eachMetricsGroup) => {
      return {
        groupTitle: eachMetricsGroup.groupTitle,
        groupSubtitle: getSubtitle(eachMetricsGroup),
        metrics: getMetricList(eachMetricsGroup.metrics)
      };
    });
  }, [filterConfig, metricData]);

  return { metricsGroupConfiguration };
};
