import { useReducer, useEffect, useRef, useState } from "react";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

export const ACTION_TYPE = {
  RESET: "reset",
  FETCHING: "fetching",
  FETCHING_NEXT_PAGE: "fetchingNextPage",
  PUBLISH_DATA: "publishData"
};

const initialState = {
  dataList: [],
  isLoading: true,
  hasNextPage: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.RESET:
      return { ...state, dataList: [], isLoading: initialState.isLoading, hasNextPage: initialState.hasNextPage };
    case ACTION_TYPE.FETCHING:
      return { ...state, dataList: [], isLoading: true, hasNextPage: false };
    case ACTION_TYPE.FETCHING_NEXT_PAGE:
      return { ...state, hasNextPage: true };
    case ACTION_TYPE.PUBLISH_DATA:
      return {
        ...state,
        isLoading: false,
        dataList: [...state.dataList, ...action.payload.dataList],
        hasNextPage: false
      };
    default:
      return state;
  }
};

export const useFetchItemHook = ({ locationIds: locationIdsParam, filterString: filterStringParam }) => {
  const xemelgoAppSyncClient = useXemelgoAppsyncClient();
  const xemelgoClient = useXemelgoClient();

  const [locationTreeMap, setLocationTreeMap] = useState(null);

  const startTimeRef = useRef();

  const [state, dispatch] = useReducer(reducer, {
    dataList: initialState.dataList,
    isLoading: initialState.isLoading,
    hasNextPage: initialState.hasNextPage
  });

  const fetchItemsByLocationIds = async (locationIds) => {
    const startTime = Date.now();
    startTimeRef.current = startTime;

    const inventoryAppSyncClient = xemelgoAppSyncClient.getInventoryClient();

    dispatch({ type: ACTION_TYPE.FETCHING });

    const response = await inventoryAppSyncClient.getItemsByLocationIds(locationIds, filterStringParam || "");

    if (startTimeRef.current === startTime) {
      dispatch({
        type: ACTION_TYPE.PUBLISH_DATA,
        payload: {
          dataList: response.items.map((eachItem) => {
            const { detectedAtId, consumedAtId, initialLocationId } = eachItem || {};
            return {
              ...eachItem,
              initialLocationName: locationTreeMap?.[initialLocationId]?.name,
              initialLocationIdentifier: locationTreeMap?.[initialLocationId]?.identifier,
              detectedAtLocationName: locationTreeMap?.[detectedAtId]?.name,
              detectedAtLocationIdentifier: locationTreeMap?.[detectedAtId]?.identifier,
              consumedAtLocationName: locationTreeMap?.[consumedAtId]?.name,
              consumedAtLocationIdentifier: locationTreeMap?.[consumedAtId]?.identifier
            };
          })
        }
      });
    }
    let { nextTokens } = response;
    while (
      startTimeRef.current === startTime &&
      nextTokens.filter((eachToken) => {
        return !!eachToken;
      }).length !== 0
    ) {
      dispatch({ type: ACTION_TYPE.FETCHING_NEXT_PAGE });

      const { items, nextTokens: newNextTokens } = await inventoryAppSyncClient.getItemsByLocationIds(
        locationIds,
        filterStringParam || "",
        nextTokens
      );
      if (startTimeRef.current === startTime) {
        dispatch({
          type: ACTION_TYPE.PUBLISH_DATA,
          payload: {
            dataList: items.map((eachItem) => {
              const { detectedAtId, consumedAtId, initialLocationId } = eachItem || {};
              return {
                ...eachItem,
                initialLocationName: locationTreeMap?.[initialLocationId]?.name,
                initialLocationIdentifier: locationTreeMap?.[initialLocationId]?.identifier,
                detectedAtLocationName: locationTreeMap?.[detectedAtId]?.name,
                detectedAtLocationIdentifier: locationTreeMap?.[detectedAtId]?.identifier,
                consumedAtLocationName: locationTreeMap?.[consumedAtId]?.name,
                consumedAtLocationIdentifier: locationTreeMap?.[consumedAtId]?.identifier
              };
            })
          }
        });
      }
      nextTokens = newNextTokens;
    }
  };

  useEffect(() => {
    const locationClient = xemelgoClient.getLocationClient();
    locationClient.getLocationTree([]).then((result) => {
      setLocationTreeMap(result);
    });
  }, []);

  useEffect(() => {
    if (locationTreeMap) {
      if (!locationIdsParam?.length) {
        dispatch({ type: ACTION_TYPE.PUBLISH_DATA, payload: { dataList: [] } });
      } else {
        fetchItemsByLocationIds(locationIdsParam);
      }
    }
  }, [locationIdsParam, filterStringParam, locationTreeMap]);

  return state;
};
