const theme = {
  FONT_SIZE_XSMALL: 12,
  FONT_SIZE_SMALL: 15,
  FONT_SIZE_BASE: 17,
  FONT_SIZE_LARGE: 20,
  FONT_SIZE_XLARGE: 25,
  APP_BLUE: "#4a90ff", // Main color of interactables/icons
  APP_LIGHTBLUE: "#E9F3FB", // Light version of the main color
  APP_BORDER_GREY: "#e2e2ea", // Line/border colors
  APP_OFFWHITE: "#f1f1f2",
  APP_BACKGROUND: "#fbfafa", // Background color of main and modal section
  APP_WHITE: "#ffffff", // Main background color
  APP_LIGHTBLACK: "#1B1C1E", // Ligher black for background color
  APP_LIGHTGREY: "#b2b2ba", // Bottom navigation icon/text color
  APP_PURPLE: "#62769E",
  APP_LIGHTPURPLE: "#E0E4EC",
  ASSET_PRIMARY: "#f66469",
  ASSET_SECONDARY: "#fef0f1",
  INVENTORY_PRIMARY: "#297ad9",
  INVENTORY_SECONDARY: "#d5e5f7",
  SHIPMENT_PRIMARY: "#FFBC00",
  SHIPMENT_SECONDARY: "#fef3d2",
  SHIPMENT_TEXT: "#c99001",
  ORDER_PRIMARY: "#008e73",
  ORDER_SECONDARY: "#d6eae6",
  PURCHASE_PRIMARY: "#8a4ee3",
  PURCHASE_SECONDARY: "#e2def8",
  PACKAGE_PRIMARY: "#ff9147",
  PACKAGE_SECONDARY: "#ffe7d7",
  NEUTRAL_PRIMARY: "#737880",
  NEUTRAL_SECONDARY: "#ebebed",
  STATUS_GREEN: "#4b9910",
  STATUS_LIGHTGREEN: "#edffef",
  STATUS_YELLOW: "#f6b044",
  STATUS_LIGHTYELLOW: "#FFFFDD",
  STATUS_ORANGE: "#e56201",
  STATUS_ORANGE_LIGHT: "#FDDFC8",
  STATUS_RED: "#ff3d46",
  STATUS_LIGHTRED: "#FFE2E3",
  TEXT_PRIMARY: "#343434",
  TEXT_SECONDARY: "#979799", // also used as shadow color (with .3 opacity)
  XEMELGO_BLUE: "#0D8CFF",
  XEMELGO_LIGHTBLUE: "#EDF5FF",
  XEMELGO_DEEP_BLUE: "#004FDB",
  FONT_BOLD: "Nunito-Bold",
  FONT_BOLD_ITALIC: "Nunito-BoldItalic",
  FONT_ITALIC: "Nunito-Italic",
  FONT_REGULAR: "Nunito-Regular",
  FONT_SEMIBOLD: "Nunito-SemiBold"
};
const xemelgoStyle = {
  theme,
  title: {
    fontFamily: theme.FONT_BOLD,
    fontSize: theme.FONT_SIZE_XLARGE,
    color: theme.TEXT_PRIMARY
  },
  subtitle: {
    fontFamily: theme.FONT_BOLD,
    fontSize: theme.FONT_SIZE_LARGE,
    color: theme.TEXT_PRIMARY
  },
  header: {
    fontFamily: theme.FONT_BOLD,
    fontSize: theme.FONT_SIZE_BASE,
    color: theme.TEXT_PRIMARY
  },
  body: {
    fontFamily: theme.FONT_REGULAR,
    fontSize: theme.FONT_SIZE_BASE,
    color: theme.TEXT_PRIMARY
  },
  bodySemibold: {
    fontFamily: theme.FONT_SEMIBOLD,
    fontSize: theme.FONT_SIZE_BASE,
    color: theme.TEXT_PRIMARY
  },
  bodyItalic: {
    fontFamily: theme.FONT_ITALIC,
    fontSize: theme.FONT_SIZE_BASE,
    color: theme.TEXT_PRIMARY
  },
  subtext: {
    fontFamily: theme.FONT_REGULAR,
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.TEXT_PRIMARY
  },
  subtextItalic: {
    fontFamily: theme.FONT_ITALIC,
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.TEXT_PRIMARY
  },
  subtextBold: {
    fontFamily: theme.FONT_BOLD,
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.TEXT_PRIMARY
  },
  subtextBoldItalic: {
    fontFamily: theme.FONT_BOLD_ITALIC,
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.TEXT_PRIMARY
  },
  subtextSemibold: {
    fontFamily: theme.FONT_SEMIBOLD,
    fontSize: theme.FONT_SIZE_SMALL,
    color: theme.TEXT_PRIMARY
  },
  regularText: {
    fontFamily: theme.FONT_REGULAR,
    fontSize: theme.FONT_SIZE_XSMALL,
    color: theme.TEXT_PRIMARY
  }
};

export default xemelgoStyle;
