import React, { useMemo } from "react";
import PropTypes from "prop-types";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import useSortableHeader from "../../../../hooks/use-sortable-header";
import { SORT_DIRECTION } from "../../../../data/constants";
import { STATUS_COLOR_MAP } from "../../data/constants";
import AssetMapStyle from "../../AssetMap.module.css";

export const LocationSelectionContent = ({ mapRef, locations, headers, onLocationClick, pageSize }) => {
  const totalAssetCount = locations.reduce((acc, eachLocation) => {
    return acc + eachLocation.totalCount;
  }, 0);

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [headers]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedLocations = useMemo(() => {
    return getSortedData(locations);
  }, [order, orderBy, locations]);

  return (
    <div className={AssetMapStyle.flex_column}>
      <p className={AssetMapStyle.total_assets_text}>
        Total Assets:
        <span className={AssetMapStyle.total_assets_count}>{totalAssetCount.toLocaleString()}</span>
      </p>
      <PaginatedList
        paginatorLocation="top"
        paginatorClassName={AssetMapStyle.paginator}
        numItemsPerPage={pageSize}
        header={headers}
        data={sortedLocations}
        headerContainerClassName={AssetMapStyle.table_header}
        renderHeader={(eachHeader) => {
          return getSortableHeader(eachHeader, {
            headerContainerClass: `${AssetMapStyle.table_column}`,
            className: AssetMapStyle.table_header_text
          });
        }}
        renderItem={(eachLocation, index) => {
          return (
            <div
              key={index}
              className={`${AssetMapStyle.flex_row} ${AssetMapStyle.table_item}`}
            >
              {headers.map((eachHeader) => {
                const value = eachLocation[eachHeader.id];
                return (
                  <div
                    key={eachHeader.id}
                    className={`${AssetMapStyle.flex_row} ${AssetMapStyle.table_column} ${AssetMapStyle.table_item_text}`}
                  >
                    {eachHeader.id === "name" ? (
                      <div
                        className={AssetMapStyle.table_item_text_active}
                        onClick={() => {
                          mapRef.current.selectLocation(eachLocation.id);
                          onLocationClick(eachLocation);
                        }}
                        onMouseEnter={() => {
                          mapRef.current.hoverLocation(eachLocation.id);
                        }}
                        onMouseLeave={() => {
                          mapRef.current.resetHover();
                        }}
                      >
                        {value}
                      </div>
                    ) : eachHeader.type === "status" ? (
                      <div
                        className={AssetMapStyle.status_pill}
                        style={{
                          backgroundColor: STATUS_COLOR_MAP[value]
                        }}
                      >
                        {(value || eachHeader.defaultValue || "-").toLocaleString()}
                      </div>
                    ) : (
                      (value || eachHeader.defaultValue || "-").toLocaleString()
                    )}
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

LocationSelectionContent.defaultProps = {
  locations: [],
  onLocationClick: () => {},
  pageSize: 10
};

LocationSelectionContent.propTypes = {
  mapRef: PropTypes.object,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      totalCount: PropTypes.number
    })
  ),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      defaultSort: PropTypes.bool,
      defaultDirection: PropTypes.string
    })
  ).isRequired,
  onLocationClick: PropTypes.func,
  pageSize: PropTypes.number
};
