import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const AssetMapConfigContext = React.createContext();

const APP_ID = "asset";
const ASSET_MAP_CONFIG = "assetMap";

const initialState = {
  initialLocationId: "",
  mapConfig: {
    locationsSourceTilesets: [],
    floorPlanTilesets: [],
    mapStyleURL: "mapbox://",
    initialViewStates: {
      bearing: 0,
      center: [-122.24292340935142, 47.20877788177407],
      zoom: 12
    },
    markersGeojson: null
  },
  switchSiteControl: {
    locationCategory: "Facility",
    enabled: false
  },
  locationCategories: ["all"],
  popupFilterControl: [
    {
      id: "itemType",
      optionTemplate: "itemType",
      title: "Asset Type",
      type: "checkboxes",
      collapsedLength: 8,
      itemTypeCategories: ["Asset"]
    }
  ],
  siteSelectionTableOptions: {
    headers: [
      {
        defaultDirection: "desc",
        defaultSort: true,
        id: "name",
        label: "Facility"
      },
      {
        id: "totalCount",
        label: "Total Assets"
      }
    ]
  },
  locationSelectionTableOptions: {
    headers: [
      {
        id: "name",
        label: "Location"
      },
      {
        defaultDirection: "desc",
        defaultSort: true,
        id: "totalCount",
        label: "Total Assets"
      }
    ],
    pageSize: 10
  },
  assetTableOptions: {
    headers: [
      {
        defaultDirection: "desc",
        defaultSort: true,
        id: "identifier",
        label: "Asset #"
      },
      {
        id: "itemTypeIdentifier",
        label: "Asset Type"
      },
      {
        id: "expiry_date",
        label: "Due Date",
        type: "date"
      }
    ]
  },
  assetTypeTableOptions: {
    headers: [
      {
        defaultDirection: "desc",
        defaultSort: true,
        id: "identifier",
        label: "Asset Type"
      },
      {
        id: "totalCount",
        label: "Total Count"
      }
    ]
  },
  showAssetStatus: true
};

export const useAssetMapConfigContext = () => {
  return useContext(AssetMapConfigContext);
};

export const AssetMapConfigContextProvider = ({ children }) => {
  const [initialLocationId, setInitialLocationId] = useState(initialState.initialLocationId);
  const [mapConfig, setMapConfig] = useState(initialState.mapConfig);
  const [switchSiteControl, setSwitchSiteControl] = useState(initialState.switchSiteControl);
  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [popupFilterControl, setPopupFilterControl] = useState(initialState.popupFilterControl);
  const [showAssetStatus, setShowAssetStatus] = useState(initialState.showAssetStatus);
  const [siteSelectionTableOptions, setSiteSelectionTableOptions] = useState(initialState.siteSelectionTableOptions);
  const [locationSelectionTableOptions, setLocationSelectionTableOptions] = useState(
    initialState.locationSelectionTableOptions
  );
  const [assetTableOptions, setAssetTableOptions] = useState(initialState.assetTableOptions);
  const [assetTypeTableOptions, setAssetTypeTableOptions] = useState(initialState.assetTypeTableOptions);
  const [isLoading, setIsLoading] = useState(true);

  const configProvider = useAppConfigProvider(APP_ID);

  useEffect(() => {
    setIsLoading(true);

    const assetMapConfig = _.cloneDeep(configProvider.getValue(ASSET_MAP_CONFIG, "object")) || {};
    const {
      mapConfig: newMapConfig = initialState.mapConfig,
      initialLocationId: newInitialLocationId = initialState.initialLocationId,
      popupFilterControl: newPopupFilterControl = initialState.popupFilterControl,
      switchSiteControl: newSwitchSiteControl = initialState.switchSiteControl,
      showAssetStatus: newShowAssetStatus = initialState.showAssetStatus,
      siteSelectionTableOptions: newSiteSelectionTableOptions = initialState.siteSelectionTableOptions,
      locationSelectionTableOptions: newLocationSelectionTableOptions = initialState.locationSelectionTableOptions,
      assetTableOptions: newAssetTableOptions = initialState.assetTableOptions,
      assetTypeTableOptions: newAssetTypeTableOptions = initialState.assetTypeTableOptions,
      locationCategories: newLocationCategories = initialState.locationCategories
    } = assetMapConfig;

    // backward compatibility support for old config for floorPlanTilesetURL
    if (newMapConfig.floorPlanTilesetURL) {
      newMapConfig.floorPlanTilesets = [
        ...(newMapConfig.floorPlanTilesets || []),
        { url: newMapConfig.floorPlanTilesetURL }
      ];
    }

    // backward compatibility support for old config for locationsSourceDatasetURL and locationsSourceDatasetName
    if (newMapConfig.locationsSourceDatasetName && newMapConfig.locationsSourceDatasetURL) {
      newMapConfig.locationsSourceTilesets = [
        ...(newMapConfig.locationsSourceTilesets || []),
        { url: newMapConfig.locationsSourceDatasetURL, name: newMapConfig.locationsSourceDatasetName }
      ];
    }

    setMapConfig(newMapConfig);
    setInitialLocationId(newShowAssetStatus ? newInitialLocationId : initialState.initialLocationId);
    setSwitchSiteControl(newSwitchSiteControl);
    setLocationCategories(newLocationCategories);
    setPopupFilterControl(newPopupFilterControl);
    setShowAssetStatus(newShowAssetStatus);
    setSiteSelectionTableOptions(newSiteSelectionTableOptions);
    setLocationSelectionTableOptions(newLocationSelectionTableOptions);
    setAssetTableOptions(newAssetTableOptions);
    setAssetTypeTableOptions(newAssetTypeTableOptions);
    setIsLoading(false);
  }, [configProvider]);

  return (
    <AssetMapConfigContext.Provider
      value={{
        initialLocationId,
        mapConfig,
        switchSiteControl,
        locationCategories,
        popupFilterControl,
        showAssetStatus,
        siteSelectionTableOptions,
        locationSelectionTableOptions,
        assetTableOptions,
        assetTypeTableOptions,
        isLoading
      }}
    >
      {children}
    </AssetMapConfigContext.Provider>
  );
};

AssetMapConfigContextProvider.defaultProps = {
  children: null
};

AssetMapConfigContextProvider.propTypes = {
  children: PropTypes.element
};
