import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DropDownInput } from "../../drop-down-input";
import { TextInput } from "../../text-input";
import "./style.css";
import { LabelWithOptionalText } from "../label-with-optional-text";

const getDefaultPayloadFromProperties = (properties) => {
  return properties.reduce((map, property) => {
    const clonedMap = { ...map };
    const { options, name } = property;
    const firstOption = options[0];
    const { key: propertyValue } = firstOption;
    clonedMap[name] = propertyValue;

    return clonedMap;
  }, {});
};

export const DropDownWithOptionControl = ({
  label,
  dropdownOptions,
  includeEmptyOption,
  onSelect,
  onUnselect,
  dropdownClassName,
  defaultSelectKey,
  onSubOptionSelect,
  autoPopulate,
  value
}) => {
  const [optionMap, setOptionMap] = useState({});
  const [selectedOptionProperties, setSelectedOptionProperties] = useState(null);
  const [subOptionPayload, setSubOptionPayload] = useState(null);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    const optMap = dropdownOptions.reduce((map, option) => {
      const clonedMap = { ...map };
      const { key } = option;
      clonedMap[key] = option;
      return clonedMap;
    }, {});

    if (!cancelled) {
      setOptionMap(optMap);
    }

    return cancelCallback;
  }, [dropdownOptions]);

  const onUnselectCallback = useCallback(() => {
    setSelectedOptionProperties(null);
    onUnselect();
  }, [onUnselect]);

  /**
   * Publish to external callback about sub option selected.
   * @type {(...args: any[]) => any}
   */
  const publishSubOptionsPayload = useCallback(
    (payload) => {
      Object.keys(payload).forEach((propId) => {
        const value = payload[propId];
        onSubOptionSelect(propId, value);
      });
    },
    [onSubOptionSelect]
  );

  /**
   * Main control select callback
   * @type {(...args: any[]) => any}
   */
  const onSelectCallback = useCallback(
    (key) => {
      const selectedOption = optionMap[key];
      const { properties: propertyMap = {} } = selectedOption;
      const filterProperties = Object.keys(propertyMap)
        .map((propId) => {
          return {
            ...propertyMap[propId],
            name: propId
          };
        })
        // only support property which has options field
        .filter((prop) => {
          return prop.options;
        });

      if (filterProperties.length > 0) {
        // automatically select first option on each of the subOption
        const defaultPayload = getDefaultPayloadFromProperties(filterProperties);
        publishSubOptionsPayload(defaultPayload);

        setSubOptionPayload(defaultPayload);
        setSelectedOptionProperties(filterProperties);
      } else {
        if (subOptionPayload) {
          const cleanOutSubOptionPayload = Object.keys(subOptionPayload).reduce((map, payloadKey) => {
            const clonedMap = { ...map };
            clonedMap[payloadKey] = null;
            return map;
          }, {});
          publishSubOptionsPayload(cleanOutSubOptionPayload);
        }
        setSubOptionPayload(null);
        setSelectedOptionProperties(null);
      }

      onSelect(key);
    },
    [optionMap, onSelect, publishSubOptionsPayload, subOptionPayload]
  );

  useEffect(() => {
    const selectedOption = optionMap[value];
    if (selectedOption) {
      const { properties: propertyMap = {} } = selectedOption;
      const filterProperties = Object.keys(propertyMap)
        .map((propId) => {
          return {
            ...propertyMap[propId],
            name: propId
          };
        })
        // only support property which has options field
        .filter((prop) => {
          return prop.options;
        });

      if (filterProperties.length > 0) {
        // automatically select first option on each of the subOption
        const defaultPayload = getDefaultPayloadFromProperties(filterProperties);
        publishSubOptionsPayload(defaultPayload);

        setSubOptionPayload(defaultPayload);
        setSelectedOptionProperties(filterProperties);
      } else {
        if (subOptionPayload) {
          const cleanOutSubOptionPayload = Object.keys(subOptionPayload).reduce((map, payloadKey) => {
            const clonedMap = { ...map };
            clonedMap[payloadKey] = null;
            return map;
          }, {});
          publishSubOptionsPayload(cleanOutSubOptionPayload);
        }
        setSubOptionPayload(null);
        setSelectedOptionProperties(null);
      }

      onSelect(value);
    }
  }, [value, optionMap]);

  const onSubOptionSelectCallback = useCallback(
    (key, value) => {
      const clonedPayload = { ...subOptionPayload };
      clonedPayload[key] = value;
      setSubOptionPayload(clonedPayload);
      publishSubOptionsPayload(clonedPayload);
    },
    [subOptionPayload, publishSubOptionsPayload]
  );
  return (
    <div className="drop-down-with-option-control">
      {autoPopulate ? (
        <TextInput
          label={label}
          incomingValue={value}
          autoPopulate={autoPopulate}
          inputClassName={dropdownClassName}
        />
      ) : (
        <DropDownInput
          dropdownOptions={dropdownOptions}
          onSelect={onSelectCallback}
          onUnselect={onUnselectCallback}
          dropdownClassName={dropdownClassName}
          includeEmptyOption={includeEmptyOption}
          label={label}
          defaultSelectedKey={value}
          autoPopulate={autoPopulate}
        />
      )}
      {selectedOptionProperties &&
        selectedOptionProperties.map((prop) => {
          const { options, displayName, name: propId } = prop;
          return (
            <DropDownInput
              dropdownOptions={options}
              onSelect={(key) => {
                return onSubOptionSelectCallback(propId, key);
              }}
              includeEmptyOption={false}
              defaultSelectedKey={subOptionPayload ? subOptionPayload[propId] : null}
              dropdownClassName="sub-options-dropdown"
              label={<LabelWithOptionalText label={displayName} />}
              key={propId}
            />
          );
        })}
    </div>
  );
};

DropDownWithOptionControl.defaultProps = {
  dropdownOptions: [],
  onSubOptionSelect: () => {},
  includeEmptyOption: true,
  onSelect: () => {},
  onUnselect: () => {},
  dropdownClassName: "",
  defaultSelectKey: null
};

DropDownWithOptionControl.propTypes = {
  dropdownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string,
          properties: PropTypes.objectOf(
            PropTypes.shape({
              displayName: PropTypes.string,
              options: PropTypes.arrayOf(
                PropTypes.shape({
                  key: PropTypes.string.isRequired,
                  value: PropTypes.string
                })
              )
            })
          )
        })
      )
    })
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  includeEmptyOption: PropTypes.bool,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  dropdownClassName: PropTypes.string,
  defaultSelectKey: PropTypes.string,
  onSubOptionSelect: PropTypes.func
};
