import React from "react";
import PropTypes from "prop-types";
import MetricsCard from "../metrics-card";
import Slides from "../slides";

export const MetricsCardSlides = ({ data, isLoading, ...restProps }) => {
  return (
    <Slides
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      data={data}
      renderSlideContent={({ id, primaryColor, secondaryColor, valueColor, ...restData }) => {
        return (
          <MetricsCard
            key={id}
            data={restData}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            valueColor={valueColor}
            isLoading={isLoading}
          />
        );
      }}
    />
  );
};

MetricsCardSlides.defaultProps = {
  data: [],
  numOfSlidesPerPage: 1,
  numOfRowsPerPage: 1,
  renderEmptySLideContent: null,
  isLoading: false
};

MetricsCardSlides.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      valueColor: PropTypes.string
    })
  ),
  numOfSlidesPerPage: PropTypes.number,
  numOfRowsPerPage: PropTypes.number,
  renderEmptySLideContent: PropTypes.func,
  isLoading: PropTypes.bool
};
