class TransferOrderDetails {
  constructor(identifier, status, statusList, timestamp, items, transferFrom, transferTo, tsProperties) {
    this.identifier = identifier;
    this.status = status;
    this.statusList = statusList;
    this.timestamp = timestamp;
    this.items = items ?? [];
    this.transferFrom = transferFrom;
    this.transferTo = transferTo;
    this.ts_properties = tsProperties ?? {};
  }
}

export { TransferOrderDetails };