import React from "react";
import useKioskConfigContext from "../../contexts/kiosk-config-context";
import { ACTION_OPTIONS_MAP } from "../../data/constants";
import CheckoutTable from "./features/checkout-table";
import TransferOrderActions from "./features/transfer-order-actions";

export const SelectedKioskFeature = () => {
  const { action } = useKioskConfigContext();

  switch (action?.actionType) {
    case ACTION_OPTIONS_MAP.PICKLIST_VERIFICATION:
    case ACTION_OPTIONS_MAP.ORDER_CREATION:
      return <TransferOrderActions actionType={action.actionType} />;
    default:
      return <CheckoutTable />;
  }
};
