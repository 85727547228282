import React from "react";
import Charts from "./features/charts";
import Style from "./LocationOverview.module.css";
import useInventoryTrackPageStateContext from "../../contexts/inventory-track-page-state-context";
import { DATA_VIEW_TYPE_MAP } from "../title-right-actions/features/change-data-view";
import Metrics from "./features/metrics";

export const LocationOverview = () => {
  const { selectedDataViewId } = useInventoryTrackPageStateContext();

  return (
    <div className={Style.container}>
      {(selectedDataViewId === DATA_VIEW_TYPE_MAP.graphs ||
        selectedDataViewId === DATA_VIEW_TYPE_MAP.graphsAndMetrics) && <Charts />}
      {(selectedDataViewId === DATA_VIEW_TYPE_MAP.metrics ||
        selectedDataViewId === DATA_VIEW_TYPE_MAP.graphsAndMetrics) && <Metrics />}
    </div>
  );
};
