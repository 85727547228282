import React, { useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import useAssetTrackPageConfigContext from "../../contexts/asset-track-page-config-context";
import useAssetTrackPageStateContext from "../../contexts/asset-track-page-state-context";
import { SideFilter, useSideFilterConfigBuilder } from "../../../side-filter";
import { TAB_OPTION_MAP } from "../../data/constants";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  ASSET_TRACK_PAGE_V2,
  ASSET_TRACK_PAGE_V2_STEPS
} from "../../../../constants/mixpanel-constant/assetTrackPageV2";
import Style from "./SideFilters.module.css";

export const SideFilters = () => {
  const { sideFilter } = useAssetTrackPageConfigContext();
  const { setApplySideFilterFn, selectedTab, sideFilterValue, setSideFilterValue } = useAssetTrackPageStateContext();
  const { sendMixPanelEvent } = useMixpanelContext();

  const assetFilter = useSideFilterConfigBuilder(sideFilter[TAB_OPTION_MAP.assetTab]);
  const assetTypeFilter = useSideFilterConfigBuilder(sideFilter[TAB_OPTION_MAP.assetTypeTab]);

  const [isTelemetrySent, setIsTelemetrySent] = useState(false);

  const currentFilter = useMemo(() => {
    return selectedTab === TAB_OPTION_MAP.assetTab ? assetFilter : assetTypeFilter;
  }, [selectedTab, assetFilter.initialFilterValue, assetTypeFilter.initialFilterValue]);

  useEffect(() => {
    setSideFilterValue(cloneDeep(currentFilter.initialFilterValue));
  }, [currentFilter.initialFilterValue, currentFilter.filterConfiguration, currentFilter.isLoading]);

  useEffect(() => {
    setApplySideFilterFn(() => {
      return (item) => {
        return currentFilter.applySideFilters(currentFilter.filterConfiguration, sideFilterValue, item);
      };
    });
  }, [sideFilterValue]);

  return (
    <div className={Style.side_column}>
      <SideFilter
        isLoading={currentFilter.isLoading}
        filterConfiguration={currentFilter.filterConfiguration}
        initialFilterValues={{ ...sideFilterValue }}
        onFilterChange={({ allValues }) => {
          if (!isTelemetrySent) {
            setIsTelemetrySent(true);
            sendMixPanelEvent(ASSET_TRACK_PAGE_V2, ASSET_TRACK_PAGE_V2_STEPS.SIDE_FILTER_APPLIED);
          }
          setSideFilterValue(allValues);
        }}
        showHideFiltersControl={false}
      />
    </div>
  );
};
