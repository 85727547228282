export const generateAddPayload = (scannedItemsMap, targetLocationIdentifier) => {
  const unconsumePayload = [];
  const itemsToCreate = [];
  const allTagsToAdd = [];
  Object.values(scannedItemsMap).forEach((item) => {
    const { vid, itemId, trackingSessionId, is_consumed } = item;
    const isConsumed = !trackingSessionId || is_consumed;

    if (!itemId) {
      itemsToCreate.push(item);
    } else if (isConsumed) {
      allTagsToAdd.push(vid);
      unconsumePayload.push({ id: itemId, trackingSessionId });
    }
  });

  const createPayload = itemsToCreate.map((tag) => {
    const { vid, type } = tag;
    if (vid) {
      allTagsToAdd.push(vid);
    }
    return {
      tracker_serial: vid,
      item_number: type.identifier,
      name: vid,
      category: "Inventory",
      class: "Inventory",
      initial_location: targetLocationIdentifier
    };
  });

  return { createPayload, unconsumePayload, allTagsToAdd };
};
