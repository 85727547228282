import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import PurchaseOrderTrackPageFeature from "../../features/purchase-order-track-page-feature/PurchaseOrderTrackPageFeature";

const PurchaseOrderApp = () => {
  return (
    <>
      <Route
        exact
        path="/purchase-order"
        component={PurchaseOrderTrackPageFeature}
      />
    </>
  );
};

export default PurchaseOrderApp;
