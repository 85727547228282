import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import usePackageTrackPageStateContext from "../../contexts/package-track-page-state-context";
import usePackageTrackPageConfigContext from "../../contexts/package-track-page-config-context";

export const useURLControlHook = () => {
  const { selectedLocationId, setSelectedLocationId } = usePackageTrackPageStateContext();
  const { initialURLParameters } = usePackageTrackPageConfigContext();
  const history = useHistory();
  const { search, pathname } = useLocation();

  useEffect(() => {
    if (!search) {
      history.replace(`${pathname}${initialURLParameters}`);
    }
  }, [initialURLParameters]);

  useEffect(() => {
    if (search) {
      const { locationId } = queryString.parse(search);
      if (locationId !== selectedLocationId) {
        setSelectedLocationId(locationId || undefined);
      }
    }
  }, [search]);
};
