import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ModalForm } from "../../components/modal-form";
import Styles from "./ModifyItemModal.module.css";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import PrintSection from "../../components/print-section";
import useGeneratePrintTagPayload from "../../hooks/use-generate-print-tag-payload";
import { PRINT_TYPES } from "../../data/constants";
import usePrintService from "../../hooks/use-print-service";
import MultiInputForm from "../../components/multi-input-form";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { APP_ID_TO_FEATURE_ID, APP_ID_TO_SOLUTION } from "../../constants/tabConfigConstants";
import "./ModifyItemModal.css";
import generateTags from "./utils/generate-tags";
import useMixpanelContext from "../../context/mixpanel-context";
import { MODIFY_ITEM_MODAL, MODIFY_ITEM_MODAL_STEPS } from "../../constants/mixpanel-constant/modifyItemModal";
import ModalFormFooter from "../../components/modal-form/components/modal-form-footer";
import { STATUS_OPTIONS } from "../../components/status-popup-component/data/constants";

export const ModifyItemModal = ({
  selectedItem,
  fieldsToUpdate,
  replaceTagEnabled,
  onCloseModal,
  modalTitle,
  appId
}) => {
  const SOLUTION = APP_ID_TO_SOLUTION[appId];
  const FEATURE_ID = APP_ID_TO_FEATURE_ID[appId];

  const configProvider = useAppConfigProvider(appId);
  const { sendMixPanelEvent } = useMixpanelContext();
  const client = useXemelgoClient();
  const itemClient = client.getItemClient();
  const sensorProfileClient = client.getSensorProfileClient();

  const { customTemplate, tagConfig } = configProvider.getValue(FEATURE_ID, "object") || {};
  const {
    printers,
    printerErrorMessage,
    selectedPrinterInfo,
    isLoading: isPrinterLoading,
    onSelectPrinter,
    print
  } = usePrintService(true, PRINT_TYPES.ZPL);

  const { generatePrintCommandBySolution } = useGeneratePrintTagPayload(SOLUTION, PRINT_TYPES.ZPL);

  const [bannerError, setBannerError] = useState(false);
  const [formSection, setFormSection] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleOnFieldsChange = (id, value) => {
    const newFormSection = { ...formSection };
    newFormSection[id].value = value;
    setFormSection(newFormSection);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let status;
    let newVid;

    try {
      // replace tag
      if (replaceTagEnabled) {
        newVid = generateTags();
        await sensorProfileClient.detachSensorProfileFromItem(selectedItem.sensor_profile_id, selectedItem.id);
        await sensorProfileClient.removeSensorProfile(selectedItem.sensor_profile_id, false);
        await sensorProfileClient.createAndAttachToItem(selectedItem.id, newVid);
      }
      // update item
      const propertiesToUpdateMap = Object.values(formSection).reduce((map, property) => {
        map[property.id] = property.value;
        return map;
      }, {});
      await itemClient.updateItemV2(selectedItem.id, propertiesToUpdateMap);

      // reprint
      const itemToPrint = {
        ...selectedItem,
        ...propertiesToUpdateMap,
        tracker_serial: replaceTagEnabled ? newVid : selectedItem.tracker_serial
      };
      const printCommand = await generatePrintCommandBySolution(itemToPrint, { customTemplate, tagConfig });
      await print(printCommand);
      status = STATUS_OPTIONS.SUCCESS;
      sendMixPanelEvent(MODIFY_ITEM_MODAL, MODIFY_ITEM_MODAL_STEPS.SUBMISSION_SUCCESS);
    } catch (e) {
      sendMixPanelEvent(MODIFY_ITEM_MODAL, MODIFY_ITEM_MODAL_STEPS.SUBMISSION_FAILED, {
        error_message: JSON.stringify(e)
      });
      status = STATUS_OPTIONS.ERROR;
      setBannerError(true);
    }
    setIsLoading(false);
    onCloseModal(status);
  };

  useEffect(() => {
    const newFormSection = fieldsToUpdate.reduce((map, field) => {
      const { id } = field;
      map[id] = field;
      return map;
    }, {});

    setFormSection(newFormSection);
    sendMixPanelEvent(MODIFY_ITEM_MODAL, MODIFY_ITEM_MODAL_STEPS.ENTRY);
  }, [fieldsToUpdate]);

  useEffect(() => {
    if (printerErrorMessage) {
      setBannerError(true);
    }
  }, [printerErrorMessage]);

  return (
    <ModalForm
      show
      prefix="reprint-modal"
      title={<div className={Styles.modal_title}>{modalTitle}</div>}
      className={Styles.modal}
      body={
        <div className={Styles.modal_body}>
          {bannerError && (
            <DisplayBanner
              bannerError
              bannerMessage={printerErrorMessage}
              onCloseBanner={() => {
                setBannerError(false);
              }}
            />
          )}
          <div>
            {`This will print and replace the tag for this ${SOLUTION}. Please confirm the details of the ${SOLUTION} and select your printer.`}
          </div>
          <br />
          <div className={Styles.sections_container}>
            <MultiInputForm
              formFields={Object.values(formSection)}
              onChange={handleOnFieldsChange}
              fieldContainerClassName={Styles.field_container}
            />
            <div>
              <PrintSection
                isPrintEnabled
                singlePrint
                printers={printers}
                selectedPrinter={selectedPrinterInfo}
                onSelectPrinter={onSelectPrinter}
                contentLoading={isPrinterLoading}
                printerError={printerErrorMessage}
                disableQuantity
                printerLabel="Select your printer:"
              />
            </div>
          </div>

          <br />
        </div>
      }
      footer={
        <ModalFormFooter
          onConfirm={onSubmit}
          onCancel={onCloseModal}
          confirmDisabled={
            isLoading ||
            (Object.keys(selectedPrinterInfo).length === 0 && !(selectedPrinterInfo?.message?.value === "Online"))
          }
          cancelDisabled={isLoading}
          confirmLabel="Print"
        />
      }
      centered
    />
  );
};

ModifyItemModal.defaultProps = {
  replaceTagEnabled: false,
  onCloseModal: () => {}
};

ModifyItemModal.propTypes = {
  selectedItem: PropTypes.shape({
    id: PropTypes.string,
    identifier: PropTypes.string,
    name: PropTypes.string,
    item_number: PropTypes.string,
    tracker_serial: PropTypes.string,
    sensor_profile_id: PropTypes.string
  }).isRequired,
  fieldsToUpdate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  replaceTagEnabled: PropTypes.bool,
  onCloseModal: PropTypes.func,
  modalTitle: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired
};
