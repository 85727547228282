/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { MetricsGrouping } from "./components/metrics-grouping/MetricsGrouping";
import { MetricsSlide } from "./components/metrics-slide/MetricsSlide";

export const Metrics = ({ title, subtitle, metrics, isLoading }) => {
  return (
    <MetricsGrouping
      title={title}
      subtitle={subtitle}
    >
      {metrics.map(({ label, value }, subIndex) => {
        return (
          <MetricsSlide
            key={`${label}_${subIndex}`}
            title={label}
            value={value}
            isLoading={isLoading}
          />
        );
      })}
    </MetricsGrouping>
  );
};

Metrics.defaultProps = {
  isLoading: false,
  subtitle: ""
};

Metrics.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string)),
  metrics: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool
};
