import React, { useRef, useMemo } from "react";
import MetricsCardSlides from "../../../../../../components/metrics-card-slides";
import useComponentDimensions from "../../../../../../hooks/use-component-dimensions";
import useInventoryTrackPageDataSourceContext from "../../../../contexts/inventory-track-page-data-source-context";
import useInventoryTrackPageConfigContext from "../../../../contexts/inventory-track-page-config-context";
import useInventoryTrackPageStateContext from "../../../../contexts/inventory-track-page-state-context";

import Style from "./Metrics.module.css";

export const Metrics = () => {
  const containerRef = useRef();

  const dimensions = useComponentDimensions(containerRef);

  const { currentLocationMetrics, itemTypesByLocationState, lotsByLocationState, isLocationTreeMapLoading } =
    useInventoryTrackPageDataSourceContext();

  const { selectedViewModeId, selectedViewTypeId } = useInventoryTrackPageStateContext();

  const { metricsControl } = useInventoryTrackPageConfigContext();

  const metricsData = useMemo(() => {
    return metricsControl
      .filter((eachChartControl) => {
        const { viewMode, tableViewType } = eachChartControl || {};
        if (viewMode && !viewMode.includes(selectedViewModeId) && !viewMode.includes("all")) {
          return false;
        }

        if (tableViewType && !tableViewType.includes(selectedViewTypeId) && !tableViewType.includes("all")) {
          return false;
        }

        return true;
      })
      .map((eachMetricsControl) => {
        return {
          ...eachMetricsControl,
          value:
            eachMetricsControl.value !== undefined
              ? eachMetricsControl.value
              : currentLocationMetrics[eachMetricsControl.id]
        };
      });
  }, [currentLocationMetrics, metricsControl, selectedViewModeId, selectedViewTypeId]);

  const numOfSlidesPerPage = useMemo(() => {
    if (dimensions.width >= 575) {
      return 6;
    }
    if (dimensions.width >= 475) {
      return 4;
    }
    return 2;
  }, [dimensions.width]);

  const isLoading = useMemo(() => {
    return (
      itemTypesByLocationState.isLoading ||
      itemTypesByLocationState.hasNextPage ||
      lotsByLocationState.isLoading ||
      lotsByLocationState.hasNextPage
    );
  }, [
    itemTypesByLocationState.isLoading,
    itemTypesByLocationState.hasNextPage,
    lotsByLocationState.isLoading,
    lotsByLocationState.hasNextPage
  ]);

  if (!metricsData.length) {
    return null;
  }

  return (
    <div className={Style.group}>
      <div
        className={Style.container}
        ref={containerRef}
      >
        <MetricsCardSlides
          id="inventory-track-page-metrics-slide"
          numOfSlidesPerPage={metricsData.length <= numOfSlidesPerPage ? metricsData.length : numOfSlidesPerPage}
          numOfRowsPerPage={metricsData.length <= numOfSlidesPerPage / 2 ? 1 : 2}
          data={metricsData}
          isLoading={isLocationTreeMapLoading || isLoading}
        />
      </div>
    </div>
  );
};
