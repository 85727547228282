export const MAP_LAYER_NAME = {
  floorPlanSource: "floor_plan_image",
  floorPlanLayer: "floor_plan_layer",
  locationsSource: "each_location",
  locationColorLayer: "color_status",
  locationOutlineLayer: "color_status_outline",
  locationSelectedOutlineShadowLayer: "selected_outline_shadow",
  locationSelectedOutlineLayer: "selected_outline",
  locationHoveredOutlineLayer: "priority_outline",
  markerSource: "marker_source",
  markerGroupLayer: "marker_group",
  markerGroupCountLayer: "marker_group_count",
  markersLayer: "markers_layer"
};
