import React, { useContext, useState, useEffect } from "react";
import { Amplify, Hub } from "aws-amplify";
import { useStore } from "react-hookstore";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { SessionStorageService } from "../../services/session-storage-service";
import configuration from "../../configuration.json";
import { UserProfile } from "../../domains/user-profile";
import { ClientConfigurationService } from "../../services/ClientConfigurationService";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import { configStore } from "../../state-managements/stores/config-store";
import { LocalCacheService } from "../../services/local-cache-service";
import { UserConfiguration } from "../../domains/user-configuration";

const FIVE_MINUTES = 1000 * 60 * 5;

const AuthenticationContext = React.createContext();

const initialState = {
  cognitoUser: null,
  isAuthenticated: false
};

export const useAuthenticationContext = () => {
  return useContext(AuthenticationContext);
};

export const AuthenticationContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(initialState.isAuthenticated);
  const [cognitoUser, setCognitoUser] = useState(initialState.cognitoUser);

  const [isLoading, setIsLoading] = useState(true);
  const [isEnvironmentSetupSuccessful, setIsEnvironmentSetupSuccessful] = useState(true);
  const [, setUserProfile] = useStore(userProfileStore);
  const [, setUserConfig] = useStore(configStore);

  const history = useHistory();

  const buildAmplifyConfig = (additionalEndpoints = [], appSyncEndpoint = {}) => {
    const config = {
      Auth: {
        ...configuration.AuthConfiguration
      },
      API: {
        endpoints: [
          ...configuration.ConfigurationService_endpoints.endpoints,
          ...configuration.ConfigurationService_endpoints_test.endpoints,
          ...additionalEndpoints
        ]
      },
      aws_appsync_graphqlEndpoint: appSyncEndpoint.endpoint,
      aws_appsync_region: appSyncEndpoint.region
    };
    return config;
  };

  const onAuthStateChange = async (authEvent) => {
    switch (authEvent.payload.event) {
      case "signIn":
      case "cognitoHostedUI":
        await setupUserEnvironment(await Amplify.Auth.currentAuthenticatedUser());
        break;
      case "tokenRefresh":
        await setupUserProfile(await Amplify.Auth.currentAuthenticatedUser());
        break;
      case "tokenRefresh_failure":
        if (authEvent.payload.data.code === "NotAuthorizedException") {
          await clearUserEnvironment();
        }
        break;
      case "cognitoHostedUI_failure":
      case "signOut":
        await clearUserEnvironment();
        break;
      default:
        break;
    }
  };

  const clearUserEnvironment = () => {
    setIsAuthenticated(false);
    setCognitoUser(null);
    setUserConfig(null);
    setUserProfile(null);
    LocalCacheService.clearAllCaches();
    SessionStorageService.clearAllCaches();
    history.replace("/login");
  };

  const setCustomerConfigCache = async () => {
    let finalUserConfig = LocalCacheService.loadUserConfig();
    if (!finalUserConfig) {
      const customerConfig = await ClientConfigurationService.getConfig();
      const rawConfig = customerConfig.getRawData();
      const newUserConfig = UserConfiguration.parse(rawConfig);
      if (rawConfig && rawConfig.webClient && rawConfig.webClient.testMode) {
        const customerTestConfig = await ClientConfigurationService.getTestConfig();
        const rawTestConfig = customerTestConfig.getRawData();
        const userTestConfig = UserConfiguration.parse(rawTestConfig);
        LocalCacheService.saveUserTestConfig(userTestConfig);
      }
      LocalCacheService.saveUserConfig(newUserConfig);
      finalUserConfig = newUserConfig;
    }

    const { config } = finalUserConfig || {};
    const { serviceMap } = config || {};
    const { xGraph = {}, appSync = {} } = serviceMap || {};
    const newAmplifyConfig = buildAmplifyConfig([xGraph, appSync], appSync);

    Amplify.configure(newAmplifyConfig);

    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: configuration.iot_core_configuration.region,
        aws_pubsub_endpoint: configuration.iot_core_configuration.endpoint
      })
    );

    setUserConfig(finalUserConfig);
    return finalUserConfig;
  };

  const setupUserProfile = async (newCognitoUser) => {
    const userProfile = UserProfile.parse(newCognitoUser);
    LocalCacheService.saveUserProfile(userProfile);
    setUserProfile(userProfile);
  };

  const setupUserEnvironment = async (newCognitoUser) => {
    setIsEnvironmentSetupSuccessful(true);
    try {
      if (!newCognitoUser || newCognitoUser instanceof Error) {
        clearUserEnvironment();
      } else {
        await newCognitoUser.refreshSession(newCognitoUser.signInUserSession.refreshToken, () => {
          // No operation; just avoiding console errors since API requires callback function
        });

        setupUserProfile(newCognitoUser);
        const newUserConfig = await setCustomerConfigCache();
        setCognitoUser(newCognitoUser);
        setIsAuthenticated(true);

        if (history.location.pathname === "/" || history.location.pathname === "/login") {
          const role = newCognitoUser?.attributes?.["custom:role"];
          const landingUrlControl = newUserConfig.getLandingPage();
          const landingUrl = landingUrlControl[role]?.url || landingUrlControl.default?.url;
          history.replace(landingUrl);
        }
      }
    } catch (err) {
      await signOut();
      setIsEnvironmentSetupSuccessful(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    Amplify.configure(buildAmplifyConfig());
    Amplify.Auth.currentAuthenticatedUser()
      .then(async (newCognitoUser) => {
        await setupUserEnvironment(newCognitoUser);
      })
      .catch(() => {
        clearUserEnvironment();
      })
      .finally(() => {
        setIsLoading(false);
      });

    Hub.listen("auth", onAuthStateChange);
    return () => {
      Hub.remove("auth");
    };
  }, []);
  const refreshTokenFn = async () => {
    try {
      await Amplify.Auth.currentSession();
    } catch (err) {
      clearUserEnvironment();
    }
  };

  useEffect(() => {
    if (isAuthenticated && cognitoUser) {
      const checkSessionInterval = setInterval(refreshTokenFn, FIVE_MINUTES);

      return () => {
        return clearInterval(checkSessionInterval);
      };
    }
    return () => {};
  }, [isAuthenticated, cognitoUser]);

  const signInWithEmailPassword = async (email, password) => {
    try {
      const newCognitoUser = await Amplify.Auth.signIn(email?.toLowerCase(), password);

      return newCognitoUser;
    } catch (err) {
      let newErrorMessage = "";

      switch (err.code) {
        case "NetworkError":
          newErrorMessage =
            "There is an issue with your internet connection. Please check your internet connection and try again.";
          break;
        case "UserNotFoundException":
        case "NotAuthorizedException":
        default:
          newErrorMessage = "Your email/password was incorrect.\n Please try again.";
          break;
      }
      setCognitoUser(initialState.cognitoUser);
      setIsAuthenticated(initialState.isAuthenticated);
      throw Error(newErrorMessage);
    }
  };

  const completeNewPassword = async (cognitoUserParam, newPassword) => {
    const newCognitoUser = await Amplify.Auth.completeNewPassword(
      cognitoUserParam,
      newPassword,
      cognitoUserParam.challengeParam.requiredAttributes
    );
    return newCognitoUser;
  };

  const forgotPassword = (email) => {
    return Amplify.Auth.forgotPassword(email);
  };

  const forgotPasswordVerification = (email, verificationCode, password) => {
    return Amplify.Auth.forgotPasswordSubmit(email, verificationCode, password);
  };

  const signOut = () => {
    return Amplify.Auth.signOut();
  };

  const federatedSignIn = (customProvider) => {
    return Amplify.Auth.federatedSignIn({ customProvider });
  };

  const changePassword = (oldPassword, newPassword) => {
    return Amplify.Auth.changePassword(cognitoUser, oldPassword, newPassword);
  };

  const updateAttributes = (payload) => {
    return Amplify.Auth.updateUserAttributes(cognitoUser, payload);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading,
        isEnvironmentSetupSuccessful,
        signInWithEmailPassword,
        completeNewPassword,
        forgotPassword,
        forgotPasswordVerification,
        federatedSignIn,
        refreshTokenFn,
        signOut,
        changePassword,
        updateAttributes,
        cognitoUser,
        isAuthenticated
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationContextProvider.defaultProps = {
  children: null
};

AuthenticationContextProvider.propTypes = {
  children: PropTypes.element
};
