import { XemelgoApp } from "./xemelgo-app";
import { AppConfigurationProvider } from "./app-configuration-provider";
import { BaseConfigurationProvider } from "./base-configuration-provider";

export class WebClientConfigurationProvider extends BaseConfigurationProvider {
  constructor(webClientConfiguration, baseModelDefinitionDomainMap) {
    super(webClientConfiguration);
    let configObj = webClientConfiguration;
    if (typeof webClientConfiguration === "string") {
      configObj = JSON.parse(webClientConfiguration);
    }

    const { apps: appIds = [], features, landingUrl, landingUrlControl = {}, appConfigurationMap = {} } = configObj;
    this.xemelgoApps = appIds.map((appId) => {
      return XemelgoApp.parse(appId);
    });
    this.features = features;
    this.landingUrl = landingUrl || "/";
    this.landingUrlControl = landingUrlControl;
    this.appConfigurationProviderMap = Object.keys(appConfigurationMap).reduce((map, appId) => {
      const newMap = { ...map };
      const appConfigObj = appConfigurationMap[appId];
      newMap[appId] = AppConfigurationProvider.parse(appId, appConfigObj, baseModelDefinitionDomainMap);

      return newMap;
    }, {});
  }

  static parse(webClientConfiguration, modelDefinitionDomainMap) {
    return new WebClientConfigurationProvider(webClientConfiguration, modelDefinitionDomainMap);
  }

  getXemelgoApps() {
    return this.xemelgoApps;
  }

  getAppConfigurationProvider(appId) {
    const appConfigProvider = this.appConfigurationProviderMap[appId];
    if (appConfigProvider) {
      return appConfigProvider;
    }

    // this case happens when app [raw] configuration is not provided so it was not registered initially, but when
    //  the app component or its feature components request it. When this happens, we inject an empty configuration object
    //  to the app config provider and register it.
    const provider = AppConfigurationProvider.parse(appId, {});
    this.appConfigurationProviderMap[appId] = provider;
    return provider;
  }

  getFeatureConfigurationProvider(appId, featureId, defaultConfiguration = {}) {
    const appConfigProvider = this.getAppConfigurationProvider(appId);
    return appConfigProvider.getFeatureConfigurationProvider(featureId, defaultConfiguration);
  }

  getLandingPage() {
    return {
      ...this.landingUrlControl,
      default: {
        url: this.landingUrl
      }
    };
  }
}
