export const generateLabelStatusCommand = (labelStatus, tagSizeInDots) => {
  const { x, y } = tagSizeInDots;
  const xCoordinate = x - 100;
  let yCoordinate = y - 150;
  let fontSize = 75;

  // 2x4 tag, 203 dpi
  if (x === 812 && y === 406) {
    fontSize = 50;
    yCoordinate = y - 100;
  }
  // vertical tag
  if (x < y) {
    return `^FO 150,150,1^A0N,100^TBR,1000,80^FD${labelStatus || ""}^FS`;
  }
  return `^FO${xCoordinate},${yCoordinate},1^A0N,${fontSize}^FD${labelStatus}^FS`;
};
