import { useRef } from "react";

export const useAvoidRacingAPIHelper = () => {
  const searchTimestampRef = useRef(null);

  const executeFn = async (actualFn) => {
    const newSearchTimestamp = Date.now();
    searchTimestampRef.current = newSearchTimestamp;
    const result = await actualFn;
    if (searchTimestampRef.current === newSearchTimestamp) {
      return { result };
    }
    return { canceled: true };
  };

  return executeFn;
};
