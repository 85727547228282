import React from "react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";
import Style from "./OnboardModalHeader.module.css";

const OnboardModalHeader = ({
  title,
  titleIconComponent,
  onClose,
  error, 
  headerMessage
}) => {
  return (
    <div className={Style.header_container}>
      <div className={Style.header_title_container}>
        <div className={Style.icon}>
          {typeof titleIconComponent === "function" ? titleIconComponent() : titleIconComponent}
        </div>
        <p className={Style.header_title}>{title}</p>
        <p className={`${Style.header_additional_message} ${error ? Style.error : Style.success}`}>{headerMessage}</p>
      </div>
      <div className={Style.close_button}>
        <div
          onClick={onClose}
          className={Style.close_icon}
        >
          <CloseRoundedIcon />
        </div>
      </div>
    </div>
  );
};

OnboardModalHeader.defaultProps = {
  error: false,
  headerMessage: ""
};

OnboardModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleIconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  headerMessage: PropTypes.string
};

export default OnboardModalHeader;
