import React from "react";
import { uniqBy } from "lodash";

import { FilterConfigurationBase } from "../../../side-filter/data/FilterConfigurationBase";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";

const all = "All";

export class ApprovalReportFilters extends FilterConfigurationBase {
  getConfiguration(configurationOptions) {
    return {
      id: "RemovedReportFilterConfiguration",
      categories: [
        {
          id: "ApprovalReportFilters",
          filters: [
            {
              id: "approvalReportLocation",
              title: "Location",
              renderComponent: (sideFilterValues, updateFilterValues) => {
                const locationOptions = getLocationOptions(
                  configurationOptions.reportList,
                  configurationOptions.limitToStates
                );

                return (
                  <SearchDropdown
                    options={locationOptions}
                    onItemSelected={(newItem) => {
                      updateFilterValues({ approvalLocation: newItem.id });
                    }}
                    selectedItem={locationOptions.find((option) => {
                      return option.id === sideFilterValues.approvalLocation;
                    })}
                    showIcon
                  />
                );
              },
              filterToApply: (sideFilterValues, { submittedAt }) => {
                return (
                  sideFilterValues.approvalLocation == null ||
                  sideFilterValues.approvalLocation === all ||
                  submittedAt?.[0]?.id === sideFilterValues.approvalLocation
                );
              }
            }
          ]
        }
      ]
    };
  }
}

const getLocationOptions = (reportList, limitToStates) => {
  const locationOptions = reportList
    .filter((report) => {
      return !limitToStates?.length || limitToStates.includes(report.state);
    })
    .map((report) => {
      if (!report.submittedAt?.[0]) {
        return undefined;
      }

      const location = report.submittedAt[0];

      return {
        id: location.id,
        label: location.name
      };
    })
    .filter((location) => {
      return location != null;
    });

  locationOptions.unshift({
    id: all,
    label: all
  });

  return uniqBy(locationOptions, "id");
};
