import xemelgoStyle from "../../../styles/variable";

export const READ_MODE_OPTIONS = {
  IOT_TOPIC: "iotTopic",
  TAGS_TABLE: "tagsTable"
};

export const EXCLUDED_ANALYTIC_VALUES_SET = new Set(["exit", "out-of-range"]);

export const READ_STATUS_OPTIONS = {
  NOT_READING: {
    id: "notReading",
    buttonLabel: "Start Scan",
    buttonIsDisabled: false,
    statusMessage: ""
  },
  STARTING: {
    id: "starting",
    buttonLabel: "Starting Scan",
    buttonIsDisabled: true,
    statusMessage: "Starting...",
    statusMessageColor: xemelgoStyle.theme.APP_BLUE
  },
  IN_PROGRESS: {
    id: "inProgress",
    buttonLabel: "Pause Scan",
    buttonIsDisabled: false,
    statusMessage: "Reading In Progress...",
    statusMessageColor: xemelgoStyle.theme.STATUS_ORANGE
  },
  PAUSING: {
    id: "pausing",
    buttonLabel: "Pausing Scan",
    buttonIsDisabled: true,
    statusMessage: "Pausing...",
    statusMessageColor: xemelgoStyle.theme.APP_BLUE
  },
  PAUSED: {
    id: "paused",
    buttonLabel: "Resume Scan",
    buttonIsDisabled: false,
    statusMessage: "Reading Paused",
    statusMessageColor: xemelgoStyle.theme.STATUS_RED
  },
  STOPPING: {
    id: "stopping",
    buttonLabel: "Stopping Scan",
    buttonIsDisabled: true,
    statusMessage: "",
    statusMessageColor: xemelgoStyle.theme.APP_BLUE
  }
};
