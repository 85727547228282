import React from "react";
import Style from "./GridCardContentGroupby.module.css";

const GridCardContentGroupby = ({ image, title = {}, contentList = [] }) => {
  return (
    <div className={Style.container}>
      <div className={Style.top_section}>{`${title.label ? `${title.label} ` : ""}${title.value || ""}`}</div>
      <div className={Style.bottom_section}>
        {image && (
          <img
            className={Style.image}
            src={image}
            alt="failed to load img"
          />
        )}
        <div className={Style.content_container}>
          {contentList?.map((eachContent) => {
            return (
              <p
                key={eachContent.label}
                className={Style.item}
              >
                {`${eachContent.label ? `${eachContent.label} ` : ""}${
                  eachContent.value || eachContent.value === 0 ? eachContent.value : eachContent.defaultValue || "-"
                }`}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GridCardContentGroupby;
