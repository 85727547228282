import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../../../../../../../../components/modal";

export const MissingScanModal = ({ title, onConfirm, onCancel }) => {
  return (
    <Modal
      title={title}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonText="Next"
    >
      <div>{`You have not scanned all the items yet. If you still wish to proceed, press "Next."`}</div>
    </Modal>
  );
};

MissingScanModal.propTypes = {
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
