/**
 * @typedef OfType
 * @property { string } id
 * @property { string } identifier
 * @property { string } description
 */

import { ITEM_SCAN_STATUS_MAP } from "../../features/selected-kiosk-feature/features/transfer-order-actions/data/constants";

/**
 * @typedef TrackItem
 * @property { string } id
 * @property { OfType[] } ofType
 */

/**
 * @typedef AssociatedWithTrackingSession
 * @property { string } id
 * @property { TrackItem[] } tracksItem
 */

/**
 * @typedef InventoryVerificationData
 * @property { string } vid
 * @property { AssociatedWithTrackingSession[] } associatedWithTrackingSession
 */

/**
 * @typedef ItemTypeReport { OfType}
 * @property { string[] } items
 * @property { string } id
 * @property { string } identifier
 * @property { string } description
 */

/**
 * @typedef ReducedItem
 * @property { string } vid
 * @property { string } itemId
 * @property { OfType } type
 */

/**
 *
 * @param items { ReducedItem[] }
 * @return { Object.<string, ItemTypeReport> }
 */
const generateItemTypeReport = (items) => {
  return items.reduce((map, item) => {
    const { type, firstSeenTimestamp } = item;
    const itemTypeKey = type.identifier;

    const clonedMap = { ...map };
    const itemTypeReport = clonedMap[itemTypeKey] || {
      ...type,
      epcToItemMap: {},
      quantityScanned: 0,
      lastUpdatedTimestamp: 0
    };

    itemTypeReport.epcToItemMap[item.vid] = {
      id: item.itemId,
      ...item,
      scanStatus: ITEM_SCAN_STATUS_MAP.SCANNED
    };
    itemTypeReport.lastUpdatedTimestamp =
      itemTypeReport.lastUpdatedTimestamp < firstSeenTimestamp
        ? firstSeenTimestamp
        : itemTypeReport.lastUpdatedTimestamp;
    itemTypeReport.quantityScanned = Object.keys(itemTypeReport.epcToItemMap).length;
    itemTypeReport.qty = itemTypeReport.quantityScanned;

    clonedMap[itemTypeKey] = itemTypeReport;

    return clonedMap;
  }, {});
};

/**
 *
 * @param { ReducedItem[] } items
 * @return {ItemTypeReport[] }
 */
export const analyzeItemsForItemTypeReport = (items) => {
  const itemTypeReportMap = generateItemTypeReport(items);
  /**
   * @type {ItemTypeReport[]}
   */
  const sorted = Object.values(itemTypeReportMap).sort((a, b) => {
    return a.lastUpdatedTimestamp - b.lastUpdatedTimestamp;
  });
  return sorted;
};
