import React, { useMemo } from "react";
import PropTypes from "prop-types";
import useWorkOrderTrackPageStateContext from "../../../../contexts/work-order-track-page-state-context";
import OrderTrackPageListTable from "../../../../components/order-track-page-list-table";
import Style from "./DefaultWOTable.module.css";
import { useStore } from "react-hookstore";
import { fullScreenModeStore } from "../../../../../../state-managements/stores/full-screen-mode-store";

export const DefaultWOTable = ({ dataList, ...restProps }) => {
  const { enabledMultiSelectOptions, selectedOrders, setSelectedOrders } = useWorkOrderTrackPageStateContext();
  const [fullScreenMode] = useStore(fullScreenModeStore);

  const checkedWorkOrderList = useMemo(() => {
    const finalWorkOrderList = [...dataList];
    finalWorkOrderList.forEach((order) => {
      order.isChecked = selectedOrders[order.id];
    });
    return finalWorkOrderList;
  }, [dataList, selectedOrders]);

  return (
    <OrderTrackPageListTable
      {...restProps}
      dataList={checkedWorkOrderList}
      selectable={!fullScreenMode && enabledMultiSelectOptions.length !== 0}
      onSelectClick={(ids, value) => {
        const newSelectedOrders = { ...selectedOrders };
        ids.forEach((id) => {
          newSelectedOrders[id] = value;
        });
        setSelectedOrders(newSelectedOrders);
      }}
      selectedRowClassName={Style.selected_table_item}
    />
  );
};

DefaultWOTable.defaultProps = {
  dataList: []
};

DefaultWOTable.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired }))
};
