/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PropTypes from "prop-types";
import Style from "./Breadcrumb.module.css";
import OptionalLink from "../optional-link/OptionalLink";

export const Breadcrumb = ({ dataList }) => {
  return (
    <ol className={`${Style.breadcrumb_container}`}>
      {dataList.map(({ title, path }, index) => {
        const isActive = index !== dataList.length - 1;
        return (
          <Fragment key={`${title}_${index}`}>
            <OptionalLink
              active={isActive && !!path}
              route={path}
            >
              <span className={`${Style.breadcrumb_item} ${isActive && Style.active}`}>{title}</span>
            </OptionalLink>
            {isActive && (
              <li className={Style.breadcrumb_item}>
                <NavigateNextIcon data-testid="next-icon" />
              </li>
            )}
          </Fragment>
        );
      })}
    </ol>
  );
};

Breadcrumb.defaultProps = {
  dataList: []
};

Breadcrumb.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      path: PropTypes.string
    })
  )
};
