import React, { useMemo } from "react";
import { FILTER_TYPE } from "../../../../components/filters";
import useInventoryTrackPageStateContext from "../../contexts/inventory-track-page-state-context";
import SelectedFiltersControl from "../../../../components/selected-filters-control";
import Style from "./FilterOverviewControl.module.css";

export const FilterOverviewControl = () => {
  const { sideFilterValue, setSideFilterValue, currentSideFilterControl } = useInventoryTrackPageStateContext();

  const filterConfigMap = useMemo(() => {
    return currentSideFilterControl.reduce((acc, filter) => {
      acc[filter.id] = filter;
      return acc;
    }, {});
  }, [currentSideFilterControl]);

  const selectedFilters = useMemo(() => {
    const newSelectedFilters = [];
    Object.keys(sideFilterValue || {}).forEach((key) => {
      if (!filterConfigMap[key]) {
        return;
      }

      const filter = sideFilterValue[key];

      switch (filterConfigMap[key].type) {
        case FILTER_TYPE.checkboxes:
          (filter || []).forEach((checkbox) => {
            if (checkbox.value) {
              newSelectedFilters.push({
                filterKey: key,
                id: checkbox.id,
                value: checkbox.label
              });
            }
          });
          break;
        case FILTER_TYPE.searchDropdown:
          if (filter && filter.label) {
            newSelectedFilters.push({
              filterKey: key,
              value: filter.label
            });
          }
          break;
        case FILTER_TYPE.dateRange:
          if (filter.startTime) {
            newSelectedFilters.push({
              filterKey: key,
              dateRangeOption: "after",
              value: `${filterConfigMap[key].title} after ${new Date(filter.startTime).toLocaleDateString()}`
            });
          }
          if (filter.endTime) {
            newSelectedFilters.push({
              filterKey: key,
              dateRangeOption: "before",
              value: `${filterConfigMap[key].title} before ${new Date(filter.endTime).toLocaleDateString()}`
            });
          }
          break;
        case FILTER_TYPE.input:
          if (filter?.length) {
            filter.forEach((eachFilterValue) => {
              newSelectedFilters.push({
                filterKey: key,
                value: eachFilterValue
              });
            });
          }
          break;
        default:
          break;
      }
    });

    return newSelectedFilters.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }, [sideFilterValue, filterConfigMap]);

  const handleClearFilters = () => {
    let newFilterValue = { ...sideFilterValue };

    selectedFilters.forEach((filter) => {
      newFilterValue = removeFilter(newFilterValue, filter);
    });

    setSideFilterValue(newFilterValue);
  };

  const removeFilter = (previousFilterValue, filter) => {
    const newFilterValue = { ...previousFilterValue };

    switch (filterConfigMap[filter.filterKey].type) {
      case FILTER_TYPE.checkboxes:
        newFilterValue[filter.filterKey] = newFilterValue[filter.filterKey].map((checkbox) => {
          if (checkbox.id === filter.id) {
            return {
              ...checkbox,
              value: false
            };
          }
          return checkbox;
        });

        if (
          !newFilterValue[filter.filterKey].filter(({ value }) => {
            return !!value;
          }).length
        ) {
          newFilterValue[filter.filterKey] = null;
        }

        break;
      case FILTER_TYPE.searchDropdown:
        newFilterValue[filter.filterKey] = null;
        break;
      case FILTER_TYPE.dateRange:
        if (filter.dateRangeOption === "after") {
          newFilterValue[filter.filterKey].startTime = null;
        } else if (filter.dateRangeOption === "before") {
          newFilterValue[filter.filterKey].endTime = null;
        }

        if (!newFilterValue[filter.filterKey]?.startTime && !newFilterValue[filter.filterKey]?.endTime) {
          newFilterValue[filter.filterKey] = null;
        }
        break;
      case FILTER_TYPE.input:
        newFilterValue[filter.filterKey] = newFilterValue[filter.filterKey].filter((eachInputString) => {
          return eachInputString !== filter.value;
        });
        break;
      default:
        break;
    }

    return Object.keys(newFilterValue).reduce((acc, eachFilterKey) => {
      if (newFilterValue[eachFilterKey]) {
        return { ...acc, [eachFilterKey]: newFilterValue[eachFilterKey] };
      }
      return acc;
    }, {});
  };

  if (selectedFilters.length === 0) {
    return null;
  }

  return (
    <div className={Style.container}>
      <SelectedFiltersControl
        filters={selectedFilters}
        onRemoveFilter={(filter) => {
          setSideFilterValue(removeFilter(sideFilterValue, filter));
        }}
        onClearFilters={handleClearFilters}
      />
    </div>
  );
};
