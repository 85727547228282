export const INVENTORY_ONBOARDING_V1 = "Inventory Onboarding V1";
export const INVENTORY_ONBOARDING_V1_STEPS = {
  ENTRY: "Entry",
  ITEM_ONBOARD_SUCCESS: "Item Onboard Success",
  ITEM_ONBOARD_FAILED: "Item Onboard Failed",
  ITEM_UPDATE_SUCCESS: "Item Update Success",
  ITEM_UPDATE_FAILED: "Item Update Failed",
  BULK_CREATE_ENTRY: "Bulk Create Entry",
  BULK_CREATE_SUCCESS: "Bulk Create Success",
  BULK_CREATE_FAILURE: "Bulk Create Failure"
};
