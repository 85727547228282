import React, { useMemo } from "react";
import PropTypes from "prop-types";
import useWorkOrderTrackPageDataSourceContext from "../../../../contexts/work-order-track-page-data-source-context";
import useWorkOrderTrackPageStateContext from "../../../../contexts/work-order-track-page-state-context";
import useWorkOrderTrackPageConfigContext from "../../../../contexts/work-order-track-page-config-context";
import OrderTrackPageListTable from "../../../../components/order-track-page-list-table";
import { getValueByType } from "../../../../components/order-track-page-list-table/OrderTrackPageListTable";
import Style from "./PrioritizedQueueWOTable.module.css";
import OptionalLink from "../../../../../../components/optional-link/OptionalLink";
import { interpolateColors, multipleSortComparison } from "../../../../../../utils";

const darkColor = "rgb(0, 79, 219)";
const lightColor = "rgb(144, 201, 253)";
const baseSortPreference = { id: "identifier" };

export const PrioritizedQueueWOTable = ({ headers, dataList, getTitleURLCallbackFn, ...restProps }) => {
  const { locationTreeMap } = useWorkOrderTrackPageDataSourceContext();
  const { selectedLocationId } = useWorkOrderTrackPageStateContext();
  const { settings } = useWorkOrderTrackPageConfigContext();

  const { sortPreferenceAttributeMap, sortPreferenceKey, valueOverrideMap } = useMemo(() => {
    const { optionControl } = settings || {};
    const { editQueuePriorityModal: editQueuePriorityModalOptionControl } = optionControl || {};
    return editQueuePriorityModalOptionControl;
  }, [settings]);

  const sortPreference = useMemo(() => {
    const { [sortPreferenceKey]: sortPreferenceAttributeListString } = locationTreeMap[selectedLocationId] || {};
    const attributeList = JSON.parse(sortPreferenceAttributeListString || "[]") || [];
    const newSortPreference = attributeList
      .map((eachAttribute) => {
        return sortPreferenceAttributeMap[eachAttribute];
      })
      .filter((eachSortPreference) => {
        return eachSortPreference;
      });
    newSortPreference.push(baseSortPreference);
    return newSortPreference;
  }, [locationTreeMap, selectedLocationId, sortPreferenceAttributeMap]);

  const finalWorkOrderList = useMemo(() => {
    return dataList.sort((a, b) => {
      const aValue = { ...a };
      const bValue = { ...b };

      Object.keys(valueOverrideMap || {}).forEach((attribute) => {
        aValue[attribute] =
          valueOverrideMap[attribute][aValue[attribute]] === undefined
            ? aValue[attribute]
            : valueOverrideMap[attribute][aValue[attribute]];
        bValue[attribute] =
          valueOverrideMap[attribute][bValue[attribute]] === undefined
            ? bValue[attribute]
            : valueOverrideMap[attribute][bValue[attribute]];
      });

      return multipleSortComparison(sortPreference)(aValue, bValue);
    });
  }, [dataList, headers, valueOverrideMap]);

  const colorMap = useMemo(() => {
    const groupsList = [
      ...new Set(
        finalWorkOrderList.map((eachData) => {
          return eachData[sortPreference[0].id];
        })
      )
    ];

    const colors = interpolateColors(darkColor, lightColor, groupsList.length);
    return groupsList.reduce((accumulator, eachGroup, index) => {
      return { ...accumulator, [eachGroup]: colors[index] };
    }, {});
  }, [finalWorkOrderList]);

  return (
    <OrderTrackPageListTable
      {...restProps}
      headers={headers}
      dataList={finalWorkOrderList}
      columnSortDisabled
      headerRowContainerClassName={Style.header_with_color_indicator}
      renderHeader={(eachHeader) => {
        return (
          <div
            key={eachHeader.id}
            className={`${Style.flex_row} ${Style.table_column}`}
          >
            <p className={Style.table_header_text}>{eachHeader.label}</p>
          </div>
        );
      }}
      renderItem={(eachItem) => {
        return (
          <div
            key={eachItem.identifier}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            <div
              className={Style.color_indicator}
              style={{ backgroundColor: colorMap[eachItem[sortPreference[0].id]] || "white" }}
            />
            {headers.map((eachHeader, subIndex) => {
              return (
                <OptionalLink
                  key={`${eachItem[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column}`}
                  active={!!eachHeader.isNavLink}
                  route={getTitleURLCallbackFn(eachItem)}
                >
                  <div
                    className={`${Style.table_item_text} ${eachHeader.isNavLink && Style.table_item_text_clickable}`}
                  >
                    {getValueByType(eachItem[eachHeader.id], eachHeader.type, eachHeader.timeFormat)}
                  </div>
                </OptionalLink>
              );
            })}
          </div>
        );
      }}
    />
  );
};

PrioritizedQueueWOTable.defaultProps = {
  dataList: [],
  headers: [],
  getTitleURLCallbackFn: () => {
    return "";
  }
};

PrioritizedQueueWOTable.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  headers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  getTitleURLCallbackFn: PropTypes.func
};
