import React, { useContext, useState } from "react";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";

const initialState = {
  formDataMap: {},
  isSubmitDisabled: true,
  submitStatus: STATUS_OPTIONS.NONE,
  printerInfo: {},
  showErrorResolveModal: false,
  isSaveLastSubmittedEntriesChecked: false
};

const AddAssetFeatureStateContext = React.createContext();

export const useAddAssetFeatureStateContext = () => {
  return useContext(AddAssetFeatureStateContext);
};

export const AddAssetFeatureStateContextProvider = ({ children }) => {
  const [showErrorResolveModal, setShowErrorResolveModal] = useState(initialState.showErrorResolveModal);
  const [formDataMap, setFormDataMap] = useState(initialState.formDataMap);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(initialState.isSubmitDisabled);
  const [submitStatus, setSubmitStatus] = useState(initialState.submitStatus);
  const [isSaveLastSubmittedEntriesChecked, setIsSaveLastSubmittedEntriesChecked] = useState(
    initialState.isSaveLastSubmittedEntriesChecked
  );

  return (
    <AddAssetFeatureStateContext.Provider
      value={{
        formDataMap,
        setFormDataMap,
        isSubmitDisabled,
        setIsSubmitDisabled,
        submitStatus,
        setSubmitStatus,
        showErrorResolveModal,
        setShowErrorResolveModal,
        isSaveLastSubmittedEntriesChecked,
        setIsSaveLastSubmittedEntriesChecked
      }}
    >
      {children}
    </AddAssetFeatureStateContext.Provider>
  );
};
